export class StringService {

  leftpad(str: string, len: number, ch: string): string {
    str = String(str || '')
    let i = -1
    len = len - str.length
    while (++i < len) {
      str = ch + str
    }
    return str
  }

  rightpad(str: string, len: number, ch: string): string {
    str = String(str || '')
    let i = -1
    len = len - str.length
    while (++i < len) {
      str = str + ch
    }
    return str
  }

  removeAllWhiteSpaces(str: string): string {
    if (str) {
      return str.replace(/\s+/g, '')
    }
    return str
  }

  removeLeadingZeros(str: string): string {
    if (str) {
      return str.replace(/^0+/, '')
    }
    return str
  }

  normalizeWhitespace(str: string): string {
    if (str) {
      return str.replace(/\s+/g, ' ')
    }
    return str
  }

  reverseString(str: string): string {
    try {
      return str.split('').reverse().join('')
    } catch (err) {
      console.log(str, typeof str)
      throw err
    }
  }

  convertToUrlSafeAsciiString(str: string): string {
    if (str) {
      return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g, ' ').replace(/\s/g, '_')
    }
    return str
  }

  newStringToOldString(oldStr: string, newStr: string): string | null {
    const newStrRemoved = this.removeStringFromString(oldStr, newStr) || '' // Remove existing string part
    const result = (newStrRemoved + ' ' + (newStr || '')).trim()
    return this.normalizeWhitespace(result) || null
  }

  removeStringFromString(removeFrom: string, remove: string): string | null {
    const removeNormed = this.normalizeWhitespace(remove ?? '').trim()
    const removeFromNormed = this.normalizeWhitespace(removeFrom ?? '').trim()
    const removalDone = removeFromNormed.replace(removeNormed, '').trim()
    return this.normalizeWhitespace(removalDone) || null
  }

  isString(suspect: any): boolean {
    return typeof suspect === 'string' || suspect instanceof String
  }

  splitEvery(str: string, every: number): string[] {
    if (every < 1 || isNaN(every)) {
      throw new Error('Split every character count is out of bounds: ' + every)
    }
    if (!str) {
      return []
    }
    if (str.length <= every) {
      return [str]
    }
    let currIndex = 0
    const result = []
    while (currIndex < str.length) {
      const splitted = str.substring(currIndex, currIndex + every).trim()
      if (splitted) {
        result.push(splitted)
      }
      currIndex += every
    }
    return result
  }

  capitaliseAllWords(str: string): string {
    if (!str) {
      return str
    }
    const normed = this.normalizeWhitespace(str).trim()
    const parts: string[] = normed.split(' ')

    return parts.map(part => this.upperCaseFirstChar(part)).join(' ')
  }

  upperCaseFirstChar(str: string) {
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
  }
}
