<div #esikatseluContainer style="text-align: left; width: 100%; overflow: visible; position: relative;">
  <div lemon-loading *ngIf="!(lataaminenValmisObservable | async) && naytaLatausmerkinta" [show]="true"></div>
  <div [style.height]="spacerHeightStyleObservable | async" style="width: 100%;"></div>
  <div [style.visibility]="(lataaminenValmisObservable | async) ? 'visible' : 'hidden'" [style.left]="laskuLeftCoordinate + 'px'" style="width: 595px; transform-origin: 0 0; position: absolute; top: 2px;" class="mat-elevation-z4" [style.transform]="transformStyleObservable | async">
    <div class="kokopdflasku" #kokopdflasku style="position:relative; width: 100%; line-height: initial;">

      <div *ngIf="ulkoinenLasku" style="color:grey; padding: 10px;">{{ 'pdf-perinteinen.ulkoinen-lasku' | translatel: kieli }}{{ ulkoinenLasku.nimi }}</div>

      <div #ylaosa>
        <table style="width: 100%;">
          <tr>
            <td [class.logocontainer]="asetukset?.base64Logo" style="width: 54.761904761904724%;">
              <div *ngIf="asetukset?.base64Logo" style="font-weight: 300; font-size: 10px;">
                <img id="logo" [src]="'data:image/jpg;base64,' + asetukset?.base64Logo" /><br />
                <span *ngIf="!asetukset?.piilotaYrityksenNimiLaskulla">{{asetukset?.nimi}}</span>
              </div>
              <div class="otsikko" *ngIf="!asetukset?.base64Logo" style="font-weight: 600; font-size: 16px;">
                {{asetukset?.nimi}}
              </div>
            </td>
            <td class="rightMargin" rowspan="2">
              <div style="font-weight: 800; font-size: 12px; margin-top: 30px; padding-bottom: 5px;">{{otsikko}}</div>
              <div class="viiva"></div>
              <table id="laskutiedot" style="padding-top: 9px;">
                <tr>
                  <td>{{'pdf-perinteinen.laskunnro' | translatel: kieli}}</td>
                  <td>{{laskunumero}}</td>
                </tr>
                <tr *ngIf="!mitatoity">
                  <td>{{'pdf-perinteinen.viitenro' | translatel: kieli}}</td>
                  <td>{{viitenumero}}</td>
                </tr>
                <tr>
                  <td>{{'pdf-perinteinen.laskunpvm' | translatel: kieli}}</td>
                  <td>{{ pvm }}</td>
                </tr>
                <tr>
                  <td>{{'pdf-perinteinen.toimituspvm' | translatel: kieli}}</td>
                  <td>{{ toimituspvm }}</td>
                </tr>
                <tr *ngIf="!mitatoity">
                  <td>{{'pdf-perinteinen.erapaiva' | translatel: kieli}}</td>
                  <td style="font-weight: 800;">{{erapaiva}}</td>
                </tr>
                <tr *ngIf="!muistutus && !mitatoity">
                  <td>{{'pdf-perinteinen.maksuehto' | translatel: kieli}}</td>
                  <td>{{maksuaikaa}} {{'pdf-perinteinen.pvnetto' | translatel: kieli}}</td>
                </tr>
                <tr *ngIf="!mitatoity">
                  <td>{{'pdf-perinteinen.viivastyskorko' | translatel: kieli}}</td>
                  <td>{{viivastysprosentti}}</td>
                </tr>
              </table>
              <div *ngIf="kasiteltava?.viitteenne" style="margin-top: 5px; font-size: 12px;">
                <span style="font-weight: 800;">{{('lasku.esikatselu.viitteenne' | translatel: kieli) + ': '}}</span> {{kasiteltava?.viitteenne}}
              </div>

              <div *ngIf="muistutushuomautus" style="margin-top: 5px; font-size: 12px; font-weight: 800;">
                {{muistutushuomautus}}
              </div>

            </td>
          </tr>
          <tr>
            <td class="leftMargin">
              <div style="font-weight: 600; font-size: 13px; line-height: 16px; padding-top: 25px;">
                {{ kasiteltava?.asiakas?.nimi }}<br />
                <ng-container *ngIf="kasiteltava?.asiakas?.ytunnus">{{ kasiteltava?.asiakas?.ytunnus }}<br /></ng-container>
                {{ kasiteltava?.asiakas?.katuosoite }}<br />
                {{ kasiteltava?.asiakas?.postinro }} {{ kasiteltava?.asiakas?.postitmp }}<br />
                {{ maa }}
              </div>
            </td>
          </tr>
        </table>
        <div style="height: 20px;"></div>
        <div class="kokosivunviiva"></div>
        <div style="height: 10px;"></div>
        <div *ngIf="lisatiedot" class="leftMargin rightMargin" style="padding-top: 10px; font-size: 12px; padding-bottom: 20px;" [innerHtml]="lisatiedot"></div>
      </div>

      <div [style.min-height]="tuotteetMinimumHeightStyleObservable | async">
        <table id="tuotteet" class="leftMargin rightMargin">
          <thead>
            <tr>
              <th class="nimi">{{'pdf-perinteinen.tuote' | translatel: kieli}}</th>
              <th class="maara">{{'pdf-perinteinen.maara' | translatel: kieli}}</th>
              <th class="hinta">{{'pdf-perinteinen.yksikkohinta' | translatel: kieli}}</th>
              <th class="alv">{{'pdf-perinteinen.alv' | translatel: kieli}}</th>
              <th class="ale">{{'pdf-perinteinen.ale' | translatel: kieli}}</th>
              <th class="yhteensa">{{'pdf-perinteinen.yhteensa' | translatel: kieli}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tuote of kasiteltava?.tuotteet">
              <td class="nimi">{{tuote?.tuote?.nimi}}</td>
              <td class="maara">{{tuote?.maara | ldecimall: 4 : kieli}}</td>
              <td class="hinta">{{tuote?.hinta | lmoneyl: kasiteltava?.valuutta : kieli}}</td>
              <td class="alv">{{ tuote?.alv?.prosentti | ldecimall: 2 : kieli }} %</td>
              <td class="ale">{{tuote?.ale | ldecimall: 4 : kieli}}{{ tuote?.ale ? ' %' : ''}}</td>
              <td class="yhteensa">{{shared.annaLaskurivinYhteensaLaskunTuotteelle(tuote) | lmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
          </tbody>
          <tfoot style="text-align: right;">
            <tr>
              <td colspan="4">{{'pdf-perinteinen.ilman-alv' | translatel: kieli}}</td>
              <td colspan="2">{{laskunSummat?.yhteensaIlmanAlv | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
            <tr *ngFor="let erittelynOsa of alvErittely" class="alverittely">
              <td colspan="4">{{(erittelynOsa.alvKanta.prosentti | ldecimall : 2 : kieli) + '%'}} {{'pdf-perinteinen.yhteensa-pienella' | translatel: kieli}}</td>
              <td colspan="2">{{erittelynOsa.alvinSumma | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
            <tr>
              <td colspan="4">{{'pdf-perinteinen.alv-yhteensa' | translatel: kieli}}</td>
              <td colspan="2">{{laskunSummat?.yhteensaAlv | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
            <tr *ngIf="muistutus && maksettuAiemmin && !maksettuAiemmin.eq('0')">
              <td colspan="4">{{'pdf-perinteinen.yhteensa-ennen-aiemmin-maksettua' | translatel: kieli}}</td>
              <td colspan="2">{{laskunSummat?.yhteensaKaikki | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
            <tr *ngIf="muistutus && maksettuAiemmin && !maksettuAiemmin.eq('0')">
              <td colspan="4">{{'pdf-perinteinen.maksettu-aiemmin' | translatel: kieli}}</td>
              <td colspan="2">{{maksettuAiemmin | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
            <tr>
              <td colspan="4">{{'pdf-perinteinen.maksettava-yhteensa' | translatel: kieli}}</td>
              <td colspan="2">{{maksettavaaYhteensa | lbigmoneyl: kasiteltava?.valuutta : kieli}}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div #alaosa>
        <div class="leftMargin rightMargin" *ngIf="alvHuomautus" style="padding-top: 3em; padding-bottom: .5em; font-size: 11px;">
          {{alvHuomautus}}
        </div>
        <div class="leftMargin rightMargin" style="font-size: 9px; line-height: 11px; padding-top: 2.5em;" *ngIf="asetukset?.ohjeet">
          {{ asetukset?.ohjeet }}
        </div>

        <div style="height: 2em;"></div>

        <table style="width: 100%;">
          <tr style="font-size: 8px; font-weight: 600;">
            <td class="leftMarginWidth"></td>
            <td class="pienenTiedonLeveysEnnenLaatikkoa">{{'pdf-perinteinen.yritys' | translatel: kieli}}</td>
            <td class="pienenTiedonLeveysEnnenLaatikkoa">{{'pdf-perinteinen.postiosoite' | translatel: kieli}}</td>
            <td class="pienenTiedonLeveysEnnenLaatikkoa">{{'pdf-perinteinen.yhteystiedot' | translatel: kieli}}</td>
          </tr>
          <tr style="font-size: 8px; font-weight: 300;">
            <td></td>
            <td>{{asetukset?.nimi}}</td>
            <td>{{asetukset?.katuosoite}}</td>
            <td>{{asetukset?.puhelin}}</td>
          </tr>
          <tr style="font-size: 8px; font-weight: 300;">
            <td></td>
            <td>{{muotoiltuYtunnus}}</td>
            <td>{{asetukset?.postinro}} {{asetukset?.postitmp}}, {{laskuttajanMaa}}</td>
            <td>{{asetukset?.replyto}}</td>
          </tr>
        </table>

        <div id="pdfpreviewaalaosa" style="width: 100%; padding-top: 7px; padding-top: 5px; border-top: 1px dashed lightgrey; position: relative;">
          <table id="pdfpreviewaalaosaloppu">
            <tr [style.height.px]=" annaRelatiivinenKorkeusmitta(16.933333333333)">
              <td class="e" style="font-size: 8px; font-weight: 600;"><br />{{'pdf-perinteinen.saajan-tilinro' | translatel: kieli}}</td>
              <td class="o t" style="font-size: 8px; font-weight: 600;">IBAN<br />
                <ng-container *ngIf="!mitatoity">{{asetukset?.pankki}} {{iban}}</ng-container>
              </td>
              <td class="" style="font-size: 8px; font-weight: 600; position: relative;" colspan="4" rowspan="3">BIC<br />
                <ng-container *ngIf="!mitatoity">{{asetukset?.bic}}</ng-container>
                <div style="position: absolute; top: 5px; right: 5px;" lemon-qrcode [qrcodeValue]="qrkoodi" *ngIf="qrkoodi && !mitatoity" [style.width.px]="annaRelatiivinenKorkeusmitta(35)" [style.height.px]="annaRelatiivinenKorkeusmitta(35)"></div>
              </td>
            </tr>
            <tr [style.height.px]="annaRelatiivinenKorkeusmitta(14.8166666666)">
              <td class="y e" style="font-size: 8px; font-weight: 600;">{{'pdf-perinteinen.saaja' | translatel: kieli}}</td>
              <td class="y o t" style="font-size: 11px; font-weight: 300;">
                <ng-container *ngIf="!mitatoity">{{ asetukset?.nimi }}</ng-container>
              </td>
            </tr>
            <tr>
              <td class="e" [style.height.px]="annaRelatiivinenKorkeusmitta(33.86666666667)" rowspan="1" class="y" style="font-size: 8px; font-weight: 600; border-right: 1px solid transparent;">
                {{'pdf-perinteinen.nimi-osoite' | translatel: kieli}}
              </td>
              <td [style.height.px]="annaRelatiivinenKorkeusmitta(33.86666666667)" rowspan="1" class="y o" style="font-size: 11px; font-weight: 300;">
                <ng-container *ngIf="!mitatoity">
                  {{ kasiteltava?.asiakas?.nimi }}<br />
                  {{ kasiteltava?.asiakas?.katuosoite }}<br />
                  {{ kasiteltava?.asiakas?.postinro }} {{ kasiteltava?.asiakas?.postitmp }}<br />
                  {{ maa }}
                </ng-container>
              </td>
            </tr>
            <tr [style.height.px]="annaRelatiivinenKorkeusmitta(8.4666666666666667)">
              <td class="e" style="font-size: 8px; font-weight: 600; border-right: 1px solid transparent;">{{'pdf-perinteinen.allekirjoitus' | translatel: kieli}}</td>
              <td class="o t">
                <div style="width: 100%; height: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.5)">&nbsp;</div>
              </td>
              <td class="y" style="font-size: 8px; font-weight: 800;">{{'pdf-perinteinen.viitenro' | translatel: kieli}}</td>
              <td class="y" style="font-size: 11px; font-weight: 300;" colspan="3">
                <ng-container *ngIf="!mitatoity">
                  {{viitenumero}}
                </ng-container>
              </td>
            </tr>
            <tr [style.height.px]="annaRelatiivinenKorkeusmitta(8.4666666666666667)">
              <td class="y a e" style="font-size: 8px; font-weight: 600;">{{'pdf-perinteinen.tililta' | translatel: kieli}}</td>
              <td class="y a o t"></td>
              <td class="y a o" style="font-size: 8px; font-weight: 800;">{{'pdf-perinteinen.erapaiva' | translatel: kieli}}</td>
              <td class="y a o" style="font-size: 11px; font-weight: 300; width: 12.5%">{{ erapaiva }}</td>
              <td class="y a" style="font-size: 8px; font-weight: 800;">{{lokalisoituValuutta}}</td>
              <td class="y a" width="*" style="font-size: 11px; font-weight: 300; text-align: right;">
                <ng-container *ngIf="!mitatoity">
                  {{maksettavaaYhteensa | lbigmoneyl: kasiteltava?.valuutta : kieli}}
                </ng-container>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="border-right: transparent; text-align: center;">

              </td>
              <td colspan="3" style="font-size: 6px; line-height: 7px; font-weight: 300; width: auto; padding-right: 2em; padding-bottom: 1.5em; padding-top: 1.5em;">
                {{'pdf-perinteinen.maksun-valitys-tiedote' | translatel: kieli}}
              </td>
            </tr>
          </table>
          <div lemon-barcode [barcodeValue]="virtuaaliviivakoodi" *ngIf="virtuaaliviivakoodi && !mitatoity" style="position: absolute; bottom: 2px; left: 20px;"></div>
        </div>
      </div>

    </div>
  </div>
</div>