import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { AsetustenOletuksetService } from '../../_angular/service/asetusten-oletukset.service'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { Maa, MaaService } from '../../_jaettu-angular/service/maa.service'

import { Laskuasetukset } from '../../_jaettu/model/lasku'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'

import { Subject, Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-perusasetukset',
  templateUrl: './perusasetukset.component.html'
})
export class PerusasetuksetComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}

  asetukset: Laskuasetukset = null
  maat: Observable<Maa[]>

  constructor(
    private laskuService: LaskuService,
    private validationService: FormValidationService,
    private laskuKopioija: LaskuKopioija,
    private asetuksetOletuksetService: AsetustenOletuksetService,
    private errorHandler: ErrorHandler,
    private snackbar: MatSnackBar,
    private ladataanService: LadataanService,
    private translationService: LemonTranslationService,
    private maaService: MaaService
  ) {
    this.maat = this.maaService.kaikkiMaatObservable
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('laskuasetukset', this.form)
  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      'nimi': new UntypedFormControl('', [Validators.required]),
      'ytunnus': new UntypedFormControl('', [Validators.required]),
      'katuosoite': new UntypedFormControl('', [Validators.required]),
      'postitoimipaikka': new UntypedFormControl('', [Validators.required]),
      'postinro': new UntypedFormControl('', [Validators.required]),
      'maa': new UntypedFormControl('', [Validators.required])
    })

    // Bind to changes
    this.form.get('nimi').valueChanges.subscribe(value => { this.asetukset.nimi = this.validationService.processValue(value) })
    this.form.get('ytunnus').valueChanges.subscribe(value => { this.asetukset.ytunnus = this.validationService.processValue(value) })
    this.form.get('katuosoite').valueChanges.subscribe(value => { this.asetukset.katuosoite = this.validationService.processValue(value) })
    this.form.get('postitoimipaikka').valueChanges.subscribe(value => { this.asetukset.postitmp = this.validationService.processValue(value) })
    this.form.get('postinro').valueChanges.subscribe(value => { this.asetukset.postinro = this.validationService.processValue(value) })
    this.form.get('maa').valueChanges.subscribe(value => { this.asetukset.maakoodi = this.validationService.processValue(value) })

    // Load data
    this.laskuService.asetuksetObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asetukset => {
      // this.form.reset()
      if (asetukset) {
        this.asetukset = this.laskuKopioija.copyAsetukset(asetukset)
        this.asetuksetOletuksetService.poistaOletuksetAsetuksista(this.asetukset)
      } else {
        this.asetukset = {
          avain: null,
          nimi: null,
          ytunnus: null,
          katuosoite: null,
          postitmp: null,
          postinro: null,
          maakoodi: 'FIN',
          date: null,
          replyto: null,
          puhelin: null,
          bic: null,
          iban: null,
          pankki: null,
          base64Logo: null,
          ohjeet: null,
          slogan: null,
          piilotaYrityksenNimiLaskulla: false,
          summahuomautusPiilotettu: false,
          muistutusSpostiasetukset: {},
          spostiasetukset: {},
          tervetuloaNahty: true
        }
      }

      this.asetukset.tervetuloaNahty = true

      this.form.get('nimi').setValue(this.asetukset.nimi)
      this.form.get('ytunnus').setValue(this.asetukset.ytunnus)
      this.form.get('katuosoite').setValue(this.asetukset.katuosoite)
      this.form.get('postitoimipaikka').setValue(this.asetukset.postitmp)
      this.form.get('postinro').setValue(this.asetukset.postinro)
      this.form.get('maa').setValue(this.asetukset.maakoodi)

      this.nimiInput.focus()
    }, err => {
      this.errorHandler.handleError(err)
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  save() {
    if (this.validationService.onkoLomakkeessaVirheita(this.form)) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
    } else {
      this.ladataanService.aloitaLataaminen()
      this.laskuService.updateLaskuasetukset(this.asetukset).then(() => {
        const lokalisoitu = this.translationService.lokalisoi('laskuasetukset.tallennettu-onnistuneesti')
        this.snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
        this.ladataanService.lopetaLataaminen()
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    }
  }

}
