import { Injectable } from '@angular/core'

import { TilinpaatosMetadata } from '../_jaettu/model/tilinpaatos'
import { getPdfRenderScale } from 'app/_jaettu/utils/pdf'

import { TuettuKieli } from 'app/_shared-core/model/common'
import { PdfConversionJobSettingsWasm } from 'app/_angular/service/pdf.service'

@Injectable()
export class ReportImagesService {

  constructor(
  ) { }

  public getTilinpaatosPdfUrl(asiakasAvain: string, metadata: TilinpaatosMetadata, lang: TuettuKieli): string {
    const currentVersionKey = metadata.viimeisinTilinpaatosVersioAvain
    const supportedLanguages = metadata.tilinpaatosVersionTuetutKielet[currentVersionKey]

    if (supportedLanguages.find(supported => supported === lang)) {
      const fileName = metadata.tilinpaatosPdfienNimet[currentVersionKey + '_' + lang]

      return 'tilinpaatokset/' + asiakasAvain + '/fi/' + fileName
    }
  }

  renderPdfPage(
    wasmSettings: PdfConversionJobSettingsWasm,
    kuvanIndeksi: number,
    kuvienTargetWidth: number
  ): Promise<string> {
    const start = Date.now()
    const page = wasmSettings.document.getPage(kuvanIndeksi)
    const info = page.getSize()
    const scale = getPdfRenderScale(kuvienTargetWidth, 2, info.width)
    const render = page.render(scale)
    page.destroy()

    const imageData = new ImageData(new Uint8ClampedArray(render.bitmap), render.width, render.height)

    // if (typeof OffscreenCanvas !== 'undefined') {
    //   try {

    //   }
    //   const offscreenCanvas = new OffscreenCanvas(render.width, render.height)
    //   const context = offscreenCanvas.getContext('2d')
    //   context.putImageData(imageData, 0, 0)

    // }
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = render.width
    canvas.height = render.height
    ctx.putImageData(imageData, 0, 0)
    const blob = this._canvasToBlob(canvas)
    console.log('Took to ' + (Date.now() - start) + 'ms render')
    return blob
  }

  private _canvasToBlob(canvas: HTMLCanvasElement): Promise<string> {
    return new Promise((resolve, reject) => {
      try {

        if (canvas.toBlob) {
          canvas.toBlob(blob => { resolve(URL.createObjectURL(blob)) }, 'image/png', 100)
        } else if (canvas.toDataURL) {
          const asDataUrl = canvas.toDataURL('image/png', 100)
          resolve(asDataUrl)
        } else {
          reject(new Error('The browser does NOT support HTMLCanvasElement.toBlob() or HTMLCanvasElement.toDataUrl()!!'))
        }

        // This MS specific version does not support quality, so don't use it.
        // Ie and old edge will use toDataUrl method.
        // else if (canvas['msToBlob']) { // IE
        //   resolve((canvas as any).msToBlob())
        // }

      } catch (err) {
        reject(err)
      }
    })
  }

}
