import { ErrorHandler } from '@angular/core'

import { PageEvent } from '@angular/material/paginator'
import { Sort, SortDirection } from '@angular/material/sort'
import { DataSource } from '@angular/cdk/table'

import { Observable, BehaviorSubject, combineLatest, of as observableOf, timer, firstValueFrom } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'

import { KayttajaService } from '../_angular/service/kayttaja.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { DocumentSnapshot } from 'firebase/firestore'
import { FirebaseLemonaid } from '../_angular/service/firebase-lemonaid.service'
import { lemonShare } from '../_jaettu-angular/_rxjs/lemon-share.operator'
import { FirestoreTosite, OstolaskujenHakupyynto, OstolaskujenHakuvastaus, PaymentStatus } from '../_jaettu/model/tosite'
import { DateService } from 'app/_shared-core/service/date.service'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'

export interface LemonSort<COLUMN = string> {
  active: COLUMN
  direction: 'asc' | 'desc'
}

export interface OstolaskuSort extends LemonSort<'dueDate'> {

}

export interface LokalisoituTila {
  nimi: string
  tunnus: PaymentStatus | 'k'
}

export interface Hakukriteerit {
  vapaahaku: string
  tilat: PaymentStatus[]
}

interface Hakutiedot {
  hakukriteerit: Hakukriteerit
  sivutustiedot: PageEvent
  lajittelutiedot: OstolaskuSort
}

export class MaksutFirestoreDataSource extends DataSource<FirestoreTosite> {

  private _initialized = false
  public modeObservable: BehaviorSubject<'locked' | 'realtime'> = new BehaviorSubject('realtime')

  public rivienKokonaismaaraObservable: Observable<number>
  public nykyinenIndeksiObservable: Observable<number>
  public valittujenRivienMaaraObservable: Observable<number>

  private _lockedMaksutObservable: BehaviorSubject<FirestoreTosite[]> = new BehaviorSubject([])

  private _edellinenKuittiObservable: Observable<{ previousBeforeQueryRangeWasNull: boolean, tosite: FirestoreTosite }>
  private _seuraavaKuittiObservable: Observable<{ nextAfterQueryRangeWasNull: boolean, tosite: FirestoreTosite }>

  private _reloadCountSubject: BehaviorSubject<number> = new BehaviorSubject(1)
  private _currentKuittiAvainSubject: BehaviorSubject<string> = new BehaviorSubject(null)
  private _internalMaksutObservable: Observable<FirestoreTosite[]>
  public maksutObservable: Observable<FirestoreTosite[]>

  public eraantyneetCountObservable: Observable<number>
  public avoimetCountObservable: Observable<number>

  public maksunTilatObservable: Observable<LokalisoituTila[]>

  // dataSourceTransformed: LaskuTransformingWrappingDataSource = null

  private lataaSubject = new BehaviorSubject<boolean>(true)
  public lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private oletuskriteerit: Hakukriteerit = {
    vapaahaku: null,
    tilat: [PaymentStatus.AVOIN, PaymentStatus.ERAANTYNYT, PaymentStatus.MAKSUSSA, PaymentStatus.PANKKI_HYLKASI]
  }

  private oletussivutustiedot: PageEvent = {
    length: Number.MAX_SAFE_INTEGER,
    pageIndex: 0,
    pageSize: 100
  }

  private oletuslajittelutiedot: OstolaskuSort = {
    active: 'dueDate',
    direction: 'asc'
  }

  private oletusHakutiedot: Hakutiedot = {
    hakukriteerit: this.kopioiHakukriteerit(this.oletuskriteerit),
    lajittelutiedot: this.kopioiLajittelutiedot(this.oletuslajittelutiedot),
    sivutustiedot: this.kopioiSivutustiedot(this.oletussivutustiedot)
  }

  private _hakutiedotSubject = new BehaviorSubject<Hakutiedot>(this.oletusHakutiedot)
  public hakutiedotObservable = this._hakutiedotSubject.asObservable()

  private lastVisible: DocumentSnapshot<FirestoreTosite>[] = []

  private _previousBeforeQueryRange: DocumentSnapshot<FirestoreTosite> = null
  private _nextAfterQueryRange: DocumentSnapshot<FirestoreTosite> = null

  constructor(
    private errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _lemonTranslationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService
  ) {
    super()

    this.maksunTilatObservable = this._lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {

        const tilat: LokalisoituTila[] = []
        tilat.push({ tunnus: 'k', nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.k', kieli) })
        tilat.push({ tunnus: PaymentStatus.AVOIN, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.AVOIN, kieli) })
        tilat.push({ tunnus: PaymentStatus.ERAANTYNYT, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.ERAANTYNYT, kieli) })
        tilat.push({ tunnus: PaymentStatus.MAKSUSSA, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.MAKSUSSA, kieli) })
        tilat.push({ tunnus: PaymentStatus.MAKSETTU, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.MAKSETTU, kieli) })
        tilat.push({ tunnus: PaymentStatus.MAKSETTU_TOISAALLA, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.MAKSETTU_TOISAALLA, kieli) })
        tilat.push({ tunnus: PaymentStatus.PANKKI_HYLKASI, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.PANKKI_HYLKASI, kieli) })
        tilat.push({ tunnus: PaymentStatus.ASIAKAS_HYLKASI, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.ASIAKAS_HYLKASI, kieli) })
        // tilat.push({ tunnus: PaymentStatus.HYVAKSYTTY, nimi: this._lemonTranslationService.lokalisoiKielella('maksut.listaus.tila.' + PaymentStatus.ASIAKAS_HYLKASI, kieli) })

        return tilat.sort((a, b): number => {
          if (a.tunnus === 'k') {
            return -1
          } else if (b.tunnus === 'k') {
            return 1
          }
          return a.nimi.localeCompare(b.nimi)
        })

      })
    )

    const paymentReceiveIsActiveObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return observableOf<number>(0)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen().pipe(
          map(config => config && config.paymentReceiveIsActive)
        )
      })
    )

    this.avoimetCountObservable = combineLatest([this._kayttajaService.kayttajanTiedotObservable, paymentReceiveIsActiveObservable]).pipe(
      switchMap(([kayttaja, paymentReceiveIsActive]) => {

        if (!kayttaja || !paymentReceiveIsActive) {
          return observableOf<number>(0)
        }

        const q = this._firebaseLemonaid.firestoreCollection<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit')
          .where('paymentStatus', 'in', [PaymentStatus.AVOIN, PaymentStatus.ERAANTYNYT, PaymentStatus.MAKSUSSA, PaymentStatus.PANKKI_HYLKASI])
        return q.listen().pipe(
          map(res => res.length)
        )

      }),
      distinctUntilChanged(),
      lemonShare()
    )

    this.eraantyneetCountObservable = combineLatest([this._kayttajaService.kayttajaObservable, paymentReceiveIsActiveObservable]).pipe(
      switchMap(([kayttaja, paymentReceiveIsActive]) => {

        if (!kayttaja || !paymentReceiveIsActive) {
          return observableOf<number>(0)
        }

        const q = this._firebaseLemonaid.firestoreCollection<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit')
          .where('paymentStatus', 'in', [PaymentStatus.ERAANTYNYT, PaymentStatus.PANKKI_HYLKASI])

        return q.listen().pipe(
          map(res => {
            // Huom! Erääntyneet voivat olla myös tilassa HYVAKSYTTY ja PANKKI_HYLKASI, joten selvitetään myös ovatko ne erääntyneet
            const eraantyneet = res.filter(tosite => PaymentStatus.ERAANTYNYT || tosite.dueDate < this._dateService.currentNumberDate())
            if (kayttaja.roolit.MAKSUT && kayttaja.roolit.MAKSUT_HYVAKSYJA && !kayttaja.roolit.MAKSUT_MAKSAJA) {
              // Maksujen hyväksyjä, näytä vain erääntyneet hyväksymättömät
              return eraantyneet.filter(maksu => Object.values(maksu?.einvoiceApprovals ?? {}).length === 0).length
            } else {
              return eraantyneet.length
            }
          })
        )

      }),
      distinctUntilChanged(),
      lemonShare()
    )

    this.rivienKokonaismaaraObservable = combineLatest([this._hakutiedotSubject, this._kayttajaService.kayttajanTiedotObservable, timer(0, 30000), this._reloadCountSubject]).pipe(
      switchMap(([tiedot, kayttaja, intervl, reload]) => {

        console.log('Lataa kokonaismäärä uudelleen.')

        if (!kayttaja) {
          this.resetSearchToDefaults()
          return observableOf<number>(0)
        }

        const q = this._firebaseLemonaid.firestoreCollection<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit')
          .where('paymentStatus', 'in', tiedot.hakukriteerit.tilat)

        return q.getCount()

      }),
      distinctUntilChanged(),
      lemonShare()
    )

    this._internalMaksutObservable = combineLatest([this._hakutiedotSubject, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      tap(() => {
        this.lataaSubject.next(true)
      }),
      switchMap(([tiedot, kayttaja]) => {

        if (
          !kayttaja ||
          !tiedot ||
          !tiedot.hakukriteerit ||
          !tiedot.lajittelutiedot ||
          !tiedot.sivutustiedot
        ) {
          this.resetSearchToDefaults()
          return observableOf<FirestoreTosite[]>([])
        }

        const hakukriteerit = tiedot.hakukriteerit
        const sort = tiedot.lajittelutiedot
        const pageEvent = tiedot.sivutustiedot

        const limitSize = pageEvent.pageSize
        const direction = sort.direction === 'asc' ? 'asc' : 'desc'

        if (hakukriteerit.vapaahaku?.trim()?.length > 2) {
          const pyynto: OstolaskujenHakupyynto = {
            limit: limitSize,
            tilat: hakukriteerit.tilat,
            orderBy: { column: sort.active, direction: sort.direction },
            skip: pageEvent.pageSize * pageEvent.pageIndex,
            vapaa: hakukriteerit.vapaahaku.trim()
          }
          return this._firebaseLemonaid.functionsCall<OstolaskujenHakupyynto, OstolaskujenHakuvastaus>('tositteet-etsiOstolasku', pyynto)
            .then(vastaus => {
              setTimeout(() => { this.lataaSubject.next(false) }, 25)
              return vastaus.tulokset
            })
        }

        let q = this._firebaseLemonaid.firestoreCollection<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit')
          .where('paymentStatus', 'in', hakukriteerit.tilat)
          .orderByFree(sort.active, direction)

        let usedLimit = limitSize + 1
        if (pageEvent.pageIndex > 0 && pageEvent.pageIndex - 1 in this.lastVisible) {
          usedLimit = limitSize + 2
        }

        if (pageEvent.pageIndex > 0 && pageEvent.pageIndex - 1 in this.lastVisible) {
          q = q.startAfter(this.lastVisible[pageEvent.pageIndex - 1])
        }

        q = q.limit(usedLimit)

        return q.listenSnapshots().pipe(
          map(snapshots => {

            if (snapshots.length === usedLimit) {
              this._nextAfterQueryRange = snapshots.pop()
            } else {
              this._nextAfterQueryRange = null
            }

            if (pageEvent.pageIndex > 0) {
              this._previousBeforeQueryRange = snapshots.shift()
            } else {
              this._previousBeforeQueryRange = null
            }

            if (snapshots.length > 1) {
              const snap = snapshots[snapshots.length - 2]
              this.lastVisible[pageEvent.pageIndex] = snap
            }
            return snapshots.map(changeAction => changeAction.data())

          }),
          map(tositteet => {
            for (const tosite of tositteet) {
              delete tosite['haku']
            }
            return tositteet
          }),
          tap(() => {
            setTimeout(() => { this.lataaSubject.next(false) }, 25)
          })
        )
      }),
      lemonShare()
    )

    this._internalMaksutObservable.pipe(
      debounceTime(1000)
    ).subscribe(() => this._reloadCountSubject.next(Date.now()))

    this.maksutObservable = combineLatest([this._internalMaksutObservable, this.lataaObservable]).pipe(
      map(([maksut, lataa]) => {
        if (lataa) {
          return []
        }
        return maksut
      })
    )

    const maksutMoodinMukaanObservable = combineLatest([this.modeObservable, this.maksutObservable, this._lockedMaksutObservable]).pipe(
      map(([mode, maksut, lockedMaksut]) => {
        if (mode === 'locked') {
          return lockedMaksut
        }
        return maksut
      })
    )

    this.valittujenRivienMaaraObservable = maksutMoodinMukaanObservable.pipe(map(maksut => maksut.length))

    this.nykyinenIndeksiObservable = combineLatest([this._hakutiedotSubject, this._currentKuittiAvainSubject, maksutMoodinMukaanObservable]).pipe(
      map(([hakutiedot, currentKuittiAvain, maksut]) => {
        if (!hakutiedot || !currentKuittiAvain || !maksut) {
          return 0
        }
        const sivunAlku = hakutiedot.sivutustiedot.pageIndex * hakutiedot.sivutustiedot.pageSize
        const nykyisenIndeksi = maksut.findIndex(maksu => maksu.avain === currentKuittiAvain) + 1
        // console.log(sivunAlku + nykyisenIndeksi, sivunAlku, nykyisenIndeksi, hakutiedot.sivutustiedot)
        return sivunAlku + nykyisenIndeksi
      })
    )

    this._edellinenKuittiObservable = combineLatest([this._currentKuittiAvainSubject, maksutMoodinMukaanObservable]).pipe(
      map(([currentKuittiAvain, maksut]) => {
        // console.log('test', currentKuittiAvain, maksut?.map(a => a.avain))

        if (!currentKuittiAvain || !maksut) {
          return { previousBeforeQueryRangeWasNull: false, tosite: null }
        }

        const nykyisenIndeksi = maksut.findIndex(maksu => maksu.avain === currentKuittiAvain)
        if (nykyisenIndeksi < 0) {
          return { previousBeforeQueryRangeWasNull: false, tosite: null }
        } else if (nykyisenIndeksi === 0) {
          if (this._previousBeforeQueryRange?.data()) {
            return { previousBeforeQueryRangeWasNull: false, tosite: this._previousBeforeQueryRange.data() }
          }
          return { previousBeforeQueryRangeWasNull: true, tosite: null }
        }
        return { previousBeforeQueryRangeWasNull: false, tosite: maksut[nykyisenIndeksi - 1] }
      }),
      lemonShare()
    )

    this._seuraavaKuittiObservable = combineLatest([this._currentKuittiAvainSubject, maksutMoodinMukaanObservable]).pipe(
      map(([currentKuittiAvain, maksut]) => {

        if (!currentKuittiAvain || !maksut) {
          return { nextAfterQueryRangeWasNull: false, tosite: null }
        }

        if (!currentKuittiAvain || !maksut) {
          return { nextAfterQueryRangeWasNull: false, tosite: null }
        }

        const nykyisenIndeksi = maksut.findIndex(maksu => maksu.avain === currentKuittiAvain)
        if (nykyisenIndeksi < 0) {
          return { nextAfterQueryRangeWasNull: false, tosite: null }
        } else if (nykyisenIndeksi + 1 === maksut.length) {
          if (this._nextAfterQueryRange?.data()) {
            return { nextAfterQueryRangeWasNull: false, tosite: this._nextAfterQueryRange?.data() }
          }
          return { nextAfterQueryRangeWasNull: true, tosite: null }
        }
        return { nextAfterQueryRangeWasNull: false, tosite: maksut[nykyisenIndeksi + 1] }
      }),
      lemonShare()
    )

  }

  // public async initializeWithAvain(avain: string) {

  //   if (this._initialized || this._initializing) {
  //     return
  //   }
  //   this._initializing = true

  //   const indexOfTositeObservable: Observable<{ pageIndex: number }> = combineLatest([this._hakutiedotSubject, this._kayttajaService.kayttajanTiedotObservable]).pipe(
  //     switchMap(([tiedot, kayttaja]) => {

  //       if (!kayttaja || !tiedot) {
  //         return
  //       }

  //       return this._firebaseLemonaid.firestoreDoc<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit/' + avain).getSnap().then(snap => {
  //         const hakukriteerit = tiedot.hakukriteerit
  //         const sort = tiedot.lajittelutiedot

  //         const direction = sort.direction === 'asc' ? 'asc' : 'desc'
  //         const q = this._firebaseLemonaid.firestoreCollection<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit')
  //           .where('paymentStatus', 'in', hakukriteerit.tilat)
  //           .orderBy('dueDate', direction)
  //           .endAt(snap)

  //         return q.getSnaps().then(snaps => snaps.length)

  //         // TODO: FIXME: COUNT SHOULD WORK, BUT DOESNT!
  //         // CHECK IT IF DOES WHEN FIRESTORE IS UPDATED
  //         // return q.getCount()

  //       }).then(index => {
  //         const pageSize = tiedot.sivutustiedot.pageSize
  //         const p = index > pageSize ? Math.floor(index / pageSize) : 1
  //         const indexInPage = index - (p - 1) * pageSize

  //         console.log('Index: ' + index)
  //         console.log('Calculated that this is the ' + indexInPage + ' entry in page ' + p)

  //         return { pageIndex: p - 1 }
  //       })

  //     })
  //   )

  //   const sivutustiedot = await firstValueFrom(indexOfTositeObservable)

  //   if (this._hakutiedotSubject.value.sivutustiedot.pageIndex !== sivutustiedot.pageIndex) {
  //     this._hakutiedotSubject.value.sivutustiedot.pageIndex = sivutustiedot.pageIndex
  //     console.log('SET PAGE', sivutustiedot.pageIndex)
  //     this._hakutiedotSubject.next(this._hakutiedotSubject.value)
  //   }

  //   this._initialized = true
  //   this._initializing = false

  // }

  public async unLockResultSet() {
    this.modeObservable.next('realtime')
    this._lockedMaksutObservable.next([])
  }

  public async lockResultSet() {
    this.modeObservable.next('locked')
    const lockedTositteet = await firstValueFrom(this.maksutObservable.pipe(filter(maksut => maksut.length > 0)))
    this._lockedMaksutObservable.next(lockedTositteet)
  }

  isInitialized() {
    return this._initialized
  }

  markAsInitialized() {
    this._initialized = true
    // this._initializing = false
  }

  public async siirrySeuraavaan(): Promise<FirestoreTosite | null> {
    const seuraavaMaksu = await firstValueFrom(this._seuraavaKuittiObservable)
    if (seuraavaMaksu.nextAfterQueryRangeWasNull) {

      // We reached the last item, jump to first
      const hakutiedot = this._hakutiedotSubject.value
      if (hakutiedot.sivutustiedot.pageIndex > 0) {

        // 1. start loading
        this.lataaSubject.next(true)

        // 2. update current page
        hakutiedot.sivutustiedot.pageIndex = 0
        this._hakutiedotSubject.next(hakutiedot)

        // 3. wait for the load to complete
        await firstValueFrom(this.lataaObservable.pipe(filter(val => !val)))

      }

      // 4. take the first kuitti
      const firstPageItems = await firstValueFrom(this.maksutObservable.pipe(filter(val => val.length > 0)))

      // Set current index && return the current one.
      await this.asetaNykyisenKuitinIndex(firstPageItems[0])
      return firstPageItems[0]

    } else if (seuraavaMaksu.tosite) {
      await this.asetaNykyisenKuitinIndex(seuraavaMaksu.tosite)
    }
    return seuraavaMaksu.tosite
  }

  public async siirryEdelliseen(): Promise<FirestoreTosite | null> {
    const edellinenMaksu = await firstValueFrom(this._edellinenKuittiObservable)

    if (edellinenMaksu.previousBeforeQueryRangeWasNull) {
      // We reached the first item, jump to last
      const hakutiedot = this._hakutiedotSubject.value
      if (hakutiedot.sivutustiedot.pageIndex === 0) {
        if (!this._nextAfterQueryRange) { // Meanst that all items fit the first page.
          // take the last kuitti
          const firstPageItems = await firstValueFrom(this.maksutObservable.pipe(filter(val => val.length > 0)))
          const lastItem = firstPageItems[firstPageItems.length - 1]
          await this.asetaNykyisenKuitinIndex(lastItem)
          return lastItem
        }
      }
    }

    if (edellinenMaksu.tosite) {
      await this.asetaNykyisenKuitinIndex(edellinenMaksu.tosite)
    }
    return edellinenMaksu.tosite
  }

  public async asetaNykyisenKuitinIndex(kuitti: FirestoreTosite) {
    if (kuitti?.avain) {
      if (this._currentKuittiAvainSubject.value !== kuitti.avain) {
        if (this._previousBeforeQueryRange?.data()?.avain === kuitti.avain && this._hakutiedotSubject.value.sivutustiedot?.pageIndex > 0) {
          this._hakutiedotSubject.value.sivutustiedot.pageIndex = this._hakutiedotSubject.value.sivutustiedot?.pageIndex - 1
          this._hakutiedotSubject.next(this._hakutiedotSubject.value)
        }
        if (this._nextAfterQueryRange?.data()?.avain === kuitti.avain) {
          this._hakutiedotSubject.value.sivutustiedot.pageIndex = this._hakutiedotSubject.value.sivutustiedot?.pageIndex + 1
          this._hakutiedotSubject.next(this._hakutiedotSubject.value)
        }
        this._currentKuittiAvainSubject.next(kuitti.avain)
      }
    } else {
      this._currentKuittiAvainSubject.next(null)
    }
  }

  public paivitaRivienMaara() {
    this._reloadCountSubject.next(Date.now())
  }

  private kopioiHakukriteerit(lahde: Hakukriteerit): Hakukriteerit {
    return {
      vapaahaku: lahde.vapaahaku,
      tilat: lahde.tilat.slice()
    }
  }

  private kopioiSivutustiedot(lahde: PageEvent): PageEvent {
    return {
      length: lahde.length,
      pageIndex: lahde.pageIndex,
      pageSize: lahde.pageSize,
      previousPageIndex: lahde.previousPageIndex
    }
  }

  private kopioiLajittelutiedot(lahde: OstolaskuSort): OstolaskuSort {
    return {
      active: lahde.active,
      direction: lahde.direction
    }
  }

  set search(vapaahaku: string) {
    const hakutiedot = this._hakutiedotSubject.value
    if (hakutiedot.hakukriteerit.vapaahaku !== vapaahaku) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.hakukriteerit.vapaahaku = vapaahaku
      this._hakutiedotSubject.next(hakutiedot)
    }
  }

  set page(pageEvent: PageEvent) {
    const hakutiedot = this._hakutiedotSubject.value
    hakutiedot.sivutustiedot = pageEvent
    // console.log('HERE, ', this._hakutiedotSubject)
    this._hakutiedotSubject.next(hakutiedot)
  }

  set sort(sort: OstolaskuSort) {
    const hakutiedot = this._hakutiedotSubject.value
    this.valmistauduHakuehdonMuutokseen(hakutiedot)
    hakutiedot.lajittelutiedot = sort
    this._hakutiedotSubject.next(hakutiedot)
  }

  set tilat(tilat: PaymentStatus[]) {
    const hakutiedot = this._hakutiedotSubject.value
    this.valmistauduHakuehdonMuutokseen(hakutiedot)
    hakutiedot.hakukriteerit.tilat = tilat
    this._hakutiedotSubject.next(hakutiedot)
  }

  // set vuosiKkKohde(kohde: 'p' | 'e') {
  //   const hakutiedot = this.hakutiedotSubject.value
  //   if (hakutiedot.hakukriteerit.vuosikk.kohde !== kohde) {
  //     this.valmistauduHakuehdonMuutokseen(hakutiedot)
  //     hakutiedot.hakukriteerit.vuosikk.kohde = kohde
  //     this.hakutiedotSubject.next(hakutiedot)
  //   }
  // }

  // setVuosiKk(vuosi: number, kk: number) {
  //   const hakutiedot = this.hakutiedotSubject.value
  //   if (hakutiedot.hakukriteerit.vuosikk.kk !== kk || hakutiedot.hakukriteerit.vuosikk.vuosi !== vuosi) {
  //     this.valmistauduHakuehdonMuutokseen(hakutiedot)
  //     hakutiedot.hakukriteerit.vuosikk.kk = kk
  //     hakutiedot.hakukriteerit.vuosikk.vuosi = vuosi
  //     this.hakutiedotSubject.next(hakutiedot)
  //   }
  // }

  // set tila(tila: LaskunTila) {
  //   const hakutiedot = this.hakutiedotSubject.value
  //   if (hakutiedot.hakukriteerit.tila !== tila) {
  //     this.valmistauduHakuehdonMuutokseen(hakutiedot)
  //     hakutiedot.hakukriteerit.tila = tila
  //     this.hakutiedotSubject.next(hakutiedot)
  //   }
  // }

  get page(): PageEvent {
    return this._hakutiedotSubject.value.sivutustiedot
  }

  get sort(): OstolaskuSort {
    return this._hakutiedotSubject.value.lajittelutiedot
  }

  resetSearchToDefaults() {

    const hakutiedot = this._hakutiedotSubject.value

    // console.log('RESET')
    // If this is not here, a forever loop is reached when user logs out.
    if (
      JSON.stringify(hakutiedot.hakukriteerit) !== JSON.stringify(this.oletuskriteerit) ||
      JSON.stringify(hakutiedot.lajittelutiedot) !== JSON.stringify(this.oletuslajittelutiedot) ||
      JSON.stringify(hakutiedot.sivutustiedot) !== JSON.stringify(this.oletussivutustiedot)
    ) {
      // console.log('RESET CHANGED')
      hakutiedot.hakukriteerit = this.kopioiHakukriteerit(this.oletuskriteerit)
      hakutiedot.lajittelutiedot = this.kopioiLajittelutiedot(this.oletuslajittelutiedot)
      hakutiedot.sivutustiedot = this.kopioiSivutustiedot(this.oletussivutustiedot)
      this.lastVisible = []
      this._hakutiedotSubject.next(hakutiedot)
    }

  }

  private valmistauduHakuehdonMuutokseen(hakutiedot: Hakutiedot) {
    this.lastVisible = []
    hakutiedot.sivutustiedot.pageIndex = 0
    delete hakutiedot.sivutustiedot.previousPageIndex
    hakutiedot.sivutustiedot.length = Number.MAX_SAFE_INTEGER
  }

  connect(): Observable<FirestoreTosite[]> {
    return this.maksutObservable
  }

  disconnect() {

  }

}
