<button class="light-blue-text back-btn" mat-icon-button (click)="peruuta()" type="button">
  <i class="icon ion-md-arrow-back"></i>
</button>

<div class="maksut-katsele-page" *ngIf="tositeObservable | async; let tosite">

  <div style="max-width: 94vw;" *ngIf="viewTypeObservable | async; let viewType">

    <div class="header" *ngIf="pagesObservable | async; let pages">
      <button [style.visibility]="pages.curr > 1 ? 'visible': 'hidden'" mat-button (click)="navigoiEdelliseen()">
        <i class="navigation-icon fa fa-chevron-left"></i>
      </button>

      <div style="text-align: center; line-height: 36px;">
        {{ 'maksut.katsele.lasku' | translate | async }} {{ pages.curr}} / {{ pages.total }}
      </div>

      <button [style.visibility]="pages.curr < pages.total ? 'visible': 'hidden'" mat-button (click)="navigoiSeuraavaan()">
        <i class="navigation-icon fa fa-chevron-right"></i>
      </button>
    </div>

    <!-- <div style="text-align: right;" *ngIf="viewType === 'hyvaksynta' || viewType === 'hyvaksynta-ja-maksaminen'">
      <div *ngIf="maksuHyvaksyttyObservable | async; else odottaaHyvaksyntaa">
        <span><i class="ion-ios-checkmark green-text" style="vertical-align: middle; font-size: 2em; padding-right: 3px;"></i></span>
        {{ 'maksut.katsele.hyvaksytty' | translate | async}}
      </div>
      <ng-template #odottaaHyvaksyntaa>
        <div>
          <span style="color: inherit;"> <i class="icon fa fa-clock-o"></i></span>
          {{ 'maksut.katsele.odottaa-hyvaksyntaa' | translate | async}}
        </div>
      </ng-template>
    </div> -->

    <div style="margin-top: 2em;">

      <h2>{{tosite.seller}}</h2>

      <form [formGroup]="maksunTiedotForm">

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.katsele.saaja' | translate | async }}</div>
          <div class="right-col" *ngIf="!saaja.enabled">{{tosite.maksutiedot?.maksunSaajanNimi ?? tosite.seller ?? ''}}</div>
          <mat-form-field class="right-col" *ngIf="saaja.enabled">
            <mat-label>{{ 'maksut.muokkaa.saaja' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #saajaVar="matInput" formControlName="saaja" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(saajaVar?.ngControl?.dirty || saajaVar?.ngControl?.touched) && saajaVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.saaja.required' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.muokkaa.iban' | translate | async }}</div>
          <div class="right-col" *ngIf="!iban.enabled">{{ formattedIban }}</div>
          <mat-form-field class="right-col" *ngIf="iban.enabled">
            <mat-label>{{ 'maksut.muokkaa.iban' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #ibanVar="matInput" formControlName="iban" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(ibanVar?.ngControl?.dirty || ibanVar?.ngControl?.touched) && ibanVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.iban.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(ibanVar?.ngControl?.dirty || ibanVar?.ngControl?.touched) && ibanVar.ngControl?.errors?.invalidiban">{{ 'maksut.muokkaa.validation.iban.invalid' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.muokkaa.bic' | translate | async }}</div>
          <div class="right-col" *ngIf="!bic.enabled">{{tosite.maksutiedot?.bic ?? tosite.bic ?? ''}}</div>
          <mat-form-field class="right-col" *ngIf="bic.enabled">
            <mat-label>{{ 'maksut.muokkaa.bic' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #bicVar="matInput" formControlName="bic" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(bicVar?.ngControl?.dirty || bicVar?.ngControl?.touched) && bicVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.bic.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(bicVar?.ngControl?.dirty || bicVar?.ngControl?.touched) && bicVar.ngControl?.errors?.invalidbic">{{ 'maksut.muokkaa.validation.bic.invalid' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.katsele.viitenumero' | translate | async }}</div>
          <div class="right-col" *ngIf="!viite.enabled">{{tosite.maksutiedot?.reference ?? tosite.viitenumero ?? ''}}</div>
          <mat-form-field class="right-col" *ngIf="viite.enabled">
            <mat-label>{{ 'maksut.muokkaa.viite' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #viiteVar="matInput" formControlName="viite" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true">
            <mat-error *ngIf="(viiteVar?.ngControl?.dirty || viiteVar?.ngControl?.touched) && viiteVar.ngControl?.errors?.viitenumero">{{ 'maksut.muokkaa.validation.viite.viitenumero' | translate | async }}</mat-error>
            <mat-error *ngIf="(viiteVar?.ngControl?.dirty || viiteVar?.ngControl?.touched) && viiteVar.ngControl?.errors?.viestitaiviite">{{ 'maksut.muokkaa.validation.viite.viite-tai-viesti' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row" style="height: initial;">
          <div class="left-col">{{ 'maksut.katsele.viesti' | translate | async }}</div>
          <div class="right-col" *ngIf="!viesti.enabled">{{ tosite.maksutiedot?.message ?? '' }}</div>
          <mat-form-field class="right-col" *ngIf="viesti.enabled">
            <textarea matInput #viestiVar="matInput" formControlName="viesti" cdkTextareaAutosize cdkAutosizeMinRows="2" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true"></textarea>
            <mat-error *ngIf="(viestiVar?.ngControl?.dirty || viestiVar?.ngControl?.touched) && viestiVar.ngControl?.errors?.viestitaiviite">{{ 'maksut.muokkaa.validation.viite.viite-tai-viesti' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.katsele.erapvm' | translate | async }}</div>
          <div class="right-col" *ngIf="!erapvm.enabled">{{ tosite.maksutiedot?.dueDate ?? tosite.dueDate | lnumberpvm | async }}</div>
          <mat-form-field class="right-col" *ngIf="erapvm.enabled">
            <input matInput #erapvmVar="matInput" formControlName="erapvm" numberDatePicker [matDatepicker]="picker" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" />
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.erapvm.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerParse">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerParse' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerMax">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerMax' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerMin">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerMin' | translate | async }}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- 
        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.katsele.tililta' | translate | async }}</div>
          <mat-form-field class="right-col">
            <mat-select formControlName="tililta" style="width: 100%;" required>
              <mat-option *ngFor="let iban of tililtaIbanitObservable | async" [value]="iban">
                {{ iban }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="tiedot-row">
          <div class="left-col">{{ 'maksut.katsele.summa' | translate | async }}</div>
          <div class="right-col" *ngIf="!summa.enabled">{{ tosite.maksutiedot?.amount ?? tosite.sum | lmoney: tosite.currency | async }}</div>
          <mat-form-field class="right-col" *ngIf="summa.enabled">
            <input matInput #summaVar="matInput" decimalNumberField formControlName="summa" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" />
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.summa.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.min">{{ 'maksut.muokkaa.validation.summa.min' | translate | async }}</mat-error>
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.max">{{ 'maksut.muokkaa.validation.summa.max' | translate | async }}</mat-error>
          </mat-form-field>
        </div>

        <div class="tiedot-row" style="height: initial; margin-top: -15px;">
          <div class="left-col">{{ 'maksut.katsele.lisatiedot' | translate | async }}</div>
          <mat-form-field class="right-col">
            <textarea matInput formControlName="lisatiedot" cdkTextareaAutosize cdkAutosizeMinRows="2" (blur)="saveLisatiedotChange(tosite)" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true"></textarea>
            <mat-hint *ngIf="showLisatiedotCheckMark"><i class="icon ion-ios-checkmark-outline green-text" style="font-size: 22px; margin-right: 5px; vertical-align: middle;"></i>{{ 'maksut.katsele.tallennettu' | translate | async }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="tiedot-row" *ngIf="onkoLaskuperusteinenObservable | async">
          <div class="left-col">{{ 'maksut.katsele.toimituspaivamaara' | translate | async }}</div>
          <div class="right-col" *ngIf="!toimituspvm.enabled">{{ tosite.deliveryDate | lnumberpvm | async }}</div>
          <mat-form-field class="right-col" *ngIf="toimituspvm.enabled">
            <input matInput class="alignLeft" #toimituspvmVar="matInput" formControlName="toimituspvm" [name]="namename" [matDatepicker]="deliveryDatePicker" autocomplete="kirjnpitomekiarki" data-lpignore="true">
            <mat-error *ngIf="(toimituspvmVar?.ngControl?.dirty || toimituspvmVar?.ngControl?.touched) && toimituspvmVar.ngControl?.errors?.matDatepickerParse">{{ 'maksut.muokkaa.validation.toimituspvm.matDatepickerParse' | translate | async }}</mat-error>
            <mat-error *ngIf="(toimituspvmVar?.ngControl?.dirty || toimituspvmVar?.ngControl?.touched) && toimituspvmVar.ngControl?.errors?.matDatepickerMax">{{ 'maksut.muokkaa.validation.toimituspvm.matDatepickerMax' | translate | async }}</mat-error>
            <mat-error *ngIf="(toimituspvmVar?.ngControl?.dirty || toimituspvmVar?.ngControl?.touched) && toimituspvmVar.ngControl?.errors?.matDatepickerMin">{{ 'maksut.muokkaa.validation.toimituspvm.matDatepickerMin' | translate | async }}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #deliveryDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngIf="maksuaMuokataanObservable | async" style="padding-top: 0; padding-bottom: 1em;" class="tiedot-row">
          <div class="left-col"></div>
          <div class="right-col" style="text-align: right;">
            <button (click)="cancelPaymentDetailsChanges(tosite)" mat-button class="secondarybutton">{{ 'yleiset.peruuta' | translate | async}}</button>
            <button mat-button (click)="savePaymentDetailsChanges(tosite)" style="margin-left: .5em" class="secondarybutton">{{ 'yleiset.tallenna' | translate | async}}</button>
          </div>
        </div>

      </form>

    </div>


    <div class="buttons" *ngIf="!(maksuaMuokataanObservable | async)">

      <button *ngIf="naytaHyvaksyNappiObservable | async" mat-button (click)="approvePayment()" class="primarybutton">{{ 'maksut.katsele.hyvaksy-lasku' | translate | async}}</button>
      <button *ngIf="naytaHyvaksynnanPeruminenNappiObservable | async" mat-button (click)="revokePaymentApproval(tosite)" class="secondarybutton">{{ 'maksut.katsele.peru-hyvaksynta' | translate | async}}</button>

      <button *ngIf="naytaMaksettuMuuallaNappiVainVastaanottoObservable | async" mat-button (click)="maksettuYrityksenTililtaVainVastaanotto(tosite)" class="primarybutton">{{ 'maksut.katsele.maksettu-yrityksen-tililta' | translate | async}}</button>
      <button *ngIf="naytaMaksettuMuuallaNappiVainVastaanottoObservable | async" mat-button (click)="maksettuOmallaRahallaVainVastaanotto(tosite)" class="secondarybutton">{{ 'maksut.katsele.maksettu-muualla' | translate | async}}</button>
      <button *ngIf="naytaMaksettuMuuallaNappiMaksutKaytossaObservable | async" mat-button (click)="maksettuMuuallaMaksutKaytossa(tosite)" class="secondarybutton">{{ 'maksut.katsele.maksettu-muualla' | translate | async}}</button>

      <button *ngIf="naytaHylkaaNappiObservable | async" mat-button (click)="rejectPayment(tosite)" class="secondarybutton">{{ 'maksut.katsele.hylkaa-lasku' | translate | async}}</button>
      <button *ngIf="naytaHylkaamisenPeruminenNappiObservable | async" mat-button (click)="rejectPaymentPeru(tosite)" class="secondarybutton">{{ 'maksut.katsele.hylkaa-lasku-peru' | translate | async}}</button>

      <button *ngIf="naytaMaksettuMuuallaPeruminenNappiObservable | async" mat-button (click)="maksettuToisaallaPeru(tosite)" class="secondarybutton">{{ 'maksut.katsele.maksettu-muualla-peru' | translate | async}}</button>

      <button *ngIf="naytaKasiteltyNappiObservable | async" mat-button (click)="merkitseKasitellyksi(tosite)" class="secondarybutton">{{ 'maksut.katsele.kasitelty' | translate | async}}</button>
      <button *ngIf="naytaKasittelynPeruminenNappiObservable | async" mat-button (click)="maksettuToisaallaPeru(tosite)" class="secondarybutton">{{ 'maksut.katsele.kasitelty-peru' | translate | async}}</button>

      <button *ngIf="naytaMuokkaaMaksuaNappiObservable | async" (click)="setMaksuaMuokataan(true)" mat-button class="secondarybutton">{{ 'maksut.katsele.muokkaa-tietoja' | translate | async}}</button>

      <button mat-button (click)="lataaLasku(tosite)" class="secondarybutton">{{'maksut.katsele.lataa-lasku' | translate | async}}</button>

    </div>

    <ng-container *ngIf="logItemsObservable | async; let logItems">
      <div style="padding-top: 20px;" *ngIf="logItems?.length">
        <div *ngFor="let log of logItems" style="line-height: 30px; height: 30px;" [class.dark-gray-text]="log.placeholder" [class.placeholder]="log.placeholder">
          <i class="ion-ios-checkmark" *ngIf="!log.placeholder" style="vertical-align: middle; font-size: 30px; padding-right: 5px;"></i>{{log.text}}
        </div>
      </div>
    </ng-container>

    <div style="margin-top: 4em;" *ngIf="viewType === 'vastaanotto' && kooditObservable | async let koodit">

      <div *ngIf="koodit.qr || koodit.viiva">
        <h2>{{'maksut.maksukoodit' | translate | async}}</h2>

        <mat-tab-group #tabgroup [(selectedIndex)]="activeTabIndex" style="min-height: 300px;" (selectedIndexChange)="tabGroupIndexChanged($event)" animationDuration="0ms">
          <mat-tab *ngIf="koodit.qr" [label]="'maksut.qr-koodi' | translate | async">
            <ng-template matTabContent>
              <div class="padded" lemon-qrcode style=" max-width: 200px;" [qrcodeValue]="koodit.qr"></div>
            </ng-template>
          </mat-tab>

          <mat-tab *ngIf="koodit.viiva" [label]="'maksut.viivakoodi' | translate | async">
            <ng-template matTabContent>
              <div class="padded" lemon-barcode barcodeCssClass="maksut-katsele-barcode" [barcodeValue]="koodit.viiva"></div>
            </ng-template>
          </mat-tab>

          <mat-tab *ngIf="koodit.viiva" [label]="'maksut.virtuaalinen-viivakoodi' | translate | async">
            <ng-template matTabContent>
              <div style="padding: 20px 10px;">{{koodit.viiva}}</div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

  </div>

  <div class="tosite-sivut">
    <div *ngFor="let kuva of kuvat">
      <div app-tosite-kuva [url]="kuva" class="mat-elevation-z4" style="width: 100%; min-height: 100px; margin-bottom: 15px; overflow-y: hidden; position: relative; border-radius: 2px;"></div>
    </div>
  </div>

</div>