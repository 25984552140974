<div *ngIf="lahetysdata | async">

  <div class="blue-background">
    <div style="margin-left: auto; margin-right: auto">

      <div class="ylapalkki valkoinen">

        <button class="sulje" mat-button (click)="peruutaLahettaminen()" type="button">
          <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
        </button>

        <div class="dropparit-container" style="width: 100%;">
          <div class="dropparit">
            <button (click)="vaihdaValilehti(0)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 0">
              <i class="icon ion-ios-at-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.laheta-lasku-spostilla' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(1)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 1">
              <i class="icon ion-ios-flash-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.verkkolasku.laheta-verkkolaskuna' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(3)" disabled mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 3">
              <i class="icon ion-ios-mail-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.paperi.laheta' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(2)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 2">
              <i class="icon ion-ios-person-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.itse.laheta' | translate | async}}
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <app-laheta-component [data]="lahetysdata | async" (laskuLahetettiin)="kasitteleLahetys($event)" [aktiivinenValilehti]="aktiivinenValilehti | async"></app-laheta-component>

</div>

<div *ngIf="!(lahetysdata | async)" class="detail-page">

  <div class="otsake-container">

    <button class="sulje light-blue-text" mat-button (click)="peruuta()" type="button">
      <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
    </button>

    <div class="otsake">
      <div>
        <span>{{'lasku.katsele-muokattava-dialog.lasku' | translate | async}}</span>
        <h2 class="light-blue-text">{{otsikko}}</h2>
      </div>
      <ng-container *ngIf="summatiedotObservable | async let summatiedot">
        <div *ngIf="summatiedot.naytaMaksuaikaa">
          <span>{{'lasku.katsele-muokattava-dialog.maksuaikaa' | translate | async}}</span>
          <h2 [class.light-blue-text]="summatiedot.maksuaikaa > -1" [class.red-text]="summatiedot.maksuaikaa < 0">{{summatiedot.maksuaikaa}} pv</h2>
        </div>
        <div>
          <span>
            {{summatiedot.otsikko}}
          </span>
          <h2 [class.blue-text]="summatiedot.summaColor === 'blue'" [class.red-text]="summatiedot.summaColor === 'red'" [class.yellow-text]="summatiedot.summaColor === 'yellow'" [class.purple-text]="summatiedot.summaColor === 'purple'" [class.green-text]="summatiedot.summaColor == 'green'">
            {{summatiedot.summa | lmoney: kasiteltava?.valuutta | async}}</h2>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="nappula-container">

    <div class="nappulat" *ngIf="nakyvyystiedotObservable | async let nakyvyystiedot">

      <div>
        <button mat-button class="secondarybutton" (click)="muokkaa()" *ngIf="nakyvyystiedot.onkoMuokkaaNakyvissa">{{'lasku.katsele-muokattava-dialog.muokkaa' | translate | async}}</button>
        <!-- <button mat-button class="secondarybutton" (click)="naytaMuokkausEstettyDialogi()" *ngIf="nakyvyystiedot.onkoMuokkaaInfoNakyvissa">{{'lasku.katsele-muokattava-dialog.muokkaa' | translate | async}}</button> -->
        <!-- <button mat-button class="secondarybutton" >
          <mat-icon>info</mat-icon>
        </button> -->
        <button mat-button class="secondarybutton" (click)="kopioiUusiLasku()" *ngIf="nakyvyystiedot.onkoKopioiUusiLaskuNakyvissa">{{'lasku.katsele-muokattava-dialog.kopioi-uusi-lasku' | translate | async}}</button>
        <button mat-button class="secondarybutton" (click)="poistaLuonnos()" *ngIf="nakyvyystiedot.onkoPoistoLuonnosNakyvissa">{{'lasku.katsele-muokattava-dialog.poista-luonnos' | translate | async}}</button>
        <!-- <button mat-button class="secondarybutton" (click)="mitatoi()"  *ngIf="nakyvyystiedot.onkoMitatoiNakyvissa()">{{'lasku.katsele-muokattava-dialog.mitatoi' | translate | async}}</button> -->
        <button mat-button class="secondarybutton" (click)="hyvita()" *ngIf="nakyvyystiedot.onkoHyvitaNakyvissa">{{'lasku.katsele-muokattava-dialog.hyvita' | translate | async}}</button>
        <button mat-button class="secondarybutton" (click)="lataaLasku()" type="button" *ngIf="nakyvyystiedot.onkoLataaLaskuNakyvissa">{{'lasku.katsele-muokattava-dialog.lataa-lasku' | translate | async}}</button>
      </div>

      <div>
        <button mat-button class="secondarybutton" (click)="merkitseOsittainMaksetuksi()" *ngIf="nakyvyystiedot.onkoMerkitseOsittainMaksetuksiNakyvissa">{{ 'lasku.katsele-muokattava-dialog.merkitse-osittain-maksetuksi' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="lahetaMuistutuslasku()" *ngIf="nakyvyystiedot.onkoMaksumuistutusNakyvissa && !nakyvyystiedot.onkoPeruutaPerintaNakyvissa && nakyvyystiedot.onkoPerintaAloittamatta">{{ 'lasku.katsele-muokattava-dialog.laheta-maksumuistutus' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="lahetaPerintaritarimuistutuslaskuHallinto()" *ngIf="naytaPerintaritari && nakyvyystiedot.onkoMaksumuistutusNakyvissa">{{ 'lasku.katsele-muokattava-dialog.laheta-maksumuistutus' | translate | async }} (Perintäritari) HALLINTO</button>

        <div class="button-wrapper" [matTooltipDisabled]="nakyvyystiedot.onkoViePerintaanEnabled" [matTooltip]="'lasku.katsele-muokattava-dialog.perinta-epaaktiivinen' | translate | async" *ngIf="nakyvyystiedot.onkoViePerintaanNakyvissa">
          <button mat-button class="secondarybutton" (click)="lahetaPerintaan()" [disabled]="!nakyvyystiedot.onkoViePerintaanEnabled">
            {{ 'lasku.katsele-muokattava-dialog.vie-perintaan' | translate | async }}
          </button>
        </div>
        <button mat-button class="secondarybutton" (click)="peruutaLaskunPerinta()" *ngIf="nakyvyystiedot.onkoPeruutaPerintaNakyvissa">
          {{ 'lasku.katsele-muokattava-dialog.peruuta-perinta' | translate | async }}
        </button>

        <button mat-button class="secondarybutton" (click)="peruutaLuottotappiomerkinta()" *ngIf="nakyvyystiedot.onkoPeruutaLuottotappioMerkintaNakyvissa">{{ 'lasku.katsele-muokattava-dialog.peruuta-merkitse-luottotappioksi' | translate | async }}</button>
        <button mat-button class="secondarybutton" (click)="merkitseLuottotappioksi()" *ngIf="nakyvyystiedot.onkoMerkitseLuottotappioksiNakyvissa">{{ 'lasku.katsele-muokattava-dialog.merkitse-luottotappioksi' | translate | async }}</button>
      </div>

      <!-- <div *ngIf="naytaTinkAutentikointi">
        <button mat-button class="secondarybutton" (click)="laskeReskontraUudelleen()">LASKE RESKONTRA UUDELLEEN</button>
        <button mat-button class="secondarybutton" (click)="paivitaTilaJaIndeksoiUudelleen()">PAIVITA TILA JA INDEKSOI UUDELLEEN</button>
      </div> -->

    </div>

    <ng-container *ngIf="naytaEpaonnistunutSahkoinen()">
      <mat-card appearance="outlined" class="laheta-uudelleen">
        <mat-card-header>
          <mat-card-title>{{'lasku.katsele-muokattava-dialog.sahkoinen.lahettaminen-epaonnistui' | translate | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="sahkoinenForm" novalidate>

            <mat-form-field>
              <mat-label>{{ 'lasku.katsele-muokattava-dialog.sahkoinen.valittaja' | translate | async }}</mat-label>
              <mat-select formControlName="valittaja" required>
                <mat-option *ngFor="let operator of verkkolaskuoperaattorit" [value]="operator.tunnus">
                  {{ operator.nimi }} ({{ operator.tunnus }})
                </mat-option>
              </mat-select>
              <mat-error *ngIf="valittajaControl?.errors?.required && (valittajaControl?.dirty || valittajaControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.valittaja.required' | translate | async}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'lasku.katsele-muokattava-dialog.sahkoinen.osoite' | translate | async }}</mat-label>
              <input type="text" [name]="namename" formControlName="osoite" matInput required />
              <mat-hint *ngIf="peppolValidationObservable | async; let peppolMessage">{{ peppolMessage.message }}</mat-hint>
              <mat-hint>{{'lasku.katsele-muokattava-dialog.sahkoinen.virheellinen-osoite' | translate | async}}</mat-hint>
              <mat-error *ngIf="osoiteControl?.errors?.minlength && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.minlength' | translate | async}}</mat-error>
              <mat-error *ngIf="osoiteControl?.errors?.required && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.required' | translate | async}}</mat-error>
              <mat-error *ngIf="osoiteControl?.errors?.valittaja && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.valittaja' | translate | async}}</mat-error>
            </mat-form-field>

            <button mat-button class="primarybutton" (click)="lahetaSahkoinenUudelleen()" type="button">
              <!-- <mat-icon>present_to_all</mat-icon> -->
              {{'lasku.katsele-muokattava-dialog.uudelleen-lahetys' | translate | async}}
            </button>

          </form>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <ng-container *ngIf="naytaEpaonnistunutSposti()">
      <mat-card appearance="outlined" class="laheta-uudelleen">
        <mat-card-header>
          <mat-card-title>{{'lasku.katsele-muokattava-dialog.spostien-lahettaminen-epaonnistui' | translate | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let korjattava of korjattavatEmailOsoitteet">
            <form novalidate>

              <mat-form-field>
                <mat-label>{{ 'lasku.katsele-muokattava-dialog.spostin-lahetysvirhe' | translate | async }}</mat-label>
                <input type="text" [name]="namename" [(ngModel)]="korjattava.uusiEmail" matInput required email />
                <mat-error>{{'lasku.katsele-muokattava-dialog.virheellinen-sahkoposti' | translate | async}}</mat-error>
                <mat-hint align="start">{{'lasku.katsele-muokattava-dialog.tekninen-virhe' | translate | async}}: {{korjattava.virhe?.Description}} </mat-hint>
              </mat-form-field>

              <button mat-button class="secondarybutton" (click)="naytaKokoVirhe(korjattava)">
                <!-- <mat-icon>bug_report</mat-icon> -->
                {{'lasku.katsele-muokattava-dialog.koko-virheen-naytto' | translate | async}}
              </button>
              <button mat-button class="primarybutton" (click)="lahetaEmailUudelleen(korjattava)" type="button">
                <!-- <mat-icon>present_to_all</mat-icon> -->
                {{'lasku.katsele-muokattava-dialog.uudelleen-lahetys' | translate | async}}
              </button>

            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>

  </div>

  <!--
  <div><pre>
    {{juurilasku?.reskontra | json}}
  </pre></div> -->

  <div style="max-width: 695px; width: 100%; margin-bottom: 25px;">
    <lasku-pdf-esikatselu-perinteinen [tiedot]="esikatselutiedot"></lasku-pdf-esikatselu-perinteinen>
  </div>

  <div *ngIf="kasiteltava?.liitteet && kasiteltava?.liitteet?.length > 0">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">{{ 'lasku.liitetiedostot.otsikko' | translate | async }}</div>
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let liite of kasiteltava?.liitteet" style="cursor: pointer;" (click)="lataaLiitetiedosto(liite)" [selectable]="false">
        {{liite.nimi}}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <!-- <div *ngIf="kasiteltava?.liitteet" style="padding-bottom: 48px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">Liitetiedostot</div>
    <div (click)="lataaLiitetiedosto(liite)" *ngFor="let liite of kasiteltava?.liitteet"></div>
  </div> -->

  <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">{{ 'lasku.tapahtumaloki.otsikko' | translate | async }}</div>
    <mat-progress-bar mode="indeterminate" *ngIf="lokitietojaLadataanObservable | async"></mat-progress-bar>
    <div *ngFor="let loki of lokitiedotObservable | async" matTooltipPosition="before" [matTooltip]="loki.loki.pvm | ltimestamptime | async">
      {{ loki.loki.pvm | ltimestamp | async }}: {{ 'lasku.tapahtumaloki.tyyppi' + loki.loki.toiminto | translate: loki.parametrit | async }}
    </div>
  </div>

  <div *ngIf="reskontraLokiObservable | async; let reskontraLokit" style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
    <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">
      {{ 'lasku.katsele-muokattava-dialog.reskontrahistoria' | translate | async }}
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="reskontraaLadataanObservable | async"></mat-progress-bar>
    <div *ngFor="let merkinta of reskontraLokit">
      {{ merkinta.teksti}}
    </div>
  </div>

  <div *ngIf="isLemontreeAdmin">
    <!-- <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
      <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">
        Uusi reskontrahistoria (Tarkemmat tiedot klikkaamalla)
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="reskontraaLadataanObservable | async"></mat-progress-bar>
      <div *ngFor="let merkinta of reskontraObservable | async" (click)="naytaMerkinta(merkinta)" style="cursor: pointer;">
        {{ merkinta.pvm | ltimestamp | async }}: {{ merkinta.suoritus | lmoney: kasiteltava?.valuutta | async }}
      </div>
    </div> -->

    <!-- <button mat-button class="secondarybutton" (click)="lataaLasku2()" type="button">{{'lasku.katsele-muokattava-dialog.lataa-lasku' | translate | async}}</button> -->
    <button mat-button class="secondarybutton" (click)="lahetaUudelleen()" type="button">{{'lasku.katsele-muokattava-dialog.laheta-uudelleen' | translate | async}}</button>
    <button mat-button class="secondarybutton" (click)="lahetaHyvityslaskuUudelleenSahkopostiin()">Lähetä hyvityslasku uudelleen sähköpostilla</button>

    <div style="color: rgb(114,115,115); font-size: 12px; max-width: 695px;">
      <div class="light-blue-text" style="font-size: 16px; margin-top: 40px; margin-bottom: 10px;">Laskuversiot:</div>
      <div *ngFor="let versio of versiot">
        Tallennettu {{ versio.kasiteltava.date | ltimestamptime | async }} <a href="#" *ngIf="versio.onkoLataaLaskuNakyvissa" (click)="lataaLaskuKasiteltavalle(versio.lasku, versio.kasiteltava, true); $event.preventDefault();">Lataa PDF</a>
      </div>
    </div>
  </div>

</div>