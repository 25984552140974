import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { KayttajaService } from './_angular/service/kayttaja.service'
import { Roolit } from './_jaettu/model/kayttaja'

@Component({
  template: `
  <div style="height: 100%; width: 100%; box-sizing: border-box; display: flex; flex-wrap: wrap; align-content: flex-start;">
    <div style="width: 100%; margin-bottom: 20px; margin-top: 40px; display: flex; justify-content: center; box-sizing: border-box;"><mat-spinner></mat-spinner></div>
    <div style="width: 100%; display: flex; justify-content: center; box-sizing: border-box;">Ladataan käyttäjätietoja, ole hyvä ja odota...</div>
  </div>`
})
export class DefaultRedirectComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private errorHandler: ErrorHandler,
    public kayttajaService: KayttajaService,
    public router: Router
  ) { }

  ngOnInit() {
    this.kayttajaService.kayttajaObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kayttajanTiedot => {
      if (kayttajanTiedot) {
        if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.LASKUTUS]) {
          this.router.navigate(['laskutus/laskut'])
        } else if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.TOSITTEET_OSTO]) {
          this.router.navigate(['tositteet/osto'])
        } else if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.TOSITTEET_MYYNTI]) {
          this.router.navigate(['tositteet/myynti'])
        } else if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.TOSITTEET_TILI]) {
          this.router.navigate(['tositteet/tiliote'])
        } else if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.TOSITTEET_PALKKA]) {
          this.router.navigate(['tositteet/palkka'])
        } else if (kayttajanTiedot.roolit && kayttajanTiedot.roolit[Roolit.PALKAT]) {
          this.router.navigate(['palkat'])
        } else if (kayttajanTiedot.roolit?.TUNNISTETAAN) {
          this.router.navigate(['kirjanpidon-raportit'])
        } else {
          this.router.navigate(['eirooleja'])
        }
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
