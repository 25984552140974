import { Injectable, ErrorHandler } from '@angular/core'

import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { DataSource } from '@angular/cdk/table'

import { Observable, BehaviorSubject, combineLatest, of as observableOf } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { LaskunSorttaukset } from '../_jaettu/service/lasku/lasku.indeksoija'
import { DateService } from '../_shared-core/service/date.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { FirestoreIndeksoija } from '../_jaettu/service/firestore.indeksoija'

import { KayttajaService } from '../_angular/service/kayttaja.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { LaskuTransformingWrappingDataSource } from '../_jaettu-angular/laskut/transforming-lasku.datasource'

import { InvoiceSource, Lasku, LaskunTila } from '../_jaettu/model/lasku'
import { DocumentSnapshot } from 'firebase/firestore'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

export interface LaskunLokalisoituTila {
  nimi: string
  tunnus: string
}

export interface VuosiKk {
  vuosi: number
  kk: number
  kohde: 'p' | 'e'
}

export interface Hakukriteerit {
  vapaahaku: string
  tila: LaskunTila
  vuosikk: VuosiKk
}

interface Hakutiedot {
  hakukriteerit: Hakukriteerit
  sivutustiedot: PageEvent
  lajittelutiedot: Sort
}

@Injectable()
export class LaskutFirestoreDataSource extends DataSource<Lasku> {

  private rivienMaaraSubject = new BehaviorSubject(Number.MAX_SAFE_INTEGER)
  public rivienMaaraObservable: Observable<number> = this.rivienMaaraSubject.asObservable()

  public laskunTilatObservable: Observable<LaskunLokalisoituTila[]>

  private internalLaskutObservable: Observable<Lasku[]>
  public laskutObservable: Observable<Lasku[]>

  dataSourceTransformed: LaskuTransformingWrappingDataSource = null

  private lataaSubject = new BehaviorSubject<boolean>(true)
  public lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private oletuskriteerit: Hakukriteerit = {
    tila: LaskunTila.avoin,
    vuosikk: {
      kk: null,
      vuosi: null,
      kohde: 'p'
    },
    vapaahaku: null
  }

  private oletussivutustiedot: PageEvent = {
    length: Number.MAX_SAFE_INTEGER,
    pageIndex: 0,
    pageSize: 20
  }

  private oletuslajittelutiedot: Sort = {
    active: 'nro',
    direction: 'desc'
  }

  private oletusHakutiedot: Hakutiedot = {
    hakukriteerit: this.kopioiHakukriteerit(this.oletuskriteerit),
    lajittelutiedot: this.kopioiLajittelutiedot(this.oletuslajittelutiedot),
    sivutustiedot: this.kopioiSivutustiedot(this.oletussivutustiedot)
  }

  private hakutiedotSubject = new BehaviorSubject<Hakutiedot>(this.oletusHakutiedot)
  public hakutiedotObservable = this.hakutiedotSubject.asObservable()

  private lastVisible: DocumentSnapshot<Lasku>[] = []

  constructor(
    private errorHandler: ErrorHandler,
    private _laskuSharedService: LaskuSharedService,
    private _kayttajaService: KayttajaService,
    private lemonTranslationService: LemonTranslationService,
    private _currencyService: CurrencyService,
    private dateService: DateService,
    private firestoreIndeksoija: FirestoreIndeksoija,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    super()

    this.dataSourceTransformed = new LaskuTransformingWrappingDataSource(this, this._currencyService, this.dateService, this._laskuSharedService, this.lemonTranslationService)

    this.laskunTilatObservable = this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {

        const tilat: LaskunLokalisoituTila[] = []
        tilat.push({ tunnus: LaskunTila.kaikki, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.kaikki, kieli) })
        tilat.push({ tunnus: LaskunTila.avoin, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.avoin, kieli) })
        tilat.push({ tunnus: LaskunTila.luottotappio, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.luottotappio, kieli) })
        tilat.push({ tunnus: LaskunTila.hyvitetty, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.hyvitetty, kieli) })
        tilat.push({ tunnus: LaskunTila.maksettu, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.maksettu, kieli) })
        tilat.push({ tunnus: LaskunTila.eraantynyt, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.eraantynyt, kieli) })
        tilat.push({ tunnus: LaskunTila.maksettuLiikaa, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.maksettuLiikaa, kieli) })
        tilat.push({ tunnus: LaskunTila.luonnos, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.luonnos, kieli) })
        tilat.push({ tunnus: LaskunTila.poistettu, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.poistettu, kieli) })
        tilat.push({ tunnus: LaskunTila.mitatoity, nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.mitatoity, kieli) })
        // tilat.push({ tunnus: LaskunTila.perinnassa,     nimi: this.lemonTranslationService.lokalisoiKielella('lasku.listaus.tila.' + LaskunTila.perinnassa,     kieli) })

        return tilat.sort((a, b): number => {
          if (a.tunnus === LaskunTila.kaikki) {
            return -1
          } else if (b.tunnus === LaskunTila.kaikki) {
            return 1
          }
          return a.nimi.localeCompare(b.nimi)
        })

      })
    )

    this.internalLaskutObservable = combineLatest([this.hakutiedotSubject, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      tap(() => {
        this.lataaSubject.next(true)
      }),
      switchMap(([tiedot, kayttaja]) => {

        const hakukriteerit = tiedot.hakukriteerit
        const sort = tiedot.lajittelutiedot
        const pageEvent = tiedot.sivutustiedot

        // console.log('TÄÄLLÄ!', hakukriteerit, pageEvent, sort, kayttaja)

        if (!kayttaja) {
          this.resetSearchToDefaults()
          return observableOf<Lasku[]>([])
        }

        const tila = hakukriteerit.tila

        // Jos muutat, tarkista eraantyneet.asiakas.kasittelija.ts
        const limitSize = pageEvent.pageSize + 1
        const sorttaus = LaskunSorttaukset.KAIKKI[sort.active]
        const tilaS = (tila && tila !== LaskunTila.kaikki) ? tila : ''
        const vuosikkS = this.getVuosiKkInternal(hakukriteerit.vuosikk)
        const vapaahakuS = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(hakukriteerit.vapaahaku)
        const hakuavain = sorttaus.tunniste + tilaS + vuosikkS + vapaahakuS
        const searchProperty = 'haku.' + hakuavain

        // console.log('Hae ', searchProperty, ':', '1 ' + sorttaus.tunniste, '2 ' + tilaS, '3 ' + vuosikkS, '4 ' + vapaahakuS, sorttaus.tyyppi)

        // console.log('laskut/' + kayttajanTiedot.asiakasId + '/laskut')

        // console.log(this.lastPageEvent.pageSize, this.lastPageEvent.pageIndex, this.lastPageEvent.pageSize * this.lastPageEvent.pageIndex)

        const uri = 'laskut/' + kayttaja.asiakasId + '/laskut'
        let q = this._firebaseLemonaid.firestoreCollection<Lasku>(uri)

        if (sorttaus.tyyppi === 'string') {
          q = q.whereFree(searchProperty, '>', '')
        } else if (sorttaus.tyyppi === 'number') {
          q = q.whereFree(searchProperty, '>', -99999999999)
        } else if (sorttaus.tyyppi === 'date') {
          q = q.whereFree(searchProperty, '>', -9999)
        }

        const direction = sort.direction === 'asc' ? 'asc' : 'desc'
        q = q.orderByFree(searchProperty, direction)

        if (pageEvent.pageIndex > 0 && pageEvent.pageIndex - 1 in this.lastVisible) {
          q = q.startAfter(this.lastVisible[pageEvent.pageIndex - 1]).limit(limitSize)
        } else {
          q = q.limit(limitSize)
        }

        return q.listenSnapshots().pipe(
          map(snapshots => {
            if (snapshots.length === limitSize) {
              this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
              snapshots.pop()
            } else {
              this.rivienMaaraSubject.next(pageEvent.pageSize * pageEvent.pageIndex + snapshots.length)
            }
            if (snapshots.length > 0) {
              const snap = snapshots[snapshots.length - 1]
              this.lastVisible[pageEvent.pageIndex] = snap
            }
            return snapshots.map(changeAction => changeAction.data()).filter(lasku => !(lasku.source === InvoiceSource.MINDUU))
          }),
          map(laskut => {
            for (const lasku of laskut) {
              delete lasku['haku']
              if (lasku.korvaus) {
                for (const korvaava of lasku.korvaus) {
                  delete korvaava['haku']
                }
              }
            }
            return laskut
          }),
          tap(() => {
            setTimeout(() => { this.lataaSubject.next(false) }, 0)
          })
        )
      }),
      lemonShare()
    )

    this.laskutObservable = combineLatest([this.internalLaskutObservable, this.lataaObservable]).pipe(
      map(([laskut, lataa]) => {
        if (lataa) {
          return []
        }
        return laskut
      })
    )

  }

  private kopioiHakukriteerit(lahde: Hakukriteerit): Hakukriteerit {
    return {
      tila: lahde.tila,
      vapaahaku: lahde.vapaahaku,
      vuosikk: {
        kk: lahde.vuosikk.kk,
        kohde: lahde.vuosikk.kohde,
        vuosi: lahde.vuosikk.vuosi
      }
    }
  }

  private kopioiSivutustiedot(lahde: PageEvent): PageEvent {
    return {
      length: lahde.length,
      pageIndex: lahde.pageIndex,
      pageSize: lahde.pageSize,
      previousPageIndex: lahde.previousPageIndex
    }
  }

  private kopioiLajittelutiedot(lahde: Sort): Sort {
    return {
      active: lahde.active,
      direction: lahde.direction
    }
  }

  set search(vapaahaku: string) {
    const hakutiedot = this.hakutiedotSubject.value
    if (hakutiedot.hakukriteerit.vapaahaku !== vapaahaku) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.hakukriteerit.vapaahaku = vapaahaku
      this.hakutiedotSubject.next(hakutiedot)
    }
  }

  set page(pageEvent: PageEvent) {
    const hakutiedot = this.hakutiedotSubject.value
    hakutiedot.sivutustiedot = pageEvent
    this.hakutiedotSubject.next(hakutiedot)
  }

  set sort(sort: Sort) {
    const hakutiedot = this.hakutiedotSubject.value
    this.valmistauduHakuehdonMuutokseen(hakutiedot)
    hakutiedot.lajittelutiedot = sort
    this.hakutiedotSubject.next(hakutiedot)
  }

  set vuosiKkKohde(kohde: 'p' | 'e') {
    const hakutiedot = this.hakutiedotSubject.value
    if (hakutiedot.hakukriteerit.vuosikk.kohde !== kohde) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.hakukriteerit.vuosikk.kohde = kohde
      this.hakutiedotSubject.next(hakutiedot)
    }
  }

  setVuosiKk(vuosi: number, kk: number) {
    const hakutiedot = this.hakutiedotSubject.value
    if (hakutiedot.hakukriteerit.vuosikk.kk !== kk || hakutiedot.hakukriteerit.vuosikk.vuosi !== vuosi) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.hakukriteerit.vuosikk.kk = kk
      hakutiedot.hakukriteerit.vuosikk.vuosi = vuosi
      this.hakutiedotSubject.next(hakutiedot)
    }
  }

  set tila(tila: LaskunTila) {
    const hakutiedot = this.hakutiedotSubject.value
    if (hakutiedot.hakukriteerit.tila !== tila) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.hakukriteerit.tila = tila
      this.hakutiedotSubject.next(hakutiedot)
    }
  }


  get vuosiKk(): VuosiKk {
    return this.hakutiedotSubject.value.hakukriteerit.vuosikk
  }

  get tila(): LaskunTila {
    return this.hakutiedotSubject.value.hakukriteerit.tila
  }

  get search(): string {
    return this.hakutiedotSubject.value.hakukriteerit.vapaahaku
  }

  get page(): PageEvent {
    return this.hakutiedotSubject.value.sivutustiedot
  }

  get sort(): Sort {
    return this.hakutiedotSubject.value.lajittelutiedot
  }

  resetSearchToDefaults() {

    const hakutiedot = this.hakutiedotSubject.value
    const hakukriteerit = this.kopioiHakukriteerit(this.oletuskriteerit)
    const lajittelutiedot = this.kopioiLajittelutiedot(this.oletuslajittelutiedot)
    const sivutustiedot = this.kopioiSivutustiedot(this.oletussivutustiedot)

    // console.log('RESET')
    // If this is not here, a forever loop is reached when user logs out.
    if (
      JSON.stringify(hakutiedot.hakukriteerit) !== JSON.stringify(hakukriteerit) ||
      JSON.stringify(hakutiedot.lajittelutiedot) !== JSON.stringify(lajittelutiedot) ||
      JSON.stringify(hakutiedot.sivutustiedot) !== JSON.stringify(sivutustiedot)
    ) {
      // console.log('RESET CHANGED')
      hakutiedot.hakukriteerit = hakukriteerit
      hakutiedot.lajittelutiedot = lajittelutiedot
      hakutiedot.sivutustiedot = sivutustiedot
      this.lastVisible = []
      this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
      this.hakutiedotSubject.next(hakutiedot)
    }

  }

  private valmistauduHakuehdonMuutokseen(hakutiedot: Hakutiedot) {
    this.lastVisible = []
    hakutiedot.sivutustiedot.pageIndex = 0
    delete hakutiedot.sivutustiedot.previousPageIndex
    hakutiedot.sivutustiedot.length = Number.MAX_SAFE_INTEGER
    this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
  }

  connect(): Observable<Lasku[]> {
    return this.laskutObservable
  }

  private getVuosiKkInternal(vuosikk: VuosiKk): string {
    if (vuosikk && vuosikk.vuosi && vuosikk.kohde && vuosikk.kk > -1) {
      return vuosikk.vuosi.toString().substring(2) + vuosikk.kohde + vuosikk.kk
    }
    return ''
  }

  disconnect() {

  }

}
