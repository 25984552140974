import { SafeUrl } from '@angular/platform-browser'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { Injectable, ErrorHandler } from '@angular/core'

import { KayttajaService } from '../kayttaja.service'
import { TositeUriService } from '../../../_jaettu/service/tosite/tosite-uri.service'

import { FirestoreTosite, Maksutapa, FirestoreTositteenKuva } from '../../../_jaettu/model/tosite'

import { Observable, of as observableOf, BehaviorSubject, combineLatest } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'

import { TositeService } from './tosite.service'
import { KuitinSorttaukset } from '../../../_jaettu/service/tosite/tosite.indeksoija'
import { FirebaseLemonaid } from '../firebase-lemonaid.service'
import { DocumentSnapshot } from 'firebase/firestore'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

export interface ListausFirestoreKuitti extends FirestoreTosite {
  ensimmainenKuva: string
  ladataan: boolean
  naytaLisaa: boolean
}

export interface VuosiKk {
  vuosi: number
  kk: number
  kohde: 'p' | 'e'
}

export interface Hakukriteerit {
  vapaahaku: string
  naytaPoistetut: boolean
  maksutapa: string
  vuosikk: VuosiKk
}

export interface MaksutapaJaKuva {
  maksutapa: Maksutapa
  kuva: BehaviorSubject<SafeUrl>
  onkoNuoli: boolean
  timeout: any
  summa: number
  lukumaara: number
  valittu: boolean
}
export interface Summat {
  summa: number
  lukumaara: number
}

@Injectable()
export class TositeDatasourceService {

  private oletuskriteerit: Hakukriteerit = {
    vuosikk: {
      kk: new Date().getMonth(),
      vuosi: new Date().getFullYear(),
      kohde: 'p'
    },
    vapaahaku: null,
    naytaPoistetut: false,
    maksutapa: null
  }
  private hakukriteeritSubject = new BehaviorSubject<Hakukriteerit>(this.oletuskriteerit)
  public hakukriteeritObservable: Observable<Hakukriteerit> = this.hakukriteeritSubject.asObservable()

  private valittuMaksutapaSubject = new BehaviorSubject<Maksutapa | null>(null)
  public valittuMaksutapaObservable: Observable<Maksutapa | null> = this.valittuMaksutapaSubject.asObservable()

  private lataaSubject = new BehaviorSubject<boolean>(true)
  public lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private internalKuititObservable: Observable<ListausFirestoreKuitti[]>
  public kuititObservable: Observable<ListausFirestoreKuitti[]>
  public rivienMaaraObservable: Observable<number>

  private pageEventSubject = new BehaviorSubject<PageEvent>({
    length: Number.MAX_SAFE_INTEGER,
    pageIndex: 0,
    pageSize: Number.MAX_SAFE_INTEGER
  })
  private sortSubject = new BehaviorSubject<Sort>({
    active: 'pvm',
    direction: 'desc'
  })

  private edellisetHakukriteerit: Hakukriteerit = this.kopioiHakukriteerienKamat(this.oletuskriteerit)
  private lastVisible: DocumentSnapshot[] = []
  private lastPageEvent: PageEvent = null
  private lastSort: Sort = null

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private errorHandler: ErrorHandler,
    private kayttajaService: KayttajaService,
    private tositeUriService: TositeUriService,
    private tositeService: TositeService
  ) {

    this.tositeService.maksutavatObservable.pipe(take(1)).subscribe(maksutavat => {
      if (!this.annaValittuMaksutapa()) {
        if (maksutavat) {
          for (const maksutapa of maksutavat) {
            if (maksutapa.d) {
              this.valittuMaksutapaSubject.next(maksutapa)
              return
            }
          }
          this.valittuMaksutapaSubject.next(maksutavat[0])
        }
      }
    })
    this.internalKuititObservable = combineLatest([this.hakukriteeritObservable, this.pageEventSubject, this.sortSubject, this.kayttajaService.kayttajanTiedotObservable]).pipe(
      tap(() => {
        this.lataaSubject.next(true)
      }),
      switchMap(([hakukriteerit, pageEvent, sort, kayttajanTiedot]) => {

        if (!kayttajanTiedot) {
          return observableOf<ListausFirestoreKuitti[]>([])
        }

        if (
          this.edellisetHakukriteerit.vapaahaku !== hakukriteerit.vapaahaku ||
          this.edellisetHakukriteerit.vuosikk.kk !== hakukriteerit.vuosikk.kk ||
          this.edellisetHakukriteerit.vuosikk.kohde !== hakukriteerit.vuosikk.kohde ||
          this.edellisetHakukriteerit.vuosikk.vuosi !== hakukriteerit.vuosikk.vuosi
        ) {
          this.pageEventSubject.value.pageIndex = 0
        }

        this.edellisetHakukriteerit = this.kopioiHakukriteerienKamat(hakukriteerit)

        if (this.lastSort !== sort) {
          this.lastVisible = []
        }
        this.lastSort = sort
        this.lastPageEvent = pageEvent

        const sorttaus = KuitinSorttaukset.KAIKKI[sort.active]
        const poistettuS = hakukriteerit.naytaPoistetut ? '' : 'r1'
        const maksutapaS = hakukriteerit.maksutapa ? 'm' + hakukriteerit.maksutapa : ''
        const vuosikkS = hakukriteerit.vapaahaku ? '' : this.getVuosiKkInternal(hakukriteerit.vuosikk)
        const vapaahakuS = hakukriteerit.vapaahaku ? hakukriteerit.vapaahaku : ''
        const hakuavain = sorttaus.tunniste + poistettuS + maksutapaS + vuosikkS + vapaahakuS
        const searchProperty = 'haku.' + hakuavain

        const polku = this.tositeUriService.annaKuittienFirestoreCollectionUri(kayttajanTiedot.asiakasId)

        let q = this._firebaseLemonaid.firestoreCollection<ListausFirestoreKuitti>(polku)

        if (sorttaus.tyyppi === 'string') {
          q = q.whereFree(searchProperty, '>', '')
        } else if (sorttaus.tyyppi === 'number') {
          q = q.whereFree(searchProperty, '>', -99999999999)
        } else if (sorttaus.tyyppi === 'date') {
          q = q.whereFree(searchProperty, '>', -9999)
        }
        const direction = sort.direction === 'asc' ? 'asc' : 'desc'
        q = q.orderByFree(searchProperty, direction)

        return q.listen().pipe(
          map(snapshotsPreFilter => {
            const snapshots = snapshotsPreFilter.filter(snap => !snap.x)
            if (snapshots.length > 0) {
              this.lastVisible[this.lastPageEvent.pageIndex] = snapshots[snapshots.length - 1]['haku'][hakuavain]
            }
            for (const snapshot of snapshots) {
              delete snapshot['haku']
            }
            return snapshots.sort((a, b) => {
              if (a.localPvm.year !== b.localPvm.year) {
                return b.localPvm.year - a.localPvm.year
              }
              if (a.localPvm.month !== b.localPvm.month) {
                return b.localPvm.month - a.localPvm.month
              }
              if (a.localPvm.day !== b.localPvm.day) {
                return b.localPvm.day - a.localPvm.day
              }
              return b.summa - a.summa
            })
          }),
          tap(kuitit => {
            if (kuitit) {
              for (const kuitti of kuitit) {

                let kuva: FirestoreTositteenKuva = null
                for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
                  const mahdollinen = kuitti.kuvat[kuvanAvain]
                  if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
                    kuva = mahdollinen
                    if (kuva.jarjestys === 1) {
                      break
                    }
                  }
                }

                if (!kuva) {
                  // this.errorHandler.handleError(new Error('Yhtään kuvaobjektia ei löytynyt kuitista ' + kuitti.avain))
                  kuitti.ladataan = false
                  kuitti.ensimmainenKuva = '/assets/noimage.png'
                } else {
                  const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitti.kuvakansio, kuva.avain, kuva.type)
                  kuitti.ensimmainenKuva = '/api/1/kuitit/kuvat/thumb/' + kuvanUrl
                }

              }
            }
            // this.startKuitinKuvatLoading(kuitit, kayttajanTiedot)
            this.lataaSubject.next(false)
          })
        )
      }),
      lemonShare()
    )

    this.kuititObservable = combineLatest([this.internalKuititObservable, this.lataaObservable]).pipe(
      map(([kuitit, lataa]) => {
        if (lataa) {
          return []
        }
        return kuitit
      })
    )

    this.rivienMaaraObservable = this.kuititObservable.pipe(
      map(kuitit => {
        return kuitit ? kuitit.length : 0
      })
    )

  }
  // async startKuitinKuvatLoading(kuitit: ListausFirestoreKuitti[], kayttajanTiedot: KayttajanTiedot): Promise<void> {

  //   for (const kuitti of kuitit) {
  //     const lopullinenKuva = this.kuittiKuvaService.annaKuvaLopullisestaKakusta(kuitti)
  //     if (lopullinenKuva) {
  //       kuitti.ladataan = false
  //       kuitti.ensimmainenKuva = lopullinenKuva
  //     } else {
  //       kuitti.ladataan = true
  //       this.paivitaKuva(kuitti, kayttajanTiedot)
  //     }
  //   }
  // }

  private kopioiHakukriteerienKamat(lahde: Hakukriteerit): Hakukriteerit {
    return {
      vapaahaku: lahde.vapaahaku,
      vuosikk: {
        kk: lahde.vuosikk.kk,
        kohde: lahde.vuosikk.kohde,
        vuosi: lahde.vuosikk.vuosi
      },
      maksutapa: lahde.maksutapa,
      naytaPoistetut: lahde.naytaPoistetut
    }
  }

  annaValittuMaksutapa(): Maksutapa | null {
    return this.valittuMaksutapaSubject.value
  }

  vaihdaValittuMaksutapa(maksutapa: Maksutapa | null) {
    this.valittuMaksutapaSubject.next(maksutapa)
  }

  getSearch(): string {
    return this.hakukriteeritSubject.value.vapaahaku
  }

  getPage(): PageEvent {
    return this.pageEventSubject.value
  }

  getSort() {
    return this.sortSubject.value
  }

  changePage(pageEvent: PageEvent): void {
    this.pageEventSubject.next(pageEvent)
  }

  changeSort(sort: Sort): void {
    this.sortSubject.next(sort)
  }

  changeSearch(vapaahaku: string): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vapaahaku = vapaahaku
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  getVuosiKk(): VuosiKk {
    return this.hakukriteeritSubject.value.vuosikk
  }

  changeVuosiKkKohde(kohde: 'p' | 'e'): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vuosikk.kohde = kohde
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  changeVuosiKk(vuosi: number, kk: number): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.vuosikk.vuosi = vuosi
    nykyisetArvot.vuosikk.kk = kk
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  resetSearchToDefaults() {
    this.hakukriteeritSubject.next(this.oletuskriteerit)
  }

  getMaksutapa(): string | null {
    return this.hakukriteeritSubject.value.maksutapa
  }

  changeMaksutapa(maksutapa: string): void {
    const nykyisetArvot = this.hakukriteeritSubject.value
    nykyisetArvot.maksutapa = maksutapa
    this.hakukriteeritSubject.next(nykyisetArvot)
  }

  private getVuosiKkInternal(vuosikk: VuosiKk): string {
    if (vuosikk && vuosikk.vuosi && vuosikk.kohde && vuosikk.kk > -1) {
      return vuosikk.vuosi.toString().substring(2) + vuosikk.kohde + vuosikk.kk
    }
    return ''
  }

  // private paivitaKuva(kuitti: ListausFirestoreKuitti, kayttajanTiedot: KayttajanTiedot) {

  //   let kuva: FirestoreTositteenKuva = null
  //   for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
  //     const mahdollinen = kuitti.kuvat[kuvanAvain]
  //     if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
  //       kuva = mahdollinen
  //       if (kuva.jarjestys === 1) {
  //         break
  //       }
  //     }
  //   }

  //   if (!kuva) {
  //     this.errorHandler.handleError(new Error('Yhtään kuvaobjektia ei löytynyt kuitista ' + kuitti.avain))
  //     kuitti.ladataan = false
  //     kuitti.ensimmainenKuva = '/assets/noimage-error.jpg'
  //     return
  //   }

  //   const kuvaValimuistista = this.kuittiKuvaService.annaKuva(kuva)
  //   if (kuvaValimuistista &&
  //     kuvaValimuistista.kuva &&
  //     kuvaValimuistista.kuva.kuvanUrlObservable &&
  //     kuvaValimuistista.kuva.kuvanUrlObservable.value) {
  //     kuitti.ensimmainenKuva = kuvaValimuistista.kuva.kuvanUrlObservable.value
  //     kuitti.ladataan = false
  //     return
  //   }

  //   let lataa: boolean = true
  //   if (kuva.alkuperaisenAvain && kuitti.alkuperaiset) {
  //     const alkuperainen = kuitti.alkuperaiset[kuva.alkuperaisenAvain]
  //     lataa = alkuperainen?.kasitelty
  //   }

  //   if (lataa) {
  //     const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitti.kuvakansio, kuva.avain, kuva.type)
  //     return this.httpService.getBinary('/api/1/kuitit/kuvat/thumb/' + kuvanUrl).then(blob => {
  //       // console.log('TÄÄLLÄ 3')
  //       const url = URL.createObjectURL(blob)
  //       return this.sanitizer.bypassSecurityTrustUrl(url)
  //     }).then(safeUrl => {
  //       // console.log('TÄÄLLÄ 3')
  //       // console.log('Returning', safeUrl)
  //       kuitti.ensimmainenKuva = safeUrl
  //       kuitti.ladataan = false
  //       this.kuittiKuvaService.lisaaKuvaLopulliseenKakkuun(kuitti, safeUrl)
  //     }).catch(error => {
  //       kuitti.ensimmainenKuva = '/assets/noimage-error.png'
  //       kuitti.ladataan = false
  //       this.errorHandler.handleError(error)
  //     })
  //   }
  //   kuitti.ensimmainenKuva = '/assets/noimage.png'
  //   kuitti.ladataan = false
  //   this.errorHandler.handleError(new Error('Alkuperäinen missing: ' + kuva.alkuperaisenAvain))
  // }

}
