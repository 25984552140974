import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot } from '@angular/router'

import { LaskuService } from '../service/lasku/lasku.service'

import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'
import { AsiakkaanTuote, LaskunAsiakas, Lasku, LaskuBase, UUDEN_LASKUN_AVAIN, LaskunumeroTyyppi, Laskuasetukset } from '../../_jaettu/model/lasku'

import { of as observableOf, Observable, from, firstValueFrom } from 'rxjs'
import { map, mergeMap, take } from 'rxjs/operators'
import { LaskuttavaAsiakasProvider } from 'app/_jaettu-angular/service/lasku/laskuttava-asiakas.service'

export interface LaskuKatseleComponentData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  tulosta: boolean
}

export interface LaskuKatseleComponentExistingData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
}

export interface LaskuComponentExistingData {
  juurilasku: Lasku
  asetukset: Laskuasetukset
}

export interface LaskuComponentData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
}

export interface LaskutComponentData {
  nollaaHakuasetukset: boolean
}

@Injectable()
export class LaskuComponentDataResolve {

  private existingData: LaskuComponentExistingData = null

  constructor(
    private router: Router,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private laskuSharedService: LaskuSharedService,
    private laskuttavaAsiakasProvider: LaskuttavaAsiakasProvider
  ) {

  }

  asetaOlemassaolevaData(data: LaskuComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskuComponentData> {
    const id = route.params['id']
    const kasiteltavaAvain = route.params['kasiteltavaAvain']
    if (id === UUDEN_LASKUN_AVAIN) {
      const lasku = this.laskuKopioija.annaUusiLasku()
      return this.haeOletusAsetukset({ juurilasku: lasku, kasiteltava: lasku })
    } else if (
      this.existingData != null &&
      this.existingData.juurilasku &&
      this.existingData.juurilasku.avain === id
    ) {
      return this.hoidaMuokattavat(this.existingData.juurilasku, kasiteltavaAvain)
    } else {
      return this.laskuService.getLaskuObservable(id).pipe(
        take(1),
        mergeMap(juurilasku => {

          if (!juurilasku) {
            this.router.navigate(['/laskutus/laskut'])
            return observableOf(null)
          }

          return this.hoidaMuokattavat(juurilasku, kasiteltavaAvain)

        })
      )
    }
  }

  private hoidaMuokattavat(juurilasku: Lasku, kasiteltavaAvain: string): Observable<LaskuComponentData> {
    const edellinen = this.laskuSharedService.annaViimeisinKasiteltavaLasku(juurilasku)
    return this.haeAsiakas(edellinen).pipe(
      mergeMap(asiakas => {

        const viimeinenTavallinen = this.laskuSharedService.annaViimeisinTavallinenLasku(juurilasku)
        if (kasiteltavaAvain === 'kopioiuusi') {
          return from(this.laskuKopioija.kopioiUusiLasku(viimeinenTavallinen, asiakas, {
            onkoLaskunLuonutAsiakasOssRekisterissa: (): Promise<boolean> => {
              return firstValueFrom(this.laskuttavaAsiakasProvider.onkoOssRekisterissa())
            }
          })).pipe(
            mergeMap(muokattavaksiJuuri => {
              return this.haeLaskunLaskuAsetukset({ juurilasku: muokattavaksiJuuri, kasiteltava: muokattavaksiJuuri })
            })
          )
        }

        if (kasiteltavaAvain === 'hyvita') {
          const muokattavaksiJuuri = this.laskuKopioija.copyLasku(juurilasku)
          const muokattavaksiKasiteltava = this.laskuKopioija.kopioiLaskuHyvitettavaksi(viimeinenTavallinen, asiakas)
          if (!muokattavaksiJuuri.korvaus) {
            muokattavaksiJuuri.korvaus = []
          }
          muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava)
          return this.haeLaskunLaskuAsetukset({ juurilasku: muokattavaksiJuuri, kasiteltava: muokattavaksiKasiteltava })
        }

        if (kasiteltavaAvain === UUDEN_LASKUN_AVAIN) {
          const muokattavaksiJuuri = this.laskuKopioija.copyLasku(juurilasku)
          const muokattavaksiKasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(viimeinenTavallinen, asiakas)
          if (!muokattavaksiJuuri.korvaus) {
            muokattavaksiJuuri.korvaus = []
          }
          muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava)
          return this.haeLaskunLaskuAsetukset({ juurilasku: muokattavaksiJuuri, kasiteltava: muokattavaksiKasiteltava })
        }
        const kasiteltava = this.laskuSharedService.annaKasiteltavaLasku(juurilasku, kasiteltavaAvain)
        if (kasiteltava && this.laskuSharedService.onkoKasiteltavaLuonnos(juurilasku, kasiteltava)) {
          const kopioidut = this.laskuKopioija.kopioiEiLahetettyMuokattavaksi(juurilasku, kasiteltava, asiakas)
          return this.haeLaskunLaskuAsetukset({ juurilasku: kopioidut.muokattavaJuuri, kasiteltava: kopioidut.muokattavaKasiteltava })
        } else if (kasiteltava && kasiteltava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || kasiteltava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
          const muokattavaksiJuuri = this.laskuKopioija.copyLasku(juurilasku)
          const muokattavaksiKasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(viimeinenTavallinen, asiakas)
          if (!muokattavaksiJuuri.korvaus) {
            muokattavaksiJuuri.korvaus = []
          }
          muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava)
          return this.haeLaskunLaskuAsetukset({ juurilasku: muokattavaksiJuuri, kasiteltava: muokattavaksiKasiteltava })
        } else if (kasiteltava && kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
          const kopioidut = this.laskuKopioija.kopioiEiLahetettyMuokattavaksi(juurilasku, kasiteltava, asiakas)
          return this.haeLaskunLaskuAsetukset({ juurilasku: kopioidut.muokattavaJuuri, kasiteltava: kopioidut.muokattavaKasiteltava })
        }

        this.router.navigate(['/laskutus/laskut', juurilasku.avain, viimeinenTavallinen.avain])
        return observableOf(null)

      }),
      take(1)
    )
  }

  private haeAsiakas(kasiteltava: LaskuBase): Observable<LaskunAsiakas | null> {
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.avain) {
      return this.laskuService.getAsiakasObservable(kasiteltava.asiakas.avain)
    }
    return observableOf(null)
  }

  private haeLaskunLaskuAsetukset(existingData: LaskuKatseleComponentExistingData): Observable<LaskuComponentData> {
    if (existingData.kasiteltava.asetukset) {
      const data: LaskuComponentData = {
        asetukset: existingData.kasiteltava.asetukset,
        juurilasku: existingData.juurilasku,
        kasiteltava: existingData.kasiteltava
      }
      return observableOf(data)
    }
    return this.haeOletusAsetukset(existingData)
  }

  private haeOletusAsetukset(existingLukittuData: LaskuKatseleComponentExistingData): Observable<LaskuComponentData> {
    return this.laskuService.asetuksetObservable.pipe(
      take(1),
      map(asetukset => {
        if (asetukset) {
          const data: LaskuComponentData = {
            asetukset: asetukset,
            juurilasku: existingLukittuData.juurilasku,
            kasiteltava: existingLukittuData.kasiteltava
          }
          return data
        }
        this.router.navigate(['/laskutus/laskut'])
        return null
      })
    )
  }

}

@Injectable()
export class LaskuKatseleComponentDataResolve {

  private existingData: LaskuKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private laskuSharedService: LaskuSharedService
  ) {

  }

  asetaOlemassaolevaData(data: LaskuKatseleComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskuKatseleComponentData> {
    const id = route.params['id']
    const kasiteltavaAvain = route.params['kasiteltavaAvain']
    const toiminto = route.params['toiminto']
    if (id === 'uusi') {
      const lasku = this.laskuKopioija.annaUusiLasku()
      return this.haeOletusAsetukset({ juurilasku: lasku, kasiteltava: lasku }, toiminto)
    } else if (
      this.existingData != null &&
      this.existingData.juurilasku &&
      this.existingData.kasiteltava &&
      this.existingData.juurilasku.avain === id &&
      this.existingData.kasiteltava.avain === kasiteltavaAvain
    ) {
      return this.haeLaskunLaskuAsetukset(this.existingData, toiminto)
    } else {
      return this.laskuService.getLaskuObservable(id).pipe(
        take(1),
        mergeMap(juurilasku => {

          if (!juurilasku) {
            this.router.navigate(['/laskutus/laskut'])
            return observableOf(null)
          }

          if (kasiteltavaAvain === 'uusi') {
            const edellinen = this.laskuSharedService.annaViimeisinKasiteltavaLasku(juurilasku)
            const kasiteltava = this.laskuKopioija.kopioiLaskuMuokattavaksi(edellinen, null)
            if (!juurilasku.korvaus) {
              juurilasku.korvaus = []
            }
            juurilasku.korvaus.push(kasiteltava)
            return this.haeLaskunLaskuAsetukset({ juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
          } else {
            const kasiteltava = this.laskuSharedService.annaKasiteltavaLasku(juurilasku, kasiteltavaAvain)
            return this.haeLaskunLaskuAsetukset({ juurilasku: juurilasku, kasiteltava: kasiteltava }, toiminto)
          }

        })
      )
    }
  }

  private haeLaskunLaskuAsetukset(existingLukittuData: LaskuKatseleComponentExistingData, toiminto: string): Observable<LaskuKatseleComponentData> {
    if (existingLukittuData.kasiteltava.asetukset) {
      const data: LaskuKatseleComponentData = {
        asetukset: existingLukittuData.kasiteltava.asetukset,
        juurilasku: existingLukittuData.juurilasku,
        kasiteltava: existingLukittuData.kasiteltava,
        tulosta: toiminto === 'tulosta'
      }
      return observableOf(data)
    }
    return this.haeOletusAsetukset(this.existingData, toiminto)
  }


  private haeOletusAsetukset(existingLukittuData: LaskuKatseleComponentExistingData, toiminto: string): Observable<LaskuKatseleComponentData> {
    return this.laskuService.asetuksetObservable.pipe(
      take(1),
      map(asetukset => {
        if (asetukset) {
          const data: LaskuKatseleComponentData = {
            asetukset: asetukset,
            juurilasku: existingLukittuData.juurilasku,
            kasiteltava: existingLukittuData.kasiteltava,
            tulosta: toiminto === 'tulosta'
          }
          return data
        }
        this.router.navigate(['/laskutus/laskut'])
        return null
      })
    )
  }

}

@Injectable()
export class TuoteComponentDataResolve {

  private existingData: AsiakkaanTuote = null

  constructor(
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija
  ) {

  }

  asetaOlemassaolevaData(data: AsiakkaanTuote) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<AsiakkaanTuote> {
    const id = route.params['id']
    if (id === 'uusi') {
      return observableOf(this.laskuKopioija.annaUusiTuote())
    } else if (this.existingData != null && this.existingData.$key === id) {
      return observableOf(this.existingData)
    } else {
      return this.laskuService.getTuoteObservable(id).pipe(take(1))
    }
  }

}

@Injectable()
export class AsiakasComponentDataResolve {

  private existingData: LaskunAsiakas = null

  constructor(
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija
  ) {

  }

  asetaOlemassaolevaData(data: LaskunAsiakas) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskunAsiakas> {
    const id = route.params['id']
    if (id === 'uusi') {
      return observableOf(this.laskuKopioija.annaUusiAsiakas())
    } else if (this.existingData != null && this.existingData.avain === id) {
      return observableOf(this.existingData)
    } else {
      return this.laskuService.getAsiakasObservable(id).pipe(take(1))
    }
  }

}

@Injectable()
export class LaskutComponentDataResolve {

  private existingData: LaskutComponentData = null

  constructor() {

  }

  asetaOlemassaolevaData(data: LaskutComponentData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<LaskutComponentData> {
    if (this.existingData) {
      const existing = this.existingData
      this.existingData = null
      return observableOf(existing)
    } else {
      return observableOf(null)
    }
  }

}
