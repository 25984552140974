import { Injectable } from '@angular/core'

import { EuVatSpecMap, LaskunAlv, LaskunTyyppi } from '../../_jaettu/model/lasku'
import { AlvBaseService } from '../../_jaettu/service/alv-base.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from 'app/_shared-core/service/date.service'

import { SharedFirebaseLemonaid } from 'app/_jaettu-angular/base-firebase.service'

@Injectable()
export class AlvService extends AlvBaseService {

  constructor(
    protected _currencyService: CurrencyService,
    protected _dateService: DateService,
    private _firebase: SharedFirebaseLemonaid,
  ) {
    super(_currencyService, _dateService)
  }

  private _mapCache: EuVatSpecMap = null
  public override async annaLaskutyypinAlvt(laskunTyyppi: LaskunTyyppi, maaKoodi: string): Promise<LaskunAlv[]> {
    return super.annaLaskutyypinAlvtWithSpecMapProvider(laskunTyyppi, maaKoodi, {
      provide: async (): Promise<EuVatSpecMap> => {
        if (!this._mapCache) {
          this._mapCache = await this._firebase.firestoreDoc<EuVatSpecMap>('admin/current-eu-vat-numbers').get()
        }
        return this._mapCache
      }
    })
  }

}
