import { Component, Input, ViewChild, ElementRef, OnInit, ChangeDetectionStrategy, AfterViewInit, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core'

import { LaskuSharedService, LaskunSummat } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuKorkoService } from '../../../_jaettu/service/lasku/lasku-korko.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { AlvErittelynOsa } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { Lasku, LaskuBase, Laskuasetukset, LaskunTila, LaskunTyypit, LaskunumeroTyyppi } from '../../../_jaettu/model/lasku'
import { TuettuKieli } from '../../../_shared-core/model/common'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { WindowSizeService } from '../../../_jaettu-angular/service/window.service'

import { Big } from 'big.js'

import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface LaskuPdfEsikatselutiedot {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  rajoitaKorkeuteen?: number
}

@Component({
  selector: 'lasku-pdf-esikatselu-perinteinen, [lasku-pdf-esikatselu-perinteinen]',
  styleUrls: ['./pdf.perinteinen.component.css'],
  templateUrl: './pdf.perinteinen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuPdfEsikatseluPerinteinen implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('esikatseluContainer', { static: true }) esikatseluContainer: ElementRef
  @ViewChild('kokopdflasku', { static: true }) kokopdflasku: ElementRef
  @ViewChild('alaosa', { static: true }) alaosa: ElementRef
  @ViewChild('ylaosa', { static: true }) ylaosa: ElementRef

  private _ngUnsubscribe = new Subject<void>()
  private _tiedot: LaskuPdfEsikatselutiedot = null

  kieli: TuettuKieli = 'fi'
  transformStyleObservable: BehaviorSubject<string> = new BehaviorSubject('scale(' + 695 / 595 + ')')
  tuotteetMinimumHeightStyleObservable: BehaviorSubject<string> = new BehaviorSubject('277px')
  spacerHeightStyleObservable: BehaviorSubject<string> = new BehaviorSubject('842px')
  lataaminenValmisObservable: BehaviorSubject<boolean> = new BehaviorSubject(false)

  ulkoinenLasku: { nimi: string }
  alvErittely: AlvErittelynOsa[] = []
  virtuaaliviivakoodi = ''
  qrkoodi = ''
  mitatoity = false
  muistutus = false
  alvHuomautus: string = ''
  muotoiltuYtunnus: string = ''
  laskunSummat: LaskunSummat = null
  maksettuAiemmin: Big = null
  maksettavaaYhteensa: Big = null
  otsikko: string = ''
  iban: string = ''
  laskunumero: string = ''
  viitenumero: string = ''
  maa: string = ''
  laskuttajanMaa: string = ''
  maksuaikaa: number = 0
  pvm: string = ''
  toimituspvm: string = ''
  lokalisoituValuutta: string = ''
  lisatiedot: string = ''
  erapaiva: string = ''
  viivastysprosentti: string = ''
  muistutushuomautus: string = null

  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  asetukset: Laskuasetukset = null

  rajoitaKorkeuteen: number = null
  laskuLeftCoordinate: number = 2

  @Input() naytaLatausmerkinta: boolean = true
  @Input() keskita: boolean = false

  get tiedot() {
    return this._tiedot
  }
  @Input()
  set tiedot(t: LaskuPdfEsikatselutiedot) {

    if (!t) {
      this.lataaminenValmisObservable.next(false)
    }

    // console.log('set TÄÄLLÄ')
    this.rajoitaKorkeuteen = t && t.rajoitaKorkeuteen ? t.rajoitaKorkeuteen : null
    // console.log('Set stuff', this.rajoitaKorkeuteen)

    this._tiedot = t
    this.juurilasku = t ? t.juurilasku : null
    this.kasiteltava = t ? t.kasiteltava : null
    this.asetukset = t ? t.asetukset : null

    if (this.asetukset) {
      this.laskuttajanMaa = this.shared.annaLokalisoituMaa(this.asetukset.maakoodi, this.kasiteltava)
      if (this.asetukset.iban) {
        const trimmed = this.stringService.removeAllWhiteSpaces(this.asetukset.iban)
        this.iban = this.ibanService.formatoiIban(trimmed)
      } else {
        this.iban = ''
      }
    } else {
      this.laskuttajanMaa = ''
      this.iban = ''
    }

    if (this.juurilasku && this.kasiteltava) {
      this.ulkoinenLasku = this.shared.onkoUlkoinenLasku(this.kasiteltava) ? { nimi: this.shared.annaUlkoisenLaskunPalvelunNimi(this.kasiteltava.source, this.kasiteltava.kieli) } : null
      this.virtuaaliviivakoodi = this.shared.annaMuotoiltuVirtuaaliviivakoodi(this.juurilasku, this.kasiteltava, this.asetukset)
      this.qrkoodi = this.shared.annaMuotoiltuQrkoodi(this.juurilasku, this.kasiteltava, this.asetukset)
      this.muistutus = this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS
      this.mitatoity = this.shared.onkoKasiteltavaMitatoity(this.juurilasku, this.kasiteltava)
      this.alvHuomautus = this.shared.annaAlvHuomautus(this.kasiteltava)
      this.laskunSummat = this.shared.annaLaskunSummat(this.kasiteltava)
      this.muotoiltuYtunnus = this.shared.muotoileYtunnusLaskunTyypinPerusteella(this.kasiteltava, this.asetukset.ytunnus)

      if (this.muistutus) {
        const aiemminMaksettuTaiHyvitetty = this.kasiteltava.summaReskontraLaskunPvm + Math.abs(this.kasiteltava.summaHyvityksetLaskunPvm)
        this.maksettuAiemmin = new Big('0').minus(aiemminMaksettuTaiHyvitetty)
        this.maksettavaaYhteensa = this.laskunSummat.yhteensaKaikki.minus(aiemminMaksettuTaiHyvitetty)
        this.muistutushuomautus = this.shared.annaLokalisoituMuistutus(this.juurilasku, this.kasiteltava)
      } else {
        this.maksettuAiemmin = null
        this.muistutushuomautus = null
        this.maksettavaaYhteensa = this.laskunSummat.yhteensaKaikki
      }

      this.otsikko = this.shared.annaLokalisoituLaskunOtsikko(this.juurilasku, this.kasiteltava, this.maksettavaaYhteensa)
      this.laskunumero = this.shared.annaMuotoiltuLaskunumero(this.juurilasku, this.kasiteltava)
      this.viitenumero = this.shared.annaMuotoiltuViitenumero(this.juurilasku)
      this.maksuaikaa = this.shared.laskeMaksuaikaa(this.kasiteltava)
      this.pvm = this.shared.formatoiPvm(this.kasiteltava.pvml, this.kasiteltava.pvm, this.kasiteltava)
      this.toimituspvm = this.shared.formatoiPvm(this.kasiteltava.toimituspvml, this.kasiteltava.toimituspvm, this.kasiteltava)
      this.lokalisoituValuutta = this.shared.annaLokalisoituValuutanNimi(this.kasiteltava)
      if (this.kasiteltava.asiakas) {
        this.maa = this.shared.annaLokalisoituMaa(this.kasiteltava.asiakas.maa, this.kasiteltava)
      } else {
        this.maa = ''
      }
      this.kieli = this.kasiteltava.kieli
      this.lisatiedot = this.annaLisatiedot()
      this.alvErittely = this.shared.annaLaskunSummat(this.kasiteltava).alvErittely.filter(osa => osa.alvKanta.prosentti > 0)
      this.erapaiva = this.annaErapaiva()

      const korkoprosentti = this.annaViivastyskorkoProsentti(this.juurilasku, this.kasiteltava)
      this.viivastysprosentti = this.currencyService.formatoiDesimaali(korkoprosentti, 2, this.kasiteltava.kieli) + ' %'

      // Set timeout will set push it _after_ render to calculate the filler
      // Then we'll render again the changes and after that view

    } else {
      this.ulkoinenLasku = null
      this.virtuaaliviivakoodi = ''
      this.qrkoodi = ''
      this.muistutus = false
      this.mitatoity = false
      this.alvHuomautus = ''
      this.muotoiltuYtunnus = this.asetukset?.ytunnus ?? ''
      this.laskunSummat = null
      this.maksettuAiemmin = null
      this.maksettavaaYhteensa = null
      this.otsikko = ''
      this.laskunumero = ''
      this.viitenumero = ''
      this.maa = ''
      this.maksuaikaa = 0
      this.pvm = ''
      this.lokalisoituValuutta = ''
      this.kieli = 'fi'
      this.lisatiedot = ''
      this.alvErittely = []
      this.muistutushuomautus = null
      this.erapaiva = ''
      this.viivastysprosentti = ''
    }

    setTimeout(() => {
      this._ngZone.run(() => {
        this.laskeUudelleen()
        this.lataaminenValmisObservable.next(true)
      })
    }, 10)

  }

  constructor(
    public shared: LaskuSharedService,
    private windowSizeService: WindowSizeService,
    private korkoService: LaskuKorkoService,
    private currencyService: CurrencyService,
    private stringService: StringService,
    private ibanService: IbanService,
    private changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      // console.log('change size')
      this.laskeUudelleen()
      // setTimeout(() => {
      // console.log('change size 2')
      //   this.laskeUudelleen()
      // }, 0)
    })
  }

  ngAfterViewInit() {
    // this.laskeUudelleen()
    setTimeout(() => {
      this.laskeUudelleen()
      this.changeDetectorRef.markForCheck()
    }, 0)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private annaErapaiva(): string {
    if (!this.kasiteltava || this.mitatoity || this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      return ''
    }
    // if (this.muistutus) {
    //   return this.shared.annaLokalisoituMerkkijono('pdf-perinteinen.heti', this.kasiteltava)
    // }
    return this.shared.formatoiPvm(this.kasiteltava.erapvml, this.kasiteltava.erapvm, this.kasiteltava)
  }

  private annaLisatiedot(): string {
    const lisatiedot = this.shared.annaLisatiedot(this.juurilasku, this.kasiteltava)
    return this.shared.valmisteleTeksti(lisatiedot)
  }

  private annaViivastyskorkoProsentti(juurilasku: Lasku, kasiteltava: LaskuBase): number {

    if (this.shared.onkoUlkoinenLasku(juurilasku)) {
      if (juurilasku.viivastyskorkoprosentti && kasiteltava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
        return juurilasku.viivastyskorkoprosentti
      }
      if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS || kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        for (let korvaus of juurilasku.korvaus) {
          if (korvaus.avain === kasiteltava.avain) {
            return korvaus.ulkoinenLaskuHyvitysTaiMuistutus?.viivastyskorkoprosentti
          }
        }
      }
      return 0
    }
    return this.currencyService.muutaBigDecimalRahaksi(this.korkoService.annaViivastyskorkoProsentti(juurilasku))
  }

  private laskeUudelleen() {

    this.paivitaEsikatselunTuotteidenKorkeusSpacer()

    const leveysEnnenTransformaatiota = this.esikatseluContainer.nativeElement.offsetWidth
    let uusiKerroin = Number(((leveysEnnenTransformaatiota - 4) / 595).toFixed(2))

    if (this.rajoitaKorkeuteen) {
      const suhdeluku = this.rajoitaKorkeuteen / 842
      const leveysOlisi = Number((595 * suhdeluku).toFixed(2))
      const rajoitusKerroin = Number(((leveysOlisi - 4) / 595).toFixed(2))
      // console.log('rajoitaKorkeuteen', this.rajoitaKorkeuteen, suhdeluku, leveysOlisi, rajoitusKerroin, uusiKerroin)
      if (rajoitusKerroin < uusiKerroin) {
        uusiKerroin = rajoitusKerroin
      }
      // console.log('LEVEYDET!!', leveysOlisi, uusiKerroin)
    }

    // console.log('leveys', leveysEnnenTransformaatiota)

    this.paivitaEsikatselunKorkeusSpacer(uusiKerroin)
    if (this.transformStyleObservable.value !== 'scale(' + uusiKerroin + ')') {
      // console.log('skaalauskerroin asetataan:', this.transformStyleObservable.value, uusiKerroin)
      this.transformStyleObservable.next('scale(' + uusiKerroin + ')')
    }

    // setTimeout(() => {
    //   this.paivitaEsikatselunKorkeusSpacer()
    // }, 0)

  }

  annaRelatiivinenKorkeusmitta(mittaMillimetreissa: number): number {
    const suhdeluku = this.kokopdflasku.nativeElement.offsetWidth / 595
    const korkeusPikseleissa = (mittaMillimetreissa / 297 * 842)
    return korkeusPikseleissa * suhdeluku
  }

  private paivitaEsikatselunTuotteidenKorkeusSpacer() {
    const ylaosanKorkeus = this.ylaosa ? this.ylaosa.nativeElement.offsetHeight : 225
    const alaosanKorkeus = this.alaosa ? this.alaosa.nativeElement.offsetHeight : 340
    const uusiSpacerKorkeus = Number((842 - (ylaosanKorkeus + alaosanKorkeus)).toFixed(0))
    // console.log('LASKE SPACER: ylaosa:', ylaosanKorkeus, this.ylaosa.nativeElement.offsetHeight, 'alaosa:', alaosanKorkeus, this.alaosa.nativeElement.offsetHeight, 'uusiSpacerKorkeus:', uusiSpacerKorkeus)
    const kaytettavaSpacerKorkeus = uusiSpacerKorkeus < 1 ? 10 : uusiSpacerKorkeus
    if (this.tuotteetMinimumHeightStyleObservable.value !== kaytettavaSpacerKorkeus + 'px') {
      // console.log('Spacerkorkeus asetetaan:', this.tuotteetMinimumHeightStyleObservable.value, kaytettavaSpacerKorkeus)
      this.tuotteetMinimumHeightStyleObservable.next(kaytettavaSpacerKorkeus + 'px')
    }
  }

  private paivitaEsikatselunKorkeusSpacer(uusiKerroin: number) {

    if (this.keskita) {
      const kokoLeveys = this.esikatseluContainer.nativeElement.offsetWidth
      const pdfLeveys = this.kokopdflasku.nativeElement.offsetWidth * uusiKerroin
      const ero = kokoLeveys - pdfLeveys
      this.laskuLeftCoordinate = ero > 2 && (ero / 2) > 2 ? Math.floor(ero / 2) : 2
      // console.log('KESKITTÄMINEN:', kokoLeveys, this.kokopdflasku.nativeElement.offsetWidth, uusiKerroin, pdfLeveys, ero, this.laskuLeftCoordinate)
    }

    const korkeusEnnenTransformaatiota = this.kokopdflasku.nativeElement.offsetHeight
    // console.log('KORKEUS ENNEN', korkeusEnnenTransformaatiota)
    const uusiKorkeus = Number((korkeusEnnenTransformaatiota * uusiKerroin).toFixed(0))
    const uusiKorkeusStr = (uusiKorkeus.toFixed(0)) + 'px'
    if (this.spacerHeightStyleObservable.value !== uusiKorkeusStr) {
      // console.log('UUSI KOKO KORKEUS', this.edellinenKorkeus, uusiKorkeus, (uusiKorkeus.toFixed(0)) + 'px')
      this.spacerHeightStyleObservable.next(uusiKorkeusStr)
    }
  }
}
