import { Component, OnInit, AfterContentChecked, ErrorHandler, OnDestroy, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core'
import { Validators, AbstractControl, ValidationErrors, ValidatorFn, FormGroup, FormControl } from '@angular/forms'

import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { LaskuSpostiService } from '../_jaettu/service/lasku/lasku-sposti.service'
import { LaskuKorkoService } from '../_jaettu/service/lasku/lasku-korko.service'
import { StringService } from '../_shared-core/service/string.service'
import { Verkkolaskuoperaattori, SahkoisenLaskunValittajaService } from '../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'

import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { TimestampService } from '../_jaettu-angular/service/timestamp-service'
import { LaskuEmailEsikatselutiedot } from '../_jaettu-angular/laskut/esikatselu/email.perinteinen.component'

import { LaskuService } from '../_angular/service/lasku/lasku.service'

import { EmailLahetysStatus, EmailLahetysStatusKoodi, LaskuasetuksetSpostille, LaskunSahkopostipohja, LaskunSahkoinenOsoite, LaskunTuote, Lasku, LaskuBase, Laskuasetukset, LaskunLahetystapa, LaskuReskontra } from '../_jaettu/model/lasku'

import { BehaviorSubject, combineLatest, firstValueFrom, from, of, ReplaySubject, Subject } from 'rxjs'
import { debounceTime, startWith, switchMap, takeUntil, throttleTime } from 'rxjs/operators'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'

export interface LaskunLahetysdata {
  reskontra: LaskuReskontra[]
  huomautuskulu: LaskunTuote
  korkokulu: LaskunTuote
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  muistutus: boolean
}

export interface LaskunLahetystiedot {
  error: any
  tapa: LaskunLahetystapa
  lahetettyJuuri: Lasku
  lahetettyKasiteltava: LaskuBase
}

export interface SahkoinenForm {
  osoite: FormControl<string>
  valittaja: FormControl<string>
}

export interface KuluForm {
  huomautuskulu: FormControl<number>
  viivastyskorko: FormControl<boolean>
}

export interface EmailForm {
  aihe: FormControl<string>
  otsikko: FormControl<string>
  teksti: FormControl<string>
  lisaavastaanottaja: FormControl<string | null>
}


@Component({
  selector: 'app-laheta-component',
  templateUrl: './laheta.component.html',
  styleUrls: ['./laheta.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LahetaComponent implements OnInit, AfterContentChecked, OnDestroy {

  private spostiasetukset: LaskuasetuksetSpostille = null
  private tilaaEsikatseluPaivitysSubject: ReplaySubject<number> = new ReplaySubject(1)
  private ngUnsubscribe = new Subject<void>()

  @Output() laskuLahetettiin = new EventEmitter<LaskunLahetystiedot>()

  private _aktiivinenValilehti: number = 0
  get aktiivinenValilehti(): number {
    return this._aktiivinenValilehti
  }
  @Input()
  set aktiivinenValilehti(indeksi: number) {
    this._aktiivinenValilehti = indeksi
  }

  private _data: LaskunLahetysdata
  get data(): LaskunLahetysdata {
    return this._data
  }
  @Input()
  set data(data: LaskunLahetysdata) {
    this._data = data
    this.onkoMuistutus = !!this._data?.muistutus
    this.alustaTiedot(this.onkoMuistutus, data)
  }

  onkoMuistutus: boolean = false
  esikatselutiedot: ReplaySubject<LaskuEmailEsikatselutiedot> = new ReplaySubject(1)
  verkkolaskuoperaattorit: Verkkolaskuoperaattori[]
  emailForm: FormGroup<EmailForm>
  sahkoinenForm: FormGroup<SahkoinenForm>
  kuluForm: FormGroup<KuluForm>
  asetuksissaOnOletustietojaVirhe = false
  fieldErrors: FieldErrors = {}
  osoitehaunHintTeksti: string = ''
  // osoitehaunTulokset: ApixHaeVerkkolaskuosoiteTulos[] = null
  namename = 'yoruenf' + Math.random()

  korko: number = 0
  viivastyskorkoLisataan: boolean = false

  private _buttonPushInflight: boolean = false
  peppolValidationObservable: BehaviorSubject<{ error: boolean, message: string }> = new BehaviorSubject(null)

  email = {
    aihe: '',
    otsikko: '',
    teksti: '',
    vastaanottajat: []
  }

  sahkoinen = {
    ytunnus: '',
    osoite: '',
    valittaja: ''
  }

  pyydaPaivitys() {
    if (this.aktiivinenValilehti === 0) {
      this.tilaaEsikatseluPaivitysSubject.next(1)
    }
  }

  annaOsoitteenValidointiFunktio(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const ctrlValue = this._stringService.removeAllWhiteSpaces(control.value)

        if (this.data.kasiteltava?.asiakas?.nimi?.toLowerCase()?.split(' ')?.includes('apix')) {
          // Special exception to enable sending invoices to Apix
          const apixTunnus = '003723327487'
          const apixValittuValittajana = this.valittajaControl.value?.trim() === apixTunnus

          if (apixValittuValittajana && control.value === apixTunnus) {
            return null
          }
        }

        const valittajat = this.verkkolaskuoperaattorit || []
        for (const valittaja of valittajat) {
          const valittajaTunnus = this._stringService.removeAllWhiteSpaces(valittaja.tunnus)
          if (valittajaTunnus === ctrlValue) {
            return { 'valittaja': true }
          }
        }
      }
      return null
    }
  }

  constructor(
    private _validationService: FormValidationService,
    private _laskuSpostiService: LaskuSpostiService,
    private _laskuService: LaskuService,
    private _errorHandler: ErrorHandler,
    private _laskuSharedService: LaskuSharedService,
    private _ladataanService: LadataanService,
    private _timestampService: TimestampService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _laskuKorkoService: LaskuKorkoService,
    private _stringService: StringService,
    private _lemonTranslation: LemonTranslationService
  ) {

    const osoiteControl = new FormControl<string>('', [Validators.required, this.annaOsoitteenValidointiFunktio()])
    const valittajaControl = new FormControl<string>('', [Validators.required])
    this.sahkoinenForm = new FormGroup<SahkoinenForm>({
      // 'ytunnus': new FormControl('', [ ]),
      osoite: osoiteControl,
      valittaja: valittajaControl
    })

    this.kuluForm = new FormGroup<KuluForm>({
      // this.data.huomautuskulu.hinta
      huomautuskulu: new FormControl<number>(0, [Validators.required]),
      viivastyskorko: new FormControl<boolean>(this.viivastyskorkoLisataan, [Validators.required])
    })

    this.emailForm = new FormGroup<EmailForm>({
      aihe: new FormControl<string>(this.email.aihe, [Validators.required]),
      otsikko: new FormControl<string>(this.email.otsikko, [Validators.required]),
      teksti: new FormControl<string>(this.email.teksti, [Validators.required]),
      lisaavastaanottaja: new FormControl<string | null>('', [this.validateEmail])
    })

    this.verkkolaskuoperaattorit = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()

    // this.sahkoinenForm.get('ytunnus').valueChanges.pipe(
    //   debounceTime(1000)
    // ).subscribe((value: string) => {
    //   if (value) {
    //     const kasitelty = this.validationService.processValue(value)
    //     this.osoitehaunHintTeksti = 'Haetaan verkkolaskuosoitetta...'

    //     const pyynto: ApixHaeVerkkolaskuosoitettaPyynto = {
    //       ytunnus: kasitelty
    //     }

    //     const callable = this.lemontreeFunctions.httpsCallable<ApixHaeVerkkolaskuosoitettaPyynto, ApixHaeVerkkolaskuosoitettaVastaus>('apixHaeVerkkolaskuosoite')
    //     callable(pyynto).then(tulos => {

    //       if (tulos && tulos.tulokset && tulos.tulokset.length > 0) {
    //         this.osoitehaunTulokset = tulos.tulokset
    //       } else {
    //         this.osoitehaunTulokset = null
    //       }
    //       this.osoitehaunHintTeksti = ''

    //     }).catch(error => {
    //       this.osoitehaunHintTeksti = 'Haun aikana tapahtui virhe.'
    //       this.errorHandler.handleError(error)
    //     })
    //   }
    // })
    // this.sahkoinenForm.get('ytunnus').valueChanges.subscribe(value => {
    //   this.sahkoinen.ytunnus = value
    // })

    combineLatest([
      osoiteControl.valueChanges,
      valittajaControl.valueChanges
    ]).pipe(
      debounceTime(500),
      switchMap(([osoite, valittaja]) => {

        if (valittaja !== 'PEPPOL') {
          return of(null)
        }

        if (!osoite || osoite.trim().length < 9) {
          return of(null)
        }

        return from(this._laskuService.validoiPeppolOsoite(osoite, this._lemonTranslation.nykyinenKieli)).pipe(
          startWith({ error: false, message: this._lemonTranslation.lokalisoi('lasku.peppol-validaatio.tarkistetaan', this._lemonTranslation.nykyinenKieli) })
        )
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.peppolValidationObservable.next(value)
    })

    osoiteControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.sahkoinen.osoite = value?.trim() ?? null
    })

    valittajaControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.sahkoinen.valittaja = value?.trim() ?? null
      if (this.sahkoinen.valittaja === 'PEPPOL') {
        osoiteControl.setValidators([Validators.required, this.annaOsoitteenValidointiFunktio(), Validators.minLength(9)])
        osoiteControl.updateValueAndValidity()
      } else {
        osoiteControl.setValidators([Validators.required, this.annaOsoitteenValidointiFunktio()])
        osoiteControl.updateValueAndValidity()
      }
    })

    // Kulu form
    this.kuluForm.get('huomautuskulu').valueChanges.subscribe(value => {
      if (this.data.huomautuskulu) {
        this.data.huomautuskulu.hinta = value
        this.tilaaEsikatseluPaivitysSubject.next(1)
      }
    })
    this.kuluForm.get('viivastyskorko').valueChanges.subscribe(value => {
      this.viivastyskorkoLisataan = value
      if (this.data.korkokulu) {
        this.data.korkokulu.hinta = this.viivastyskorkoLisataan ? this.korko : 0
        this.tilaaEsikatseluPaivitysSubject.next(1)
      }
    })

    // Email form
    this.emailForm.get('aihe').valueChanges.subscribe(value => {
      this.email.aihe = value || ''
      this.data.kasiteltava.email.aihe = this.email.aihe
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('otsikko').valueChanges.subscribe(value => {
      this.email.otsikko = value || ''
      this.data.kasiteltava.email.otsikko = this.email.otsikko
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('teksti').valueChanges.subscribe(value => {
      this.email.teksti = value || ''
      this.data.kasiteltava.email.teksti = this.email.teksti
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })
    this.emailForm.get('lisaavastaanottaja').valueChanges.subscribe((value: string) => {
      if (value && (value.indexOf(',') !== -1 || value.indexOf(' ') !== -1)) {
        this.lisaaEmail(this.emailForm.get('lisaavastaanottaja'))
      }
    })

    this.tilaaEsikatseluPaivitysSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      throttleTime(250)
    ).subscribe(() => {
      if (this.data.asetukset && this.data.juurilasku && this.data.kasiteltava) {
        this._laskuSharedService.paivitaRyppaanJaKasiteltavanSummat(this.data.juurilasku, this.data.kasiteltava, this.data.reskontra)
        this.esikatselutiedot.next({
          asetukset: this.data.asetukset,
          juurilasku: this.data.juurilasku,
          kasiteltava: this.data.kasiteltava,
          maksumuistutus: false
        })
      }
    })

  }

  ngAfterContentChecked() {
    this.fieldErrors = this._validationService.updateValidationStatus('lasku.sposti', this.emailForm)
  }

  validateEmail = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  // private _filter(value: string | Verkkolaskuoperaattori): Verkkolaskuoperaattori[] {
  //   if (value === '' || !value) {
  //     return []
  //   }
  //   let filterValue = ''
  //   if (value instanceof String || typeof value === 'string') {
  //     filterValue = value.toLowerCase()
  //   } else {
  //     filterValue = value && value.nimi ? value.nimi.toLowerCase() : ''
  //   }
  //   return this._sahkoisenLaskunValittajaService.annaKaikkiValittajat().filter(operaattori => operaattori.nimi.toLowerCase().includes(filterValue) || operaattori.tunnus.toLowerCase().includes(filterValue))
  // }

  valittajaDisplayFn(operaattori?: Verkkolaskuoperaattori): string | undefined {
    if (operaattori && operaattori.nimi) {
      return operaattori.nimi + ' (' + operaattori.tunnus + ')'
    }
    if (operaattori instanceof String || typeof operaattori === 'string') {
      return operaattori as any as string
    }
    return undefined
  }

  ngOnInit() {
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  private alustaTiedot(onkoMuistutus: boolean, data: LaskunLahetysdata) {
    // Tyhjennä lomakkeet:
    this.email = {
      aihe: '',
      otsikko: '',
      teksti: '',
      vastaanottajat: []
    }
    this.sahkoinen = {
      ytunnus: '',
      osoite: '',
      valittaja: ''
    }

    if (!data.asetukset) {
      return
    }

    // Alusta email
    if (!data.asetukset.spostiasetukset) {
      data.asetukset.spostiasetukset = {}
    }

    this.spostiasetukset = this._laskuSpostiService.annaSpostiAsetukset(data.kasiteltava, onkoMuistutus, data.asetukset)

    if (onkoMuistutus) {
      this.spostiasetukset = data.asetukset.muistutusSpostiasetukset[data.kasiteltava.kieli]
    } else {
      this.spostiasetukset = data.asetukset.spostiasetukset[data.kasiteltava.kieli]
    }

    if (!this.spostiasetukset) {
      if (onkoMuistutus) {
        this.spostiasetukset = {
          aihe: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.aihe', data.kasiteltava),
          kieli: data.kasiteltava.kieli,
          teksti: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.teksti', data.kasiteltava),
          otsikko: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.otsikko', data.kasiteltava),
          template: LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN
        }
      } else {
        this.spostiasetukset = {
          aihe: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.aihe', data.kasiteltava),
          kieli: data.kasiteltava.kieli,
          teksti: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.teksti', data.kasiteltava),
          otsikko: this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.otsikko', data.kasiteltava),
          template: LaskunSahkopostipohja.PERINTEINEN
        }
      }
    }

    this.email.aihe = this.korvaaMuuttujat(this.spostiasetukset.aihe || this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.aihe', data.kasiteltava))
    this.email.otsikko = this.korvaaMuuttujat(this.spostiasetukset.otsikko || this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.otsikko', data.kasiteltava))
    this.email.teksti = this.korvaaMuuttujat(this.spostiasetukset.teksti || this._laskuSharedService.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.teksti', data.kasiteltava))

    if (data.kasiteltava.asiakas.laskunVastaanottajat) {
      for (const vastaanottaja of data.kasiteltava.asiakas.laskunVastaanottajat) {
        this.email.vastaanottajat.push(vastaanottaja)
      }
    }

    if (!data.kasiteltava.email) {
      data.kasiteltava.email = {
        start: this._timestampService.now(),
        done: null,
        aihe: '',
        otsikko: '',
        teksti: '',
        slogan: '',
        template: null,
        vastaanottajat: null
      }
    }
    data.kasiteltava.email.aihe = this.email.aihe
    data.kasiteltava.email.otsikko = this.email.otsikko
    data.kasiteltava.email.teksti = this.email.teksti
    data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
    data.kasiteltava.email.footer = 'footer2'
    data.kasiteltava.email.slogan = this.data.asetukset.slogan || ''

    this.emailForm.get('aihe').setValue(this.email.aihe)
    this.emailForm.get('otsikko').setValue(this.email.otsikko)
    this.emailForm.get('teksti').setValue(this.email.teksti)

    // Alusta sähköinen lasku
    this.sahkoinen.ytunnus = data.kasiteltava.asiakas.ytunnus ? data.kasiteltava.asiakas.ytunnus : ''

    if (!data.kasiteltava.asiakas.sahkoinenosoite) {
      data.kasiteltava.asiakas.sahkoinenosoite = {
        sahkoinenOsoite: '',
        sahkoinenValittaja: ''
      }
    }

    if (!data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenOsoite) {
      data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenOsoite = ''
    }

    if (!data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenValittaja) {
      data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenValittaja = ''
    }

    this.sahkoinen.osoite = data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenOsoite
    this.osoiteControl.setValue(data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenOsoite)

    const unconfirmedValittajaTunnus = data.kasiteltava.asiakas.sahkoinenosoite.sahkoinenValittaja
    const confirmedValittajaTunnus = unconfirmedValittajaTunnus ? this._parseAndConfirmValittajaTunnus(unconfirmedValittajaTunnus) : null
    this.valittajaControl.setValue(confirmedValittajaTunnus)

    // Alusta korkokulut
    if (onkoMuistutus) {
      this.korko = this._laskuKorkoService.laskeKorkoLaskulle(data.juurilasku, data.kasiteltava, data.asetukset, data.reskontra)
      this.data.korkokulu.hinta = this.viivastyskorkoLisataan ? this.korko : 0
      this.data.huomautuskulu.hinta = 0
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  lisaa() {
    const field = this.emailForm.get('lisaavastaanottaja')
    field.updateValueAndValidity()
    this.lisaaEmail(field)
  }

  private lisaaEmail(field: AbstractControl) {
    // console.log('raaka', field.value)
    if ((field.value || '').trim()) {
      let arvo = field.value.trim()
      arvo = arvo.replace(/,/g, '').trim()
      // console.log('kasitelty', arvo)
      if (!new FormControl(arvo, [Validators.email]).errors) {
        this.email.vastaanottajat.push(arvo)
        field.setValue('')
        this.data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
        this.tilaaEsikatseluPaivitysSubject.next(1)
      }
    }
  }


  poistaAsiakas(vastaanottaja: string): void {
    const index = this.email.vastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.email.vastaanottajat.splice(index, 1)
    }
    this.data.kasiteltava.email.vastaanottajat = this.muodostaVastaanottaja()
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  private muodostaVastaanottaja() {
    const vastaanottajat: EmailLahetysStatus[] = []
    for (const vastaanottaja of this.email.vastaanottajat) {
      vastaanottajat.push({
        email: vastaanottaja.trim().toLowerCase(),
        status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
        viesti: null
      })
    }
    return vastaanottajat
  }

  async lahetaSahkoinen() {

    if (!this.sahkoinen || !this.sahkoinen.osoite || !this.sahkoinenForm.valid) {

      const formErrors = Object.values(this.sahkoinenForm.controls).filter(control => control.errors)

      const peppolErrorObject = await firstValueFrom(this.peppolValidationObservable)
      const peppolError = !!peppolErrorObject?.error
      if ((!peppolError && formErrors.length > 0) || (peppolError && formErrors.length > 1)) {
        this._validationService.merkitseKokoLomakeKosketuksi(this.sahkoinenForm)
        return
      }

    }

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      // Päivitä tiedot
      // this.data.kasiteltava.asiakas.ytunnus = this.sahkoinen.ytunnus

      this._ladataanService.aloitaLataaminen()

      if (this.onkoMuistutus) {
        const osoite: LaskunSahkoinenOsoite = { sahkoinenOsoite: this.sahkoinen.osoite, sahkoinenValittaja: this.sahkoinen.valittaja }
        await this._laskuService.merkitseLaskuLahetetyksiSahkoisesti(osoite, this.data.juurilasku, this.data.kasiteltava).then(() => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.SAHKOINEN, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      } else {
        const osoite: LaskunSahkoinenOsoite = { sahkoinenOsoite: this.sahkoinen.osoite, sahkoinenValittaja: this.sahkoinen.valittaja }
        await this._laskuService.merkitseLaskuLahetetyksiSahkoisesti(osoite, this.data.juurilasku, this.data.kasiteltava).then(() => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.SAHKOINEN, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      }

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async saveAndPrint() {

    this._ladataanService.aloitaLataaminen()

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {

      if (this.onkoMuistutus) {
        await this._laskuService.merkitseLaskunMaksumuistutusLahetetyksiTulostamalla(this.data.juurilasku, this.data.kasiteltava).then(result => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.ITSE, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      } else {
        await this._laskuService.merkitseLaskuLahetetyksiTulostamalla(this.data.juurilasku, this.data.kasiteltava).then(result => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.ITSE, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      }

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }

  }

  async send() {

    if (!this.email || !this.email.vastaanottajat || this.email.vastaanottajat.length < 1) {
      this.emailForm.get('lisaavastaanottaja').setErrors({ 'min': true })
      return
    }

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {

      this._ladataanService.aloitaLataaminen()
      const vastaanottajat: EmailLahetysStatus[] = this.muodostaVastaanottaja()

      const postmarkTemplateId = this._laskuSpostiService.annaSahkopostipohjanPostmarkNumero(this.spostiasetukset.template, this.data.kasiteltava.kieli)

      if (this.onkoMuistutus) {
        await this._laskuService.merkitseLaskunMaksumuistutusLahetetyksiSpostilla(postmarkTemplateId, this.data.juurilasku, this.data.kasiteltava, vastaanottajat, this.email.aihe, this.email.otsikko, this.email.teksti, this.data.asetukset.slogan, true).then(result => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.SAHKOPOSTI, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      } else {
        await this._laskuService.merkitseLaskuLahetetyksiSpostilla(postmarkTemplateId, this.data.juurilasku, this.data.kasiteltava, vastaanottajat, this.email.aihe, this.email.otsikko, this.email.teksti, this.data.asetukset.slogan).then(result => {
          this.laskuLahetettiin.next({ error: null, tapa: LaskunLahetystapa.SAHKOPOSTI, lahetettyJuuri: this.data.juurilasku, lahetettyKasiteltava: this.data.kasiteltava })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.laskuLahetettiin.next({ error: err, tapa: null, lahetettyJuuri: null, lahetettyKasiteltava: null })
        })
      }

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }

  }

  private korvaaMuuttujat(korvattava: string): string {
    return this._laskuSpostiService.korvaaMuuttujat(korvattava, this.data.asetukset, this.data.juurilasku, this.data.kasiteltava)
  }

  get osoiteControl(): AbstractControl {
    return this.sahkoinenForm.get('osoite')
  }
  get valittajaControl(): AbstractControl {
    return this.sahkoinenForm.get('valittaja')
  }

  // This form field used to be a free-text input, so extra validation is needed here to catch invalid operator ID-s in legacy data.
  private _parseAndConfirmValittajaTunnus(unconfirmed: string) {
    if (!unconfirmed) {
      return null
    }
    if (unconfirmed['tunnus']) {
      return unconfirmed['tunnus']
    }
    if (!unconfirmed['toUpperCase']) {
      return null
    }
    const upperCase = unconfirmed.toUpperCase()
    const found: Verkkolaskuoperaattori = this._sahkoisenLaskunValittajaService.annaValittaja(upperCase)
    return found?.tunnus ?? null
  }

}
