/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export class TranslationProvider {

  getAllTranslations(): any {
    const fi = this.getFinnish()
    const en = this.getEnglish()
    const sv = this.getSwedish()
    return {
      'fi': fi,
      'en': en,
      'sv': sv
    }
  }

  getSwedish(): any {
    return {
    }
  }

  getEnglish(): any {
    return {
      'browser-clock-check': {
        'browser-time-wrong': 'Your browser\'s internal time seems to be wrong. Please check that the time is set correctly on your device. Wrong time will eventually lead to errors.',
        'current-time': 'Current time: ',
        'browser-time': 'Browser time: ',
      },
      'unsupported-browser': 'The browser you are using is too old. If you continue to use Lemonaid with this browser there will be errors. Supported browser include recent versions of Chrome, Firefox, Edge and Safari.',
      'unsupported-browser-detected': 'Detected browser is',
      'unsupported-browser-required': 'minimum supported',
      'app': {
        'laskut': 'Invoices',
        'palkat': {
          'palkat': 'Salaries',
          'yleiskuva': 'Overview',
          'laskelmat': 'Payslips',
          'listat': 'Payroll',
          'tyontekijat': 'Employees',
          'raportit': 'Reports',
          'asetukset': 'Settings',
          'ohjeet': 'Help',
          'aspa': 'Customer support'
        },
        'maksut': 'e-invoices',
        'tuotteet': 'Products',
        'asiakkaat': 'Clients',
        'asetukset': 'Settings',
        'tiliotteet': 'Bank statements',
        'palkkamateriaali': 'Wage material',
        'ostotositteet': 'Receipts',
        'myyntitositteet': 'Sales documents',
        'laskujen-vastaanotto': 'Receive invoices',
        'laskujen-hyvaksyminen': 'Approve invoices',
        'laskutus': 'Invoicing',
        'tositteet': 'Documents',
        'kirjaudu-ulos': 'Sign out',
        'no-internet-connection': 'No connection to Lemonaid backend',
        'selvitettavia-tilitapahtumia': 'Transactions need clarification!',
        'reports': 'Reports',
        'uutta': 'New!',
        'tilinpaatokset': 'Financial statements',
        'kirjanpito': 'Accounting'
      },
      'vaihda-kieli-dialog': {

      },
      'sopimus-ja-ehdot': {
        'sopimus-ja-ehdot': 'Agreement and terms',
        'sopimus': 'Agreement',
        'palvelukuvaus': 'List of services',
        'yleiset-palveluehdot': 'General service conditions',
        'sopimus-henkilotietojen': 'Personal data processing agreement',
        'allekirjoitettu-sahkoisesti': 'Signed electronically',
        'ei-viela-allekirjoitettu': 'Not signed yet',
        'lataa-pdf': 'Download PDF'
      },
      'palkkausfi': {
        'virhe': 'There was an error signing you into palkkaus.fi. Please resfresh the page. If the problem persists, please contact your accountant. We apologize your inconvenience.'
      },
      'listaus': {
        'kpl-per-sivu': 'Items in a page',
        'seuraava-sivu': 'Next page',
        'edellinen-sivu': 'Previous page'
      },
      'valuutta': {
        'AED': 'Arabiemiirikuntien dirhami',
        'AFN': 'afgaani',
        'ALL': 'lek',
        'AMD': 'dram',
        'ANG': 'Alankomaiden Antillien guldeni',
        'AOA': 'kwanza',
        'ARS': 'Argentiinan peso',
        'AUD': 'Australian dollari',
        'AWG': 'Aruban guldeni',
        'AZN': 'Azerbaidžanin manat',
        'BAM': 'vaihdettava markka',
        'BBD': 'Barbadosin dollari',
        'BDT': 'taka',
        'BGN': 'lev',
        'BHD': 'Bahrainin dinaari',
        'BIF': 'Burundin frangi',
        'BMD': 'Bermudan dollari',
        'BND': 'Brunein dollari',
        'BOB': 'boliviano',
        'BRL': 'real',
        'BSD': 'Bahaman dollari',
        'BTN': 'ngultrum',
        'BWP': 'pula',
        'BYN': 'Valko-Venäjän rupla',
        'BYR': 'Valko-Venäjän rupla',
        'BZD': 'Belizen dollari',
        'CAD': 'Kanadan dollari',
        'CDF': 'Kongon frangi',
        'CHF': 'Sveitsin frangi',
        'CLP': 'Chilen peso',
        'CNY': 'juan renminbi',
        'COP': 'Kolumbian peso',
        'CRC': 'Costa Rican colon',
        'CUC': 'vaihdettava peso',
        'CUP': 'Kuuban peso',
        'CVE': 'Kap Verden escudo',
        'CZK': 'Tšekin koruna',
        'DJF': 'Djiboutin frangi',
        'DKK': 'Tanskan kruunu',
        'DOP': 'Dominikaanisen tasavallan peso',
        'DZD': 'Algerian dinaari',
        'EGP': 'Egyptin punta',
        'ERN': 'nakfa',
        'ETB': 'birr',
        'EUR': 'euro',
        'FJD': 'Fidžin dollari',
        'FKP': 'Falklandin punta',
        'GBP': 'Englannin punta',
        'GEL': 'lari',
        'GGP': 'Guernseyn punta',
        'GHS': 'Ghanan cedi',
        'GIP': 'Gibraltarin punta',
        'GMD': 'dalasi',
        'GNF': 'Guinean frangi',
        'GTQ': 'quetzal',
        'GYD': 'Guyanan dollari',
        'HKD': 'Hongkongin dollari',
        'HNL': 'lempira',
        'HRK': 'kuna',
        'HTG': 'gourde',
        'HUF': 'forintti',
        'IDR': 'Indonesian rupia',
        'ILS': 'sekeli',
        'IMP': 'Mansaaren punta',
        'INR': 'Intian rupia',
        'IQD': 'Irakin dinaari',
        'IRR': 'Iranin rial',
        'ISK': 'Islannin kruunu',
        'JEP': 'Jerseyn punta',
        'JMD': 'Jamaikan dollari',
        'JOD': 'Jordanian dinaari',
        'JPY': 'jeni',
        'KES': 'Kenian šillinki',
        'KGS': 'som',
        'KHR': 'riel',
        'KMF': 'Komorien frangi',
        'KPW': 'Pohjois-Korean won',
        'KRW': 'Etelä-Korean won',
        'KWD': 'Kuwaitin dinaari',
        'KYD': 'Caymansaarten dollari',
        'KZT': 'tenge',
        'LAK': 'kip',
        'LBP': 'Libanonin punta',
        'LKR': 'Sri Lankan rupia',
        'LRD': 'Liberian dollari',
        'LSL': 'loti',
        'LYD': 'Libyan dinaari',
        'MAD': 'Marokon dirham',
        'MDL': 'Moldovan leu',
        'MGA': 'ariary',
        'MKD': 'denaari',
        'MMK': 'kyat',
        'MNT': 'tugrug',
        'MOP': 'pataca',
        'MRO': 'Mauritanian ouguija',
        'MRU': 'Mauritanian ouguija',
        'MUR': 'Mauritiuksen rupia',
        'MVR': 'rufiyaa',
        'MWK': 'Malawin kwacha',
        'MXN': 'Meksikon peso',
        'MYR': 'ringgit',
        'MZN': 'metical',
        'NAD': 'Namibian dollari',
        'NGN': 'naira',
        'NIO': 'kultacórdoba',
        'NOK': 'Norjan kruunu',
        'NPR': 'Nepalin rupia',
        'NZD': 'Uuden-Seelannin dollari',
        'OMR': 'Omanin rial',
        'PAB': 'balboa',
        'PEN': 'sol',
        'PGK': 'kina',
        'PHP': 'Filippiinien peso',
        'PKR': 'Pakistanin rupia',
        'PLN': 'zloty',
        'PYG': 'guarani',
        'QAR': 'Qatarin rial',
        'RON': 'Romanian leu',
        'RSD': 'Serbian dinaari',
        'RUB': 'Venäjän rupla',
        'RWF': 'Ruandan frangi',
        'SAR': 'Saudi-Arabian rial',
        'SBD': 'Salomonsaarten dollari',
        'SCR': 'Seychellien rupia',
        'SDG': 'Sudanin punta',
        'SEK': 'Ruotsin kruunu',
        'SGD': 'Singaporen dollari',
        'SHP': 'Saint Helenan punta',
        'SLL': 'leone',
        'SOS': 'Somalian šillinki',
        'SRD': 'Surinamen dollari',
        'SSP': 'Etelä-Sudanin punta',
        'STD': 'dobra',
        'STN': 'dobra',
        'SVC': 'El Salvadorin colón',
        'SYP': 'Syyrian punta',
        'SZL': 'lilangeni',
        'THB': 'baht',
        'TJS': 'somoni',
        'TMT': 'Turkmenistanin manat',
        'TND': 'Tunisian dinaari',
        'TOP': 'pa’anga',
        'TRY': 'Turkin liira',
        'TTD': 'Trinidadin ja Tobagon dollari',
        'TWD': 'Taiwanin uusi dollari',
        'TZS': 'Tansanian šillinki',
        'UAH': 'grivna',
        'UGX': 'Ugandan šillinki',
        'USD': 'Yhdysvaltain dollari',
        'UYU': 'Uruguayn peso',
        'UZS': 'sum',
        'VEF': 'bolivar',
        'VND': 'dong',
        'VUV': 'vatu',
        'WST': 'tala',
        'XAF': 'CFA-frangi (BEAC)',
        'XCD': 'Itä-Karibian dollari',
        'XOF': 'CFA-frangi (BCEAO)',
        'XPF': 'CFP-frangi',
        'YER': 'Jemenin rial',
        'ZAR': 'randi',
        'ZMW': 'Sambian kwacha',
        'ZWL': 'Zimbabwen dollari'
      },
      'kieli': {
        'fi': 'Finnish',
        'sv': 'Swedish',
        'en': 'English'
      },
      'laskuntyyppi': {
        'tav': 'Domestic sale (Finland)',
        'tav_ulkom': 'Domestic sale (Finland) to foreign company',
        'taveu': 'Community sales to other country of EU (goods)',
        'palveu': 'Community sales to other country of EU (service)',
        'eieu': 'Sales outside of EU',
        'kuleu': 'Consumer sales to other country of EU',
        'kuleut': 'Consumer sales to other country of EU (goods)',
        'kuleup': 'Consumer sales to other country of EU (service)',
        'kuleur': 'Consumer sales to other country of EU',
        'rak': 'Sales of construction services in Finland'
      },
      'alv': {
        'yht': '0 %',
        'suomi0': '0 %',
        'suomi10': '10 %',
        'suomi14': '14 %',
        'suomi24': '24 %',
        'suomi255': '25.5 %',
        'eieu': '0 %',
        'rak': '0 %',
        'huomautuskulukorkoalv': '0 %'
      },
      'reset': {
        'vaihda': 'Reset password',
        'password': 'New password',
        'onnistui': 'Password was changed succesfully. You can now use the new password to log in to our iOS or Android app. Tap "Continue" to log in to the web app.',
        'validation': {
          'min': 'Minimum length is 10 characters',
          'differentchars': 'At least 5 different characters',
          'number': 'At least one number',
          'lettersmall': 'At least one lowercase letter',
          'letterbig': 'At least one uppercase letter',
        },
        'virheet': {
          'koodi-vanhentunut': 'Password reset is too old. Start new reset by selecting "Cancel" and then "Forgot password?".',
          'koodi-ei-oikea': 'Password reset code is wrong. Start new reset by selecting "Cancel" and then "Forgot password?".',
          'kayttaja-inaktiivinen': 'User is inactive. Password can\'t be changed.',
          'kayttaja-ei-loydy': 'No such user. Password can\'t be changed.',
          'salasana-heikko': 'Password is too easy to guess. Enter more complex password.'
        }
      },
      'change': {
        'otsikko': 'Change password',
        'vaihda': 'Change',
        'oldpassword': 'Old password',
        'newpassword': 'New password',
        'onnistui': 'Password was changed succesfully. Remember to use the new password next time you login to the iOS, Android or web app.',
        'validation': {
          'oldpassword': {
            'required': 'Old password is required',
            'wrong-password': 'Old password is incorrect.'
          },
          'newpassword': {
            'required': 'New password is required'
          }
        }
      },
      'forgot': {
        'palauta': 'Reset password',
        'onnistui': 'The password can now be reset. Please follow the instructions sent to your email address.'
      },
      'login': {
        'login': 'SIGN IN',
        'email': 'Email',
        'password': 'Password',
        'puhelin': 'Phone number',
        'sms-code': 'Code',
        'forgot': 'Forgot password?',
        'sms-text-web': 'Your Lemonaid verification code is {{code}}.',
        'sms-text-ios': 'Your Lemonaid verification code is {{code}}.',
        'sms-text-android': 'Your Lemonaid verification code is {{code}}.\n\n{{hash}}',
        'send-sms-again': 'Tap here to send a new code.',
        'insert-code-text': 'Insert the code sent to your phone number',
        'two-factor-auth': 'Two factor authentication',
        'send-success': 'Resend was successful',
        'login-out-of-date': 'Login has expired. Please cancel to start over.',
        'beta-off-limits': 'You are attempting to sign in to the wrong Lemonaid version. Please sign in to your Lemonaid account from <a href="https://lemonaid.lemontree.fi">lemonaid.lemontree.fi</a>',
        'validation': {
          'email': {
            'required': 'Email address is missing.',
            'user-not-found': 'No user found using given email address.',
            'invalid-email': 'Email address is invalid.',
            'email': 'Email address is invalid.',
            'user-disabled': 'User account is disabled.',
          },
          'password': {
            'required': 'Password is missing.',
            'wrong-password': 'Password is incorrect.',
            'auth-failed': 'Email or password is incorrect',
          },
          'code': {
            'required': 'Please enter a code.',
            'wrong-code': 'Code is incorrect.',
            'contains-non-nums': 'Please insert only numbers'
          }
        }
      },
      'lisaa-puhelin': {
        'add-number-text': 'Insert your phone number so we can send you a confirmation code',
        'two-factor-explanation': 'Two factor authentication further secures your account. From now on, when you sign in, we\'ll send you a verification code to the phone number provided here.',
        'required': 'Please add a phone number.',
        'min': 'The number is too short',
        'success': 'The phone number was successfully added to the account.',
        'wrong-creds': 'Wrong user credentials.',
        'number-exists': 'The account already contains a phone number.',
        'notinternational': 'The number must be in international format starting with +, like +358',
        'leadingzero': 'The international format of Finnish phone numbers does not include leading zeros. Please remove the first zero after +358.',
        'invalid': 'The number is not valid'
      },
      'ei-oikeuksia': {
        'otsikko': 'Missing rights',
        'teksti': 'You don\'t have the correct rights to view the page you tried to access. If you think this is a mistake, please let your accountant know about it so we can fix this.'
      },
      'ei-asetuksia': {
        'otsikko': 'Missing settings',
        'teksti': 'Lemonaid can\'t find settings that are required to use Lemonaid. Please refresh the browser page and try again. Most likely this is due to a bad internet connection, however if this problem persists, please contact us.'
      },
      'ei-rooleja': {
        'otsikko': 'Missing roles',
        'teksti': 'It seems you have no roles configured in Lemonaid. Please contact your accountant to get your required roles.'
      },
      'laskut': {
        'etsi': 'Search invoices',
        'nayta': 'Show',
        'lisaa': 'Create an invoice',
        'ei-pankkiyhteysvaltuutusta': 'No bank access authorisation - automatic accounts receivable is not active.',
        'lisaa-valtuutus': 'Give authorisation',
        'poista-valinta': 'Clear selection',
        'rajaa-kuukauden-mukaan': 'Limit months',
        'kummasta-haetaan-erapaiva': 'Due date',
        'kummasta-haetaan-paiva': 'Invoice date',
        'kummasta-haetaan': 'Which is used',
        'numero': 'Number',
        'asiakas': 'Client',
        'erapaiva': 'Due date',
        'laskun-pvm': 'Invoice date',
        'summa': 'Sum',
        'avoinna': 'Unpaid',
        'tila': 'State',
        'muistutus': 'Reminder invoice',
        'perinta': 'Forwarded to debt collection',
        'perinta-poisto': 'Debt collection terminated',
        'hyvitys': 'Credit invoice',
        'lahettaminen-epaonnistui': 'Transmission failure',
        'hakuehdoilla-ei-yhtaan-tulosta': 'No invoice could be found with given criteria.',
        'merkitse-maksetuksi': 'Paid',
        'kommentti': 'Comment',
        'muokkaa-kommenttia': 'Edit comment',
        'summat': {
          'kaikki': 'Show all currencies',
          'huomautus': 'Why sums didn\'t update?',
          'tilin-saldo': 'Account balance',
          'tilin-saldo-paivitetty': 'Updated {{date}}',
          'avoinna': 'Unpaid',
          'maksettu': 'Paid',
          'eraantynyt': 'Overdue',
          'luottotappiot': 'Credit loss',
        }
      },
      'lasku': {
        'spostipohjat': {
          'oletukset': {
            'sposti': {
              'aihe': 'Invoice, {{yrityksen_nimi}}',
              'otsikko': 'Hello, please find your invoice attached',
              'teksti': 'Please do not hesitate to contact us if you require further information. We\'re more than happy to help! \n\nWith kindest regards, {{yrityksen_nimi}}'
            },
            'muistutussposti': {
              'aihe': 'Invoice reminder, {{yrityksen_nimi}}',
              'otsikko': 'Hi, the attached invoice is overdue',
              'teksti': 'According to our bookkeeping invoice number {{laskunumero}} is still unpaid (due date {{erapaiva}}). We ask you to handle this by paying the attached reminder invoice as soon as possible. If you have already paid the original invoice no further action is required.'
            }
          }
        },
        'muokkaa-tulostettua-dialog': {
          'otsikko': 'Was printed invoice sent to client?',
          'teksti': 'If the invoice has already been given or sent to the client, new invoice will be created that replaces the old one. If the invoice has not been sent or given to client, it can be still edited.',
          'on-toimitettu': 'The client already has the invoice',
          'ei-ole-toimitettu': 'The client has not received the invoice yet'
        },
        'laheta-comp': {
          'email': {
            'otsikko': 'Send via email',
            'info-tavallinen': 'Invoice will be sent to given email addresses.',
            'info-muistutus': 'Reminder invoice will be sent to given email addresses.'
          },
          'sahkoinen': {
            'otsikko': 'Send via e-invoice',
            'info-tavallinen': 'Invoice will be sent to given electronic invoice address.',
            'info-muistutus': 'Reminder invoice will be sent to given electronic invoice address.',
            'validation': {
              'valittaja': {
                'required': 'Please select an operator'
              },
              'osoite': {
                'required': 'Address is missing',
                'valittaja': 'Address cannot be the operator id.',
                'minlength': 'Address can\'t be shorter than 9 characters.'
              },
            }
          },
          'itse': {
            'otsikko': 'Send it on your own',
            'info-tavallinen': 'Download invoice as PDF file and send it as you see fit. Downloading will mark the invoice as sent in the system.',
            'info-muistutus': 'Download reminder invoice as PDF file and send it as you see fit. Downloading will mark the invoice as sent in the system'
          }
        },
        'yhteisomyynnit-esta-hyvitys-popup': 'Sending a separate credit note for your community sales is not possible due to the VAT summary declaration. If you want to send a credit note, please navigate to the original invoice and select the \'Credit note\' option from the top menu.',
        'mitatointirivin-nimi': 'Invalidated {{alv-kanta}}',
        'tallennettu-onnistuneesti': 'Invoice was saved succesfully.',
        'tiedostonimen-alku-ladattaessa': 'Invoice',
        'tiedostonimen-alku-ladattaessa-numerolla': 'Invoice {{saaja}}',
        'alv-info-teksti-head': 'Attention!',
        'alv-info-teksti1': 'A new EU directive is in effect starting 1.7.2021. This directive mandates that consumer sales to other EU countries will follow the VAT rates of the destination country.',
        'alv-info-teksti2': 'If the total sum of yearly consumer sales to other EU countries is less than €10 000 (and was also below that the previous year), the sales can be made under Finnish VAT regulation and you can proceed to send the invoice here.',
        'alv-info-teksti3': 'If the total sum of yearly consumer sales to other EU countries is more than €10 000, you need to register to the VAT special scheme. Please contact your accountant for more information',
        'suomifi-valtuutus-puuttuu-head': 'Suomi.fi authorization missing',
        'suomifi-valtuutus-puuttuu-teksti1': 'Community sales invoices cannot be sent without Suomi.fi authorization. Please authorize according to this instruction:',
        'suomifi-valtuutus-puuttuu-teksti2': 'Please note that it takes one day to register the authorization in our system.',
        'muokkaus-info-teksti': 'Editing an incoive will create new one, that will replace he original.',
        'hyvitys-info-teksti': 'Credit invoice is a new invoice that credits the old invoice.',
        'title': 'Create invoice',
        'tyyppi': 'Invoice type',
        'kieli': 'Invoice language',
        'nimi': 'Client',
        'ytunnus': 'Finnish business id',
        'vat-tunnus': 'VAT number',
        'katuosoite': 'Street address',
        'postinro': 'Postal code',
        'postitoimipaikka': 'City',
        'maa': 'Country',
        'laskunro': 'Invoice number',
        'laskunpvm': 'Invoice date',
        'erapaiva': 'Due date',
        'toimituspvm': 'Dispatch date',
        'valuutta': 'Currency',
        'maksuaikaa': 'Payment time',
        'viivastyskorko': 'Penalty interest',
        'viitteenne': 'Your reference',
        'lisatiedot': 'Additional information',
        'tuote-kuvaus': 'Product / description',
        'a-hinta-veroton': 'Unit price',
        'maara': 'Amount',
        'alv-kanta': 'VAT',
        'ale-pros': 'Disc. %',
        'tuote-yhteensa-veroton': 'Sum',
        'kpl': 'pcs',
        'lisaa-rivi': 'Add row',
        'yhteensa-veroton': 'Sum without VAT',
        'alv': 'VAT sum',
        'yhteensa-verollinen': 'To be paid',
        'spostilahetys': 'Continue',
        'tulosta-itse': 'Print',
        'muokkaa': 'Edit',
        'huomautuskulu-tuote': 'Collection fee {{numero}}',
        'viivastyskorko-tuote': 'Penalty interest',
        'aiemmin-maksettu-tuote': 'Paid before',
        'lisaa-liite': 'Add attchment',
        'raahaa-tanne': 'Drag attachment here to add it',
        'tuetut-tiedostot': 'Supported file types: pdf, jpeg, png.',
        'tallentaminen-epaonnistui': 'Saving failed. Please return to the previous view and try again. If the error happens again please contact your accountant.',
        'ladataan': 'Loading',
        'tallennettu-asiakas': 'Retrieved from client registry',
        'muokkaa-asiakasta': 'Editing client',
        'tallenna-uutena-asiakkaana': 'Saving as new client',
        'tiedoston-lahettaminen-epaonnistui-alku': 'Following errors happened during upload:',
        'tiedoston-lahettaminen-epaonnistui-loppu': 'Please remove this file and try to upload again. If the error happens again please contact your accountant.',
        'vaara-muoto': 'The file is not in a supported format. Supported formats are {{tuetutMuodot}}.',
        'liian-suuri': 'The file is too large. The maximum size is {{kokoMax}} megabytes. (File size was {{kokoNyt}} megabytes)',
        'liian-monta': 'Can\'t add more than {{lukumaaraMax}} files.',
        'alv-numeron-tarkistaminen': {
          'tarkistetaan': 'Checking...',
          'oikein': 'Correct, registered data: {{nimi}}, {{osoite}}',
          'epaonnistui-eu': 'VAT-number check failed due to EU server failure.',
          'virheellinen-maakoodi': 'Country code is invalid. VAT-code starts with two letter country code like FI.',
          'virheellinen-numero': 'The number is not correct. Please check the number.',
          'tuntematon-virhe': 'Unknown error happened: {{error}}'
        },
        'peppol-validaatio': {
          'tarkistetaan': 'Checking...',
          'osoite-eivalidi': 'The address does not seem to be valid. Please check the address.',
          'osoite-kuuluu': 'The address given belongs to company {{nimi}}, {{maa}}',
          'loytyi-yritykset': 'The following businesses were found at the given address: {{yritykset}}'
        },
        'validation': {
          'hyvitys': {
            'ei-voi-olla-positiivinen': 'Credit note invoice total to be paid sum can not be positive.'
          },
          'tuotteet': {
            'min': 'Please add at least one product.'
          },
          'asiakastyyppi': {
            'required': 'Selection missing.'
          },
          'nimi': {
            'required': 'Name is missing.'
          },
          'katuosoite': {
            'required': 'Street address is missing.',
            'maxlength': 'Street address is too long.'
          },
          'maa': {
            'required': 'Country is missing.'
          },
          'postinro': {
            'required': 'Postal code is missing.'
          },
          'postitoimipaikka': {
            'required': 'City is missing.'
          },
          'rivin-alku': 'Row {{rivi_nro}} has error: ',
          'ahinta': {
            'required': 'Unit price is missing.',
            'max': 'Unit price is too much.',
            'min': 'Unit price is not enough.'
          },
          'yhteensa': {
            'required': 'Sum is missing.',
            'max': 'Sum is too much.',
            'min': 'Sum is not enough.'
          },
          'maara': {
            'required': 'Amount is missing.',
            'max': 'Amount is too much.',
            'min': 'Amount is not enough.'
          },
          'tuote': {
            'required': 'Product name is missing.'
          },
          'ytunnus': {
            'required': 'ID number is missing.',
            'invalid-ytunnus': 'Finnish business ID is invalid.',
            'invalid-vat-number': 'VAT number is invalid.',
            'invalid-vat-number-or-ytunnus': 'Finnish business ID or VAT number is invalid'
          },
          'erapaiva': {
            'required': 'Due date is missing.',
            'matDatepickerMin': 'At earliest due date can be tomorrow.',
            'matDatepickerParse': 'Invalid date. (use mm/dd/yyyy)'
          },
          'toimituspvm': {
            'required': 'Dispatch date is missing.',
            'matDatepickerParse': 'Invalid date. (use mm/dd/yyyy)'
          },
          'maksuaikaa': {
            'required': 'Payment time is missing.',
            'min': 'At minimum one day payment time.'
          },
          'viivastyskorko': {
            'required': 'Penalty interest is missing.',
            'min': 'Penalty interest has to be more than or equal to zero.'
          },
          'asetukset': {
            'oletuksia-print': 'Invoice can\'t be printed before settings are edited to contain proper data.',
            'oletuksia-sposti': 'Invoice can\'t be sent before settings are edited to contain proper data.'
          },
          'ale': {
            'min': 'Discount percentage must be more than zero.',
            'max': 'Discount percentage must be equal to or less than 100.'
          },
          'alv': {
            'required': 'VAT percentage must be selected.'
          }
        },
        'tapahtumaloki': {
          'otsikko': 'History',
          'tyyppi1': 'Invoice {{lasku_nro}} was created',
          'tyyppi2': 'Invoice was edited',
          'tyyppi3': '{{summa}} was paid.',
          'tyyppi4': 'Invoice {{lasku_nro}} was sent to {{osoitteet}}',
          'tyyppi5': 'Invoice {{lasku_nro}} was printed',
          'tyyppi6': 'Invoice {{lasku_nro}} was sent via e-invoicing system to address {{sahkoinenValittaja}} {{sahkoinenOsoite}}',
          'tyyppi7': 'Reminder invoice {{lasku_nro}} was sent to {{osoitteet}}',
          'tyyppi8': 'Reminder invoice {{lasku_nro}} was printed',
          'tyyppi9': 'Reminder invoice {{lasku_nro}} was sent via e-invoicing system',
          'tyyppi10': 'Invoice {{lasku_nro}} was marked as credit loss',
          'tyyppi11': 'Invoice {{lasku_nro}} credit loss mark was removed',
          'tyyppi12': 'Invoice draft was created',
          'tyyppi13': 'Invoice draft was edited',
          'tyyppi14': 'Invoice draft was deleted',
          'tyyppi15': 'Invoice was invalidated',
          'tyyppi16': 'Comment was updated',
          'tyyppi17': 'Invoice {{lasku_nro}} was sent via e-invoicing system to address {{sahkoinenValittaja}} {{sahkoinenOsoite}} to be approved in Lemonaid by the receiver',
          'tyyppi18': 'Invoice {{lasku_nro}} was sent via e-invoicing system to address {{sahkoinenValittaja}} {{sahkoinenOsoite}} after receiver approved to pay it in their Lemonaid',
          'tyyppi19': 'Invoice {{lasku_nro}} was forwarded to Perintäritari for debt collection',
          'tyyppi20': 'Invoice {{lasku_nro}} was removed from debt collection',
          'tyyppi21': 'Credit note {{lasku_nro}} regarding the outstanding debt was forwarded to the debt collector Perintäritari'
        },
        'liitetiedostot': {
          'otsikko': 'Attachments'
        },
        'mitatointi-dialog': {
          'voidaan': {
            'otsikko': 'Are you certain you wish to invalidate the invoice?',
            'teksti': 'Invoice invalidation is permanent and cannot be cancelled.',
            'mitatoi': 'Invalidate'
          },
          'ei-voida': {
            'otsikko': 'Invoice can\'t be invalidated',
            'teksti': 'Invoice can\'t be invalidated because there are payments made to it.'
          }
        },
        'asiakas-poisto-varmitus-dialog': {
          'otsikko': 'Delete client?',
          'teksti': 'Are you sure you want to delete the client? Deletion cannot be undone.'
        },
        'tuote-poisto-varmitus-dialog': {
          'otsikko': 'Delete product?',
          'teksti': 'Are you sure you want to delete the product? Deletion cannot be undone.'
        },
        'katsele-muokattava-dialog': {
          'vie-perintaan': 'Forward to debt collection (Perintäritari)',
          'peruuta-perinta': 'Cancel debt collection assignment',
          'perinta-epaaktiivinen': 'The invoice must be overdue and a reminder invoice must have been sent before starting collection proceedings',
          'merkitse-luottotappioksi': 'Mark as credit loss',
          'peruuta-merkitse-luottotappioksi': 'Cancel credit loss marking',
          'merkitse-osittain-maksetuksi': 'Mark as paid',
          'muokkaa': 'Edit',
          'kopioi-uusi-lasku': 'Copy',
          'mitatoi': 'Invalidate',
          'hyvita': 'Credit note',
          'laheta-maksumuistutus': 'Send reminder invoice',
          'lataa-lasku': 'Download pdf',
          'laheta-uudelleen': 'Send again',
          'spostin-lahetysvirhe': 'Error sending emails:',
          'virheellinen-sahkoposti': 'Email address is invalid',
          'tekninen-virhe': 'Technical error',
          'koko-virheen-naytto': 'Show full error',
          'uudelleen-lahetys': 'Send again',
          'lasku': 'Invoice',
          'maksuaikaa': 'Payment time',
          'spostien-lahettaminen-epaonnistui': 'Error sending emails',
          'korvatun-otsikko': 'Replaced by invoice {{korvaavanNumero}}',
          'poista-luonnos': 'Delete draft',
          'sahkoinen': {
            'lahettaminen-epaonnistui': 'Error sending e-invoice',
            'osoite': 'Address',
            'valittaja': 'Operator address',
            'virheellinen-osoite': 'Check e-invoice address',
            'virheellinen-valittaja': 'Check operator address'
          },
          'reskontrahistoria': 'Accounts receivable history',
          'reskontraan-merkittiin-kasin': 'User added to accounts receivable',
          'automaattimerkinta-tilitapahtumasta': 'Automatic entry from bank transaction'
        },
        'peruuta-maksumerkinta-dialog': {
          'otsikko': 'Revert payment marking?',
          'teksti': 'The unpaid amount will be reverted to the previous amount.'
        },
        'koko-virhe-dialog': {
          'otsikko': 'Whole technical email sending error'
        },
        'email-preview': {
          'spostin-esikatselun-lataus': 'Loading email preview'
        },
        'lataa-dialog': {
          'odota-pdf-luodaan': 'Please wait, we\'re creating PDF...',
          'tiedosto-latautuu-valmistuttuaan': 'The file will download as soon as it is ready. If creation takes too long, you can safely close this dialog and download the file later.',
        },
        'maksumuistutus-spostilla-dialog': {
          'laheta-spostilla': 'Send reminder invoice email',
          'vastaanottaja': 'Recipient',
          'laheta': 'Send'
        },
        'maksumuistutus': {
          'laheta-maksumuistutus': 'Send reminder invoice',
          'lisaa-korko': 'Add penalty interest',
          'laheta-spostilla': 'Send',
          'tulosta-itse': 'Send by printing (snailmail)'
        },
        'merkitse-osittain-maksetuksi-dialog': {
          'merkitse-maksetuksi': 'Mark as paid',
          'lasku-nro': 'Invoice number',
          'avoinna': 'Unpaid',
          'avoimeksi-jaa': 'Left unpaid',
          'merkitse': ' Mark',
          'maksettu-maara': 'Paid amount'
        },
        'perinta-sposti-dialog': {
          'vie-perintaan': 'Forward to debt collection',
          'laheta-teksti': 'In Lemonaid you can assign the debt collection to Perintäritari Oy.<br><br>By setting up a collection assignment to Perintäritari through Lemonaid, you confirm your acceptance of the <a href="https://perintaritari.fi/sopimusehdot/" target="_blank">service terms</a> and <a href="https://perintaritari.fi/hinnasto/" target="_blank">price list</a>, so please familiarize yourself with these before proceeding with the assignment. Upon successful collection, Perintäritari will transfer the principal amount to you and invoice Their fee separately. Any late payment interest will be included in the fee. <br><br> For further information, please contact <a href="mailto:info@perintaritari.fi">info@perintaritari.fi</a> or call 09 42890650.<br> Please note that Perintäritari only handles debt collection between businesses.',
          'peruuta-toimeksianto-teksti': 'Are you sure you want to cancel the debt collection actions? Please note that Perintäritari will charge a commission fee if debt collection actions have already been started.',
          'laheta': 'Send assignment to Perintäritari'
        },
        'sposti-dialog': {
          'lisaavastaanottaja': 'Recipients',
          'aihe': 'Subject',
          'otsikko': 'Title',
          'teksti': 'Text',
          'huomautuskulu': 'Add collection fee',
          'laheta-lasku-spostilla': 'Send email',
          'laheta': 'Send',
          'esikatselu': 'Email preview',
          'verkkolasku': {
            'laheta-verkkolaskuna': 'Send e-invoice',
            'osoite': 'E-invoice address',
            'valittajatunnus': 'Operator'
          },
          'itse': {
            'laheta': 'Send yourself',
            'intro1': 'Download invoice as PDF and send it as you see fit.',
            'intro2': 'The invoice will be marked as sent after downloading.',
            'lataa': 'Download'
          },
          'paperi': {
            'laheta': 'Send as paper invoice'
          }
        },
        'listaus': {
          'tila': {
            'm': 'Paid',
            'a': 'Unpaid',
            'l': 'Credit loss',
            'ml': 'Overpaid',
            'k': 'All',
            'e': 'Overdue',
            'lu': 'Draft',
            'p': 'Deleted',
            'mi': 'Invalidated',
            'h': 'Credited',
            'oh': 'Partially credited',
            'pe': 'In debt collection'
          }
        },
        'tila': {
          'undefined': '',
          'm': 'Paid',
          'a': 'Unpaid',
          'e': 'Overdue',
          'l': 'Credit loss',
          'ml': 'Overpaid',
          'kl': 'Replaced', // {{korvaavanLaskunNumero}}
          'kml': 'Reminded', // {{korvaavanLaskunNumero}}
          'lu': 'Draft',
          'p': 'Deleted',
          'mi': 'Invalidated',
          'h': 'Credited',
          'oh': 'Partially credited',
          'pe': 'In debt collection'
        },
        'sposti': {
          'otsikko': 'Title',
          'teksti': 'Texxt',
          'lisaavastaanottaja': 'Add recipient...',
          'validation': {
            'aihe': {
              'required': 'Email subject is missing.'
            },
            'otsikko': {
              'required': 'Email title is missing.'
            },
            'teksti': {
              'required': 'Email text is missing.'
            },
            'lisaavastaanottaja': {
              'email': 'Email address is invalid.',
              'min': 'Add at least one recipient.'
            },
            'huomautuskulu': {
              'required': 'Amount missing'
            }
          }
        },
        'esikatselu': {
          'viitteenne': 'Your reference',
          'lisatiedot': 'Additional information',
          'otsikko': 'Preview'
        },
        'muokkaus-estetty': {
          'ei-mahdollista': 'Editing the invoice is not possible',
          'katseltavaa-laskua-ei-voi-muokata': 'The displayed invoice cannot be edited. If you want to send the customer a different version of it, first create a new credit note and then send a new invoice. Contact your accountant for extra information, if needed.'
        }
      },
      'laskuasetukset': {
        'laskupohja-otsikko': 'Invoice email template',
        'maksumuistutuspohja-otsikko': 'Reminder invoice email template',
        'yleiset': 'Common settings',
        'nimi': 'Company / name',
        'ytunnus': 'VAT id',
        'katuosoite': 'Street address',
        'postinro': 'Postal code',
        'postitoimipaikka': 'City',
        'maa': 'Country',
        'replyto': 'Reply to email address',
        'replyto-hint': 'If the client replies to the email, the response will be sent to this address.',
        'aihe': 'Subject',
        'otsikko': 'Title',
        'teksti': 'Text',
        'slogan': 'Tagline',
        'slogan-hint': 'Free text displayed at the bottom of the email.',
        'template': 'TECHNICAL FIELD; DO NOT TOUCH!',
        'ohjeet': 'Free text displayed in the PDF invoice',
        'pankkiyhteyden-tiedot': 'Bank information:',
        'asiakaspalvelun-yhteystiedot': 'Customer service contact information:',
        'pankki': 'Bank',
        'bic': 'BIC',
        'iban': 'IBAN',
        'automaattinen-reskontra': 'Automatic accounts receivable',
        'automaattinen-reskontra-kaytossa': 'Automatic accounts receivable is active',
        'reskontraa-ei-voida-kayttaa': 'Automatic accounts receivable cannot be used since no active bank connections were found.',
        'reskontra-kun-kaytossa': 'When automatic accounts receivable is active, Lemonaid will mark sales invoices as paid based on incoming transactions. Lemonaid will process all payments that include a reference number. If your customer pays the invoice without a reference number, you need to mark it manually.',
        'iban-bic-autofill': 'Automatically filled for Finnish banks.',
        'piilota-yrityksen-nimi': 'Hide company name in invoice',
        'piilota-yrityksen-nimi-hint': 'Check the invoice layout from the preview. Usually invoice looks better without company name if it is part of the logo.',
        'aspaemail': 'Customer service email',
        'aspapuh': 'Customer service phone number',
        'tallennettu-onnistuneesti': 'Settings succesfully saved.',
        'spostipohjat-eri-kielilla': 'Email templates in different languages',
        'validation': {
          'nimi': {
            'required': 'Company / Name is missing.'
          },
          'ytunnus': {
            'required': 'VAT id is missing.'
          },
          'katuosoite': {
            'required': 'Street address is missing.'
          },
          'postinro': {
            'required': 'Postal code is missing.'
          },
          'postitoimipaikka': {
            'required': 'City is missing.'
          },
          'maa': {
            'required': 'Country is missing.'
          },
          'replyto': {
            'required': 'Email reply to address is missing.',
            'email': 'Email address is invalid.'
          },
          'template': {
            'required': 'Template is missing.'
          },
          'pankki': {
            'required': 'Bank shown in invoice is missing.'
          },
          'iban': {
            'required': 'Account number shown in invoice is missing.',
            'iban': 'Account number is invalid.'
          },
          'bic': {
            'required': 'BIC code shown in invoice is missing.',
            'bic': 'BIC code is invalid.'
          },
          'aspaemail': {
            'required': 'Customer service email address is missing.'
          },
          'aspapuh': {
            'required': 'Customer service phone number is missing.'
          },
          'aihe': {
            'required': 'Email subject is missing.'
          },
          'otsikko': {
            'required': 'Email title is missing.'
          },
          'teksti': {
            'required': 'Email text is missing.'
          }
        },
        'logo': {
          'otsikko': 'Invoice logo',
          'lataa': 'Select logo',
          'vaihda': 'Upload logo',
          'info-teksti': 'Drag file here to change the image,<br/>or click to select the file',
          'muista-tallentaa': 'Image was optimised, remember to save the changes.',
          'muista-tallentaa-poisto': 'Image was deleted, remember to save the changes.',
          'poista': 'Delete logo',
          'virheet': {
            'vain-yksi-kerrallaan': 'Only one image can be selected.',
            'ei-tiedosto': 'Dragged object is not a file.',
            'liian-suuri': 'Image is too large. The maximum size is {{kokoMax}} megabytes. (Image size was {{kokoNyt}} megabytes)',
            'vaara-muoto': 'The image is not in a supported format. Supported formats are {{tuetutMuodot}}.',
            'tuntematon': 'Unknown error. The administration has been notified of this. Please try again.'
          }
        },
        'welcome-dialog': {
          'laskutusasetukset': 'Invoicing settings',
          'paaset-kohta-laskuttamaan': 'You can start invoicing in a moment!',
          'varmistetaan-ensin': 'First let\'s check if all your settings are correct.',
          'paaset-kohta-laskuttamaan-laitetaan-ensin': 'You can start invoicing in moment, let\'s just sort out a few settings first.',
          'yrityksen-tiedot': 'Company information',
          'yhteystiedot': 'Contact information',
          'kaikki-valmis': 'Everything is now in order and Lemonaid is ready to start invoicing.',
          'kay-tutustumassa': 'You can also take a look at other available settings. For example, it is possible to add a logo to the invoice and edit the e-mail template.',
        }
      },
      'tuote': {
        'nimi': 'Product name',
        'hinta': 'Product price',
        'aloita-kirjoittamalla-tuotteen-nimi': 'Start by typing product\'s name...',
        'validation': {
          'nimi': {
            'required': 'Product name is missing.'
          }
        }
      },
      'tuotteet': {
        'vihje': 'Products are automatically saved while creating invoices - you don\'t have to create products before sending invoices.',
        'etsi': 'Find products',
        'lisaa': 'Add product',
        'nimi': 'Name',
        'hinta': 'Price',
        'hakuehdoilla-ei-yhtaan-tulosta': 'No products could be found using given criteria.'
      },
      'maksut': {
        'etsi': 'Search purchase invoices',
        'nayta': 'Show',
        'lisaa': 'Add purchase invoice',
        'maksun-saaja': 'Payee',
        'poista-valinta': 'Clear selection',
        'kummasta-haetaan-erapaiva': 'Due date',
        'kummasta-haetaan-paiva': 'Invoice date',
        'numero': 'Number',
        'asiakas': 'Client',
        'erapaiva': 'Due date',
        'kayta-erapaivaa': 'Use due date',
        'laskun-pvm': 'Invoice date',
        'laskua-yhteensa': 'invoices totaling',
        'maksa-erapaivana': 'Pay by due date',
        'maksa-heti': 'Pay immediately',
        'summa': 'Sum',
        'avoinna': 'Unpaid',
        'tila': 'State',
        'et-vastaanota-laskuja': 'You currently do not receive invoices in Lemonaid. If you want your invoices be visible in Lemonaid for payment, you can enable receiving e-invoices ',
        'tasta': 'here',
        'vahvistaminen-epaonnistui-ssn': 'Authorization of the payments failed! The information from the authorization flow does not match with currently logged in user.',
        'maksu-hylkaamisen-syy': 'Rejection reason',
        'maksu-epaonnistui': 'The bank rejected the payment. You can retry the payment or pay it from elsewhere',
        'maksu-epaonnistui-1': 'Unknown',
        'maksu-epaonnistui-2': 'WS agreement',
        'maksu-epaonnistui-3': 'Insufficient funds',
        'maksu-epaonnistui-4': 'Account limit exceeded',
        'lopeta-vastaanotto-dialog': {
          'otsikko': 'Are you sure you want to stop receiving invoices?',
          'teksti': 'Please make sure you have given instructions to all of your suppliers where to send the invoices now. All and any invoices sent to this address will be permanently lost after confirming.',
          'lopeta': 'Understood, stop receiving invoices'
        },
        'avoimet': 'Open',
        'maksetut': 'Paid',
        'laskujen-vastaanotto': 'Receiving e-invoices',
        'laskujen-maksaminen': 'Paying e-invoices',
        'miten-lasku-on-maksettu': 'How was the invoice paid?',
        'merkitse-maksetuksi': 'Mark as paid',
        'merkitse': 'Mark',
        'maksupaivamaara': 'Payment date',
        'valitse-maksutapa': 'Select payment method',
        'vahvista-valitut': 'Confirm the payment of selected invoices by online banking authentication',
        'luonti-onnistui': 'The e-invoice address was created successfully!',
        'yrityksellesi-on-nyt-luotu': 'An e-invoice address has been created for your business, and the receiving of e-invoices is now active. Please inform your suppliers of the e-invoice address so that invoices can be directed to Lemonaid.',
        'maksukoodit': 'Payment codes',
        'viivakoodi': 'Barcode',
        'virtuaalinen-viivakoodi': 'Virtual barcode',
        'qr-koodi': 'QR code',
        'lahettaminen-epaonnistui': 'Transmission failure',
        'hakuehdoilla-ei-yhtaan-tulosta': 'All purchase invoices handled',
        'hakuehdoilla-ei-yhtaan-tulosta-historia': 'No purchase invoices could be found with given criteria.',
        'maksutili': 'Payment account',
        'tilat': {
          'h': 'Accepted',
          'a': 'Unpaid',
          'e': 'Overdue',
          'n': 'Waiting for payment',
          'm': 'Paid',
          'ah': 'Rejected',
          'p': 'Bank rejected',
          't': 'Paid',
          'k': 'Handled'
        },
        'summat': {
          'paivitetty': 'Updated',
          'kaikki': 'Show all currencies',
          'huomautus': 'Why sums didn\'t update?',
          'avoinna': 'Unpaid',
          'maksettu': 'Paid',
          'eraantynyt': 'Overdue',
          'luottotappiot': 'Credit loss'
        },
        'muokkaa': {
          'otsikko-muokkaa': 'Edit purchase invoice',
          'otsikko-lisaa': 'Add purchase invoice',
          'yhdista': 'Combine all files as one material',
          'pvm': 'Invoice date',
          'erapvm': 'Due date',
          'bic': 'BIC',
          'iban': 'IBAN',
          'summa': 'Sum',
          'saaja': 'Beneficiary',
          'viite': 'Reference number',
          'viesti': 'Message',
          'lisatiedot': 'Additional information to accounting',
          'toimituspaivamaara': 'Dispatch date',
          'validation': {
            'ladataan': 'Atleast one file is still uploading, please wait...',
            'pdf-parse-error': 'File "{{tiedostonNimi}}" could not be parsed. Please send this file to your accountant via email for more detailed analysis. Thank you!',
            'pvm': {
              'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
              'matDatepickerMax': 'Invoice date is too far in the future.',
              'matDatepickerMin': 'Invoice date is too far in the past.',
              'required': 'Date is missing.'
            },
            'erapvm': {
              'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
              'matDatepickerMax': 'Due date is too far in the future.',
              'matDatepickerMin': 'Due date is too far in the past.',
              'required': 'Date is missing.'
            },
            'toimituspvm': {
              'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
              'matDatepickerMax': 'Delivery date is too far in the future.',
              'matDatepickerMin': 'Delivery date is too far in the past.',
            },
            'summa': {
              'required': 'Sum is missing.',
              'min': 'Sum must be more than 0.',
              'max': 'Sum must be less than 9999999,99.'
            },
            'iban': {
              'invalid': 'IBAN is invalid',
              'required': 'IBAN is missing'
            },
            'bic': {
              'invalid': 'BIC is invalid',
              'required': 'BIC is missing'
            },
            'saaja': {
              'required': 'Beneficiary is missing'
            },
            'viite': {
              'viite-tai-viesti': 'Reference number or message is required',
              'viitenumero': 'Referernce number is invalid'
            },
            'viesti': {
              'viite-tai-viesti': 'Message or reference number is required'
            }
          }
        },
        'katsele': {
          'hyvaksy-ja-siirry': 'Approve and continue by pressing Enter',
          'hyvaksytty': 'Approved',
          'hyvaksynta-puuttuu': 'approval is missing.',
          'siirretty-maksuun': 'Set for payment',
          'odottaa-hyvaksyntaa': 'Waiting for approval',
          'siirry-seuraavaan': 'Continue by pressing Enter',
          'edellinen-lasku': 'Previous invoice',
          'seuraava-lasku': 'Next invoice',
          'hyvaksy-lasku': 'Approve invoice',
          'hylkaa-lasku': 'Reject invoice',
          'hylkaa-lasku-peru': 'Cancel invoice rejection',
          'kasitelty': 'Mark as handled',
          'kasitelty-peru': 'Remove handled marking',
          'peru-hyvaksynta': 'Cancel approval',
          'maksettu-yrityksen-tililta': 'Paid from company account',
          'maksettu-muualla': 'Paid elsewhere',
          'maksettu-muualla-peru': 'Remove paid marking',
          'lataa-lasku': 'Download pdf',
          'maksun-tiedot': 'Payment information',
          'muokkaa-tietoja': 'Edit details',
          'lasku': 'Invoice',
          'saaja': 'Recipient',
          'saajan-tilinumero': 'Bank account of recipient',
          'viitenumero': 'Reference number',
          'erapvm': 'Due date',
          'tililta': 'From account',
          'summa': 'Sum',
          'lisatiedot': 'Details for accounting',
          'toimituspaivamaara': 'Dispatch date',
          'viesti': 'Message',
          'muokkaa-maksun-tietoja': 'Edit purchase invoice information',
          'muokkaa-lisatietoja': 'Edit additional information',
          'tallennettu': 'Saved'
        },
        'asetukset': {
          'laskujen-maksaminen': 'Paying e-invoices',
          'voit-maksaa-sahkoiset-ostolaskut': 'You can easily pay e-invoices directly through the Lemonaid interface. Paying e-invoices and making other payments can be done with just a couple of clicks.',
          'maksuominaisuuden-aktivointi': 'Activating the payment feature requires two steps. First, an agreement is made between your company and the bank via a Web Services contract. Then, we connect the payment channel opened by the bank to Lemonaid. Our technical customer service team will assist you in making the agreement and activating the service.',
          'maksuominaisuus-maksaa': 'The payment feature costs €0.49 per payment + VAT. Please note that, in addition, the bank will charge its own fees according to its pricing for the Web Services connection and its usage.',
          'haluan-ottaa-maksuominaisuuden-kayttoon': 'I want to activate the payment feature',
          'toteutuneet-maksut-veloitetaan': 'The completed payments will be retroactively charged after the end of the month. The applicable VAT will be added to each payment.',
          'maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu': 'Payment feature requires the e-invoice receiving to be active. Please enable e-invoice receiving first',
          'maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu-linkki': 'here',
          'pyynto-lahetetty': 'The request has been sent',
          'tekninen-asiakaspalvelu-ottaa-yhteytta': 'Our technical customer service will contact you.',
          'maksuominaisuus-on-paalla': 'The payment feature is active',
          'lopeta-ominaisuuden-kaytto': 'If you want to stop using the feature, please contact your accountant.',
          'vastaanotto': 'Laskujen vastaanotto',
          'vastaanota-verkkolaskuja': 'You can receive e-invoices directly into Lemonaid. Lemonaid generates virtual barcodes for invoices making payment quick and simple. Payment is especially easy if your bank\'s mobile app has a barcode reader. After payment the invoice can be added into the accounting material with just one click, eliminating the need to worry about uploading invoices!',
          'aktivoi-laskujen-maksuominaisuus': 'If you wish to further simplify payments, you can activate the invoice payment feature as well. This will establish a Web Services payment channel between your bank and Lemonaid, enabling paying invoices directly from Lemonaid. We recommend activating <a routerLink="/maksut/asetukset/maksaminen">the payment feature</a> if your company deals with a high volume of purchase invoices.',
          'vastaanottamisen-aloittaminen': 'Starting to receive e-invoices is simple',
          'aktivoi-laskujen-vastaanottopalvelu': 'Activate receiving e-invoices',
          'lataa-verkkolaskuosoite': 'Download your e-invoice address from Lemonaid and send it to your suppliers.',
          'palvelu-maksaa': 'The service costs 5€ /month + VAT. The monthly price includes an unlimited number of invoices received. The fee is charged for each month during which the service has been used. The applicable tax is added to the price. No notice period - the service can be terminated at any time.',
          'tallenna-vastaanoton-aktivointi': 'Save activation',
          'laskujen-vastaanotto-on-paalla': 'The receiving of e-invoices is enabled',
          'laskujen-vastaanotto-lopetetaan': 'The receiving of e-invoices will be stopped, and the e-invoice address will be closed immediately. If invoices are sent to this address after this, they will be lost.',
          'tallenna-vastaanoton-lopetus': 'Save termination',
          'sahkoisten-laskujen-vastaanotto-on-paalla': 'Receiving of e-invoices is enabled, and an e-invoice address has been created for your business. You can request invoices to be sent to Lemonaid by providing the e-invoice address to your suppliers.',
        },
        'mainos': {
          'vastaanota-verkkolaskuja-suoraan': 'You can receive e-invoices directly into Lemonaid. Lemonaid generates virtual barcodes for invoices making payment easy to do in your online bank. Payment is especially easy if your bank\'s mobile app has a barcode reader. After payment the invoice can be added into the accounting material with just one click, eliminating the need to worry about uploading invoices!',
          'kaikki-laskut-samassa-paikassa': 'All invoices in one place',
          'helpottaa-laskujen-maksamista': 'Simplifies invoice payment',
          'muistuttaa-eraantyneista-laskuista': 'Reminds of overdue invoices',
          'laskut-automaattisesti-kirjanpitoon': 'Invoices automatically to bookkeeping',
          'ei-enaa-kadonneita-laskuja': 'No more missing invoices',
          'laskujen-vastaanotto-otsikko': 'Receive e-invoices',
          'ota-kayttoon': 'Activate',
          'kk': 'month',
          'maksu': 'payment',
          'kuukausihinta-sisaltaa': 'The monthly price includes an unlimited number of invoices received. The fee is charged for each month during which the service has been used. The applicable tax is added to the price. No notice period - the service can be terminated at any time.',
          'voit-maksaa-sahkoiset-ostolaskut': 'You can easily pay e-invoices directly through the Lemonaid interface. Paying e-invoices and making other payments can be done with just a couple of clicks.',
          'maksuominaisuuden-aktivointi': 'Activating the payment feature requires two steps. First, an agreement is made between your company and the bank via a Web Services contract. Then, we connect the payment channel opened by the bank to Lemonaid. Our technical customer service team will assist you in making the agreement and activating the service.',
          'maksuominaisuus-maksaa': 'The payment feature costs €0.49 per payment + VAT. Please note that, in addition, the bank will charge its own fees according to its pricing for the Web Services connection and its usage.',
          'toteutuneet-maksut-veloitetaan': 'The completed payments will be retroactively charged after the end of the month. The applicable VAT will be added to each payment.',
          /** dialog */
          'dialog-vastaanota-verkkolaskuja-suoraan': 'You can now receive e-invoices directly into Lemonaid. Lemonaid generates virtual barcodes for invoices making payment easy to do in your online bank. After payment the invoice can be added into the accounting material with just one click, eliminating the need to worry about uploading invoices!',
          'kokeile-laskujen-vastaanottoa': 'Try receiving e-invoices',
          'ehka-myohemmin': 'Maybe later',
        }
      },
      // 'tav': 'Kotimaan myynti',
      // 'taveu': 'Yhteisömyynti toiseen EU-maahan (tavara)',
      // 'palveu': 'Yhteisömyynti toiseen EU-maahan (palvelu)',
      // 'eieu': 'Myynti EU:n ulkopuolelle',
      // 'kuleu': 'Kuluttajamyynti toiseen EU-maahan',
      // 'rak': 'Rakentamispalveluiden myynti'

      'alv-huomautus': {
        'palveu': 'VAT 0% (Reverse charge, VAT Directive art 44.)',
        'taveu': 'VAT 0% (Intra-community supply)',
        'eieu': 'VAT 0 %, Export of goods or services',
        'rak': 'Lasku ei sisällä arvonlisäveroa. Verovelvollisuus ostajalla (AVL 8 c §).'
      },
      'email-perinteinen': {
        'otsikko': 'Invoice information',
        'otsikko-muistutuslasku': 'Reminder invoice information',
        'summa': 'To be paid',
        'erapaiva': 'Due date',
        'viitenumero': 'Reference number',
        'tilinumero': 'Account number',
        'virtuaaliviivakoodi': 'Virtual bank barcode',
        'lahetetty': 'Sent with Lemonaid',
        'lue-lisaa': 'Read more',
        'vastaanottajat': 'Recipients',
        'aihe': 'Subject',
        'yhteystiedot': 'Contact information'
      },
      'pdf-perinteinen': {
        'lasku': 'INVOICE',
        'muistutuslasku': 'REMINDER INVOICE',
        'hyvityslasku': 'CREDIT NOTE',
        'luonnos': 'DRAFT',
        'mitatoity': 'INVALIDATED INVOICE',
        'heti': 'IMMEDIATELY',
        'laskunnro': 'Invoice number',
        'ulkoinen-lasku': 'Invoice was created in other system: ',
        'korvaa-laskun': 'This invoice replaces earlier versions of invoice number {{korvatunLaskunNumero}}.',
        'hyvittaa-laskun': 'This invoice credits invoice number {{korvatunLaskunNumero}}.',
        'mitatoi-laskun': 'This invoice invalidates earlier versions of invoice number {{korvatunLaskunNumero}}.',
        'monesko-muistutuslasku-1': 'This invoice is first reminder invoice.',
        'monesko-muistutuslasku-2': 'This invoice is second reminder invoice.',
        'monesko-muistutuslasku-3': 'This invoice is third reminder invoice.',
        'monesko-muistutuslasku-4': 'This invoice is fourth reminder invoice.',
        'monesko-muistutuslasku-5': 'This invoice is fifth reminder invoice.',
        'monesko-muistutuslasku-6': 'This invoice is sixth reminder invoice.',
        'monesko-muistutuslasku': 'This invoice is {{muistutuslaskujenMaara}} reminder invoice.',
        'viitenro': 'Reference number',
        'laskunpvm': 'Invoice date',
        'toimituspvm': 'Dispatch date',
        'erapaiva': 'Due date',
        'maksuehto': 'Payment term',
        'pvnetto': ' days net',
        'viivastyskorko': 'Penalty interest',
        'ytunnus': 'VAT id',
        'tuote': 'Product',
        'yksikkohinta': 'Unit price',
        'maara': 'Amount',
        'lisatiedot': 'Additional information:',
        'lisatiedot-lopussa': 'Please note! Additional information at the end of PDF!',
        'viitteenne': 'Your reference:',
        'muistutus': 'Invoice number {{laskunumero}} is still unpaid according to our bookkeeping (due date {{erapaiva}}). We ask you to handle this by paying the attached reminder invoice as soon as possible.',
        'jatkuu': 'Product listing continues in next page... ',
        'alv': 'VAT',
        'ale': 'Disc.',
        'yhteensa': 'Total',
        'yhteensa-pienella': 'total',
        'ilman-alv': 'Total without VAT',
        'yhteensa-ennen-aiemmin-maksettua': 'Total with VAT',
        'maksettu-aiemmin': 'Paid before',
        'maksettava-yhteensa': 'To be paid',
        'alv-yhteensa': 'VAT sum',
        'yritys': 'Company',
        'postiosoite': 'Postal address',
        'yhteystiedot': 'Contact information',
        'saajan-tilinro': 'Recipient\'s account number',
        'saaja': 'Recipient',
        'nimi-osoite': 'Payer\'s name and address',
        'allekirjoitus': 'Signature',
        'tilisiirto': 'CREDIT TRANSFER',
        'tililta': 'From account no.',
        'euro': 'Euro',
        'sivu': 'Page',
        'esikatselu': 'Preview',
        'maksun-valitys-tiedote': 'The payment will be cleared for the recipient in accordance with the General terms for payment transmission and only on the basis of the account number given by the payer.',
      },
      'ulkoiset-laskut': {
        'minduu': 'Minduu',
        'tuntematon': 'Unknown'
      },
      'asetukset': {
        'lasku': 'Invoice settings',
        'omat': 'Invoicer information',
        'spostipohja': 'Email templates',
        'reskontra': 'Accounts receivable'
      },
      'yleiset': {
        'tallenna': 'Save',
        'peruuta': 'Cancel',
        'jatka': 'Continue',
        'muokkaa': 'Edit',
        'poista': 'Delete',
        'lataa': 'Download',
        'ok': 'OK',
        'ei': 'NO',
        'kylla': 'YES',
        'takaisin': 'Back',
        'sulje': 'Close',
        'tervetuloa': 'Welcome!',
        'aloita': 'Start',
        'aloitus': 'Start',
        'valmis': 'Done!',
        'valmista': 'All done!',
        'laheta': 'Send',
        'kiitos': 'Thank you!',
        'myohemmin': 'Later',
        'toimintoa-ei-voi-suorittaa-ilman-internet-yhteytta': 'Operation could not be completed: connection to Lemonaid backend failed. Check internet connectivity.',
        'tuntematon-virhe': 'An unknown error occurred. Please try again.',
        'tiivistaa': 'Collapse',
        'laajentaa': 'Expand',
        'decimal-field-required-error': 'Please insert only numbers',
        'decimal-field-max-error': 'Please insert only {max} numbers',
        'ladataan': 'Loading',
        'kuukausi': 'Month',
        'neljannesvuosi': 'Quarter',
        'vuosi': 'Year',
        'paivitetty': 'Updated'
      },
      'asiakas': {
        'nimi': 'Name',
        'ytunnus': 'VAT id',
        'katuosoite': 'Street address',
        'postitoimipaikka': 'City',
        'postinro': 'Postal code',
        'maa': 'Country',
        'verkkolaskuotsikko': 'e-Invoice address',
        'verkkolaskuvalittaja': 'Operator',
        'verkkolaskuosoite': 'Address',
        'asiakastyyppi': {
          'asiakastyyppi': 'Client type',
          'henkilo': 'Person',
          'yritys': 'Company'
        },
        'vastaanottaja': 'Email invoice recipients',
        'validation': {
          'asiakastyyppi': {
            'required': 'Selection missing'
          },
          'nimi': {
            'required': 'Client name is missing.'
          },
          'ytunnus': {
            'required': 'VAT id is missing.'
          },
          'katuosoite': {
            'required': 'Street address is missing.'
          },
          'postitoimipaikka': {
            'required': 'City is missing.'
          },
          'postinro': {
            'required': 'Postal code is missing.'
          },
          'maa': {
            'required': 'Country is missing.'
          },
          'lisaavastaanottaja': {
            'email': 'Email address is invalid.',
            'required': 'Recipient is missing.'
          }
        }
      },
      'asiakkaat': {
        'vihje': 'Clients are automatically saved while creating invoices - you don\'t have to create clients before sending invoices.',
        'etsi': 'Find client',
        'lisaa': 'Add client',
        'nimi': 'Name',
        'ytunnus': 'VAT id',
        'katuosoite': 'Street address',
        'postinro': 'Postal code',
        'postitoimipaikka': 'City',
        'maa': 'Country',
        'hakuehdoilla-ei-yhtaan-tulosta': 'No clients could be found using given criteria'
      },
      'stepper': {
        'optional': 'Optional'
      },
      'tositteet': {
        'yksityisotto': {
          'receipt-comment': 'Private withdrawal',
          'dialog': {
            'title': 'Mark as a private withdrawal',
            'message': 'The transaction is accounted for as a personal expense and is not taken into account in the taxation of your business.',
            'action': 'Mark'
          }
        },
        'yhteiset': {
          'tallentaminen-epaonnistui': 'Saving failed. Please return to the previous view and try again. If the error happens again please contact your accountant.',
          'tiedoston-lahettaminen-epaonnistui-alku': 'Following errors happened during upload:',
          'tiedoston-lahettaminen-epaonnistui-loppu': 'Please return to the previous view and try again. If the error happens again please contact your accountant.',
        },
        'osto': {
          'listaus': {
            'lisaa': 'Add receipts',
          },
          'katsele': {
            'lataa-tositteen-nimi': 'Receipt',
            'ei-maksutapaa': 'No payment method'
          },
        },
        'myynti': {
          'listaus': {
            'lisaa': 'Add sales documents',
            'ei-tositteita': 'Add new sales document by dragging it here or by tapping "Add sales documents"',
            'rahaa-tanne': 'Drag files here'
          },
          'katsele': {
            'otsikon-maksutapa': 'Sales document',
            'lataa-tositteen-nimi': 'Sales document'
          },
          'muokkaa': {
            'pvm': 'Document date',
            'summa': 'Document sum',
            'validation': {
              'ladataan': 'Atleast one file is still uploading, please wait...',
              'pdf-parse-error': 'File "{{tiedostonNimi}}" could not be parsed. Please send this file to your accountant via email for more detailed analysis. Thank you!',
              'maksutapa': {
                'required': 'Payment method is missing.'
              },
              'pvm': {
                'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
                'matDatepickerMax': 'Payment date is too far in the future.',
                'matDatepickerMin': 'Payment date is too far in the past.',
                'required': 'Date is missing.'
              },
              'summa': {
                'required': 'Sum is missing.',
                'min': 'Sum must be more than 0.',
                'max': 'Sum must be less than 9999999,99.'
              }
            }
          }
        },
        'palkka': {
          'listaus': {
            'etsi': 'Find Wage Material',
            'lisaa': 'Add Wage Materials',
            'ei-tositteita': 'Add new Wage Material by dragging it here or by tapping "Add Wage Materials"',
            'rahaa-tanne': 'Drag files here'
          },
          'katsele': {
            'otsikon-maksutapa': 'Wage Material',
            'lataa-tositteen-nimi': 'Wage Material'
          },
          'muokkaa': {
            'otsikko-muokkaa': 'Edit material',
            'otsikko-lisaa': 'Add material',
            'yhdista': 'Combine all files as one material',
            'tosite': 'Wage Material',
            'pvm': 'Material date',
            'validation': {
              'ladataan': 'Atleast one file is still uploading, please wait...',
              'pdf-parse-error': 'File "{{tiedostonNimi}}" could not be parsed. Please send this file to your accountant via email for more detailed analysis. Thank you!',
              'pvm': {
                'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
                'matDatepickerMax': 'Payment date is too far in the future.',
                'matDatepickerMin': 'Payment date is too far in the past.',
                'required': 'Date is missing.'
              }
            }
          }
        },
        'tiliote': {
          'listaus': {
            'etsi': 'Find Bank Statement',
            'lisaa': 'Add Bank Statements',
            'ei-tositteita': 'Add new Bank Statements by dragging it here or by tapping "Add Bank Statements"',
            'rahaa-tanne': 'Drag files here'
          },
          'katsele': {
            'otsikon-maksutapa': 'Bank Statement',
            'lataa-tositteen-nimi': 'Bank Statement'
          },
          'muokkaa': {
            'otsikko-muokkaa': 'Edit statement',
            'otsikko-lisaa': 'Add statement',
            'yhdista': 'Combine all files as one statement',
            'tosite': 'Bank Statement',
            'pvm': 'Statement date',
            'validation': {
              'ladataan': 'Atleast one file is still uploading, please wait...',
              'pdf-parse-error': 'File "{{tiedostonNimi}}" could not be parsed. Please send this file to your accountant via email for more detailed analysis. Thank you!',
              'pvm': {
                'matDatepickerParse': 'Date is invalid. (mm/dd/yyyy).',
                'matDatepickerMax': 'Payment date is too far in the future.',
                'matDatepickerMin': 'Payment date is too far in the past.',
                'required': 'Date is missing.'
              }
            }
          }
        },
        'selvitettavat': {
          'selvitettavat': 'To be cleared',
          'merkitse-yksityisotoksi': 'Private withdrawal',
          'kirjanpitajasi-on-merkannut': 'Your accountant has marked one or more transactions as in need of further information. Please add receipts or additional information to the transactions below.'
        },
        'laskujen-vastaanotto': {
          'vastaanotto-ja-valityspalvelu': 'Invoice receiving and transfer service (beta)',
          'voit-vastaanottaa-laskuja': 'You can receive electronic invoices directly in Lemonaid. Lemonaid will transfer the received invoices directly to the online bank of your company so you\'ll be able to to pay them with just a few clicks. We also save a copy of the invoices for accounting, meaning that you won\'t have to deliver the invoices to us separately anymore.',
          'valityspalvelu-on-ilmaista': 'The receiving and transfer of invoices is completely free for the duration of the beta. The final decision on the pricing of the product has not been made yet. Note, however, that your bank may have a service fee for receiving and paying the invoices in their system.',
          'ota-kayttoon': 'Enable service',
          'valittaja': 'Operator',
          'valittaja-required': 'No operator selected',
          'osoite': 'Address',
          'asetukset': 'Settings',
          'osoite-required': 'Address missing',
          'verkkolaskuosoite': 'E-invoice address',
          'haluan-myos-lemon-treen-laskut': 'I want to also receive Lemon Tree\'s service fee invoices and VAT-invoices to this address.',
          'haluan-hyvaksya': 'I want to approve received invoices before they are sent to the bank and accounting.',
          'ennen-palvelun-kayttoonottoa': 'In order to enable the service you need to have created an e-invoice address capable of receiving electronic invoices at your company\'s online bank.  If you have an e-invoice address, enter it to the fields below. If not, contact your bank.',
          'aktivoi-palvelu': 'Activate the invoice receiving and transfer service (0,00€/mo)',
          'aktiivinen': 'Active',
          'osoite-on-tallennettu': ' ',
          'osoite-tallennettu-ei-hyvaksyntaa': 'The e-invoice address of your company has been saved and the invoice receiving and transfer service is active. Lemonaid will automatically transfer received invoices to your bank for payment and create copies of the approved invoices to accounting.',
          'osoite-tallennettu-hyvaksynta-valittu': 'The e-invoice address of your company has been saved and the invoice receiving and transfer service is active. You\'ll find received invoices pending approval from “Approve invoices” -tab. After approval Lemonaid transfers invoices to your bank for payment and create copies of the approved invoices to accounting.',
          'tarkastele-osoitetta': 'Check or edit the service settings here.',
          'alta-loydat': 'The address below is the standard e-invoice address through which you can order invoices to Lemonaid. Give this address to your suppliers.',
          'voit-myos-antaa': 'You can also present your suppliers the attached invoicing information PDF containing the necessary information.',
          'lataa-pdf': 'Download PDF.',
          'pdf-epaonnistui': 'Invoicing information PDF download failed. Please try again.'
        },
        'laskujen-hyvaksyminen': {
          'laskujen-hyvaksyminen': 'Approve invoices',
          'saapuneet-verkkolaskut': 'Here you will find your received electronic invoices.',
          'kun-hyvaksyt-laskun': 'When you approve an invoice it will be transferred to you bank acccount for payment and a copy of it will be added to the accounting.',
          'erapaiva': 'Due date',
          'haluatko-varmasti-hylata': 'Are you sure you want to reject the invoice?',
          'laskua-lahetetaan': 'Invoice is being sent',
          'hyvaksy': 'Approve',
          'hylkaa': 'Reject',
          'peruuta': 'Cancel',
          'ei-hyvaksymattomia': 'No unapproved invoices',
          'kylla': 'Yes',
          'verkkolaskun-hyvaksynyt': 'E-invoice approved by'
        },
        'laskujen-hyvaksyminen-estetty-dialog': {
          'otsikko': 'Invoice can\'t be approved anymore',
          'leipateksti': 'Banks don\'t support receiving electronic invoices that have due date in the past or in the immediate future. Pay the invoice manually and mark it as paid to transfer it to accounting.',
          'merkitse-maksetuksi': 'Mark as paid'
        }
      },
      'kuitit': {
        'lisaa': 'Add receipts',
        'etsi': 'Find receipts',
        'veda-tanne': 'Drag receipt here',
        'nayta-lisaa': 'Show more',
        'nayta-vahemman': 'Show less',
        'hakuehdoilla-ei-yhtaan-tulosta': 'No receipts could be found using given criteria, add new receipts by dragging or tapping "Add receipts"',
        'lataaminen': {
          'virheet': {
            'ei-tiedosto': 'Dragged object is not a file.',
            'vaara-muoto': 'The receipt is not in a supported format. Supported formats are {{tuetutMuodot}}.',
            'liian-suuri': 'The receipt is too large. The maximum size is {{kokoMax}} megabytes. (File size was {{kokoNyt}} megabytes)'
          }
        },
        'ulkopuolisen-jarjestelman-viesti': 'Message from external system',
        'poista-dialog': {
          'otsikko': 'Are you sure you want to delete the receipt?',
          'body': 'Are you sure you want to delete the receipt dated {{pvm}} with sum of {{summa}}?',
          'poista': 'Delete receipt'
        },
        'katsele': {
          'tosite': 'Receipt',
          'summa': 'Sum',
          'poista': 'Delete receipt',
          'lataa': 'Download receipt',
          'alv': 'VAT-deductibility'
        },
        'muokkaa': {
          'tosite-lukittu': 'Receipt is locked',
          'kirjanpitaja-on-lukinnut': 'Your accountant has processed and locked this receipt. If you think there is a mistake and it should be edited please contact your accountant.',
          'otsikko-lisaa': 'Add receipts',
          'otsikko-muokkaa': 'Edit receipt',
          'yhdista': 'Combine all files as one receipt',
          'tosite': 'Receipt',
          'maksettu': 'Receipt was paid using',
          'laji': 'Receipt type',
          'lisatiedot': 'Additional information',
          'maksupvm': 'Payment date',
          'summa': 'Payment sum',
          'alv': 'VAT-deductibility',
          'ladataan': 'Uploading files to server...',
          'ladataan-sivua': 'Loading page',
          'validation': {
            'ladataan': 'Atleast one file is still uploading, please wait...',
            'pdf-parse-error': 'File "{{tiedostonNimi}}" could not be parsed. Please send this file to your accountant via email for more detailed analysis. Thank you!',
            'maksutapa': {
              'required': 'Payment method is missing.'
            },
            'pvm': {
              'matDatepickerParse': 'Payment date is invalid. (mm/dd/yyyy).',
              'matDatepickerMax': 'Payment date is too far in the future.',
              'matDatepickerMin': 'Payment date is too far in the past.',
              'required': 'Payment date is missing.'
            },
            'summa': {
              'required': 'Payment sum is missing.',
              'min': 'Payment sum must be more than 0.',
              'max': 'Payment sum must be less than 9999999,99.'
            },
            'ei-kuvia': 'Please add at least one image.'
          },
          'kirjanpitajan-kommentti': 'Accountant\'s comment',
          'lisaa-tiedosto': 'Add a file by dragging or clicking here',
        },
      },
      'kirjanpito-lahetys-veroilmoitus': {
        'input-start-regular-tmi': 'Hello',
        'input-start-regular-oy': 'Hello',
        'input-start-holvi': 'Hi,',
        'input-end-regular-tmi': 'If you have any questions about your tax return, I\'m happy to help.',
        'input-end-regular-oy': 'I will get back to you regarding the financial statements in a separate email.\n\nIf you have any questions about your tax return, I\'m happy to help.',
        'input-end-holvi': 'Best wishes,\n\n',
        'email-generated-regular-tmi': 'The business tax return for {{vuosi}} has now been submitted to the Tax Administration.{{liitteetText}} This means that everything is now completed for last year. 😊',
        'email-generated-regular-oy': 'The tax return for {{yrityksenNimi}} for {{vuosi}} has now been submitted to the Tax Administration.{{liitteetText}} You can check the return at MyTax in a few days.',
        'email-generated-holvi': 'We have uploaded the {{vuosi}} tax return of your company. You can check it at the MyTax service.\n\nDon\'t hesitate to contact us if you have any question about the accounting. You can contact us through the Holvi Zen customer service page at https://support.holvi.com/hc/fi/requests/new. Please do not reply to this e-mail.',
        'account-9940-text-html': 'For the financial year, there is a back tax payable of €{{sum}}  The Tax Administration will provide the due date for the back tax once they have processed the tax return and issued the tax decision. The tax decision will contain detailed information about the amount of the back tax and instructions for payment. The tax decision will be sent by mail and can also be found in MyTax. Interest must be paid on any unpaid tax. If you wish to make the payment before the due date, please inform me. You can find more information about the back tax in the <a href="https://www.vero.fi/en/businesses-and-corporations/taxes-and-charges/payments/" target="_blank">guidance</a> provided by the Tax Administration.',
        'email-generated': '{{vuosi}} tax return is now done.{{liitteetText}} Check the reports and approve those by replying to this message declaring the reports are OK.',
        'liitteet-text': ' You can also find the {{liitteet}} attached to this message.',
        'tuloslaskelma': 'profit and loss statement',
        'virallinen-tuloslaskelma': 'official profit and loss statement',
        'virallinen-tase': 'official balance sheet',
        'veroilmoitus': 'tax return',
        'and': 'and',
        'liitteena-veroilmoitus': ' Attached is a copy of the sent tax return.',
        'veroilmoitus-liitteineen': 'sent tax return',
        'pitka-tuloslaskelma': 'detailed profit and loss statement',
        'tase-erittelyt': 'balance sheet specifications',
      },
      'kirjanpito-lahetys-tilinpaatos': {
        'input-start': 'Hello',
        'email-generated': 'The financial statements for the year {{vuosi}} are now ready and you can find them in Lemonaid using the web browser and checking under Reports-section.{{liitteetText}} The financial statements are signed by the company\'s board and it can be done in Lemonaid. Please note that every board member should sign the financial statements with their own Lemonaid username.\n\nOnce the financial statements have been signed, they must be confirmed at the general meeting. The meeting must be held within 6 months after the end of the financial year. Minutes of the meeting will be prepared and must be signed. You can do this easily in Lemonaid.\n\nAfter this the signed and confirmed financial statements must be registered. I will take care of the registration for you.\n\nSo you still have four things to do in Lemonaid for the financial statements: <b><ol><li>Read the financial statements</li><li>Sign the financial statements and the board\'s minutes approving the financial statement</li><li>Have the annual general meeting</li><li>Sign the minutes of the annual general meeting</li></ol></b>After completing these tasks, I will send the financial statements for registration with the PRH (Patent and Registration Office).',
        'email-generated-tarkastettava-ja-osingonjako': 'The financial statements for the year {{vuosi}} are now ready.{{liitteetText}} The financial statements are signed by the company\'s board and it can be done traditionally or using electronic signature services.\n\nThe financial statements still need to be audited. I will get back to you on this matter in a separate email.\n\nOnce the financial statements have been audited and signed, they must be confirmed at the general meeting. The meeting must be held within 6 months after the end of the financial year. Minutes of the meeting will be prepared and must be signed.\n\nAfterward, the signed and confirmed financial statements must be registered. Please provide me with the signed financial statements, the minutes of the general meeting, and the audit report, and I will take care of the registration for you.\n\nDividends of €{{osingonjaonMaara}} were recorded in the financial statements. Please note that dividends can only be paid after the minutes of the general meeting have been signed, and withholding tax on dividends has been calculated. Once I have received the signed minutes of the general meeting, I will send a separate email regarding the payment of dividends and withholding tax.',
        'email-generated-tarkastettava': 'The financial statements for the year {{vuosi}} are now ready.{{liitteetText}} The financial statements are signed by the company\'s board and it can be done traditionally or using electronic signature services.\n\nThe financial statements still need to be audited. I will get back to you on this matter in a separate email.\n\nOnce the financial statements have been audited and signed, they must be confirmed at the general meeting. The meeting must be held within 6 months after the end of the financial year. Minutes of the meeting will be prepared and must be signed.\n\nAfterward, the signed and confirmed financial statements must be registered. Please provide me with the signed financial statements, the minutes of the general meeting, and the audit report, and I will take care of the registration for you.',
        'email-generated-osingonjako': 'The financial statements for the year {{vuosi}} are now ready and you can find them in Lemonaid using the web browser and checking under Reports-section.{{liitteetText}} The financial statements are signed by the company\'s board and it can be done in Lemonaid. Please note that every board member should sign the financial statements with their own Lemonaid username.\n\nOnce the financial statements have been signed, they must be confirmed at the general meeting. The meeting must be held within 6 months after the end of the financial year. Minutes of the meeting will be prepared and must be signed. You can do this easily in Lemonaid.\n\nAfter this the signed and confirmed financial statements must be registered. I will take care of the registration for you.\n\nSo you still have four things to do in Lemonaid for the financial statements: <b><ol><li>Read the financial statements</li><li><b>Sign the financial statements and the board\'s minutes approving the financial statement</li><li>Have the annual general meeting</li><li>Sign the minutes of the annual general meeting</li></ol></b>After completing these tasks, I will send the financial statements for registration with the PRH (Patent and Registration Office).\n\nDividends of €{{osingonjaonMaara}} were recorded in the financial statements. Please note that dividends can only be paid after the minutes of the general meeting have been signed, and withholding tax on dividends has been calculated. Once I have received the signed minutes of the general meeting, I will send a separate email regarding the payment of dividends and withholding tax.',
        'input-end': 'If you have any questions, I\'m happy to help.',
        'liitteet-text': ' Please find attached the {{liitteet}}.',
        'liitteet-text-tilintarkastetaan': ' Please find attached the {{liitteet}}.',
        'tuloslaskelma': 'detailed profit and loss statement',
        'tase-erittelyt': 'balance sheet specifications',
        'tilinpaatos': 'financial statement',
        'tilinpaatos-to-besigned': 'financial statement to be signed',
        'and': 'and'
      },
      'kirjanpito-lahetys': {
        'tuloslaskelma': 'profit and loss statement',
        'tase': 'balance sheet',
        'paakirja': 'general ledger',
        'alv-ilmoitus': 'VAT return',
        'selvitettavat': 'unreconciled statements',
        'alv-verkkolasku-lahetetty': 'An electronic invoice for the VAT payment has been sent to your bank.',
        'alv-verkkolasku-lahetetty-payments': 'An electronic invoice for the VAT payment has been sent to Lemonaid.',
        'teknisista-syista-erapaiva': 'Please note that due to technical requirements the due date on the VAT-invoice has been moved forward. The actual date due for the tax is nevertheless still the same and the payment must be done according to the date provided by the Tax Administration',
        'regular': {
          'input-start': 'Hello',
          'input-end': 'Please let me know if you have any questions or concerns regarding the accounting. I\'m happy to clarify anything.',
          'liitteet-text': ' You can find the {{liitteet}} attached to this message.',

          'mail-text-monthly-alv-jakso': {
            'positive-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} VAT for {{kuukaudenNimi}} is €{{alvSum}} and due date for the payment is {{dueDate}}.',
            'negative-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} The VAT refund is €{{alvSum}}. The Tax Administration will either refund the VAT to the business\' bank account or use it to pay your other taxes.',
            'zero-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} There was no VAT payable or refundable.',
          },
          'mail-text-not-alv-month': {
            'positive-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} So far, the VAT due for the current VAT period is €{{alvSum}}. The final amount of VAT will be determined once the accounts for the whole VAT period have been completed.',
            'negative-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} So far, the VAT refund for the current VAT period is €{{alvSum}}. The final amount of VAT will be determined once the accounts for the whole VAT period have been completed.',
            'zero-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} So far the current VAT period has no payable or refundable VAT. The final amount of VAT will be determined once the accounts for the whole VAT period have been completed.',
          },
          'mail-text-alv-month': {
            'positive-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} VAT for this VAT period is €{{alvSum}} and due date for the payment is {{dueDate}}.',
            'negative-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} The VAT refund is €{{alvSum}}. The Tax Administration will either refund the VAT to the business\' bank account or use it to pay your other taxes.',
            'zero-alv': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}} There was no VAT payable or refundable.',
          },
          'mail-text-non-alv-client': 'The bookkeeping for {{kuukaudenNimi}} has now been completed.{{liitteetText}}',

          'ei-selvitettavia': 'Excellent, everything was in order regarding the documents! 😊',

          'selvitettavat-ostot-tmi': 'There were transactions in the accounts for which I still need further information. You can find these transactions in Lemonaid and add supporting documents and additional information for each transaction. If it is a personal expense, please mark it as a private withdrawal in Lemonaid.',
          'selvitettavat-ostot-oy': 'There were transactions in the accounts for which I still need further information. You can find these transactions in Lemonaid. Please include supporting documents and statements for each transaction at the latest when you submit your accounting data for the following month. Please note that unresolved transactions cannot be left in the financial statements.',

          'selvitettavat-myynnit': 'There were sales transactions on the bank statement for which I could not find receipts (list attached). Could you please provide supporting documents such as sales invoices, sales reports or further information for these? You can add them directly to Lemonaid.',

          'selvitettavat-ostot-ja-myynnit-tmi': 'There were transactions in the accounts for which I still need further information. You can find these transactions in Lemonaid and add supporting documents and additional information for each transaction. If it is a personal expense, please mark it as a private withdrawal in Lemonaid.\n\nI also noticed that there were sales transactions on the bank statement for which I could not find receipts (list attached). Could you please provide supporting documents such as sales invoices, sales reports or further information for these? You can add them directly to Lemonaid.',
          'selvitettavat-ostot-ja-myynnit-oy': 'There were transactions in the accounts for which I still need further information. You can find these transactions in Lemonaid. Please include supporting documents and statements for each transaction at the latest when you submit your accounting data for the following month. Please note that unresolved transactions cannot be left in the financial statements.\n\nI also noticed that there were sales transactions on the bank statement for which I could not find receipts (list attached). Could you please provide supporting documents such as sales invoices,  sales reports or further information for these? You can add them directly to Lemonaid.'

        },

        'holvi': {
          'input-start': 'Hei!',
          'input-end': 'Jos sinulla on kysyttävää kirjanpitoasi koskien, autamme mielellämme. Voit ottaa meihin yhteyttä Holvi Zen asiakaspalveluumme sivulla https://support.holvi.com/hc/fi/requests/new. Huomaathan, että et voi vastata tähän sähköpostiin.\n\n\nYstävällisin terveisin',
          'liitteet-text': ', ohessa {{liitteet}}',

          'mail-text-monthly-alv-jakso': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Maksettavaa arvonlisäveroa kertyi {{alvSum}} euroa ja se menee maksuun Holvi-tililtäsi {{weekdayOfDueDate}}na {{dueDate}}. Varmistathan, että tililläsi on tuolloin riittävästi katetta. Jos maksua ei pystytä tekemään, sinun pitää hoitaa se itse. ',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Palautettavaa arvonlisäveroa kertyi {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron Holvi-tilillesi tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-not-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä maksettavaa arvonlisäveroa {{alvSum}} euroa.',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä palautettavaa arvonlisäveroa {{alvSum}} euroa.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta ei ole tähän mennessä kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi maksettavaa arvonlisäveroa {{alvSum}} euroa ja se menee maksuun Holvi-tililtäsi {{weekdayOfDueDate}}na {{dueDate}}. Varmistathan, että tililläsi on tuolloin riittävästi katetta. Jos maksua ei pystytä tekemään, sinun pitää hoitaa se itse. ',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi palautettavaa arvonlisäveroa {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron Holvi-tilillesi tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-non-alv-client': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}.',
          'ei-selvitettavia': 'Aineistossa kaikki kunnossa! 😊',
          'selvitettavat-ostot': 'Aineistossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät lisäselvitystä vaativat tapahtumat oheisesta listasta. Lisääthän selvitykset kuhunkin tositteeseen Holvi-tililläsi pikimmiten, jotta voimme huomioida ne seuraavan kirjanpidon laadinnan yhteydessä.\n\n',
          'selvitettavat-myynnit': 'Aineistossa oli myyntitapahtumia, joihin emme löytäneet tositteita tai tarvittavia lisätietoja. Löydät tapahtumat oheisesta listasta. Lisääthän tarvittavat tositteet tai lisätiedot kuhunkin tositteeseen Holvi-tililläsi viipymättä, jotta voimme huomioida ne seuraavan kirjanpidon laadinnan yhteydessä.\n\n'
        }

      },
      'lemonaid-user-terms-dialog': {
        'kayttoehdot': 'Terms and conditions',
        'hyvaksy': 'Agree',
        'kayttoehdot-ovat-muuttuneet': 'Terms and conditions have changed',
        'sinun-pitaa-hyvaksya': 'Lemonaid app terms and conditions have changed. Please agree to the new terms to continue.',
        'kayttoehdot-hyvaksytty': 'You have successfully agreed to the new terms and conditions and can continue using Lemonaid.'
      },
      'lemonaid-sopimus-dialog': {
        'toimeksiantosopimus-nonbreak': 'Service agreement',
        'toimeksiantosopimus-break': 'Service agreement',
        'toimeksiantosopimuksen': 'Service agreement',
        'valmis-kaytettavaksi': 'Your Lemonaid account has been activated.',
        'ennen-aloittamista': 'Please complete the following steps to start using the service.',
        'palvelumme-sisaltaa': 'Our service includes a personal accountant and tools for financial management according to a fixed monthly price.',
        'lue-tarkempi-erittely': 'You can find a more detailed description of the service, its contents and scope in the service agreement.',
        'kuukausihinta-alkaessa': 'Fixed monthly price at the start of the service',
        'kuukaudessa': 'mo',
        'ensimmainen-kuukausi': 'First month that will be billed',
        'aiempiin-laskutetaan-tuntiperusteisesti': 'Any accounting related to preceding months will be billed by an hourly rate.',
        'lukenut-ja-hyvaksyn': 'I have read and agree to the',
        'hyvaksy-required': 'Please accept the agreement to continue',
        'palvelukuvaus': 'List of services',
        'yleiset-sopimusehdot': 'General agreement',
        'sopimus-henkilotietojen': 'Personal data processing agreement',
        'hyvaksy-ja-jatka': 'Approve and continue',
        'hyvaksy': 'Approve',
        'tunnistaminen': 'Identification',
        'asiakkaan-tunnistaminen': 'User identification',
        'rahanpesulaki-velvoittaa-tunnistamaan': 'Anti-money laundering regulation obligates us to identify our customers and their representatives.',
        'rahanpesulaki-velvoittaa-keramaan': 'Anti-money laundering regulation obligates us to gather due diligence information about the client\'s company.',
        'anna-lisatiedot': 'Please provide the following additional information.',
        'yhtiosi-tuntemistiedot': 'The due diligence information of your company needs to be updated. Please provide the current information on the company\'s owners and representatives.',
        'tunnistaudu-pankkitunnuksilla': 'Please identify yourself by tapping your bank icon.',
        'tunnistautuminen-onnistui': 'Identification was successful!',
        'tunnistautuminen-epaonnistui': 'Identification was unsuccessful. Please try again.',
        'tunnistaudu': 'Identify',
        'sopimus-hyvaksytty': 'You can now start to use the service.',
        'tuntemistiedot': 'Due diligence',
        'kyc': {
          'yhtion-tunteminen': 'Company due diligence',
          'osakkaat': 'Shareholders',
          'osakkeiden-lukumaara': 'Number of shares',
          'osakkeiden-lukumaara-puuttuu': 'Please enter the number of shares',
          'kansallisuus': 'Nationality',
          'kansallisuus-required': 'Please select nationality',
          'pep': 'Politically exposed person',
          'kuka-on-pep': 'Who is a politically exposed person?',
          'pep-required': 'No option selected',

          'pep-miksi-kerataan': 'Why do we collect this data?',
          'pep-naita-tuntemistietoja': `This due diligence information and other personal data can be used in activities related to
          countering and exposing money laundering and terrorist financing as well as in investigations of money laundering and terrorist financing
          and crimes through which the assets or criminal profits of money laundering or terrorist financing were obtained.
          `,
          'pep-teksti': `A politically exposed person (“PEP-person”) means a natural person who is or has been entrusted with a prominent public function.<br/><br/>
            <b>A prominent public function includes the following:</b>
            <br/><br/>
            - heads of State, heads of government, ministers and deputy or assistant ministers<br/>
            - members of parliament<br/>
            - members of governing bodies of political parties<br/>
            - members of the supreme courts, of constitutional courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances<br/>
            - members of courts of auditors or of the highest decision-making body, equivalent to the National Audit office of Finland, that audits the State\'s finances<br/>
            - members of boards of central banks<br/>
            - ambassadors or chargés d\'affaires<br/>
            - members of the high-ranking officers in the armed forces (at least a member the body of generals in the armed forces)<br/>
            - members of the administrative, management or supervisory bodies of entirely State-owned enterprises<br/>
            - directors, deputy directors and members of the board of an international organisation`,

          'kylla': 'Yes',
          'ei': 'No',

          'lisaa-osakas': 'Add a shareholder',
          'anna-osakkeiden-tiedot': 'Add company shareholder information and their holdings',
          'hallitus-ja-toimitusjohtaja': 'Board and CEO',
          'hallituksen-puheenjohtaja': 'Chairman of the Board',
          'hallituksen-jasen': 'Board Member',
          'hallituksen-varajasen': 'Deputy Board Member',
          'toimitusjohtaja': 'Chief Executive Officer',
          'anna-paattajien-tiedot': 'Add information on the company\'s decision-makers.',
          'vahintaan-yksi-hallituksen-jasenen': 'Please enter at least one board member.',
          'vahintaan-yksi-osakas': 'Please enter at least one shareholder.',
          'lisaa-jasen': 'Add a member',
          'edunsaajat': 'Beneficial Owner',
          'edunsaajien-tiedot': 'Add information on other beneficiaries.',
          'lisaa-edunsaaja': 'Add a beneficial owner',
          'osakas-on-luonnollinen': 'The shareholder is a natural person',
          'osakas-on-yritys': 'The shareholder is a company',
          'ei-suomalaista-tunnusta': 'No Finnish personal ID code or Y-tunnus',
          'nimi': 'Name',
          'nimi-required': 'Please enter a name',
          'hetu-ytunnus': 'Personal ID / Y-tunnus',
          'hetu-ytunnus-required': 'Please enter personal ID code or Y-tunnus',
          'hetu-ytunnus-invalid': 'The personal ID code or Y-tunnus is not valid',
          'hetu': 'Personal ID',
          'hetu-required': 'Please enter personal ID code',
          'hetu-invalid': 'The personal ID code is not valid',
          'syntymaaika': 'Birth date',
          'syntymaaika-required': 'Please enter a birth date',
          'rooli': 'Role',
          'rooli-required': 'Please select a role',
          'miksi-tietoja-kerataan': 'Why do we collect the data?',

          'edunsaajalla-tarkoitetaan': 'Beneficial owners are natural persons who can affect the decisions of a company due to their holdings. Under current law a beneficial owner is a) an individual natural person owns or controls more than 25% of the company\'s votes or shares, or b) a person who directly or indirectly exercises control over a company on other grounds through another company. Indirect control occurs when more than 25% of the company signing the contract is owned by another company which is majority owned by a natural person who owns or controls more the 50% of the votes or shares of the company in question.',
          'naita-tuntemistietoja': 'This customer due diligence information and other personal data can be used in activities related to countering and exposing money laundering and terrorist financing as well as in investigations of money laundering and terrorist financing and crimes through which the assets or criminal profits of money laundering or terrorist financing were obtained.',
          'jos-muutoksia': 'Tilitoimisto Lemon Tree Oy must be notified immediately of any changes to this information.',
          'oma-ytunnus': 'The ID cannot be the company\'s own Y-tunnus'
        }
      },
      'lemonaid-uusi-sopimus': {
        'uusi-toimeksiantosopimus': 'New service agreement',
        'uusi-toimeksiantosopimus-break': 'New service agreement',
        'toimeksiantosopimus-paivittyy': 'The current service agreement has been updated. The new agreement must be signed by {{loppupaiva}}.',
        'uudessa-sopimuksessa-palvelun-sisalto': 'The new agreement provides a more detailed description of the contents of the service.',
        'kuukausihintanne-ei-muuttuu': 'Your fixed monthly price will not change.',
        'jos-sopimusta-ei-hyvaksyta': 'If the new agreement is not signed, then the current service will end at {{loppupaiva}}.',
        'uuden-sopimuksen-kuukausihinta': 'The fixed monthly price of the new service agreement is',
        'alv': 'VAT',
        'myohemmin': 'Later',
        'hyvaksy-ja-jatka': 'Agree and continue',
        'toimeksiantosopimus-hyvaksytty': 'Service agreement signed',
        'uusi-toimeksiantosopimus-nyt-hyvaksytty': 'You have accepted the new service agreement and can now continue using the service.'
      },
      'sopimus-tauolla': {
        'tauko': 'On hold',
        'sopimuksesi-on-tauolla': 'Your contract is on hold, and you cannot use all Lemonaid features during this time. You can resume your contract by contacting your accountant. Press the button to let your accountant know you want to continue the contract, they will get in touch with you.',
        'lopeta-tauko': 'I want to resume the contract',
        'kiitos-yhteydenotostasi': 'Thank you for contacting us',
        'kirjanpitajan-tiedotus': 'Your information has been forwarded to your accountant. They will contact you soon.'
      },
      'magic-link': {
        'expired-ajopaivakirja-link-regular': 'The tax return has been sent and this form cannot be filled anymore. Please contact your accountant if there are changes needed to be done on the form.',
        'expired-ajopaivakirja-link-holvi': 'The tax return has been sent and this form cannot be filled anymore. Please contact the Holvi Zen helpdesk if there are changes needed to be done on the form.',
        'expired-link': 'The link you\'re trying to use has expired and for security reasons it can\'t be used anymore. Please contact us to get a new link.',
        'used-link': 'You have already used the link and for security reasons it can\'t be reused. Try signing in by clicking the button below.'
      },
      'new-user-welcome': {
        'title': 'Setting up Lemonaid',
        'start-text': 'Please fill in missing information',
        'etunimi': 'First name',
        'sukunimi': 'Last name',
        'approve-user-terms-start': 'Agree to ',
        'approve-user-terms-link': 'our user terms',
        'approve-user-terms-error': 'Please agree to the user terms to continue',
        'create-account': 'Create account'
      },
      'ajopaivakirja': {
        'field-error': 'Ole hyvä ja täytä tämä kenttä',
        'auton-kaytto-tyoajoissa-too-large-error': 'Työajojen määrä ei voi olla isompi kuin kokonaiskilometrimäärä',
        'kirjanpidon-ulkopuoliset-vahennykset': 'Kirjanpidon ulkopuoliset vähennykset',
        'toiminimen-veroilmoituksella-voidaan-vahentaa': `Toiminimen veroilmoituksella voidaan vähentää kirjanpidon ulkopuolisia kuluja. Täytä tiedot tähän lomakkeeseen niin siirrämme ne
        veroilmoituksellesi. Lomake tulee lähettää viimeistään {{loppupaiva}}, jotta tiedot ehtivät varmuudella veroilmoitukselle.
        Mikäli sinulla on kysymyksiä vähennyksiin tai lomakkeeseen liittyen voit ottaa yhteyttä asiakaspalveluun tai kirjanpitäjääsi.`,
        'yritys': 'Yritys',
        'verovuosi': 'Verovuosi',
        'oma-ajoneuvon-kaytto': 'Oma ajoneuvon käyttö elinkeinotoiminnassa',
        'auton-kayton-km-maara': 'Auton käytön kokonaiskilometrimäärä',
        'auton-kaytto-tyoajoissa': 'Auton käyttö työajoissa',
        'onko-autoa-kaytetty-tyoajoissa': 'Onko autoa käytetty työajoissa',
        'alle-50': 'Alle 50 %:ia',
        'yli-50': 'Yli 50%:ia',
        'jos-autoa-kaytetty-alle-50': 'Jos autoa on käytetty alle 50 %:ia työajoissa, tehdään veroilmoituksella lisävähennys {{kilometrikorvaus}}€/km korvauksena auton käytön kustannuksista.',
        'jos-autoa-kaytetty-yli-50': 'Jos autoa on käytetty yli 50 %:ia työajoissa, katsotaan auton kuuluvat yrityksen varoihin ja silloin kaikki autokulut kirjataan kirjanpitoon. Veroilmoituksella yksityiskäytön osuus huomioidaan vähennettävissä kuluissa.',
        'auton-tiedot-perustuvat': 'Auton käyttöselvityksen tiedot perustuvat',
        'ajopaivakirjaan': 'ajopäiväkirjaan',
        'muuhun-selvitykseen': 'muuhun selvitykseen',
        'yritaja-sailyttaa-itsellaan': 'Yrittäjä säilyttää ajopäiväkirjan / muun selvityksen ajoista itsellään.',
        'tyomatkat': 'Työmatkat',
        'yli-10h-lukumaara': 'Yli 10h kestäneiden kotimaan matkojen lukumäärä',
        'yli-6h-lukumaara': 'Yli 6h kestäneiden kotimaan matkojen lukumäärä',
        'vuoden-paivaraha': 'Vuonna 2019 kokopäiväraha on 42 € ja puolipäiväraha 19 €.',
        'ulkomaan-lukumaara': 'Ulkomaan matkapäivien lukumäärä (kpl)',
        'ulkomaan-paivarahojen-yhteismaara': 'Ulkomaan päivärahojen yhteismäärä (€)',
        'laske-kaikkien-paivarahojen': 'Laske kaikkien ulkomaan päivärahojen summat yhteen ja syötä kokonaissumma yllä olevaan kenttään.',
        'tyohuonevahennys': 'Työhuonevähennys',
        'ei-tyohuonevahennysta': 'Ei työhuonevähennystä',
        'kotia-paasaantoisesti': 'Kotia on käytetty pääsääntöisesti yrityksen työtilana (vähennys 900 €/ v.)',
        'kotia-osa-aikaisesti': 'Kotia on käytetty osa-aikaisesti yrityksen työtilana (vähennys 450 €/ v.)',
        'kotia-satunnaisesti': 'Kotia on käytetty satunnaisesti yrityksen työtilana (vähennys 225 €/ v.)',
        'vakutaan-etta-antamani tiedot': 'Vakuutan, että antamani tiedot ovat oikein ja vähennettävät kulut aihellisia',
        'sitoidun-sailyttamaan': 'Sitoidun säilyttämään tähän lomakkeseen liittyvät asiakirjat vähintään 6 vuotta (esim. ajopäiväkirja)',
        'submit-successful': 'Lomake on nyt lähetetty ja siirrämme tiedot veroilmoituksellesi.',
        'checkboxes-required': 'Hyväksy ehdot ennen jatkamista',
      },
      'ulkopuoliset-kulut': {
        'field-error': 'Please fill in this field',
        'auton-kaytto-tyoajoissa-too-large-error': 'Business use amount cannot be greater than the number of total kilometres',
        'kirjanpidon-ulkopuoliset-vahennykset': 'Additional deductions for the tax return',
        'voidaan-vahentaa-kuluja': 'Additional expenses can be deducted on the business tax return of self-employed individuals. Fill in the information on this form and we will transfer them to the business tax return.',
        'tulee-lahettaa': {
          'holvi': {
            'yearly': 'This form has to be sent by {{loppupaiva}} at the latest.\nNote: Fill in the information on business-related trips for the entire year even if you have already reported the information previously for 1.1.-30.9.{{year}}.',
            'half-year': 'This form has to be sent by {{loppupaiva}} at the latest. Also reports if you do not have any have business-related drives on {{year}}. Fill in the kilometres driven for business and private use from 1.1.{{year}} to 30.6.{{year}}',
          },
          'regular': 'Additional expenses can be deducted on the business tax return of self-employed individuals. Fill in the information on this form and we will transfer them to the business tax return. This form has to be sent by {{loppupaiva}} at the latest so that the information can be transferred to the tax return. Please contact your accountant if you have any questions about the deductions or this form.'
        },
        'yritys': 'Company',
        'verovuosi': 'Tax year',
        'ei-ilmoitettavaa': 'No deductions to report',
        'ajoneuvon-kaytto': 'Use of private car for business purposes',
        'ajoneuvot': 'Vehicles',
        'rekisteritunnus': 'Registration number',
        'ajoneuvotyyppi': 'Type of vehicle',
        'henkiloauto': 'Personal car',
        'pakettiauto': 'Van',
        'muu-ajoneuvo': 'Other vehicle',
        'ajettu-yhteensa': 'Total kilometres during the year',
        'ajettu-tyoajoa': 'Business use (km)',
        'lisaa-kaikki-ajoneuvot-joita': 'Please add any vehicles you have used for business purposes during the financial year. Enter the total amount of kilometres of all journeys made by the vehicle during the financial year in the “Total kilometres during the year” field. Enter the total amount of kilometres of all business-related trips in the “Business use (km)” field.',
        'lisaa-ajoneuvo': 'Add a vehicle',
        'onko-autoa-kaytetty-tyoajoissa': 'The car been used for business purposes',
        'alle-50': 'less than 50%',
        'yli-50': 'more than 50%',
        'jos-autoa-kaytetty-alle-50': 'If a car has been used for business purposes for less than 50% of the total kilometres driven, an extra deduction of {{kilometrikorvaus}} €/km will be granted as reimbursement of expenses.',
        'jos-autoa-kaytetty-yli-50': 'If a car has been used for business purposes for more than 50% of the total kilometres driven, the car will be considered a part of company assets and all car expenses will be included in accounting. The amount of personal use will be taken into account in tax return deductions.',
        'auton-tiedot-perustuvat': 'Car use data is based on',
        'ajopaivakirjaan': 'driver\'s log',
        'muuhun-selvitykseen': 'other clarification',
        'yritaja-sailyttaa-itsellaan': 'The business owner must store the driver\'s log / other clarification.',
        'tyomatkat': 'Business travel',
        'kotimaan-tyomatkat': 'Trips in Finland',
        'olet-oikeutettu-paivarahavahennykseen': 'You are entitled to a daily allowance if the destination of your business trip is located more than 15 kilometres away from either your home or your main place of work and the trip takes more than six hours. For a journey of 6-10 hours a {{osapaivaraha}}€ deduction can be made in taxation (partial allowance), and for a journey of more than 10 hours a {{kokopaivaraha}}€ deduction can be made in taxation (full allowance). Enter the amount of business trips you made during the financial year in the fields below.',
        'yli-10h-lukumaara': '(number of business trips in Finland, more than 10 hours)',
        'kokopaivarahojen-lukumaara': 'Number of full allowances',
        'yli-6h-lukumaara': '(number of business trips in Finland, more than 6 hours)',
        'osapaivarahojen-lukumaara': 'Number of partial allowances',
        'ulkomaan-tyomatkat': 'Trips abroad',
        'laske-ulkomaan-paivarahojen': 'Calculate the sum of daily allowances for trips to other countries and enter the total sum to this field.',
        'maakohtaised-paivarahat': 'You can check the allowances for each country here.',
        'ulkomaan-lukumaara': 'The number of travel days abroad',
        'ulkomaan-paivarahojen-yhteismaara': 'Per diems for trips to other countries (€)',
        'laske-kaikkien-paivarahojen': 'Calculate the sum of per diems for trips to other countries and insert the total sum to the field above.',
        'tyohuonevahennys': 'Home office deduction',
        'ei-tyohuonevahennysta': 'No home office deduction',
        'kotia-paasaantoisesti': 'You use your home office as the primary work location of your business (deduction {{vahennys}} €/ yr)',
        'kotia-osa-aikaisesti': 'You use your home office as a work location on a part-time basis (deduction {{vahennys}} €/ yr)',
        'kotia-satunnaisesti': 'You use your home office as a work location on an occasional basis. (deduction {{vahennys}} €/ yr)',
        'vakutaan-etta-antamani tiedot': 'I confirm that the information I have given is correct and the deductions are justified',
        'sitoidun-sailyttamaan': 'I commit to storing the documents related to this form (eg the driver\'s log) for at least 6 years',
        'submit-successful': 'The form has been submitted and we will transfer the information to the business tax return.',
        'checkboxes-required': 'Agree to the terms to continue'
      },
      'general-bank-selection': {
        'pankkiyhteysvaltuutus': 'Bank connection authorisation',
        'yksityistili': 'Private',
        'yritystili': 'Business',
        'corporate': 'Corporate',
        'palvelun-kaytto-edellyttaa': 'Connnecting your company\'s bank account to Lemonaid by bank access authorisation is required to use this service. The authorisation is used to load the bank transactions of your company to our accounting system.\n\nYou can grant the authorisation by selecting your bank from the list below. Granting the authorisation only takes a couple of minutes. Your bank credentials are needed to provide access.',
        'valittu-pankki': 'Selected bank',
        'valitse-tilin-tyyppi': 'Please select the type of your company\'s bank account.',
      },
      'viia-login-required': {
        'ei-tileja': {
          'yhdista-yrityksesi-pankkitili': 'Connect your company\'s bank account to Lemonaid',
          'palvelun-kayto-edellyttaa': 'Connnecting your company\'s bank account to Lemonaid by bank access authorisation is required to use this service. After the authorisation has been granted, we can load your company\'s bank transactions into our system.\n\nGranting the authorisation takes only a couple of minutes and it is valid for 180 days at a time.\n\nPress Continue to start the authorisation process. Your bank credentials are needed to provide access.',
        },
        'on-tileja': {
          'yhteys-vanhenemassa': 'Your bank access authorisation is expiring',
          'yhteys-ei-ole-aktiivinen': 'Your bank access authorisation is not active or is expiring.',
          'valtuutus-kunnossa': 'Authorisation exists for these accounts:\n',
          'valtuutus-puuttuu': 'Authorisation is required for these accounts:\n',
          'valtuutuksen-antaminen': '\n\nGranting the authorisation takes only a couple of minutes and it is valid for 180 days at a time. Your bank credentials are needed to provide access.',

        },
        'login-ok': {
          'yhdistetty-onnistuneesti': 'Your account was successfully connected!',
          'valtuutus-on-voimassa': 'The authorisation is valid for a maximum of 180 days at a time. We will ask for a new authorisation when the current one is about to expire.',
        },
        'login-failed': {
          'virhe': 'Error',
          'yhdistely-epaonnistui': 'Bank access authorisation failed or was cancelled.',
        },
        'lue-lisaa': 'Read more about the authorisation',
        'valtuutus-pitka-selitus': `<b>What is bank access authorisation?</b>\n\nA connection is created between the company's bank account and Lemonaid, which is used to query information about transactions. The account owner's authorisation is required to create this connection.\n\n
                                  <b>What is the authorisation used for?</b>\n
                                  We use this authorisation to load your company's bank transactions into our system. Bank transactions function as the basis for accounting and with them we can also automatically mark any sent sales invoices as paid.\n\n
                                  <b>How is the authorisation granted?</b>\n
                                  Granting the authorisation takes only a couple of minutes. When it is required, you fill see this pop-up appear in Lemonaid. After clicking "continue" you will be directed to the authorisation flow. This is done through the Aiia service, which authenticates you by e-mail confirmation. After that select the to which bank your company's account belongs to and log in using your bank credentials. Once the authentication is done, select the company's account from the list and continue to finish granting the authorisation.\n\n
                                  <b>Which account should I give the authorisation for?</b>\n
                                  We require access authorisation for the company's main account. If the company does not have an official bank account, select the account which the company's sales amounts are paid to. If your company has multiple accounts in use then provide authorisation for all of them. Note that company accounts may only include the company's own transactions, so do not use these accounts for personal purchases.\n\n
                                  <b>How long is the authorisation valid?</b>\n
                                  The authorisation is generally valid for 180 days at a time. In some cases the period of validity can also be shorter. We will ask for a new authorisation when the current one is about to expire. You can also cancel the authorisation whenever you wish.\n\n
                                  <b>Is the connection safe?</b>\n
                                  The bank connection uses an interface based on the EU directive PSD2. The interface is provided by Danish enterprise Aiia A/S, which is a payments and transaction information provider licensed by the Danish Financial Supervisory Authority. The PSD2 interface is a secure, safe, standardised, and end-to-end encrypted channel.\n\n
                                  <b>What does it cost?</b>\n
                                 The European Payment Services Directive 2 (PSD2) requires banks to provide the PSD2 interface free of charge. This means that no fees should arise from providing the bank account consent or from using the account connection.`
      },
      'holvi-oauth': {
        'pankkiyhteysvaltuutus': 'Bank connection authorization',
        'selitys': 'We can automatically retrieve bank account transactions from Holvi and we need your authorization to do this.\n\nThe authorization is granted in Holvi\'s interface using your Holvi bank credentials.\n\nThe authorization does not give Tilitoimisto Lemon Tree the right to use your account or make payments - we can only read the data from the Holvi account.',
        'pitaa-uusia': 'Holvi bank authorisation needs to be renewed. Renewal is done using your Holvi bank credentials. Select \"Continue\" to start.',
        'completed': {
          'valtuutus-onnistui': 'authorization successful!',
          'valtuutus-annettiin-onnistuneesti': 'authorization was successfully granted. We may automatically request a new authorization in the future, if needed.',
        },
        'failed': {
          'virhe': 'Error',
          'yhdistely-epaonnistui': 'Bank connection authorization failed or was cancelled.',
        },
        'lue-lisaa': 'Read more about the authorisation',
        'valtuutus-pitka-selitus': `<b>What is bank access authorisation?</b>\n\nA connection is created between the company's bank account and Lemonaid, which is used to query information about transactions. The account owner's authorisation is required to create this connection.\n\n
        <b>What is the authorisation used for?</b>\n
        We use this authorisation to load your company's bank transactions into our system. Bank transactions function as the basis for accounting and with them we can also automatically mark any sent sales invoices as paid.\n\n
        <b>How is the authorisation granted?</b>\n
        Granting the authorisation takes only a couple of minutes. When it is required, you fill see this pop-up appear in Lemonaid. After clicking "continue" you will be directed to the authorisation flow. You will be redirected to Holvi where you are authenticated using your bank credentials. Complete the authorisation in Holvi and return to Lemonaid.\n\n
        <b>Which account should I give the authorisation for?</b>\n
        We require access authorisation for the company's main account. If the company does not have an official bank account, select the account which the company's sales amounts are paid to. If your company has multiple accounts in use then provide authorisation for all of them. Note that company accounts may only include the company's own transactions, so do not use these accounts for personal purchases.\n\n
        <b>How long is the authorisation valid?</b>\n
        You can cancel the authorisation at any time by signing in to Holvi and deleting the consent.\n\n
        <b>What does it cost?</b>\n
        The bank connection technology we use is fully free of charge for you. Your company will not have to pay anything for giving the authorisation or using the bank connection.\n\n
        <b>Is the connection safe?</b>\n
        We use a system set out by Holvi to create the bank connection. The system is a secure, reliable, and standardised end-to-end encrypted channel.`
      },
      'raportit': {
        'tuloslaskelma': 'Profit and loss statement',
        'tulos': 'P&L statement',
        'tase': 'Balance sheet',
        'tulos-ja-tase': 'P&L statement and balance sheet',
        'tase-ja-tulos': 'Balance sheet and P&L statement',
        'paakirja': 'General ledger',
        'paivakirja': 'General journal',
        'alv-laskelma': {
          'alv-laskelma': 'VAT calculation',
          'verollinen': 'With tax',
          'veroton': 'Without tax',
          'vero': 'Tax',
          'myynnit': 'Sales',
          'ostot': 'Purchases'
        },
        'alv-ilmoitus': {
          'alv-ilmoitus': 'VAT return',
          'lahetetty-verohallinnolle': 'Sent to the Tax Administration',
          'esikatseluversio': 'Preview - NOT sent to the Tax Administration',
          'vero-kotimaan-myynnista-verokannoittain': 'Tax on domestic sales by percentage',
          '255-vero': '25.5% tax',
          '24-vero': '24% tax',
          '14-vero': '14% tax',
          '10-vero': '10% tax',
          'vero-ostoista-ja-maahantuonneista': 'Tax on purchases and imports',
          'vero-tavaraostoista-muista-eu-maista': 'Tax on goods purchased from other EU countries',
          'vero-palveluostoista-muista-eu-maista': 'Tax on services purchased from other EU countries',
          'vero-tavaroiden-maahantuonneista-eun-ulkopuolelta': 'VAT on import of goods from outside the EU',
          'vero-rakentamispalvelun-ja-metalliromun-ostoista': 'Tax on purchases of construction services/scrap metal',

          'vahennettava-vero': 'Deductible tax',
          'verokauden-vahennettava-vero': 'Tax deductible for the tax period',
          'alarajahuojennus': 'VAT relief',
          'alarajahuojennukseen-oikeuttava-liikevaihto': 'Sales that qualify for VAT relief',
          'alarajahuojennukseen-oikeuttava-vero': 'Tax that qualifies for VAT relief',
          'alarajahuojennuksen-maara': 'Amount of VAT relief',
          'maksettava-vero-palautukseen-oikeuttava-vero': 'Tax to be paid / tax qualified for relief',

          'myynnit-ostot-ja-maahantuonnit': 'Sales, purchases and imports',
          '0-verokannan-alainen-liikevaihto': 'Sales taxable at zero VAT rate',
          'tavaroiden-myynnit-muihin-eu-maihin': 'Sales of goods to other EU countries',
          'palveluiden-myynnit-muihin-eu-maihin': 'Sales of service to other EU countries',
          'tavaraostot-muista-eu-maista': 'Purchases of goods from other EU countries',
          'palveluostot-muista-eu-maista': 'Purchases of services from other EU countries',
          'tavaroiden-maahantuonnit-eun-ulkopuolelta': 'Imports of goods from outside the EU',
          'rakentamispalvelun-ja-metalliromun-myynnit': 'Sales of construction services/scrap metal (reverse change)',
          'rakentamispalvelun-ja-metalliromun-ostot': 'Purchases of construction services/scrap metal',

          'yhteyshenkilo': 'Contact person',
          'nimi': 'Name',
          'puhelinnumero': 'Phone number',
          'maksutiedot': 'Payment information',
          'saaja': 'Recipient',
          'verohallinto': 'Finnish Tax Administration',
          'viitenumero': 'Reference number',
          'ei-viitenumeroa': 'Suomi.fi authorisation missing. Check the reference numbers from Omavero.',
          'erapaiva': 'Due date',
          'summa': 'Amount',
          'tilinumerot': 'Account numbers',
          'pankki': 'Bank'
        },
        'veroilmoitus': 'Tax return',
        'selvitettavat-ostot': 'Unreconciled purchases',
        'selvitettavat-myynnit': 'Unreconciled sales',
        'tase-erittely': 'Balance sheet specifications',
        'tilintarkastuskertomus': 'Audit report',
        'tiliote': {
          'tiliote': 'Bank statement',
          'arkistointitunnus-tili-bic': 'Archival ID\nBeneficiary\'s account number/BIC\n',
          'arvopaiva': 'Value date',
          'selitys': 'Description',
          'tap-nro': 'Trans. no.',
          'maara': 'Amount',
          'viitenumero': 'Reference number'
        },
        'tilinpaatos': 'Financial statements',
        'liitetiedot': 'Notes',
        'yhtiokokouksen-poytakirja': 'Minutes of the annual general meeting',
        'brutto-tulos': 'Gross profit and loss',
        'brutto-tuloslaskelma': 'Gross profit and loss statement',
        'brutto-tuloslaskelma-lyhyt': 'Gross P&L statement',
        'lyhyt-tase-ja-tulos': 'Short balance sheet and P&L statement',
        'virallinen-tase': 'Official balance sheet',
        'virallinen-tulos': 'Official profit and loss statement',
        'virallinen-tulos-ja-tase': 'Official profit and loss statement and balance sheet',
        'virallinen-tase-ja-tulos': 'Official balance sheet and profit and loss statement',
        'virallinen': {
          'vastaavaa': 'A s s e t s',
          'pysyvat-vastaavat': 'NON-CURRENT ASSETS',
          'aineettomat-hyodykkeet': 'Intangible assets',
          'kehittamismenot': 'Development expenses',
          'aineettomat-oikeudet': 'Intangible rights',
          'liikearvo': 'Goodwill',
          'muut-aineettomat-hyodykkeet': 'Other intangible assets',
          'ennakkomaksut': 'Advance payments',
          'aineettomat-hyodykkeet-yhteensa': 'Intangible assets total',
          'aineelliset-hyodykkeet': 'Tangible assets',
          'maa-ja-vesialueet': 'Land and waters',
          'rakennukset-ja-rakennelmat': 'Buildings',
          'koneet-ja-kalusto': 'Machinery and equipment',
          'muut-aineelliset-hyodykkeet': 'Other tanginble assets',
          'ennakkomaksut-ja-keskeneraiset-hankinnat': 'Advance payments and acquisitions in progress',
          'aineelliset-hyodykkeet-yhteensa': 'Tangible assets total',
          'sijoitukset': 'Investments',
          'osuudet-saman-konsernin-yrityksissa': 'Holdings in group undertakings',
          'saamiset-saman-konsernin-yrityksilta': 'Amounts owed by group member companies',
          'osuudet-omistusyhteysyrityksissa': 'Participating interests',
          'saamiset-omistusyhteysyrityksilta': 'Amounts owed by participating interest companies',
          'muut-osakkeet-ja-osuudet': 'Other shares and interests',
          'muut-saamiset': 'Other amounts owed',
          'sijoitukset-yhteensa': 'Investments total',
          'pysyvat-vastaavat-yhteensa': 'NON-CURRENT ASSETS TOTAL',
          'vaihtuvat-vastaavat': 'CURRENT ASSETS',
          'vaihto-omaisuus': 'Stocks',
          'aineet-ja-tarvikkeet': 'Raw materials and consumables',
          'keskeneraiset-tuotteet': 'Work in progress',
          'valmiit-tuotteet-tavarat': 'Finished products / goods',
          'muu-vaihto-omaisuus': 'Other stocks',
          'vaihto-omaisuus-yhteensa': 'Stocks total',
          'pitkaaikaiset-saamiset': 'Long-term receivables',
          'myyntisaamiset': 'Trade debtors',
          'lainasaamiset': 'Loan receivables',
          'maksamattomat-osakkeet-osuudet': 'Unpaid shares/interests',
          'siirtosaamiset': 'Prepayments and accrued income',
          'pitkaaikaiset-saamiset-yhteensa': 'Long-term receivables total',
          'lyhytaikaiset-saamiset': 'Short-term receivables',
          'lyhytaikaiset-saamiset-yhteensa': 'Short-term receivables total',
          'rahoitusarvopaperit': 'Investments',
          'muut-arvopaperit': 'Other investments',
          'rahoitusarvopaperit-yhteensa': 'Investments total',
          'rahat-ja-pankkisaamiset': 'Cash in hand and at banks',
          'vaihtuvat-vastaavat-yhteensa': 'CURRENT ASSETS TOTAL',
          'vastaavaa-yhteensa': 'A s s e t s total',
          'vastattavaa': 'L i a b i l i t i e s',
          'oma-paaoma': 'CAPITAL AND RESERVES',
          'osake-osuus-tai-muu-vastaava-paaoma': 'Subscribed, co-operative or other liability capital',
          'arvonkorotusrahasto': 'Revaluation reserve',
          'vararahasto': 'Legal reserve',
          'muut-rahastot': 'Other reserves',
          'sijoitetun-vapaan-oman-paaoman-rahasto': 'Reserve for invested unrestricted capital',
          'yhtiojarjestyksen-tai-saantojen-mukaiset-rahastot': 'Reserves provided for by the articles of association',
          'muut-rahastot-yhteensa': 'Other reserves total',
          'paaoma-ay': 'Capital (general partnership)',
          'peruspaaoma-tmi': 'Capital (private trader)',
          'edellisten-tilikausien-voitto-tappio': 'Retained earnings gain or loss',
          'yksityistilit-tilikaudella': 'Private accounts (private trader)',
          'tilikauden-voitto-tappio': 'Profit / loss for the fiscal year',
          'paaomalaina': 'Capital loans',
          'oma-paaoma-yhteensa': 'CAPITAL AND RESERVES TOTAL',
          'tilinpaatossiirtojen-kertyma': 'APPROPRIATIONS',
          'poistoero': 'Depreciation reserve',
          'verotusperaiset-varaukset': 'Taxation-based reserves',
          'tilinpaatossiirtojen-kertyma-yhteensa': 'APPROPRIATIONS TOTAL',
          'pakolliset-varaukset': 'PROVISIONS',
          'elakevaraukset': 'Provisions for pensions',
          'verovaraukset': 'Provisions for taxation',
          'muut-pakolliset-varaukset': 'Other provisions pakolliset varaukset',
          'pakolliset-varaukset-yhteensa': 'PROVISIONS TOTAL',
          'vieras-paaoma': 'CREDITORS',
          'pitkaaikainen-vieras-paaoma': 'Long-term liabilities',
          'paaomalainat': 'Capital loans (subordinated loans)',
          'vaihtovelkakirjalainat': 'Convertible debentures',
          'lainat-rahoituslaitoksilta': 'Loans from credit institutions',
          'saadut-ennakot': 'Advances received',
          'ostovelat': 'Accounts payables (trade creditors)',
          'velat-saman-konsernin-yrityksille': 'Amounts owed to group member companies',
          'velat-omistusyhteysyrityksille': 'Amounts of to participating interest companies',
          'muut-velat': 'Other creditors',
          'siirtovelat': 'Accruals and deferred income',
          'pitkaaikainen-vieras-paaoma-yhteensa': 'Long-term liabilities total',
          'lyhytaikainen-vieras-paaoma': 'Short-term liabilities',
          'lyhytaikainen-vieras-paaoma-yhteensa': 'Short-term liabilities total',
          'vieras-paaoma-yhteensa': 'CREDITORS TOTAL',
          'vastattavaa-yhteensa': 'L i a b i l i t i e s total',

          'liikevaihto': 'T U R N O V E R',
          'valmiiden-ja-keskeneraisten-muutokset': 'Inventory change, finished products and work in progress',
          'valmistus-omaan-kayttoon': 'Manufacturing for enterprise\'s own use',
          'liiketoiminnan-muut-tuotot': 'Other operating income',
          'materiaalit-ja-palvelut': 'Raw materials and services',
          'aineet-tarvikkeet-ja-tavarat': 'Raw materials and consumables',
          'ostot-tilikauden-aikana': 'Purchases during fiscal year',
          'varastojen-muutos': 'Inventory change',
          'aineet-tarvikkeet-ja-tavarat-yhteensa': 'Raw materials and consumables total',
          'ulkopuoliset-palvelut': 'External services',
          'materiaalit-ja-palvelut-yhteensa': 'Raw materials and services total',
          'henkilostokulut': 'Personnel expenses',
          'palkat-ja-palkkiot': 'Wages and salaries',
          'henkilostosivukulut': 'Personnel incidentals',
          'elakekulut': 'Pension expenses',
          'muut-henkilosivukulut': 'Other personnel incidentals',
          'henkilostosivukulut-yhteensa': 'Personnel incidentals total',
          'henkilostokulut-yhteensa': 'Personnel expenses total',
          'poistot-ja-arvonalentumiset': 'Depreciation and reduction in value',
          'suunnitelman-mukaiset-poistot': 'Depreciation according to plan',
          'arvonalentumiset-pysyvien-vastaavien-hyodykkeista': 'Reduction in value of non-current assets',
          'vaihtuvien-vastaavien-poikkeukselliset-arvonalentumiset': 'Exceptional reduction in value of current assets',
          'poistot-ja-arvonalentumiset-yhteensa': 'Depreciation and reduction in value total',
          'liiketoiminnan-muut-kulut': 'Other operating charges',
          'liikevoitto': 'O P E R A T I N G  P R O F I T (- L O S S) TOTAL',
          'rahoitustuotot-ja-kulut': 'Financial income and expenses',
          'tuotot-osuuksista-saman-konsernin-yrityksissa': 'Income from group undertakings',
          'tuotot-osuuksista-omistusyhteysyrityksissa': 'Income from participating interests',
          'tuotot-muista-pysyvien-vastaavien-sijoituksista': 'Income from other investments held as non-current assets',
          'muilta': 'From others',
          'muut-korko-ja-rahoitustuotot': 'Other interest and financial income',
          'saman-konsernin-yrityksilta': 'From group undertakings',
          'arvonalentumiset-pysyvien-vastaavien-sijoituksista': 'Reduction in value of investments held as non-current assets',
          'arvonalentumiset-vaihtuvien-vastaavien-rahoitusarvopapereista': 'Reduction in value of current assets securities',
          'korkokulut-ja-muut-rahoituskulut': 'Interest and other financial expenses',
          'muille': 'To others',
          'rahoitustuotot-ja-kulut-yhteensä': 'Financial income and expenses total',
          'satunnaiset-erät': 'Indidental items',
          'voitto-ennen-tilinpaatossiirtoja-ja-veroja': 'P R O F I T (- L O S S) BEFORE FINANCIAL STATEMENT TRANSFERS AND TAXES',
          'tilinpaattosiirrot': 'Financial statement transfers',
          'poistoeron-muutos': 'Change in depreciation reserve',
          'verotusperusteisten-varausten-muutos': 'Change in taxation-based reserves',
          'konserniavustus': 'Group contribution',
          'tilinpaattosiirrot-yhteensä': 'Financial statement transfers in total',
          'tuloverot': 'Income taxes',
          'muut-valittomat-verot': 'Other direct taxes',
          'tilikauden-voitto': 'PROFIT (-LOSS) FOR FISCAL YEAR'
        },
        'luettelo-kirjanpidoista': 'List of accounts and records',
        'tilikartta': 'Chart of accounts',
        'hallituksen-poytakirja': 'Minutes of the board',
        'laskutustiedot': 'Invoicing information',
        'toimeksiantosopimus': 'Service agreement',
        'palvelukuvaus': 'List of services',
        'valittu-paivamaara-ei-tilikaudella': 'The selected date range does not match any financial years in the system.',
        'tilille-ei-vienteja': 'Account has no entries',
        'ei-tapahtumia': 'There are no transaction in the selected date range.',
        'laji': 'Type',
        'tosite': 'Receipt',
        'pvm': 'Date',
        'selite': 'Descr.',
        'alv': 'VAT',
        'debet': 'Debit',
        'kredit': 'Credit',
        'saldo': 'Balance',
        'alkusaldo': 'Starting balance',
        'yhteensa': 'In total',
        'yhteensa-pienella': 'in total',
        'tulos-ennen-veroja': 'income before taxes',
        'tilikauden-voitto': 'profits of financial year',
        'tase-erittelyt-laati': 'Balance sheet specifications loaded',
        'liite-tase-erittelyyn': 'Balance sheet specifications attachment',
        'erillisen-liitteen-mukaan': 'According to separate attachment',
        'muutos': 'Change',
        'tili': 'Account',
        'kirjanpitajan-kommentit': 'Comments by accountant',
        'otot': 'Withdrawals',
        'panot': 'Deposits',
        'kpl': '',
        'kuluva-tilikausi': 'Current financial year',
        'edellinen-tilikausi': 'Prev financial year',
        'vertailuaikavali': 'Comparison',
        'ei-tilikaudella': 'Not on financial year',
        'vertailuaikavali-ei-tilikaudella': 'Comparison not on financial year',
        'reskontra': {
          'reskontra': 'Accounts receivable',
          'reskontraraportti': 'Accounts receivable report',
          'tili': 'Account',
          'pvm': 'Date',
          'naytetaan': 'Selected',
          'avoimet-viennit': 'Open exports',
          'kaikki-viennit': 'All exports',
          'debet': 'Debit',
          'kredit': 'Credit',
          'puuttuu': 'Missing',
          'saldo': 'Balance'
        },
        'varsinaisen-yhtiokokouksen-poytakirja': 'Minutes of the General Meeting of Shareholders',
        'lataa-pdf': 'Download PDF'
      },
      'reports-lemonaid': {
        'tilikausi': 'Financial year',
        'ei-tilikausia-text': 'There are no financial years registered. Please contact your accountant to open financial years.',

        'lataa-asiakirja': 'Download document',
        'lataa-tilinpaatos': 'Download financial statement',
        'lataa-poytakirja': 'Download minutes',

        'tilinpaatos': 'Financial statement',
        'hallituksen-poytakirja': 'Minutes of the board',
        'tilinpaatos-ja-hallituksen-poytakirja': 'Financial statement and minutes of the board',
        'allekirjoitukset': 'Signatures',
        'tilinpaatoksen-allekirjoittaa': 'The financial statement is signed by the <b>CEO</b> - if the company has one - and <b>the Board</b> (excluding deputy members).',
        'hyvaksymisesta-on-laadittava': 'The approval of the financial statement must also be recorded in the minutes of the board. The financial statement can be signed and the minutes of the board can be recorded in Lemonaid. Lemonaid credentials are required for signing. If the required persons do not have Lemonaid access yet, you can request your accountant to provide it. The financial statement may also be signed on paper - in that case please contact your accountant.',
        'lemonaidin-sahkoinen-allekirjoitus': 'The electronic signing of the financial statement is possible when all signers have Lemonaid credentials. If the required persons do not have Lemonaid access yet, you can request your accountant to provide it. The financial statement may also be signed on paper - in that case please contact your accountant.',
        'alla-on-listattuna': 'The members of the board and the CEO (if any) you have reported are listed below. Check that all members are on the list and that they have the correct role. Please contact your accountant if any members are missing from the list.',
        'tilinpaatoksen-allekirjoittavat-henkilot': 'Signers of the financial statement',
        'rooli': 'Role',
        'hyvaksymispoytakirjan-tiedot': 'Financial statement approval minutes information',
        'pvm': 'Date',
        'hyvaksy-valinta': 'Approve selection',
        'muokkaa-allekirjoittajia': 'Edit signer list',
        'allekirjoita': 'Sign',
        'allekirjoita-tilinpaatos': 'Sign the financial statement and the minutes of the board meeting',
        'allekirjoita-poytakirja': 'Sign the minutes',
        'olen-kaynyt-lapi': 'I have reviewed the financial statement and the minutes of the board and agree to sign them.',
        'tilinpaatos-pitaa-rekisteroida': 'The financial statement and information on the distribution of profits must be declared to Finnish Patent and Registration Office.',
        'valtuutan-lemon-treen': 'I authorise Tilitoimisto Lemon Tree Oy to declare this information.',
        'valtuutan-lemon-treen-required': 'Please approve the authorisation.',
        'ylla-oleva-poytakirja': 'The minutes above reflect the decisions made at the meeting. I agree to sign this document.',
        'allekirjoitettu-sahkoisesti': 'Electronically signed',
        'allekirjoitus-puuttuu': 'Signature not given',
        'tilinpaatos-ei-viela-tehty': 'Financial statement for fiscal year has not been done yet.',
        'tilinpaatos-asiakirjaa-ladataan': 'Loading financial statement document...',
        'tilinpaatoksen-tietoja-ladataan': 'Loading financial statement metadata...',
        'no-lemonaid-user': 'The person does not have Lemonaid credentials, so signing is not possible. Please ask your accountant to create the credentials',

        'tilinpaatos-allekirjoitettu': 'Financial statement signed',
        'avaa-yhtiokokouksen-poytakirja': 'Open Minutes of the General Meeting',

        'ei-tilitarkasteta': {
          'tilinpaatos-allekirjoitettu-osaltasi': 'You have now signed the financial statement. Once other board members have given their signature as well, the financial statement will be approved at the general meeting of shareholders. Minutes of the general meeting can be created in the Minutes of the General Meeting tab.',
          'tilinpaatos-valmis-vahvistettavaksi': 'The financial statement has now been signed and is ready to be approved at the annual general meeting of shareholders. You can create the minutes of the meeting in the Minutes of the General Meeting tab.',
          'tilinpaatos-pitaa-allekirjoittaa': 'The financial statement must be signed before the general meeting of shareholders is held.',
        },
        'tilitarkastetaan': {
          'tilinpaatos-allekirjoitettu-osaltasi': 'You have now signed the financial statement. Once board members have given their signature as well, the financial statement will be approved at the general meeting of shareholders. Minutes of the general meeting can be created in the Minutes of the General Meeting tab.',
          'tilinpaatos-valmis-tilintarkastettavaksi': 'The financial statement has now been signed and is ready to be audited. After the audit the financial statement wil lbe approved at the general meeting of shareholders and minutes of the general meeting will be created in the Minutes of the General Meeting tab.',
          'tilinpaatos-pitaa-tilintarkastaa': 'The financial statement has to be audited before the general meeting of shareholders can be held',
        },

        'varsinainen-yhtiokokous': 'Annual General Meeting of Shareholders',
        'varsinaisen-yhtiokokouksen-poytakirja': 'Minutes of the General Meeting of Shareholders',
        'yhtiokokouksen-poytakirja': 'Minutes of the General Meeting',
        'voit-luoda-ja-allekirjoittaa': 'Here you can create and sign the Minutes of the General Meeting if the meeting is convened according to the Companies Act and the decisions made at the meeting concern only mandatory matters. If there will also be decisions about other matters made during the meeting, the minutes must be presented on paper. In order to sign this document the chairman and the examiner of the minutes need to have a Lemonaid account.',
        'perustiedot': 'Basic information',
        'kokouksen-aika': 'Time of meeting',
        'kokouksen-aika-min-date': 'Meeting date cannot be before the financial statement was signed',
        'kokouspaikka': 'Place of meeting',
        'kokouspaikka-esimerkki': 'Eg. \"Company offices\"',
        'poytakirjan-numero': 'Minutes number',
        'lasnaolijat': 'Shareholders present',
        'nimi': 'Name',
        'osakkeet': 'Shares',
        'kokouksen-avaus': 'Calling the meeting to order',
        'kokouksen-avasi': 'Called to order by',
        'kokouksen-avasi-name': 'Called to order by {{name}}.',
        'kokouksen-jarjestaytyminen': 'Organisation of the meeting',
        'puheenjohtaja': 'Chairman',
        'sihteeri': 'Secretary',
        'poytakirjan-tarkastaja': 'Examiner of the Minutes',
        'osinkojen-jakaminen': 'Distribution of dividends',
        'osinkoa-ei-jaeta': 'Dividends will not be distributed',
        'osinkoa-jaetaan': 'The amount of €{{sum}} will be paid out as dividends (proposal of the board)',
        'esikatsele-ja-allekirjoita': 'Preview and sign',
        'muokkaa-poytakirjaa': 'Edit the minutes',

        'hallituksen-paatos': 'Hallituksen päätös',
        'paatoksentekoon-osallistuneet': 'Päätöksentekoon osallistuneet',
        'asema-hallituksessa': 'Asema hallituksessa',
        'paatoksenteon-laillisuus': 'Päätöksenteon laillisuus',
        'paatokset-tehtiin-kokousta-pitamatta': 'Päätökset tehtiin kokousta pitämättä osakeyhtiölain mukaisesti. Kaikki hallituksen jäsenet osallistuivat päätöksentekoon. Näin ollen tämän pöytäkirjan mukaiset päätökset ovat laillisia.',
        'tilinpaatoksen-kasitteleminen': 'Tilinpäätöksen käsitteleminen',
        'esitettiin-tilinpaatosajanjaksolta': 'Esitettiin tilinpäätös ajanjaksolta {{ajanjakso}}. Hyväksyttiin ja allekirjoitettiin tilinpäätösasiakirjat.',
        'esitys-tuloksen-kayttamisesta': 'Esitys taseen osoittaman tuloksen käyttämisestä',
        'todettiin-etta-tilinpaatoksen-voitto': 'Todettiin, että tilinpäätöksen osoittama voitto oli {{profit}} euroa.',
        'todettiin-etta-tilinpaatoksen-tappio': 'Todettiin, että tilinpäätöksen osoittama tappio oli {{profit}} euroa.',
        'paatettiin-esittaa-etta-jaetaan-osinkoa': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta jaetaan osinkoa {{dividendPerShare}} euroa osakkeelta eli yhteensä {{dividendTotal}} euroa ja että loput tuloksesta siirretään voitto/tappio -tilille. Todettiin, että yhtiön jakokelpoiset varat ja maksukyky mahdollistavat esitetyn osingon jakamisen.',
        'paatettiin-esittaa-etta-ei-jaeta-osinkoa-tilikauden-voitto': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta ei jaeta osinkoa ja tilikauden voitto kirjataan edellisten tilikausien voitto/tappio -tilille.',
        'paatettiin-esittaa-etta-ei-jaeta-osinkoa-tilikauden-tappio': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta ei jaeta osinkoa ja tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.',

        'poytakirja': 'Minutes',
        'aika': 'Time of meeting',
        'paikka': 'Place of meeting',
        'lasna': 'Present',
        'osakas': 'Shareholder',
        'aanet': 'Votes',
        'kokouksen-avaaminen': 'Calling the Meeting to Order',
        'kokouksen-puheenjohtajaksi-ja-tarkistajaksi': '{{puheenjohtaja}} was selected as the Chairman of the Meeting and {{tarkastaja}} was selected to act as the Examiner of the Minutes.',
        'aaniluettelon-vahvistaminen': 'Adoption of the List of Votes',
        'puheenjohtaja-totesi': 'The Chairman noted that the list of votes is visible at the Meeting. The list of votes was confirmed.',
        'kokouksen-laillisuus': 'Legality and quorum',
        'todettiin-etta-kaikki-osakkeet': 'It was noted that all of the Company\'s shares are represented at the Meeting, therefore the meeting has been convened legally and constitutes a quorum.',
        'todettiin-kokoon-kutsutuksi': 'The meeting was convened legally and constitutes a quorum according to the Limited Liability Companies Act and the articles of association.',
        'tilinpaatoksen-esittaminen': 'Presentation of the Financial Statement',
        'esitettiin-tilinpaatos': 'The financial statement for {{start}} - {{end}} was presented.',
        'esitettiin-tilinpaatos-ja-tilintarkastuskertomus': 'The financial statement for {{start}} - {{end}} and the audit report based on it were presented.',
        'tilinpaatoksen-vahvistaminen': 'Approving of the Financial Statement',
        'vahvistettiin-tilinpaatos': 'The financial statement was approved in the form presented by the Board.',
        'taseen-osoittaman': 'Results as shown on the Balance Sheet',
        'todettiin-etta-vahvistettu-tase': 'It was noted that that the approved balance sheet indicates a profit (or loss) of €{{profit}}.',
        'paatettiin-jakaa-osinkoa': 'It was decided that a total of €{{dividendTotal}} or €{{dividendPerShare}} per share will be distributed as dividends. The dividend will be paid out {{dividendPayoutTime}}. It was decided that the remaining profit will be recorded as the previous financial year\'s profit / loss.',
        'heti-yhtiokokouksen-jalkeen': 'immediately after the general meeting',
        'kun-poytakirja-on-allekirjoitettu': 'after the minutes of the general meeting have been signed',
        'todettiin-etta-osoittaa-voittoa-ei-osinkoa': 'It was noted that that the approved financial results indicate a profit of €{{profit}}. It was decided that a dividend will not be distributed. The profit was decided to be recorded as the previous financial year\'s profit / loss.',
        'todettiin-etta-osoittaa-tappiota-ei-osinkoa': 'It was noted that that the approved financial results indicate a loss of €{{loss}}. It was decided that the loss will be recorded as the previous financial year\'s profit / loss. It was decided that a dividend will not be distributed.',
        'todettiin-etta-osittaa-tappiota-jakaa-osinkoa': 'It was noted that that the approved financial results indicate a loss of €{{loss}}. It was decided that the loss will be recorded as the previous financial year\'s profit / loss. It was decided that a total of €{{dividendTotal}} or €{{dividendPerShare}} per share will be distributed as dividends. The dividend will be paid out {{dividendPayoutTime}}.',
        'vastuuvapaudesta-paattaminen': 'Discharging of Liability',
        'myonnettiin-vastuuvapautus': 'The Board Members the Chief Executive Officer (if selected) of financial year {{start}} - {{end}} were dischanged from liability.',
        'kokouksen-paattaminen': 'Closing of the Meeting',
        'puheenjohtaja-paattii-kokouksen': 'The Chairman closed the Meeting as there were no other matters to address.',
        'henkiloita-ei-voi-valita': 'The persons marked gray cannot be selected because they do not have active Lemonaid accounts.',
        'valitse-eri-henkilot': 'Please set different persons as the Chairman of the Meeting and the Examiner of the Minutes.',

        'tilikausi-paketissa': 'Financial year wrapped up!',
        'poytakirja-allekirjoitettu-tilikausi-paketissa': 'The Minutes of the General Meeting have now been signed and the financial year of {{year}} is done. Lemonaid will archive the signed financial documents and you can download them from here.',

        'yhtiokokouksen-poytakirjaa-ladataan': 'Loading general meeting minutes preview...',
        'yhtiokokouksen-poytakirjan-tietoja-ladataan': 'Loading general meeting minutes metadata...',

        'tilinpaatos-no-signers-error': 'Please select at least one signer',
        'yhtiokokous-no-signers-error': 'Please select at least one shareholder'
      },
      'luettelo-kirjanpidoista': {
        'luettelo-kirjanpidoista-ja-aineistoista': 'Luettelo kirjanpidoista ja aineistoista',
        'tilikaudella': 'Tilikaudella',
        'kirjanpidot-ja-aineistot': 'Kirjanpidot ja aineistot',
        'tilinpaatos': 'Tilinpäätös',
        'tase-erittelyt': 'Tase-erittelyt',
        'virallinen-tuloslaskelma': 'Virallinen tuloslaskelma',
        'virallinen-tase': 'Virallinen tase',
        'tilikohtainen-tuloslaskelma': 'Tilikohtainen tuloslaskelma',
        'tilikohtainen-tase': 'Tilikohtainen tase',
        'paakirjat': 'Pääkirjat',
        'paivakirjat': 'Päiväkirjat',
        'palkkakirjanpito': 'Palkkakirjanpito',
        'tililuettelo': 'Tililuettelo',
        'tositelajit': 'Tositelajit',
        'tositelaji': 'Tositelaji',
        'tunnus': 'Tunnus',
        'vastatili': 'Vastatili',
        'lisatietoja': 'Lisätietoja',
        'muut-tositteet': 'Muut tositteet',
        'kirjanpitajan-tai-jarjestelman-luoma': 'Kirjanpitäjän luoma tai järjestelmän luoma',
        'sahkoinen-tiliote': 'Sähköinen tiliote',
        'kayttajan-luoma': 'Lemonaid-käyttäjän luoma',
        'kirjanpitajan-luoma': 'Kirjanpitäjän luoma',
        'sahkoisen-tiliotteen-kirjaukset': 'Sähköisen tiliotteen kirjaukset on luotu automaattisesti tilitapahtumien pohjalta ja niihin on liitetty asiakkaan toimittamat tositteet.',
        'kayttajan-luoma-kirjaus': 'Lemonaid-käyttäjän luoma kirjaus on luotu automaattisesti asiakkaan toimittaman tositteen pohjalta',
        'muut-tositteet-sisaltaa': 'Muut tositteet tositelaji sisältää järjestelmän automaattisesti luomat kirjaukset sekä kirjanpitäjään itse luomia tositteet, joita voi olla esim. alkusaldot, palkkalaskelmat tai myyntiraportit.',
        'sailytystavat': 'Säilytystavat',
        'kaikki-aineisto-sailytetaan': 'Kaikki aineisto säilytetään Tilitoimisto Lemon Tree Oy:n (jäljempänä Tilitoimisto) tietojärjestelmässä kirjanpitovelvollisen ja Tilitoimisto välisen toimeksiannon jatkuessa. Tilitoimisto toimittaa aineiston pdf-tiedostoina pyydettäessä. Toimeksiannon päätyttyä kaikki aineisto säilytetään kirjanpitovelvollisen hallinnoimilla sähköisillä tallennusvälineillä.'
      },
      'laskutustiedot-pdf': {
        'laskutustiedot': 'Invoicing information',
        'hyva-toimittajamme-pyydamme-teita': 'Dear supplier,\n\nPlease send your invoices electronically to the address below.',
        'ostolaskujen-vastaanotto': 'Receiving of purchase invoices',
        'verkkolaskuosoite': 'E-invoice address',
        'verkkolaskujen-valittaja': 'E-invoice intermediator',
        'ystavallisin-terveisin': 'Best regards,'
      }
    }
    //   'listaus': {
    //     'kpl-per-sivu': 'Lines in a page',
    //     'seuraava-sivu': 'Next page',
    //     'edellinen-sivu': 'Previous page'
    //   },
    //   'valuutta': {
    //     'EUR': 'EUR (Euro)'
    //   },
    //   'kieli': {
    //     'fi': 'Finnish',
    //     'sv': 'Swedish',
    //     'en': 'English'
    //   },
    //   'laskun tyyppi': {
    //     'tav': 'Domestic sale',
    //     'taveu': 'Communitysales to other country of EU (goods)',
    //     'palveu': 'Communitysales to other country of EU (service)',
    //     'eieu': 'Sales outside of EU',
    //     'kuleu': 'Consumersales to other country of EU',
    //     'kuleut': 'Consumersales to other country of EU (goods)',
    //     'kuleup': 'Consumersales to other country of EU (service)',
    //     'kuleur': 'Consumersales to other country of EU',
    //     'rak': 'Rakentamispalveluiden myynti'
    //   },
    //   'alv': {
    //     'yht': 'Alv 0% Communitysales',
    //     'suomi0': '0 %',
    //     'suomi10': '10 %',
    //     'suomi14': '14 %',
    //     'suomi24': '24 %',
    //     'suomi255': '25,5 %',
    //     'eieu': 'Alv 0% Sales outside of EU',
    //     'rak': 'Alv 0% Käännetty verovelvollisuus'
    //   },
    //   'login': {
    //     'login': 'SIGN IN',
    //     'email': 'Email address',
    //     'password': 'Password',
    //     'validation': {
    //       'email': {
    //         'required': 'Email address is missing.',
    //         'user-not-found': 'Annetulla sähköpostiosoitteella ei löytynyt käyttäjää Email address you have given does not exist.',
    //         'invalid-email': 'Email address is incorrect.',
    //         'email': 'Email address is incorrect.',
    //         'user-disabled': 'Useraccount is not active.'
    //       },
    //       'password': {
    //         'required': 'Password is missing.',
    //         'wrong-password': 'Password is incorrect.'
    //       }
    //     }
    //   },
    //   'lasku': {
    //     'title': 'Create an invoice',
    //     'tyyppi': 'Type on an invoice',
    //     'kieli': 'Language of an invoice',
    //     'nimi': 'Company / Name',
    //     'ytunnus': 'VAT-numero',
    //     'katuosoite': 'Street address',
    //     'postinro': 'Postnumber',
    //     'postitoimipaikka': 'Post office',
    //     'maa': 'Country',
    //     'laskunnro': 'Number of an invoice',
    //     'laskunpvm': 'Date of an invoice',
    //     'erapaiva': 'Maturity',
    //     'toimituspvm': 'Toimituspvm.',
    //     'valuutta': 'Currency',
    //     'maksuaikaa': 'Time limit for paying',
    //     'viitteenne': 'Your index number',
    //     'lisatiedot': 'Further information for your invoice',
    //     'merkitse-luottotappioksi': 'Merkitse luottotappioksi',
    //     'merkitse-osittain-maksetuksi': 'Merkitse maksetuksi',
    //     'laheta-maksumuistutus': 'Lähetä maksumuistutus',
    //     'merkitse-osittain-maksetuksi-dialog': {
    //       'maksettu-maara': 'Maksun määrä'
    //     },
    //     'listaus': {
    //       'tila': {
    //         'm': 'Maksetut',
    //         'a': 'Avoimet',
    //         'om': 'Osittain maksetut',
    //         'l': 'Luottotappiolliset',
    //         'ml': 'Ylisuoritetut',
    //         'k': 'Kaikki'
    //       }
    //     },
    //     'tila': {
    //       'undefined': '',
    //       'm': 'Maksettu',
    //       'a': 'Avoin',
    //       'om': 'Osittain maksettu',
    //       'l': 'Luottotappio',
    //       'ml': 'Ylisuoritus',
    //       'kl': 'Korvattu laskulla {{korvaavanLaskunNumero}}',
    //       'kml': 'Korv. muistutuslaskulla {{korvaavanLaskunNumero}}'
    //     },
    //     'sposti': {
    //       'otsikko': 'Otsikko',
    //       'teksti': 'Teksti',
    //       'lisaavastaanottaja': 'Lisää vastaanottaja...',
    //       'validation': {
    //         'otsikko': {
    //           'required': 'Sähköpostin otsikko puuttuu.'
    //         },
    //         'teksti': {
    //           'required': 'Sähköpostin teksti puuttuu.'
    //         },
    //         'lisaavastaanottaja': {
    //           'email': 'Sähköpostiosoite on virheellinen.',
    //           'min': 'Lisää vähintään yksi vastaanottaja.'
    //         }
    //       }
    //     }
    //   },
    //   'laskuasetukset': {
    //     'nimi': 'Yritys / Nimi',
    //     'ytunnus': 'VAT-numero',
    //     'katuosoite': 'Katuosoite',
    //     'postinro': 'Postinumero',
    //     'postitoimipaikka': 'Postitoimipaikka',
    //     'maa': 'Maa',
    //     'replyto': 'Sähköpostin vastausosoite (reply to)',
    //     'template': 'TEKNINEN KENTTÄ, ÄLÄ KOSKE!',
    //     'validation': {
    //       'nimi': {
    //         'required': 'Yritys / Nimi puuttuu.'
    //       },
    //       'ytunnus': {
    //         'required': 'VAT-numero puuttuu.'
    //       },
    //       'katuosoite': {
    //         'required': 'Katuosoite puuttuu.'
    //       },
    //       'postinro': {
    //         'required': 'Postinumero puuttuu.'
    //       },
    //       'postitoimipaikka': {
    //         'required': 'Postitoimipaikka puuttuu.'
    //       },
    //       'maa': {
    //         'required': 'Maa puuttuu.'
    //       },
    //       'replyto': {
    //         'required': 'Sähköpostin vastausosoite puuttuu.',
    //         'email': 'Sähköpostiosoite on virheellinen.'
    //       },
    //       'template': {
    //         'required': 'Template puuttuu.'
    //       }
    //     }
    //   },
    //   'tuote': {
    //     'nimi': 'Tuotteen nimi',
    //     'hinta': 'Tuotteen hinta',
    //     'validation': {
    //       'nimi': {
    //         'required': 'Tuotteen nimi puuttuu.'
    //       }
    //     }
    //   },
    //   'asetukset': {
    //     'lasku': 'Laskuasetukset'
    //   }
  }

  getFinnish(): any {
    return {
      'browser-clock-check': {
        'browser-time-wrong': 'Selaimesi kello näyttäisi olevan väärässä ajassa. Ole hyvä ja tarkista, että laitteesi aika on oikein asetettu. Väärä aika johtaa väistämättä virheisiin myöhemmin.',
        'current-time': 'Tämänhetkinen aika: ',
        'browser-time': 'Selaimen aika: ',
      },
      'unsupported-browser': 'Käytät Lemonaidia liian vanhalla selaimella. Jos jatkat tällä selaimella et voi välttyä virheiltä. Ole hyvä ja päivitä selain uudempaan versioon. Tuettuja selaimia ovat mm. viimeisimmät versiot seuraavista: Chrome, Firefox, Edge tai Safari.',
      'unsupported-browser-detected': 'Tunnistettu selain',
      'unsupported-browser-required': 'pitää vähintään olla',
      'app': {
        'laskut': 'Laskut',
        'palkat': {
          'palkat': 'Palkat',
          'yleiskuva': 'Yleiskuva',
          'laskelmat': 'Laskelmat',
          'listat': 'Palkkalistat',
          'tyontekijat': 'Työntekijät',
          'raportit': 'Raportit',
          'asetukset': 'Asetukset',
          'ohjeet': 'Ohjeet',
          'aspa': 'Asiakaspalvelu'
        },
        'maksut': 'Ostolaskut',
        'tuotteet': 'Tuotteet',
        'asiakkaat': 'Asiakkaat',
        'asetukset': 'Asetukset',
        'tiliotteet': 'Tiliotteet',
        'palkkamateriaali': 'Palkkamateriaali',
        'ostotositteet': 'Ostotositteet',
        'myyntitositteet': 'Myyntitositteet',
        'laskujen-vastaanotto': 'Laskujen vastaanotto',
        'laskujen-hyvaksyminen': 'Laskujen hyväksyntä',
        'laskutus': 'Laskutus',
        'tositteet': 'Tositteet',
        'kirjaudu-ulos': 'Kirjaudu ulos',
        'no-internet-connection': 'Ei yhteyttä Lemonaid taustajärjestelmään',
        'selvitettavia-tilitapahtumia': 'Selvitettäviä tilitapahtumia!',
        'reports': 'Raportit',
        'uutta': 'Uutta!',
        'tilinpaatokset': 'Tilinpäätökset',
        'kirjanpito': 'Kirjanpito'
      },
      'vaihda-kieli-dialog': {

      },
      'sopimus-ja-ehdot': {
        'sopimus-ja-ehdot': 'Sopimus ja ehdot',
        'sopimus': 'Sopimus',
        'palvelukuvaus': 'Palvelukuvaus',
        'yleiset-palveluehdot': 'Yleiset palveluehdot',
        'sopimus-henkilotietojen': 'Sopimus henkilötietojen käsittelystä',
        'allekirjoitettu-sahkoisesti': 'Allekirjoitettu sähköisesti',
        'ei-viela-allekirjoitettu': 'Ei vielä allekirjoitettu',
        'lataa-pdf': 'Lataa PDF'
      },
      'palkkausfi': {
        'virhe': 'Palkkaus.fi integraatiossa tapahtui virhe. Ole hyvä päivitä sivu uudelleen. Jos ongelma ei poistu, ole hyvä ja ota yhteys kirjanpitäjääsi. Pahoittelemme aiheutunutta vaivaa.'
      },
      'listaus': {
        'kpl-per-sivu': 'Rivejä sivulla',
        'seuraava-sivu': 'Seuraava sivu',
        'edellinen-sivu': 'Edellinen sivu'
      },
      'valuutta': {
        'AED': 'Arabiemiirikuntien dirhami',
        'AFN': 'afgaani',
        'ALL': 'lek',
        'AMD': 'dram',
        'ANG': 'Alankomaiden Antillien guldeni',
        'AOA': 'kwanza',
        'ARS': 'Argentiinan peso',
        'AUD': 'Australian dollari',
        'AWG': 'Aruban guldeni',
        'AZN': 'Azerbaidžanin manat',
        'BAM': 'vaihdettava markka',
        'BBD': 'Barbadosin dollari',
        'BDT': 'taka',
        'BGN': 'lev',
        'BHD': 'Bahrainin dinaari',
        'BIF': 'Burundin frangi',
        'BMD': 'Bermudan dollari',
        'BND': 'Brunein dollari',
        'BOB': 'boliviano',
        'BRL': 'real',
        'BSD': 'Bahaman dollari',
        'BTN': 'ngultrum',
        'BWP': 'pula',
        'BYN': 'Valko-Venäjän rupla',
        'BYR': 'Valko-Venäjän rupla',
        'BZD': 'Belizen dollari',
        'CAD': 'Kanadan dollari',
        'CDF': 'Kongon frangi',
        'CHF': 'Sveitsin frangi',
        'CLP': 'Chilen peso',
        'CNY': 'juan renminbi',
        'COP': 'Kolumbian peso',
        'CRC': 'Costa Rican colon',
        'CUC': 'vaihdettava peso',
        'CUP': 'Kuuban peso',
        'CVE': 'Kap Verden escudo',
        'CZK': 'Tšekin koruna',
        'DJF': 'Djiboutin frangi',
        'DKK': 'Tanskan kruunu',
        'DOP': 'Dominikaanisen tasavallan peso',
        'DZD': 'Algerian dinaari',
        'EGP': 'Egyptin punta',
        'ERN': 'nakfa',
        'ETB': 'birr',
        'EUR': 'euro',
        'FJD': 'Fidžin dollari',
        'FKP': 'Falklandin punta',
        'GBP': 'Englannin punta',
        'GEL': 'lari',
        'GGP': 'Guernseyn punta',
        'GHS': 'Ghanan cedi',
        'GIP': 'Gibraltarin punta',
        'GMD': 'dalasi',
        'GNF': 'Guinean frangi',
        'GTQ': 'quetzal',
        'GYD': 'Guyanan dollari',
        'HKD': 'Hongkongin dollari',
        'HNL': 'lempira',
        'HRK': 'kuna',
        'HTG': 'gourde',
        'HUF': 'forintti',
        'IDR': 'Indonesian rupia',
        'ILS': 'sekeli',
        'IMP': 'Mansaaren punta',
        'INR': 'Intian rupia',
        'IQD': 'Irakin dinaari',
        'IRR': 'Iranin rial',
        'ISK': 'Islannin kruunu',
        'JEP': 'Jerseyn punta',
        'JMD': 'Jamaikan dollari',
        'JOD': 'Jordanian dinaari',
        'JPY': 'jeni',
        'KES': 'Kenian šillinki',
        'KGS': 'som',
        'KHR': 'riel',
        'KMF': 'Komorien frangi',
        'KPW': 'Pohjois-Korean won',
        'KRW': 'Etelä-Korean won',
        'KWD': 'Kuwaitin dinaari',
        'KYD': 'Caymansaarten dollari',
        'KZT': 'tenge',
        'LAK': 'kip',
        'LBP': 'Libanonin punta',
        'LKR': 'Sri Lankan rupia',
        'LRD': 'Liberian dollari',
        'LSL': 'loti',
        'LYD': 'Libyan dinaari',
        'MAD': 'Marokon dirham',
        'MDL': 'Moldovan leu',
        'MGA': 'ariary',
        'MKD': 'denaari',
        'MMK': 'kyat',
        'MNT': 'tugrug',
        'MOP': 'pataca',
        'MRO': 'Mauritanian ouguija',
        'MRU': 'Mauritanian ouguija',
        'MUR': 'Mauritiuksen rupia',
        'MVR': 'rufiyaa',
        'MWK': 'Malawin kwacha',
        'MXN': 'Meksikon peso',
        'MYR': 'ringgit',
        'MZN': 'metical',
        'NAD': 'Namibian dollari',
        'NGN': 'naira',
        'NIO': 'kultacórdoba',
        'NOK': 'Norjan kruunu',
        'NPR': 'Nepalin rupia',
        'NZD': 'Uuden-Seelannin dollari',
        'OMR': 'Omanin rial',
        'PAB': 'balboa',
        'PEN': 'sol',
        'PGK': 'kina',
        'PHP': 'Filippiinien peso',
        'PKR': 'Pakistanin rupia',
        'PLN': 'zloty',
        'PYG': 'guarani',
        'QAR': 'Qatarin rial',
        'RON': 'Romanian leu',
        'RSD': 'Serbian dinaari',
        'RUB': 'Venäjän rupla',
        'RWF': 'Ruandan frangi',
        'SAR': 'Saudi-Arabian rial',
        'SBD': 'Salomonsaarten dollari',
        'SCR': 'Seychellien rupia',
        'SDG': 'Sudanin punta',
        'SEK': 'Ruotsin kruunu',
        'SGD': 'Singaporen dollari',
        'SHP': 'Saint Helenan punta',
        'SLL': 'leone',
        'SOS': 'Somalian šillinki',
        'SRD': 'Surinamen dollari',
        'SSP': 'Etelä-Sudanin punta',
        'STD': 'dobra',
        'STN': 'dobra',
        'SVC': 'El Salvadorin colón',
        'SYP': 'Syyrian punta',
        'SZL': 'lilangeni',
        'THB': 'baht',
        'TJS': 'somoni',
        'TMT': 'Turkmenistanin manat',
        'TND': 'Tunisian dinaari',
        'TOP': 'pa\'anga',
        'TRY': 'Turkin liira',
        'TTD': 'Trinidadin ja Tobagon dollari',
        'TWD': 'Taiwanin uusi dollari',
        'TZS': 'Tansanian šillinki',
        'UAH': 'grivna',
        'UGX': 'Ugandan šillinki',
        'USD': 'Yhdysvaltain dollari',
        'UYU': 'Uruguayn peso',
        'UZS': 'sum',
        'VEF': 'bolivar',
        'VND': 'dong',
        'VUV': 'vatu',
        'WST': 'tala',
        'XAF': 'CFA-frangi (BEAC)',
        'XCD': 'Itä-Karibian dollari',
        'XOF': 'CFA-frangi (BCEAO)',
        'XPF': 'CFP-frangi',
        'YER': 'Jemenin rial',
        'ZAR': 'randi',
        'ZMW': 'Sambian kwacha',
        'ZWL': 'Zimbabwen dollari'
      },
      'kieli': {
        'fi': 'Suomi',
        'sv': 'Ruotsi',
        'en': 'Englanti'
      },
      'laskuntyyppi': {
        'tav': 'Kotimaan myynti',
        'tav_ulkom': 'Kotimaan myynti ulkomaiselle yritykselle',
        'taveu': 'Yhteisömyynti toiseen EU-maahan (tavara)',
        'palveu': 'Yhteisömyynti toiseen EU-maahan (palvelu)',
        'eieu': 'Myynti EU:n ulkopuolelle',
        'kuleu': 'Kuluttajamyynti toiseen EU-maahan',
        'kuleut': 'Kuluttajamyynti toiseen EU-maahan (tavara)',
        'kuleup': 'Kuluttajamyynti toiseen EU-maahan (palvelu)',
        'kuleur': 'Kuluttajamyynti toiseen EU-maahan',
        'rak': 'Rakentamispalveluiden myynti'
      },
      'alv': {
        'yht': '0 %',
        'suomi0': '0 %',
        'suomi10': '10 %',
        'suomi14': '14 %',
        'suomi24': '24 %',
        'suomi255': '25,5 %',
        'eieu': '0 %',
        'rak': '0 %',
        'huomautuskulukorkoalv': '0 %'
      },
      'reset': {
        'vaihda': 'Palauta salasana',
        'password': 'Uusi salasana',
        'onnistui': 'Salasana vaihdettiin onnistuneesti. Voit nyt kirjautua iOS tai Android sovellukseen käyttämällä uutta salasanaa. Painamalla "Jatka" kirjaudut web-Lemonaidiin.',
        'validation': {
          'min': 'Minipituus on 10 merkkiä',
          'differentchars': 'Vähintään 5 eri merkkiä',
          'number': 'Vähintään yksi numero',
          'lettersmall': 'Vähintään yksi pieni kirjain',
          'letterbig': 'Vähintään yksi iso kirjain',
        },
        'virheet': {
          'koodi-vanhentunut': 'Salasanan vaihto on vanhentunut. Aloita vaihtaminen uudelleen valitsemalla "Peruuta" ja sitten "Unohdin salasanan".',
          'koodi-ei-oikea': 'Salasanan vaihtokoodi ei ole oikea. Aloita vaihtaminen uudelleen valitsemalla "Peruuta" ja sitten "Unohdin salasanan".',
          'kayttaja-inaktiivinen': 'Käyttäjä ei ole aktiivinen. Salasanaa ei voi vaihtaa.',
          'kayttaja-ei-loydy': 'Käyttäjää ei löydy. Salasanaa ei voi vaihtaa.',
          'salasana-heikko': 'Salasana on liian heikko. Syötä monimutkaisempi salasana.'
        }
      },
      'change': {
        'otsikko': 'Vaihda salasana',
        'vaihda': 'Vaihda',
        'oldpassword': 'Nykyinen salasana',
        'newpassword': 'Uusi salasana',
        'onnistui': 'Salasana vaihdettiin onnistuneesti. Muista käyttää uutta salasanaa seuraavalla kerralla kun kirjaudut iOS-, Android- tai web-sovellukseen.',
        'validation': {
          'oldpassword': {
            'required': 'Vanha salasana puuttuu',
            'wrong-password': 'Vanha salasana on väärin.'
          },
          'newpassword': {
            'required': 'Uusi salasana puuttuu'
          }
        }
      },
      'forgot': {
        'palauta': 'Palauta salasana',
        'onnistui': 'Salasana voidaan nyt palauttaa. Seuraa sähköpostiisi lähetettyjä ohjeita.'
      },
      'login': {
        'login': 'KIRJAUDU',
        'email': 'Sähköpostiosoite',
        'password': 'Salasana',
        'puhelin': 'Puhelinnumero',
        'sms-code': 'Koodi',
        'forgot': 'Unohdin salasanan',
        'sms-text-web': 'Lemonaid-vahvistuskoodisi on {{code}}.',
        'sms-text-ios': 'Lemonaid-vahvistuskoodisi on {{code}}.',
        'sms-text-android': 'Lemonaid-vahvistuskoodisi on {{code}}.\n\n{{hash}}',
        'send-sms-again': 'Lähetä uusi koodi napauttamalla tätä.',
        'insert-code-text': 'Syötä puhelinnumeroosi lähetetty varmistuskoodi',
        'two-factor-auth': 'Kaksivaiheinen vahvistus',
        'send-success': 'Lähettäminen onnistui',
        'login-out-of-date': 'Kirjautuminen on vanhentunut. Ole hyvä ja peruuta aloittaaksesi alusta.',
        'beta-off-limits': 'Yrität kirjautua väärälle Lemonaidin sivulle. Ole hyvä ja kirjaudu Lemonaidin tilillesi sivulta <a href="https://lemonaid.lemontree.fi">lemonaid.lemontree.fi</a>',
        'validation': {
          'email': {
            'required': 'Sähköpostiosoite puuttuu.',
            'user-not-found': 'Annetulla sähköpostiosoitteella ei löytynyt käyttäjää.',
            'invalid-email': 'Sähköpostiosoite on virheellinen.',
            'email': 'Sähköpostiosoite on virheellinen.',
            'user-disabled': 'Käyttäjätili ei ole aktiivinen.',
          },
          'password': {
            'required': 'Salasana puuttuu.',
            'wrong-password': 'Salasana on virheellinen.',
            'auth-failed': 'Sähköpostiosoite tai salasana ei kelpaa'
          },
          'code': {
            'required': 'Koodia ei ole syötetty.',
            'wrong-code': 'Koodi on virheellinen.',
            'contains-non-nums': 'Ole hyvä ja käytä vain numeroita'
          }
        }
      },
      'lisaa-puhelin': {
        'add-number-text': 'Syötä puhelinnumero varmistuskoodin lähettämistä varten',
        'two-factor-explanation': 'Kaksivaiheisen vahvistuksen käyttöönotto parantaa tietoturvaa. Kun jatkossa kirjaudut sisään, lähetämme varmistuskoodin antamaasi puhelinumeroon.',
        'required': 'Lisää puhelinnumero',
        'min': 'Numero on liian lyhyt',
        'success': 'Puhelinnumeron lisääminen onnistui.',
        'wrong-creds': 'Käyttäjätunnusvirhe. Aloita kirjautuminen uudelleen.',
        'number-exists': 'Puhelinnumero on jo tallennettu. Aloita kirjautuminen uudelleen.',
        'notinternational': 'Numeron on oltava kansainvälisessä muodossa ja alettava plus-merkillä, esim. +358',
        'leadingzero': 'Suomalaisen puhelinnumeron kansainvälisessä muodossa ei käytetä etunollia. Poista nolla +358:n jälkeen.',
        'invalid': 'Numero on virheellinen'
      },
      'ei-oikeuksia': {
        'otsikko': 'Ei oikeuksia',
        'teksti': 'Sinulla ei ole tarvittavia oikeuksia sivun katsomiseen. Jos tämä on mielestäsi virhe, lähetä asiasta sähköpostia kirjanpitäjällesi, niin korjaamme asian.'
      },
      'ei-asetuksia': {
        'otsikko': 'Ei asetuksia',
        'teksti': 'Lemonaid ei löydä asetuksia, joita tarvitaan Lemonaidin käyttöön. Ole hyvä ja lataa sivu uudelleen ja yritä uudelleen. Yleensä ongelma johtuu huonosta internet-yhteydestä, mutta jos ongelma jatkuu, ole hyvä ja ota yhteys kirjanpitäjääsi.'
      },
      'ei-rooleja': {
        'otsikko': 'Ei rooleja',
        'teksti': 'Sinulla ei näytä olevan yhtään roolia Lemonaidissa. Ole hyvä ja ota yhteys kirjanpitäjääsi saadaksesi tarvitsemasi roolit.'
      },
      'laskut': {
        'etsi': 'Etsi laskuja',
        'nayta': 'Näytä',
        'lisaa': 'Luo uusi lasku',
        'ei-pankkiyhteysvaltuutusta': 'Ei pankkiyhteysvaltuutusta - automaattinen reskontra ei ole käytössä.',
        'lisaa-valtuutus': 'Lisää valtuutus',
        'poista-valinta': 'Poista valinta',
        'rajaa-kuukauden-mukaan': 'Rajaa kuukausia',
        'kummasta-haetaan-erapaiva': 'Eräpäivä',
        'kummasta-haetaan-paiva': 'Laskun päivä',
        'kummasta-haetaan': 'Kummasta haetaan',
        'numero': 'Numero',
        'asiakas': 'Asiakas',
        'erapaiva': 'Eräpäivä',
        'laskun-pvm': 'Laskun pvm',
        'summa': 'Summa',
        'avoinna': 'Avoinna',
        'tila': 'Tila',
        'muistutus': 'Muistutus',
        'perinta': 'Lähetetty perintään',
        'perinta-poisto': 'Perintä lopetettu',
        'hyvitys': 'Hyvitys',
        'lahettaminen-epaonnistui': 'Lähettäminen epäonnistui',
        'hakuehdoilla-ei-yhtaan-tulosta': 'Hakuehdoilla ei löydy yhtään laskua',
        'merkitse-maksetuksi': 'Maksettu',
        'kommentti': 'Kommentti',
        'muokkaa-kommenttia': 'Muokkaa kommenttia',
        'summat': {
          'kaikki': 'Näytä kaikki valuutat',
          'huomautus': 'Eikö summa päivittynyt?',
          'avoinna': 'Avoinna',
          'maksettu': 'Maksettu',
          'eraantynyt': 'Erääntynyt',
          'luottotappiot': 'Luottotappiot',
        }
      },
      'lasku': {
        'spostipohjat': {
          'oletukset': {
            'sposti': {
              'aihe': 'Lasku, {{yrityksen_nimi}}',
              'otsikko': 'Hei, ohessa lasku palveluistamme',
              'teksti': 'Olettehan yhteydessä mikäli laskun tiimoilta herää kysymyksiä. \n\nYstävällisin terveisin, {{yrityksen_nimi}}'
            },
            'muistutussposti': {
              'aihe': 'Muistutuslasku, {{yrityksen_nimi}}',
              'otsikko': 'Hei, lähettämämme lasku on erääntynyt',
              'teksti': 'Kirjanpitomme mukaan lasku nro. {{laskunumero}} on vielä maksamatta (eräpäivä {{erapaiva}}). Pyydämme teitä hoitamaan asian maksamalla oheisen muistutuslaskun viivytyksettä. Mikäli alkuperäisen laskun maksu on jo matkalla, on tämä muistutuslasku aiheeton.'
            },
            'perintasposti': {
              'aihe': 'LemonAid toimeksianto / lasku {{laskunumero}}',
              'otsikko': 'LemonAid toimeksianto / lasku {{laskunumero}}',
              'teksti': 'Tämä on Lemonaid ohjelmiston kautta tehty perintätoimeksianto'
            }
          }
        },
        'muokkaa-tulostettua-dialog': {
          'otsikko': 'Onko lasku jo lähetetty asiakkaalle?',
          'teksti': 'Jos laskua ei olla vielä lähetetty, alkuperäistä laskua voidaan vielä muokata. Jos lasku on jo lähetetty, luodaan uusi lasku, joka korvaa alkuperäisen.',
          'on-toimitettu': 'Kyllä (luodaan korvauslasku)',
          'ei-ole-toimitettu': 'Ei (muokataan alkuperäistä)'
        },
        'laheta-comp': {
          'email': {
            'otsikko': 'Lähetä sähköpostilla',
            'info-tavallinen': 'Lasku lähetetään PDF-liitteenä annettuihin sähköpostiosoitteisiin.',
            'info-muistutus': 'Muistutuslasku lähetetään PDF-liitteenä annettuihin sähköpostiosoitteisiin.'
          },
          'sahkoinen': {
            'otsikko': 'Lähetä verkkolasku',
            'info-tavallinen': 'Lasku lähetetään annettuun verkkolaskuosoitteeseen.',
            'info-muistutus': 'Muistutuslasku lähetetään annettuun verkkolaskuosoitteeseen.',
            'validation': {
              'valittaja': {
                'required': 'Välittäjä puuttuu'
              },
              'osoite': {
                'required': 'Osoite puuttuu',
                'valittaja': 'Osoitteeseen ei voi syöttää välittäjän tunnusta.',
                'minlength': 'Osoitteessa pitää olla enemmän kuin 8 merkkiä'
              }
            }
          },
          'itse': {
            'otsikko': 'Lähetä itse',
            'info-tavallinen': 'Lataa lasku PDF-tiedostona ja lähetä se haluamallasi tavalla. Lataamisen jälkeen lasku merkitään järjestelmässä lähetetyksi.',
            'info-muistutus': 'Lataa muistutuslasku PDF-tiedostona ja lähetä se haluamallasi tavalla. Lataamisen jälkeen muistutuslasku merkitään järjestelmässä lähetetyksi.'
          }
        },
        'yhteisomyynnit-esta-hyvitys-popup': 'Erillistä hyvityslaskua ei ole mahdollista lähettää yhteisömyynneistä arvonlisäveron yhteenvetoilmoituksen vuoksi. Voit lähettää hyvityslaskun navigoimalla alkuperäiseen hyvitettävään laskuun ja valitsemalla ruudun ylälaidasta hyvitä.',
        'mitatointirivin-nimi': 'Mitätöity {{alv-kanta}}',
        'tallennettu-onnistuneesti': 'Lasku tallennettiin onnistuneesti.',
        'tiedostonimen-alku-ladattaessa': 'Lasku',
        'tiedostonimen-alku-ladattaessa-numerolla': 'Lasku {{saaja}}',
        'alv-info-teksti-head': 'Huomio!',
        'alv-info-teksti1': '1.7.2021 astui voimaan uusi etämyyntidirektiivi, jonka myötä toiseen EU-maahan tapahtuvaan tuotteiden myyntiin kuluttajille sovelletaan ostajan valtion ALV-kantoja.',
        'alv-info-teksti2': 'Mikäli etämyyntiä on alle 10 000 euroa kalenterivuodessa (eikä raja ole ylittynyt edellisenä vuonna), käsitellään myynti Suomen alv-kohtelulla ja voit lähettää laskun tästä.',
        'alv-info-teksti3': 'Jos etämyynti ylittää 10 000 euroa kalenterivuodessa, tulee sinun rekisteröityä arvonlisäveron erityisjärjestelmään. Kirjanpitäjäsi kertoo mielellään lisää asiasta. ',
        'suomifi-valtuutus-puuttuu-head': 'Suomi.fi -valtuutus puuttuu.',
        'suomifi-valtuutus-puuttuu-teksti1': 'Et voi lähettää yhteisömyyntilaskuja ilman Suomi.fi-valtuutusta. Ole hyvä ja anna valtuutus tämän ohjeen mukaan:',
        'suomifi-valtuutus-puuttuu-teksti2': 'Huomioi, että valtuutuksen rekisteröinti järjestelmäämme kestää yhden vuorokauden.',
        'muokkaus-info-teksti': 'Voit muokata laskua ja lähettää sen uudestaan asiakkaalle.',
        'hyvitys-info-teksti': 'Laskun hyvittäminen luo uuden laskun, joka hyvittää aiemmin tehtyä laskua.',
        'title': 'Luo lasku',
        'tyyppi': 'Laskun tyyppi',
        'kieli': 'Laskun kieli',
        'nimi': 'Asiakkaan nimi',
        'ytunnus': 'Y-tunnus',
        'vat-tunnus': 'ALV-numero',
        'katuosoite': 'Katuosoite',
        'postinro': 'Postinumero',
        'postitoimipaikka': 'Postitoimipaikka',
        'maa': 'Maa',
        'laskunro': 'Laskun numero',
        'laskunpvm': 'Laskun pvm.',
        'erapaiva': 'Eräpäivä',
        'toimituspvm': 'Toimituspvm.',
        'valuutta': 'Valuutta',
        'maksuaikaa': 'Maksuaikaa',
        'viivastyskorko': 'Viivästyskorko',
        'viitteenne': 'Viitteenne',
        'lisatiedot': 'Lisätiedot laskulle',
        'tuote-kuvaus': 'Tuote / kuvaus',
        'a-hinta-veroton': 'A-hinta',
        'maara': 'Määrä',
        'alv-kanta': 'Alv-kanta',
        'ale-pros': 'Ale %',
        'tuote-yhteensa-veroton': 'Yhteensä',
        'kpl': 'Kpl',
        'lisaa-rivi': 'Lisää rivi',
        'yhteensa-veroton': 'Veroton summa',
        'alv': 'Veron osuus',
        'yhteensa-verollinen': 'Yhteensä',
        'spostilahetys': 'Jatka',
        'tulosta-itse': 'Tulosta',
        'muokkaa': 'Muokkaa',
        'huomautuskulu-tuote': 'Huomautuskulu {{numero}}',
        'viivastyskorko-tuote': 'Viivästyskorko',
        'aiemmin-maksettu-tuote': 'Aiemmin maksettu',
        'lisaa-liite': 'Lisää liitetiedosto',
        'raahaa-tanne': 'Lisää liitetiedosto vetämällä se tähän',
        'tuetut-tiedostot': 'Tuetut tiedostomuodot: pdf, jpeg, png.',
        'tallentaminen-epaonnistui': 'Tallentaminen epäonnistui. Ole hyvä ja palaa edelliselle näytölle ja yritä uudelleen. Jos virhe ei poistu, ole hyvä ja ota yhteys kirjanpitäjääsi.',
        'ladataan': 'Ladataan',
        'tallennettu-asiakas': 'Haettu asiakasrekisteristä',
        'muokkaa-asiakasta': 'Muutokset tallennetaan asiakkaan tietoihin',
        'tallenna-uutena-asiakkaana': 'Tallennetaan uusi asiakas rekisteriin',
        'tiedoston-lahettaminen-epaonnistui-alku': 'Tiedostojen lähettämisen estivät seuraavat virheet:',
        'tiedoston-lahettaminen-epaonnistui-loppu': 'Ole hyvä ja poista tiedosto ja yritä uudelleen. Jos virhe ei poistu, ole hyvä ja ota yhteys kirjanpitäjääsi.',
        'vaara-muoto': 'Tiedosto ei ole oikeassa muodossa. Tuetut muodot ovat {{tuetutMuodot}}.',
        'liian-suuri': 'Tiedosto on liian suuri. Voit lisätä maksimissaan {{kokoMax}} megatavun tiedoston. (Tiedoston koko on nyt {{kokoNyt}} megatavua)',
        'liian-monta': 'Tiedostoja voi olla maksimissaan {{lukumaaraMax}}.',
        'alv-numeron-tarkistaminen': {
          'tarkistetaan': 'Tarkistetaan...',
          'oikein': 'Oikein, rekisteröidyt tiedot: {{nimi}}, {{osoite}}',
          'epaonnistui-eu': 'Virhe: tarkistaminen ei onnistunut EU-palvelimen takia.',
          'virheellinen-maakoodi': 'Virhe: VAT-numero alkaa kahdella kirjaimella, esim. FI.',
          'virheellinen-numero': 'Virheellinen numero. Tarkista numero.',
          'tuntematon-virhe': 'Tuntematon virhe: {{error}}'
        },
        'peppol-validaatio': {
          'tarkistetaan': 'Tarkistetaan...',
          'osoite-eivalidi': 'Osoite ei vaikuta olevan validi. Tarkista osoite.',
          'osoite-kuuluu': 'Annettu osoite kuuluu yritykselle {{nimi}}, {{maa}}',
          'loytyi-yritykset': 'Annetulla osoitteella löytyi seuraavat yritykset: {{yritykset}}'
        },
        'validation': {
          'hyvitys': {
            'ei-voi-olla-positiivinen': 'Hyvityslaskun loppusumma ei voi olla positiivinen.'
          },
          'tuotteet': {
            'min': 'Ole hyvä lisää vähintään yksi tuote.'
          },
          'asiakastyyppi': {
            'required': 'Valinta puuttuu.'
          },
          'nimi': {
            'required': 'Nimi puuttuu.'
          },
          'katuosoite': {
            'required': 'Katuosoite puuttuu.',
            'maxlength': 'Katuosoite on liian pitkä.'
          },
          'maa': {
            'required': 'Maa puuttuu.'
          },
          'postinro': {
            'required': 'Postinumero puuttuu.'
          },
          'postitoimipaikka': {
            'required': 'Postitoimipaikka puuttuu.'
          },
          'rivin-alku': 'Rivillä {{rivi_nro}} on virhe:',
          'ahinta': {
            'required': 'Kappalehinta puuttuu.',
            'max': 'Kappalehinta on liian suuri.',
            'min': 'Kappalehinta on liian pieni.'
          },
          'yhteensa': {
            'required': 'Yhteensä puuttuu.',
            'max': 'Yhteensä on liian suuri.',
            'min': 'Yhteensä on liian pieni.'
          },
          'maara': {
            'required': 'Määrä puuttuu.',
            'max': 'Määrä on liian suuri.',
            'min': 'Määrä on liian pieni.'
          },
          'tuote': {
            'required': 'Tuote puuttuu.'
          },
          'ytunnus': {
            'required': 'Numero puuttuu',
            'invalid-ytunnus': 'Y-tunnus on virheellinen.',
            'invalid-vat-number': 'ALV-numero on virheellinen.',
            'invalid-vat-number-or-ytunnus': 'ALV-numero tai y-tunnus on virheellinen'
          },
          'erapaiva': {
            'required': 'Eräpäivä puuttuu.',
            'matDatepickerMin': 'Eräpäivä voi olla aikaisintaan huomenna.',
            'matDatepickerParse': 'Virheellinen arvo (käytä pp.mm.vvvv)'
          },
          'toimituspvm': {
            'required': 'Toimituspäivä puuttuu.',
            'matDatepickerParse': 'Virheellinen arvo (käytä pp.mm.vvvv)'
          },
          'maksuaikaa': {
            'required': 'Maksuaika puuttuu.',
            'min': 'Maksuaikaa pitää olla ainakin yksi päivä.'
          },
          'viivastyskorko': {
            'required': 'Viivästyskorko puuttuu.',
            'min': 'Viivästyskoron on oltava suurempi tai yhtäsuuri kuin 0.'
          },
          'asetukset': {
            'oletuksia-print': 'Et voi tulostaa laskua ennen kuin käyt täydentämässä asetuksissa olevat tiedot.',
            'oletuksia-sposti': 'Et voi lähettää laskua ennen kuin käyt täydentämässä asetuksissa olevat tiedot.'
          },
          'ale': {
            'min': 'Alennusprosentin on oltava suurempi kuin 0.',
            'max': 'Alennusprosentin on oltava yhtä suuri tai pienempi kuin 100.'
          },
          'alv': {
            'required': 'ALV-prosentti on valittava.'
          }
        },
        'tapahtumaloki': {
          'otsikko': 'Historia',
          'tyyppi1': 'Lasku {{lasku_nro}} luotiin',
          'tyyppi2': 'Laskua muokattiin',
          'tyyppi3': 'Kirjattu maksetuksi: {{summa}}',
          'tyyppi4': 'Lasku {{lasku_nro}} lähetettiin sähköpostilla osoitteisiin {{osoitteet}}',
          'tyyppi5': 'Lasku {{lasku_nro}} tulostettiin',
          'tyyppi6': 'Lasku {{lasku_nro}} lähetettiin sähköisesti osoitteeseen {{sahkoinenValittaja}} {{sahkoinenOsoite}}',
          'tyyppi7': 'Laskusta {{lasku_nro}} lähetettiin maksumuistutus sähköpostilla osoitteisiin {{osoitteet}}',
          'tyyppi8': 'Laskusta {{lasku_nro}} lähetettiin maksumuistutus tulosteena',
          'tyyppi9': 'Laskusta {{lasku_nro}} lähetettiin maksumuistutus sähköisesti',
          'tyyppi10': 'Lasku {{lasku_nro}} merkittiin luottotappioksi',
          'tyyppi11': 'Laskun {{lasku_nro}} luottotappiomerkintä poistettiin',
          'tyyppi12': 'Laskuluonnos luotiin',
          'tyyppi13': 'Laskuluonnosta muokattiin',
          'tyyppi14': 'Laskuluonnos poistettiin',
          'tyyppi15': 'Lasku mitätöitiin',
          'tyyppi16': 'Laskun kommenttia muokattiin',
          'tyyppi17': 'Lasku {{lasku_nro}} lähetettiin sähköisesti osoitteeseen {{sahkoinenValittaja}} {{sahkoinenOsoite}} vastaanottajan hyväsyttäväksi heidän Lemonaidiin',
          'tyyppi18': 'Lasku {{lasku_nro}} lähetettiin sähköisesti osoitteeseen {{sahkoinenValittaja}} {{sahkoinenOsoite}} vastaanottajan hyväksyttyä sen Lemonaidissa',
          'tyyppi19': 'Lasku {{lasku_nro}} lähetettiin perintään Perintäritarille',
          'tyyppi20': 'Lasku {{lasku_nro}} poistettiin perinnästä',
          'tyyppi21': 'Hyvityslasku {{lasku_nro}} lähetetettiin avoinna olevaan perintään liittyen Perintäritarille'
        },
        'liitetiedostot': {
          'otsikko': 'Liitetiedostot'
        },
        'mitatointi-dialog': {
          'voidaan': {
            'otsikko': 'Haluatko varmasti mitätöidä laskun?',
            'teksti': 'Laskun mitätöintiä ei voi peruuttaa.',
            'mitatoi': 'Mitätöi'
          },
          'ei-voida': {
            'otsikko': 'Laskua ei voi mitätöidä',
            'teksti': 'Lasku ei voi mitätöidä, koska siihen on jo kohdistunut maksuja.'
          }
        },
        'asiakas-poisto-varmitus-dialog': {
          'otsikko': 'Poistetaanko asiakas?',
          'teksti': 'Haluatko varmasti poistaa asiakkaan? Poistaminen on lopullista, eikä asiakasta saa enää poistamisen jälkeen takaisin.'
        },
        'tuote-poisto-varmitus-dialog': {
          'otsikko': 'Poistetaanko tuote?',
          'teksti': 'Haluatko varmasti poistaa tuotteen? Poistaminen on lopullista, eikä tuotetta saa enää poistamisen jälkeen takaisin.'
        },
        'katsele-muokattava-dialog': {
          'vie-perintaan': 'Vie perintään (Perintäritari)',
          'peruuta-perinta': 'Peruuta perintätoimeksianto',
          'perinta-epaaktiivinen': 'Laskun tulee olla erääntynyt ja siitä tulee olla lähetetty muistutuslasku ennen perintätoimien aloittamista',
          'merkitse-luottotappioksi': 'Merkitse luottotappioksi',
          'peruuta-merkitse-luottotappioksi': 'Peruuta luottotappiomerkintä',
          'merkitse-osittain-maksetuksi': 'Merkitse maksetuksi',
          'muokkaa': 'Muokkaa',
          'kopioi-uusi-lasku': 'Kopioi',
          'mitatoi': 'Mitätöi',
          'hyvita': 'Hyvitä',
          'laheta-maksumuistutus': 'Lähetä maksumuistutus',
          'lataa-lasku': 'Lataa pdf',
          'laheta-uudelleen': 'Lähetä uudelleen',
          'spostin-lahetysvirhe': 'Virhe sähköpostin lähettämisessä:',
          'virheellinen-sahkoposti': 'Sähköpostiosoite on virheellinen',
          'tekninen-virhe': 'Tekninen virhe',
          'koko-virheen-naytto': 'Näytä koko virhe',
          'uudelleen-lahetys': 'Lähetä uudelleen',
          'lasku': 'Lasku',
          'maksuaikaa': 'Maksuaikaa',
          'spostien-lahettaminen-epaonnistui': 'Sähköpostien lähettäminen epäonnistui',
          'korvatun-otsikko': 'Korvattu laskulla {{korvaavanNumero}}',
          'poista-luonnos': 'Poista luonnos',
          'sahkoinen': {
            'lahettaminen-epaonnistui': 'Sähköisen laskun lähettäminen epäonnistui',
            'osoite': 'Verkkolaskuosoite',
            'valittaja': 'Välittäjä',
            'virheellinen-osoite': 'Tarkista verkkolaskuosoite',
            'virheellinen-valittaja': 'Tarkista välittäjän osoite'
          },
          'reskontrahistoria': 'Reskontrahistoria',
          'reskontraan-merkittiin-kasin': 'Käyttäjän merkintä',
          'automaattimerkinta-tilitapahtumasta': 'Automaattimerkintä tilitapahtumasta'
        },
        'peruuta-maksumerkinta-dialog': {
          'otsikko': 'Perutaanko maksumerkintä?',
          'teksti': 'Laskun avoinna saldo palautetaan merkintää edeltävään tilaan.'
        },
        'koko-virhe-dialog': {
          'otsikko': 'Sähköpostin lähettämiseen liittyvä koko virhe'
        },
        'email-preview': {
          'spostin-esikatselun-lataus': 'Ladataan sähköpostin esikatselua'
        },
        'lataa-dialog': {
          'odota-pdf-luodaan': 'Ole hyvä ja odota, PDF-laskua luodaan..',
          'tiedosto-latautuu-valmistuttuaan': 'Tiedosto latautuu heti kun se on valmis. Jos tiedoston luonti kestää kauan, voit sulkea tämän dialogin ja ladata tiedoston myöhemmin.',
        },
        'maksumuistutus-spostilla-dialog': {
          'laheta-spostilla': 'Lähetä maksumuistutus sähköpostilla',
          'vastaanottaja': 'Vastaanottaja',
          'laheta': 'Lähetä'
        },
        'maksumuistutus': {
          'laheta-maksumuistutus': 'Lähetä maksumuistutus',
          'lisaa-korko': 'Lisää korko',
          'laheta-spostilla': 'Lähetä',
          'tulosta-itse': 'Lähetä tulostamalla itse (postita)'
        },
        'merkitse-osittain-maksetuksi-dialog': {
          'merkitse-maksetuksi': 'Merkitse maksetuksi',
          'lasku-nro': 'Lasku nro',
          'avoinna': 'Avoinna',
          'avoimeksi-jaa': 'Avoimeksi jää',
          'merkitse': ' Merkitse',
          'maksettu-maara': 'Maksettu määrä'
        },
        'perinta-sposti-dialog': {
          'vie-perintaan': 'Vie perintään',
          'laheta-teksti': 'Lemonaidissa voit tehdä suoran perintätoimeksiannon Perintäritari Oy:lle.<br><br> Tekemällä perintätoimeksiannon Perintäritarille Lemonaidin kautta vahvistat hyväksyväsi <a href="https://perintaritari.fi/sopimusehdot/" target="_blank">palvelun ehdot</a> sekä <a href="https://perintaritari.fi/hinnasto/" target="_blank">hinnaston</a>, joten käythän tutustumassa näihin ennen toimeksiannon tekemistä. Perintäritari tilittää onnistuneen perinnän päätteeksi pääoman sinulle ja laskuttaa palkkionsa sinulta erillisellä laskulla. Peritty viivästyskorko huomioidaan palkkiossa.<br><br> Lisätietoja voit kysyä <a href="mailto:info@perintaritari.fi">info@perintaritari.fi</a> tai numerosta 09 42890650.<br> Huomioithan, että Perintäritari toteuttaa vain yritysten välistä perintää.',
          'peruuta-toimeksianto-teksti': 'Haluatko varmasti perua perintätoimet? Huomaathan, että Perintäritari veloittaa toimeksiantajapalkkion, mikäli perintätoimet on jo aloitettu.',
          'laheta': 'Lähetä toimeksianto Perintäritarille'
        },
        'sposti-dialog': {
          'lisaavastaanottaja': 'Vastaanottajat',
          'aihe': 'Aihe',
          'otsikko': 'Otsikko',
          'teksti': 'Teksti',
          'huomautuskulu': 'Lisää huomautuskulua',
          'laheta-lasku-spostilla': 'Sähköposti',
          'laheta': 'Lähetä',
          'esikatselu': 'Sähköpostin esikatselu',
          'verkkolasku': {
            'laheta-verkkolaskuna': 'Verkkolasku',
            'osoite': 'Verkkolaskuosoite',
            'valittajatunnus': 'Välittäjä'
          },
          'itse': {
            'laheta': 'Lähetä itse',
            'intro1': 'Lataa lasku PDF-muodossa ja lähetä se haluamallasi tavalla.',
            'intro2': 'Laskun lataamisen jälkeen lasku käsitellään järjestelmässä lähetettynä.',
            'lataa': 'Lataa'
          },
          'paperi': {
            'laheta': 'Paperilasku'
          }
        },
        'listaus': {
          'tila': {
            'm': 'Maksetut',
            'a': 'Avoimet',
            'l': 'Luottotappiolliset',
            'ml': 'Ylisuoritetut',
            'k': 'Kaikki',
            'e': 'Erääntyneet',
            'lu': 'Luonnos',
            'p': 'Poistettu',
            'mi': 'Mitätöity',
            'h': 'Hyvitetty',
            'pe': 'Perinnässä'
          }
        },
        'tila': {
          'undefined': '',
          'm': 'Maksettu',
          'a': 'Avoin',
          'e': 'Erääntynyt',
          'l': 'Luottotappio',
          'ml': 'Ylisuoritus',
          'kl': 'Korvattu', // {{korvaavanLaskunNumero}}
          'kml': 'Muistutettu', // {{korvaavanLaskunNumero}}
          'lu': 'Luonnos',
          'p': 'Poistettu',
          'mi': 'Mitätöity',
          'h': 'Hyvitetty',
          'pe': 'Perinnässä'
        },
        'sposti': {
          'otsikko': 'Otsikko',
          'teksti': 'Teksti',
          'lisaavastaanottaja': 'Lisää vastaanottaja...',
          'validation': {
            'aihe': {
              'required': 'Sähköpostin aihe puuttuu.'
            },
            'otsikko': {
              'required': 'Sähköpostin otsikko puuttuu.'
            },
            'teksti': {
              'required': 'Sähköpostin teksti puuttuu.'
            },
            'lisaavastaanottaja': {
              'email': 'Sähköpostiosoite on virheellinen.',
              'min': 'Lisää vähintään yksi vastaanottaja.'
            },
            'huomautuskulu': {
              'required': 'Määrä puuttuu'
            }
          }
        },
        'esikatselu': {
          'viitteenne': 'Viitteenne',
          'lisatiedot': 'Lisätiedot',
          'otsikko': 'Esikatselu'
        },
        'muokkaus-estetty': {
          'ei-mahdollista': 'Laskun muokkaaminen ei ole mahdollista',
          'katseltavaa-laskua-ei-voi-muokata': 'Katseltavaa laskua ei voi muokata. Jos haluat lähettää uuden version asiakkaalle, luo ensin hyvityslasku ja lähetä sitten uusi lasku. Voit kysyä lisätietoja kirjanpitäjältäsi.'
        }
      },
      'laskuasetukset': {
        'laskupohja-otsikko': 'Laskujen sähköpostipohja',
        'maksumuistutuspohja-otsikko': 'Maksumuistutusten sähköpostipohja',
        'yleiset': 'Yleiset asetukset',
        'nimi': 'Yritys / Nimi',
        'ytunnus': 'VAT-numero',
        'katuosoite': 'Katuosoite',
        'postinro': 'Postinumero',
        'postitoimipaikka': 'Postitoimipaikka',
        'maa': 'Maa',
        'replyto': 'Sähköpostin vastausosoite (reply to)',
        'replyto-hint': 'Jos asiakas vastaa laskusähköpostiin, lähetetään vastaus tähän sähköpostiosoitteeseen.',
        'aihe': 'Aihe',
        'otsikko': 'Otsikko',
        'teksti': 'Teksti',
        'slogan': 'Slogan',
        'slogan-hint': 'Sähköpostin alaosaan tuleva vapaamuotoinen teksti.',
        'template': 'TEKNINEN KENTTÄ, ÄLÄ KOSKE!',
        'ohjeet': 'Laskulle tuleva vapaamuotoinen teksti',
        'pankkiyhteyden-tiedot': 'Pankkiyhteystiedot:',
        'asiakaspalvelun-yhteystiedot': 'Asiakaspalvelun yhteystiedot:',
        'pankki': 'Pankki',
        'bic': 'BIC',
        'iban': 'IBAN',
        'automaattinen-reskontra': 'Automaattinen reskontra',
        'automaattinen-reskontra-kaytossa': 'Automaattinen reskontra käytössä',
        'reskontraa-ei-voida-kayttaa': 'Automaattista reskontraa ei voida käyttää, koska yhtään voimassa olevaa pankkiyhteyttä ei löytynyt.',
        'reskontra-kun-kaytossa': 'Kun automaattinen reskontra on käytössä, Lemonaid merkitsee myyntilaskut maksetuksi saapuvien tilitapahtumien perusteella. Lemonaid käsittelee kaikki saapuvat maksut, joissa on viitenumero. Jos asiakkaasi maksaa laskun ilman viitenumeroa, pitää reskontramerkintä tehdä käsin.',
        'iban-bic-autofill': 'Täytetään automaattisesti suomalaisille pankeille IBAN-kentän mukaan',
        'piilota-yrityksen-nimi': 'Piilota yrityksen nimi laskulla',
        'piilota-yrityksen-nimi-hint': 'Tarkista ulkoasu laskun esikatselusta. Lasku näyttää usein paremmalta ilman yrityksen nimeä, jos se on jo osana logoa.',
        'aspaemail': 'Asiakaspalvelun sähköpostiosoite',
        'aspapuh': 'Asiakaspalvelun puhelinnumero',
        'tallennettu-onnistuneesti': 'Asetukset tallennettiin onnistuneesti.',
        'spostipohjat-eri-kielilla': 'Sähköpostipohjien tekstit eri kielillä',
        'validation': {
          'nimi': {
            'required': 'Yritys / Nimi puuttuu.'
          },
          'ytunnus': {
            'required': 'VAT-numero puuttuu.'
          },
          'katuosoite': {
            'required': 'Katuosoite puuttuu.'
          },
          'postinro': {
            'required': 'Postinumero puuttuu.'
          },
          'postitoimipaikka': {
            'required': 'Postitoimipaikka puuttuu.'
          },
          'maa': {
            'required': 'Maa puuttuu.'
          },
          'replyto': {
            'required': 'Sähköpostin vastausosoite puuttuu.',
            'email': 'Sähköpostiosoite on virheellinen.'
          },
          'template': {
            'required': 'Template puuttuu.'
          },
          'pankki': {
            'required': 'Laskulla näkyvä pankki puuttuu. (Esim. Osuuspankki)'
          },
          'iban': {
            'required': 'Laskulla näkyvä tilinumero puuttuu. (Esim. FI 90 800026 2776 1348)',
            'iban': 'Tilinumero on virheellinen'
          },
          'bic': {
            'required': 'Laskulla näkyvä BIC-koodi puuttuu. (Esim. NDEAFIHH)',
            'bic': 'BIC on virheellinen.'
          },
          'aspaemail': {
            'required': 'Asiakaspalvelun sähköpostiosoite puuttuu. (Esim. asiakaspalvelu@yritykseni.fi)'
          },
          'aspapuh': {
            'required': 'Asiakaspalvelun puhelinnumero puuttuu. (Esim. +358 45 12358999)'
          },
          'aihe': {
            'required': 'Sähköpostin aihe puuttuu.'
          },
          'otsikko': {
            'required': 'Sähköpostin otsikko puuttuu.'
          },
          'teksti': {
            'required': 'Sähköpostin leipäteksti puuttuu.'
          }
        },
        'logo': {
          'otsikko': 'Laskun logo',
          'lataa': 'Lataa logo',
          'vaihda': 'Vaihda logo',
          'info-teksti': 'Vaihda kuva raahaamalla tiedosto tähän,<br/>tai klikkaa valitaksesi tiedosto',
          'muista-tallentaa': 'Kuva on nyt optimoitu, ota se käyttöön tallentamalla.',
          'muista-tallentaa-poisto': 'Kuva on nyt poistettu, muista tallentaa muutokset.',
          'poista': 'Poista logo',
          'virheet': {
            'vain-yksi-kerrallaan': 'Voit lisätä kerrallaan vain yhden kuvan.',
            'ei-tiedosto': 'Vedetty objekti ei ollut tiedosto.',
            'liian-suuri': 'Kuva on liian suuri. Voit lisätä maksimissaan {{kokoMax}} megatavun kuvan. (Kuvan koko nyt {{kokoNyt}} megatavua)',
            'vaara-muoto': 'Kuva ei ole oikeassa muodossa. Tuetut kuvamuodot ovat {{tuetutMuodot}}.',
            'tuntematon': 'Tuntematon virhe. Ylläpidolle on lähetetty viesti virheestä. Ole hyvä ja yritä uudelleen.'
          }
        },
        'welcome-dialog': {
          'laskutusasetukset': 'Laskutus&shy;asetukset',
          'paaset-kohta-laskuttamaan': 'Pääset laskuttamaan tuotapikaa!',
          'varmistetaan-ensin': 'Varmistetaan kuitenkin ensin, että kaikki asetukset ovat kunnossa.',
          'paaset-kohta-laskuttamaan-laitetaan-ensin': 'Pääset laskuttamaan tuotapikaa, mutta laitetaan ensin muutama asetus kuntoon!',
          'yrityksen-tiedot': 'Yrityksen tiedot',
          'yhteystiedot': 'Yhteystiedot',
          'kaikki-valmis': 'Kaikki on nyt kunnossa ja Lemonaid valmiina laskuttamiseen.',
          'kay-tutustumassa': 'Kannattaa kuitenkin käydä tutustumassa muihin asetuksiin. Siellä pystyt esim. lisämään laskulle logon ja muokkaamaan sähköpostipohjia.'
        }
      },
      'tuote': {
        'nimi': 'Tuotteen nimi',
        'hinta': 'Tuotteen hinta',
        'aloita-kirjoittamalla-tuotteen-nimi': 'Aloita kirjoittamalla tuotteen nimi...',
        'validation': {
          'nimi': {
            'required': 'Tuotteen nimi puuttuu.'
          }
        }
      },
      'tuotteet': {
        'vihje': 'Tuotteet tallennetaan automaattisesti osana laskujen luontia - tuotteita ei tarvitse syöttää etukäteen.',
        'etsi': 'Etsi tuotteita',
        'lisaa': 'Lisää tuote',
        'nimi': 'Nimi',
        'hinta': 'Hinta',
        'hakuehdoilla-ei-yhtaan-tulosta': 'Hakuehdoilla ei löydy yhtään tuotetta'
      },
      'maksut': {
        'etsi': 'Etsi ostolaskuja',
        'nayta': 'Näytä',
        'lisaa': 'Lisää ostolasku',
        'maksun-saaja': 'Maksunsaaja',
        'poista-valinta': 'Poista valinta',
        'kummasta-haetaan-erapaiva': 'Eräpäivä',
        'kummasta-haetaan-paiva': 'Laskun päivä',
        'numero': 'Numero',
        'asiakas': 'Asiakas',
        'erapaiva': 'Eräpäivä',
        'kayta-erapaivaa': 'Käytä eräpäivää',
        'laskun-pvm': 'Laskun pvm',
        'laskua-yhteensa': 'laskua yhteensä',
        'maksa-erapaivana': 'Maksa eräpäivänä',
        'maksa-heti': 'Maksa heti',
        'summa': 'Summa',
        'avoinna': 'Avoinna',
        'tila': 'Tila',
        'et-vastaanota-laskuja': 'Huomaathan, että et vastaanota laskuja Lemonaidiin. Jos haluat laskut maksamista varten Lemonaidiin, ota laskujen vastaanotto käyttöön ',
        'tasta': 'tästä',
        'vahvistaminen-epaonnistui-ssn': 'Maksujen valtuuttaminen epäonnistui! Tunnistautumisen tiedot eivät täsmää kirjautuneen käyttäjän tietoihin.',
        'maksu-hylkaamisen-syy': 'Hylkäämisen syy',
        'maksu-epaonnistui': 'Pankki hylkäsi maksun. Voit yrittää maksua uudelleen tai maksaa sen muualta.',
        'maksu-epaonnistui-1': 'Tuntematon',
        'maksu-epaonnistui-2': 'WS-sopimus',
        'maksu-epaonnistui-3': 'Kate',
        'maksu-epaonnistui-4': 'Tilin rajoitukset',
        'lopeta-vastaanotto-dialog': {
          'otsikko': 'Haluatko varmasti lopettaa laskujen vastaanoton?',
          'teksti': 'Varmista, että olet ilmoittanut uuden laskutusosoitteen toimittajillesi ennen palvelun lopettamista. Kaikki vahvistamisen jälkeen osoitteeseen lähetetyt laskut menetetään pysyvästi.',
          'lopeta': 'Ymmärrän, haluan lopettaa laskujen vastaanoton'
        },
        'avoimet': 'Avoimet',
        'maksetut': 'Maksetut',
        'laskujen-vastaanotto': 'Ostolaskujen vastaanotto',
        'laskujen-maksaminen': 'Ostolaskujen maksaminen',
        'miten-lasku-on-maksettu': 'Miten lasku on maksettu?',
        'merkitse-maksetuksi': 'Merkitse maksetuksi',
        'merkitse': 'Merkitse',
        'maksupaivamaara': 'Maksupäivämäärä',
        'valitse-maksutapa': 'Valitse maksutapa',
        'vahvista-valitut': 'Vahvista valitut maksut tunnistautumalla',
        'luonti-onnistui': 'Verkkolaskuosoitteen luonti onnistui!',
        'yrityksellesi-on-nyt-luotu': 'Yrityksellesi on nyt luotu verkkolaskuosoite ja laskujen vastaanotto on päällä. Ilmoita verkkolaskuosoite laskuttajillesi niin laskut saapuvat Lemonaidiin.',
        'maksukoodit': 'Maksukoodit',
        'viivakoodi': 'Viivakoodi',
        'virtuaalinen-viivakoodi': 'Virtuaalinen viivakoodi',
        'qr-koodi': 'QR-koodi',
        'lahettaminen-epaonnistui': 'Lähettäminen epäonnistui',
        'hakuehdoilla-ei-yhtaan-tulosta': 'Kaikki ostolaskut käsitelty',
        'hakuehdoilla-ei-yhtaan-tulosta-historia': 'Hakuehdoilla ei löydy yhtään ostolaskua',
        'maksutili': 'Maksutili',
        'tilat': {
          'h': 'Hyväksytty',
          'a': 'Avoin',
          'e': 'Erääntynyt',
          'n': 'Maksussa',
          'm': 'Maksettu',
          'ah': 'Hylätty',
          'p': 'Pankki hylkäsi',
          't': 'Maksettu',
          'k': 'Käsitelty'
        },
        'summat': {
          'paivitetty': 'Päivitetty',
          'kaikki': 'Näytä kaikki valuutat',
          'huomautus': 'Eikö summa päivittynyt?',
          'tilin-saldo': 'Tilin saldo',
          'tilin-saldo-paivitetty': 'Päivitetty {{date}}',
          'avoinna': 'Avoinna',
          'maksettu': 'Maksettu',
          'eraantynyt': 'Erääntynyt',
          'luottotappiot': 'Luottotappiot'
        },
        'muokkaa': {
          'otsikko-muokkaa': 'Muokkaa ostolaskua',
          'otsikko-lisaa': 'Lisää ostolasku',
          'yhdista': 'Yhdistä tositteet yhdeksi ostolaskuksi',
          'pvm': 'Laskun päivä',
          'erapvm': 'Eräpäivä',
          'bic': 'BIC',
          'iban': 'IBAN',
          'summa': 'Summa',
          'saaja': 'Maksun saaja',
          'viite': 'Viitenumero',
          'viesti': 'Viesti',
          'lisatiedot': 'Lisätiedot kirjanpitoon',
          'toimituspaivamaara': 'Toimituspäivämäärä',
          'validation': {
            'ladataan': 'Ainakin yhtä tiedostoa ladataa vielä palvelimelle. Ole hyvä ja odota...',
            'pdf-parse-error': 'Tiedostoa "{{tiedostonNimi}}" ei voitu parsia. Ole hyvä ja lähetä tämä tiedosto kirjanpitällesi sähköpostitse tarkempaa analyysiä varten.',
            'pvm': {
              'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
              'matDatepickerMax': 'Laskun päivä on liian kaukana tulevaisuudessa.',
              'matDatepickerMin': 'Laskun päivä on liian kaukana menneisyydessä.',
              'required': 'Päivämäärä puuttuu.'
            },
            'erapvm': {
              'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
              'matDatepickerMax': 'Eräpäivä on liian kaukana tulevaisuudessa.',
              'matDatepickerMin': 'Eräpäivä on liian kaukana menneisyydessä.',
              'required': 'Päivämäärä puuttuu.'
            },
            'toimituspvm': {
              'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
              'matDatepickerMax': 'Toimituspäivä on liian kaukana tulevaisuudessa.',
              'matDatepickerMin': 'Toimituspäivä on liian kaukana menneisyydessä.',
            },
            'summa': {
              'required': 'Summa puuttuu.',
              'min': 'Summan on oltava enemmän kuin 0.',
              'max': 'Summan on oltava pienempi kuin 9999999,99.'
            },
            'iban': {
              'invalid': 'IBAN on virheellinen',
              'required': 'IBAN puuttuu'
            },
            'bic': {
              'invalid': 'BIC on virheellinen',
              'required': 'BIC puuttuu'
            },
            'saaja': {
              'required': 'Maksun saaja puuttuu'
            },
            'viite': {
              'viite-tai-viesti': 'Viitenumero tai viesti on pakollinen',
              'viitenumero': 'Viitenumero on virheellinen'
            },
            'viesti': {
              'viite-tai-viesti': 'Viesti tai viitenumero on pakollinen'
            }
          }
        },
        'katsele': {
          'odottaa-hyvaksyntaa': 'Odottaa hyväksyntää',
          'hyvaksytty': 'Hyväksytty',
          'hyvaksynta-puuttuu': 'hyväksyntä puuttuu.',
          'siirretty-maksuun': 'Siirretty maksuun',
          'hyvaksy-ja-siirry': 'Hyväksy ja siirry seuraavaan painamalla Enter',
          'siirry-seuraavaan': 'Siirry seuraavaan painamalla Enter',
          'edellinen-lasku': 'Edellinen lasku',
          'seuraava-lasku': 'Seuraava lasku',
          'hyvaksy-lasku': 'Hyväksy lasku',
          'hylkaa-lasku': 'Hylkää lasku',
          'hylkaa-lasku-peru': 'Peruuta laskun hylkääminen',
          'kasitelty': 'Merkitse käsitellyksi',
          'kasitelty-peru': 'Peru käsitelty -merkintä',
          'peru-hyvaksynta': 'Peruuta hyväksyntä',
          'maksettu-yrityksen-tililta': 'Maksettu yrityksen tililtä',
          'maksettu-muualla': 'Maksettu muualla',
          'maksettu-muualla-peru': 'Peruuta maksumerkintä',
          'lataa-lasku': 'Lataa lasku',
          'maksun-tiedot': 'Ostolaskun tiedot',
          'muokkaa-tietoja': 'Muokkaa tietoja',
          'lasku': 'Lasku',
          'saaja': 'Saaja',
          'saajan-tilinumero': 'Saajan tilinumero',
          'viitenumero': 'Viitenumero',
          'erapvm': 'Eräpäivä',
          'tililta': 'Tililtä',
          'summa': 'Summa',
          'lisatiedot': 'Lisätiedot kirjanpitoon',
          'toimituspaivamaara': 'Toimituspäivämäärä',
          'viesti': 'Viesti',
          'muokkaa-maksun-tietoja': 'Muokkaa ostolaskun tietoja',
          'muokkaa-lisatietoja': 'Muokkaa lisätietoja',
          'tallennettu': 'Tallennettu'
        },
        'asetukset': {
          'laskujen-maksaminen': 'Laskujen maksaminen',
          'voit-maksaa-sahkoiset-ostolaskut': 'Voit maksaa sähköiset ostolaskut helposti suoraan Lemonaidin käyttöliittymässä. Ostolaskujen maksaminen ja muiden maksujen tekeminen onnistuu parilla klikkauksella.',
          'maksuominaisuuden-aktivointi': 'Maksuominaisuuden aktivointi tapahtuu kahdessa vaiheessa. Ensin yrityksesi ja pankin välille tehdään Web Services -sopimus. Tämän jälkeen kytkemme pankin avaaman maksukanavan Lemonaidiin. Tekninen asiakaspalvelumme auttaa sinua sopimuksen tekemisessä ja palvelun käyttöönotossa.',
          'maksuominaisuus-maksaa': 'Maksuominaisuuden käyttö maksaa 0,49 € / maksu + alv. Huomaa, että tämän lisäksi pankki veloittaa Web Services -yhteydestä ja sen käytöstä oman hinnastonsa mukaisesti.',
          'haluan-ottaa-maksuominaisuuden-kayttoon': 'Haluan ottaa maksuominaisuuden käyttöön',
          'toteutuneet-maksut-veloitetaan': 'Toteutuneet maksut veloitetaan takautuvasti kunkin kuun päätyttyä. Hintaan lisätään kulloinkin voimassaoleva arvonlisävero.',
          'maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu': 'Maksaminen vaatii laskujen vastaanoton aktivoinnin.\nVoit ottaa laskujen vastaanoton käyttöön ',
          'maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu-linkki': 'täältä',
          'pyynto-lahetetty': 'Pyyntö lähetetty',
          'tekninen-asiakaspalvelu-ottaa-yhteytta': 'Tekninen asiakaspalvelumme ottaa sinuun yhteyttä.',
          'maksuominaisuus-on-paalla': 'Maksuominaisuus on päällä',
          'lopeta-ominaisuuden-kaytto': 'Jos haluat lopettaa ominaisuuden käytön ota yhteys kirjanpitäjääsi.',
          'vastaanotto': 'Laskujen vastaanotto',
          'vastaanota-verkkolaskuja': 'Voit vastaanottaa verkkolaskuja suoraan Lemonaidiin. Lemonaid muodostaa laskuista virtuaaliviivakoodit, joiden avulla laskujen maksaminen käy käden käänteessä. Maksaminen on erityisen helppoa, mikäli pankkisi mobiilisovelluksessa on viivakoodin lukija. Maksun jälkeen lasku siirtyy yhdellä klikkauksella kirjanpitoon eikä laskujen toimittamisesta tarvitse enää huolehtia!',
          'aktivoi-laskujen-maksuominaisuus': 'Mikäli haluat helpottaa maksamista edelleen, voit aktivoida myös laskujen maksuominaisuuden. Tällöin pankin ja Lemonaidin välille muodostetaan Web Services -maksukanava, jonka jälkeen laskujen maksaminen onnistuu suoraan Lemonaidista. Suosittelemme ottamaan <a routerLink="/maksut/asetukset/maksaminen">maksuominaisuuden</a> käyttöön jos yritykselläsi on paljon ostolaskuja.',
          'vastaanottamisen-aloittaminen': 'Vastaanottamisen aloittaminen on helppoa',
          'aktivoi-laskujen-vastaanottopalvelu': 'Aktivoi laskujen vastaanottopalvelu',
          'lataa-verkkolaskuosoite': 'Lataa Lemonaidista verkkolaskuosoiteesi ja toimita se laskuttajillesi',
          'palvelu-maksaa': 'Palvelu maksaa 5 € /kk + alv. Kuukausihinta sisältää rajattoman määrän vastaanotettuja laskuja. Maksu veloitetaan jokaiselta kuukaudelta, jonka aikana palvelu on ollut käytössä. Hintaan lisätään kulloinkin voimassa oleva arvonlisävero. Ei irtisanomisaikaa - palvelu voidaan lopettaa koska tahansa.',
          'tallenna-vastaanoton-aktivointi': 'Tallenna vastaanoton aktivointi',
          'laskujen-vastaanotto-on-paalla': 'Laskujen vastaanotto on päällä',
          'laskujen-vastaanotto-lopetetaan': 'Laskujen vastaanotto lopetetaan ja verkkolaskuosoite suljetaan heti. Jos osoitteeseen lähetetään tämän jälkeen laskuja, ne menetetään.',
          'tallenna-vastaanoton-lopetus': 'Tallenna vastaanoton lopetus',
          'sahkoisten-laskujen-vastaanotto-on-paalla': 'Sähköisten laskujen vastaanotto on päällä ja yrityksellesi on luotu verkkolaskuosoite. Voit tilata laskuja Lemonaidiin ilmoittamalla alla olevan verkkolaskuosoitteen toimittajillesi.',
        },
        'mainos': {
          'vastaanota-verkkolaskuja-suoraan': 'Vastaanota verkkolaskuja suoraan Lemonaidiin. Lemonaid muodostaa laskuista virtuaaliviivakoodit, joiden avulla laskujen maksaminen käy kätevästi verkkopankissasi. Maksaminen on erityisen helppoa, mikäli pankkisi mobiilisovelluksessa on viivakoodin lukija. Maksun jälkeen lasku siirtyy yhdellä klikkauksella kirjanpitoon eikä laskujen toimittamisesta tarvitse enää huolehtia!',
          'kaikki-laskut-samassa-paikassa': 'Kaikki laskut samassa paikassa',
          'helpottaa-laskujen-maksamista': 'Helpottaa laskujen maksamista',
          'muistuttaa-eraantyneista-laskuista': 'Muistuttaa erääntyneistä laskuista',
          'laskut-automaattisesti-kirjanpitoon': 'Laskut automaattisesti kirjanpitoon',
          'ei-enaa-kadonneita-laskuja': 'Ei enää kadonneita laskuja',
          'laskujen-vastaanotto-otsikko': 'Laskujen vastaanotto',
          'ota-kayttoon': 'Ota käyttöön',
          'kk': 'kk',
          'maksu': 'maksu',
          'kuukausihinta-sisaltaa': 'Kuukausihinta sisältää rajattoman määrän vastaanotettuja laskuja. Maksu veloitetaan jokaiselta kuukaudelta, jonka aikana palvelu on ollut käytössä. Hintaan lisätään kulloinkin voimassa oleva arvonlisävero. Ei irtisanomisaikaa - palvelu voidaan lopettaa koska tahansa.',
          'voit-maksaa-sahkoiset-ostolaskut': 'Voit maksaa sähköiset ostolaskut helposti suoraan Lemonaidin käyttöliittymässä. Ostolaskujen maksaminen ja muiden maksujen tekeminen onnistuu parilla klikkauksella.',
          'maksuominaisuuden-aktivointi': 'Maksuominaisuuden aktivointi tapahtuu kahdessa vaiheessa. Ensin yrityksesi ja pankin välille tehdään Web Services -sopimus. Tämän jälkeen kytkemme pankin avaaman maksukanavan Lemonaidiin. Tekninen asiakaspalvelumme auttaa sinua sopimuksen tekemisessä ja palvelun käyttöönotossa.',
          'maksuominaisuus-maksaa': 'Maksuominaisuuden käyttö maksaa 0,49 € / maksu + alv. Huomaa, että tämän lisäksi pankki veloittaa Web Services -yhteydestä ja sen käytöstä oman hinnastonsa mukaisesti.',
          'toteutuneet-maksut-veloitetaan': 'Toteutuneet maksut veloitetaan takautuvasti kunkin kuun päätyttyä. Hintaan lisätään kulloinkin voimassaoleva arvonlisävero.',
          /** dialog */
          'dialog-vastaanota-verkkolaskuja-suoraan': 'Voit nyt vastaanottaa verkkolaskuja suoraan Lemonaidiin! Lemonaid muodostaa laskuista virtuaaliviivakoodit, joiden avulla laskujen maksaminen käy kätevästi verkkopankissasi. Maksun jälkeen lasku siirtyy yhdellä klikkauksella kirjanpitoon eikä laskujen toimittamisesta tarvitse enää huolehtia!',
          'kokeile-laskujen-vastaanottoa': 'Kokeile laskujen vastaanottoa',
          'ehka-myohemmin': 'Ehkä myöhemmin',
        }
      },
      // 'tav': 'Kotimaan myynti',
      // 'taveu': 'Yhteisömyynti toiseen EU-maahan (tavara)',
      // 'palveu': 'Yhteisömyynti toiseen EU-maahan (palvelu)',
      // 'eieu': 'Myynti EU:n ulkopuolelle',
      // 'kuleu': 'Kuluttajamyynti toiseen EU-maahan',
      // 'kuleut': 'Kuluttajamyynti toiseen EU-maahan (tavara)',
      // 'kuleup': 'Kuluttajamyynti toiseen EU-maahan (palvelu)',
      // 'kuleur': 'Kuluttajamyynti toiseen EU-maahan',
      // 'rak': 'Rakentamispalveluiden myynti'

      'alv-huomautus': {
        'palveu': 'VAT 0% (Reverse charge, VAT Directive art 44.)',
        'taveu': 'VAT 0% (Intra-community supply)',
        'eieu': 'VAT 0 %, Export of goods or services',
        'rak': 'Lasku ei sisällä arvonlisäveroa. Verovelvollisuus ostajalla (AVL 8 c §).'
      },
      'email-perinteinen': {
        'otsikko': 'Laskun tiedot',
        'otsikko-muistutuslasku': 'Muistutuslaskun tiedot',
        'summa': 'Laskun summa',
        'erapaiva': 'Eräpäivä',
        'viitenumero': 'Viitenumero',
        'tilinumero': 'Tilinumero',
        'virtuaaliviivakoodi': 'Virtuaaliviivakoodi',
        'lahetetty': 'Lähetetty Lemonaidilla',
        'lue-lisaa': 'Lue lisää',
        'vastaanottajat': 'Vastaanottajat',
        'aihe': 'Aihe',
        'yhteystiedot': 'Yhteystiedot'
      },
      'pdf-perinteinen': {
        'lasku': 'LASKU',
        'muistutuslasku': 'MUISTUTUSLASKU',
        'hyvityslasku': 'HYVITYSLASKU',
        'luonnos': 'LUONNOS',
        'mitatoity': 'MITÄTÖINTILASKU',
        'heti': 'HETI',
        'laskunnro': 'Laskun numero',
        'ulkoinen-lasku': 'Lasku luotu palvelussa: ',
        'korvaa-laskun': 'Tämä lasku korvaa laskun numero {{korvatunLaskunNumero}} aikaisemmat versiot.',
        'hyvittaa-laskun': 'Tämä lasku hyvittää laskua numero {{korvatunLaskunNumero}}.',
        'mitatoi-laskun': 'Tämä lasku mitätöi laskun numero {{korvatunLaskunNumero}}.',
        'monesko-muistutuslasku-1': 'Tämä lasku on ensimmäinen muistutuslasku.',
        'monesko-muistutuslasku-2': 'Tämä lasku on toinen muistutuslasku.',
        'monesko-muistutuslasku-3': 'Tämä lasku on kolmas muistutuslasku.',
        'monesko-muistutuslasku-4': 'Tämä lasku on neljäs muistutuslasku.',
        'monesko-muistutuslasku-5': 'Tämä lasku on viides muistutuslasku.',
        'monesko-muistutuslasku-6': 'Tämä lasku on kuudes muistutuslasku.',
        'monesko-muistutuslasku': 'Tämä lasku on {{muistutuslaskujenMaara}}:s muistutuslasku.',
        'viitenro': 'Viitenumero',
        'laskunpvm': 'Laskun pvm',
        'toimituspvm': 'Toimituspvm',
        'erapaiva': 'Eräpäivä',
        'maksuehto': 'Maksuehto',
        'pvnetto': 'pv netto',
        'viivastyskorko': 'Viivästyskorko',
        'ytunnus': 'VAT-numero',
        'tuote': 'Tuote',
        'yksikkohinta': 'Yksikköhinta',
        'maara': 'Määrä',
        'lisatiedot': 'Lisätiedot:',
        'lisatiedot-lopussa': 'HUOM! Katso lisätiedot laskun lopusta!',
        'viitteenne': 'Viitteenne:',
        'muistutus': 'Lasku nro. {{laskunumero}} on jäänyt kirjanpitomme mukaan maksamatta (eräpäivä {{erapaiva}}). Pyydämme teitä hoitamaan asian maksamalla tämän muistutuslaskun viivytyksettä.',
        'jatkuu': 'Tuotelistaus jatkuu seuraavalla sivulla... ',
        'alv': 'Alv',
        'ale': 'Ale',
        'yhteensa': 'Yhteensä',
        'yhteensa-pienella': 'yhteensä',
        'ilman-alv': 'Yhteensä ilman arvonlisäveroa',
        'yhteensa-ennen-aiemmin-maksettua': 'Yhteensä',
        'maksettu-aiemmin': 'Maksettu aiemmin',
        'maksettava-yhteensa': 'Maksettava yhteensä',
        'alv-yhteensa': 'Arvonlisävero yhteensä',
        'yritys': 'Yritys',
        'postiosoite': 'Postiosoite',
        'yhteystiedot': 'Yhteystiedot',
        'saajan-tilinro': 'Saajan tilinumero',
        'saaja': 'Saaja',
        'nimi-osoite': 'Maksajan nimi ja osoite',
        'allekirjoitus': 'Allekirjoitus',
        'tilisiirto': 'TILISIIRTO',
        'tililta': 'Tililtä nro',
        'euro': 'Euro',
        'sivu': 'Sivu',
        'esikatselu': 'Esikatselu',
        'maksun-valitys-tiedote': 'Maksu välitetään saajalle maksujenvälityksen ehtojen mukaisesti ja vain maksajan ilmoittaman tilinumeron perusteella.',
      },
      'ulkoiset-laskut': {
        'minduu': 'Minduu',
        'tuntematon': 'Tuntematon'
      },
      'asetukset': {
        'lasku': 'Laskuasetukset',
        'omat': 'Laskuttajan tiedot',
        'spostipohja': 'Sähköpostipohjat',
        'reskontra': 'Reskontra'
      },
      'yleiset': {
        'tallenna': 'Tallenna',
        'peruuta': 'Peruuta',
        'jatka': 'Jatka',
        'muokkaa': 'Muokkaa',
        'poista': 'Poista',
        'lataa': 'Lataa',
        'ok': 'OK',
        'ei': 'EI',
        'kylla': 'KYLLÄ',
        'takaisin': 'Takaisin',
        'sulje': 'Sulje',
        'tervetuloa': 'Tervetuloa!',
        'aloita': 'Aloita',
        'aloitus': 'Aloitus',
        'valmis': 'Valmis!',
        'valmista': 'Valmista!',
        'laheta': 'Lähetä',
        'kiitos': 'Kiitos!',
        'myohemmin': 'Myöhemmin',
        'toimintoa-ei-voi-suorittaa-ilman-internet-yhteytta': 'Toimintoa ei voi suorittaa: ei saatu yhteyttä Lemonaid taustajärjestelmään. Tarkista verkkoyhteytesi.',
        'tuntematon-virhe': 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.',
        'tiivistaa': 'Sulje',
        'laajentaa': 'Avaa',
        'decimal-field-required-error': 'Ole hyvä ja käytä vain numeroita',
        'decimal-field-max-error': 'Ole hyvä ja lisää vain {max} numeroa',
        'ladataan': 'Ladataan',
        'kuukausi': 'Kuukausi',
        'neljannesvuosi': 'Neljännesvuosi',
        'vuosi': 'Vuosi',
        'paivitetty': 'Päivitetty'
      },
      'asiakas': {
        'nimi': 'Nimi',
        'ytunnus': 'VAT-numero',
        'katuosoite': 'Katuosoite',
        'postitoimipaikka': 'Postitoimipaikka',
        'postinro': 'Postinumero',
        'maa': 'Maa',
        'verkkolaskuotsikko': 'Verkkolaskuosoite',
        'verkkolaskuvalittaja': 'Välittäjä',
        'verkkolaskuosoite': 'Osoite',
        'asiakastyyppi': {
          'asiakastyyppi': 'Asiakastyyppi',
          'henkilo': 'Henkilö',
          'yritys': 'Yritys'
        },
        'vastaanottaja': 'Sähköpostilaskujen vastaanottajat',
        'validation': {
          'asiakastyyppi': {
            'required': 'Valinta puuttuu'
          },
          'nimi': {
            'required': 'Asiakkaan nimi puuttuu.'
          },
          'ytunnus': {
            'required': 'VAT-numero puuttuu.'
          },
          'katuosoite': {
            'required': 'Katuosoite puuttuu.'
          },
          'postitoimipaikka': {
            'required': 'Postitoimipaikka puuttuu.'
          },
          'postinro': {
            'required': 'Postinumero puuttuu.'
          },
          'maa': {
            'required': 'Maa puuttuu.'
          },
          'lisaavastaanottaja': {
            'email': 'Sähköpostiosoite on virheellinen.',
            'required': 'Vastaanottaja puuttuu.'
          }
        }
      },
      'asiakkaat': {
        'vihje': 'Asiakkaat tallennetaan automaattisesti osana laskujen luontia - asiakkaita ei tarvitse syöttää etukäteen.',
        'etsi': 'Etsi asiakas',
        'lisaa': 'Lisää asiakas',
        'nimi': 'Nimi',
        'ytunnus': 'VAT-numero',
        'katuosoite': 'Katuosoite',
        'postinro': 'Postinumero',
        'postitoimipaikka': 'Postitoimipaikka',
        'maa': 'Maa',
        'hakuehdoilla-ei-yhtaan-tulosta': 'Hakuehdoilla ei löydy yhtään asiakasta'
      },
      'stepper': {
        'optional': 'Vapaavalintainen'
      },
      'tositteet': {
        'yksityisotto': {
          'receipt-comment': 'Yksityisotto',
          'dialog': {
            'title': 'Merkitse tapahtuma yksityisotoksi',
            'message': 'Tapahtuma käsitellään kirjanpidossa henkilökohtaisena kuluna, eikä sitä huomioida yrityksesi verotuksessa.',
            'action': 'Merkitse'
          }
        },
        'yhteiset': {
          'tallentaminen-epaonnistui': 'Tallentaminen epäonnistui. Ole hyvä ja palaa edelliselle näytölle ja yritä uudelleen. Jos virhe ei poistu, ole hyvä ja ota yhteys kirjanpitäjääsi.',
          'tiedoston-lahettaminen-epaonnistui-alku': 'Tiedostojen lähettämisen estivät seuraavat virheet:',
          'tiedoston-lahettaminen-epaonnistui-loppu': 'Ole hyvä ja palaa edelliselle näytölle ja yritä uudelleen. Jos virhe ei poistu, ole hyvä ja ota yhteys kirjanpitäjääsi.',
        },
        'osto': {
          'listaus': {
            'lisaa': 'Lisää ostotositteita',
          },
          'katsele': {
            'lataa-tositteen-nimi': 'Ostotosite',
            'ei-maksutapaa': 'Ei maksutapaa'
          }
        },
        'myynti': {
          'listaus': {
            'lisaa': 'Lisää myyntitositteita',
            'ei-tositteita': 'Lisää uusi myyyntitosite vetämälle se tänne tai napauta "Lisää myyntitositteita"',
            'rahaa-tanne': 'Raahaa tiedostot tänne'
          },
          'katsele': {
            'otsikon-maksutapa': 'Myyntitosite',
            'lataa-tositteen-nimi': 'Myyntitosite'
          },
          'muokkaa': {
            'pvm': 'Tositteen päivämäärä',
            'summa': 'Tositteen summa',
            'validation': {
              'ladataan': 'Ainakin yhtä tiedostoa ladataa vielä palvelimelle. Ole hyvä ja odota...',
              'pdf-parse-error': 'Tiedostoa "{{tiedostonNimi}}" ei voitu parsia. Ole hyvä ja lähetä tämä tiedosto kirjanpitällesi sähköpostitse tarkempaa analyysiä varten.',
              'maksutapa': {
                'required': 'Maksutapa puuttuu'
              },
              'pvm': {
                'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
                'matDatepickerMax': 'Maksupäivä on liian kaukana tulevaisuudessa.',
                'matDatepickerMin': 'Maksupäivä on liian kaukana menneisyydessä.',
                'required': 'Päivämäärä puuttuu.'
              },
              'summa': {
                'required': 'Summa puuttuu.',
                'min': 'Summan on oltava enemmän kuin 0.',
                'max': 'Summan on oltava pienempi kuin 9999999,99.'
              }
            }
          }
        },
        'palkka': {
          'listaus': {
            'etsi': 'Etsi materiaalia',
            'lisaa': 'Lisää palkkamateriaali',
            'ei-tositteita': 'Lisää uusi palkkamateriaali vetämälle se tänne tai napauta "Lisää palkkamateriaali"',
            'rahaa-tanne': 'Raahaa tiedostot tänne'
          },
          'katsele': {
            'otsikon-maksutapa': 'Palkkamateriaali',
            'lataa-tositteen-nimi': 'Palkkamateriaali'
          },
          'muokkaa': {
            'otsikko-muokkaa': 'Muokkaa materiaalia',
            'otsikko-lisaa': 'Lisää materiaali',
            'yhdista': 'Yhdistä ladatut tiedostot yhdeksi materiaaliksi',
            'tosite': 'Materiaali',
            'pvm': 'Materiaalin päivämäärä',
            'validation': {
              'ladataan': 'Ainakin yhtä tiedostoa ladataa vielä palvelimelle. Ole hyvä ja odota...',
              'pdf-parse-error': 'Tiedostoa "{{tiedostonNimi}}" ei voitu parsia. Ole hyvä ja lähetä tämä tiedosto kirjanpitällesi sähköpostitse tarkempaa analyysiä varten.',
              'pvm': {
                'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
                'matDatepickerMax': 'Maksupäivä on liian kaukana tulevaisuudessa.',
                'matDatepickerMin': 'Maksupäivä on liian kaukana menneisyydessä.',
                'required': 'Päivämäärä puuttuu.'
              }
            }
          }
        },
        'tiliote': {
          'listaus': {
            'etsi': 'Etsi tiliotetta',
            'lisaa': 'Lisää tiliote',
            'ei-tositteita': 'Lisää uusi tiliote vetämälle se tänne tai napauta "Lisää tiliote"',
            'rahaa-tanne': 'Raahaa tiedostot tänne'
          },
          'katsele': {
            'otsikon-maksutapa': 'Tiliote',
            'lataa-tositteen-nimi': 'Tiliote'
          },
          'muokkaa': {
            'otsikko-muokkaa': 'Muokkaa tiliotetta',
            'otsikko-lisaa': 'Lisää tiliote',
            'yhdista': 'Yhdistä ladatut tiedostot yhdeksi tiliotteeksi',
            'tosite': 'Tiliote',
            'pvm': 'Tiliotteen päivämäärä',
            'validation': {
              'ladataan': 'Ainakin yhtä tiedostoa ladataa vielä palvelimelle. Ole hyvä ja odota...',
              'pdf-parse-error': 'Tiedostoa "{{tiedostonNimi}}" ei voitu parsia. Ole hyvä ja lähetä tämä tiedosto kirjanpitällesi sähköpostitse tarkempaa analyysiä varten.',
              'pvm': {
                'matDatepickerParse': 'Päivämäärän muoto on virheellinen (pp.kk.vvvv).',
                'matDatepickerMax': 'Maksupäivä on liian kaukana tulevaisuudessa.',
                'matDatepickerMin': 'Maksupäivä on liian kaukana menneisyydessä.',
                'required': 'Päivämäärä puuttuu.'
              }
            }
          }
        },
        'selvitettavat': {
          'selvitettavat': 'Selvitettävät',
          'merkitse-yksityisotoksi': 'Merkitse yksityisotoksi',
          'kirjanpitajasi-on-merkannut': 'Kirjanpitäjäsi on merkannut yhden tai useamman tilitapahtuman selvitettäväksi. Ole hyvä ja lisää tosite tai anna selvitys alla oleviin tapahtumiin.',
        },
        'laskujen-vastaanotto': {
          'vastaanotto-ja-valityspalvelu': 'Laskujen vastaanotto- ja välityspalvelu (beta)',
          'voit-vastaanottaa-laskuja': 'Laskujen vastaanotto- ja välityspalvelussa Lemonaid vastaanottaa verkkolaskut ja välittää ne automaattisesti yrityksesi verkkopankkiin maksettavaksi. Samalla verkkolaskuista tallennetaan kopio kirjanpitoa varten, eikä niitä enää tarvitse toimittaa erikseen.',
          'valityspalvelu-on-ilmaista': 'Laskujen vastaanotto- ja välityspalvelu on betan ajan ilmaista. Lopullisen tuotteen hinnoittelusta ei olla vielä päätetty. Huomaa kuitenkin, että pankki saattaa periä laskujen vastaanotosta tai maksamisesta palvelumaksuja.',
          'ota-kayttoon': 'Ota käyttöön',
          'ennen-palvelun-kayttoonottoa': 'Ennen palvelun käyttöönottoa yrityksesi verkkopankissa tulee olla avattuna verkkolaskuosoite, johon voidaan vastaanottaa verkkolaskuja. Kun olet saanut verkkolaskuosoitteen, syötä se alla oleviin kenttiin. Jos sinulla ei ole vielä verkkolaskuosoitetta, ota yhteyttä yrityksesi pankkiin.',
          'aktivoi-palvelu': 'Aktivoi laskujen vastaanotto- ja välityspalvelu (0,00€/kk)',
          'aktiivinen': 'Aktiivinen',
          'valittaja': 'Välittäjä',
          'valittaja-required': 'Välittäjä puuttuu',
          'osoite': 'Osoite',
          'asetukset': 'Asetukset',
          'verkkolaskuosoite': 'Verkkolaskuosoite',
          'osoite-required': 'Osoite puuttuu',
          'haluan-myos-lemon-treen-laskut': 'Haluan, että myös palvelumaksuista ja maksettavista arvonlisäveroista lähetetään verkkolaskut tähän osoitteeseen.',
          'haluan-hyvaksya': 'Haluan hyväksyä saapuvat verkkolaskut, ennen kuin ne lähetetään verkkopankkiin ja kirjanpitoon.',
          'osoite-tallennettu-ei-hyvaksyntaa': 'Yrityksesi verkkopankin verkkolaskuosoite on tallennettu ja laskujen vastaanotto- ja välityspalvelu on aktiivinen. Lemonaid välittää vastaanotetut laskut verkkopankkiisi automaattisesti maksamista varten.',
          'osoite-tallennettu-hyvaksynta-valittu': 'Yrityksesi verkkopankin verkkolaskuosoite on tallennettu ja laskujen vastaanotto- ja välityspalvelu on aktiivinen. Saapuneet verkkolaskut löytyvät “Laskujen hyväksyntä” -välilehdeltä. Kun hyväksyt laskun, se lähetetään verkkopankkiisi maksettavaksi ja kopio siitä siirretään kirjanpitoon.',
          'tarkastele-osoitetta': 'Tarkastele tai muokkaa palvelun asetuksia.',
          'alta-loydat': 'Alta löydät varsinaisen verkkolaskuosoitteesi, jolla saat tilattua laskut Lemonaidiin. Ilmoita osoite toimittajillesi.',
          'voit-myos-antaa': 'Voit myös antaa toimittajillesi oheisen laskutustiedot-pdf:n, josta tarvittavat tiedot selviää.',
          'lataa-pdf': 'Lataa pdf.',
          'pdf-epaonnistui': 'Laskutustiedot-pdf:in lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        },
        'laskujen-hyvaksyminen': {
          'laskujen-hyvaksyminen': 'Laskujen hyväksyntä',
          'saapuneet-verkkolaskut': 'Saapuneet verkkolaskut löytyvät täältä.',
          'kun-hyvaksyt-laskun': 'Kun hyväksyt laskun, se lähetetään verkkopankkiisi maksettavaksi ja kopio siitä siirretään kirjanpitoon.',
          'erapaiva': 'Eräpäivä',
          'haluatko-varmasti-hylata': 'Haluatko varmasti hylätä laskun?',
          'laskua-lahetetaan': 'Laskua lähetetään',
          'hyvaksy': 'Hyväksy',
          'hylkaa': 'Hylkää',
          'peruuta': 'Peruuta',
          'ei-hyvaksymattomia': 'Ei hyväksymättömiä laskuja',
          'kylla': 'Kyllä',
          'verkkolaskun-hyvaksynyt': 'Verkkolaskun hyväksynyt'
        },
        'laskujen-hyvaksyminen-estetty-dialog': {
          'otsikko': 'Laskua ei voi enää hyväksyä',
          'leipateksti': 'Pankit eivät hyväksy sähköisiä laskuja, joiden eärpäivä on mennyt tai jotka erääntyvät lähitulevaisuudessa. Tästä syystä tätä laskua ei enää voi hyväksyä. Maksa lasku manuaalisesti ja merkitse se maksetuksi, jolloin se siirtyy myös kirjanpitoon.',
          'merkitse-maksetuksi': 'Merkitse maksetuksi'
        }
      },
      'kuitit': {
        'lisaa': 'Lisää tositteita',
        'etsi': 'Etsi tositteita',
        'veda-tanne': 'Vedä tosite tänne',
        'nayta-lisaa': 'Näytä lisää',
        'nayta-vahemman': 'Näytä vähemmän',
        'hakuehdoilla-ei-yhtaan-tulosta': 'Hakuehdoilla ei löydy yhtään tositetta, lisää tositteita raahaamalla tai napauttamalla "Lisää tositteita"',
        'lataaminen': {
          'virheet': {
            'ei-tiedosto': 'Vedetty objekti ei ollut tiedosto.',
            'vaara-muoto': 'Tosite ei ole oikeassa muodossa. Tuetut muodot ovat {{tuetutMuodot}}.',
            'liian-suuri': 'Tosite on liian suuri. Voit lisätä maksimissaan {{kokoMax}} megatavun tositteen. (Tiedoston koko on nyt {{kokoNyt}} megatavua)',
          }
        },
        'ulkopuolisen-jarjestelman-viesti': 'Ulkopuolisen järjestelmän viesti',
        'poista-dialog': {
          'otsikko': 'Haluatko varmasti poistaa tositteen?',
          'body': 'Haluatko varmasti poistaa {{pvm}} päivätyn tositteen, jonka summa on {{summa}}?',
          'poista': 'Poista tosite'
        },
        'katsele': {
          'tosite': 'Tosite',
          'summa': 'Summa',
          'poista': 'Poista tosite',
          'lataa': 'Lataa tosite',
          'alv': 'ALV-vähennysoikeus'
        },
        'muokkaa': {
          'tosite-lukittu': 'Tosite on lukittu',
          'kirjanpitaja-on-lukinnut': 'Kirjanpitäjäsi on käsitellyt ja lukinnut tositteen. Jos tositetta pitäisi mielestäsi muuttaa, ota yhteys kirjanpitäjääsi.',
          'otsikko-lisaa': 'Lisää tositteita',
          'otsikko-muokkaa': 'Muokkaa tositetta',
          'yhdista': 'Yhdistä ladatut tiedostot yhdeksi tositteeksi',
          'tosite': 'Tosite',
          'maksettu': 'Tosite on maksettu',
          'laji': 'Tositteen laji',
          'lisatiedot': 'Lisätiedot',
          'maksupvm': 'Maksupäivämäärä',
          'summa': 'Maksun summa',
          'alv': 'Alv-vähennysoikeus',
          'ladataan': 'Ladataan tiedostoja palvelimelle...',
          'ladataan-sivua': 'Ladataan sivua',
          'validation': {
            'ladataan': 'Ainakin yhtä tiedostoa ladataa vielä palvelimelle. Ole hyvä ja odota...',
            'pdf-parse-error': 'Tiedostoa "{{tiedostonNimi}}" ei voitu parsia. Ole hyvä ja lähetä tämä tiedosto kirjanpitällesi sähköpostitse tarkempaa analyysiä varten.',
            'maksutapa': {
              'required': 'Maksutapa puuttuu'
            },
            'pvm': {
              'matDatepickerParse': 'Maksupäivän muoto on virheellinen (pp.kk.vvvv).',
              'matDatepickerMax': 'Maksupäivä on liian kaukana tulevaisuudessa.',
              'matDatepickerMin': 'Maksupäivä on liian kaukana menneisyydessä.',
              'required': 'Maksupäivämäärä puuttuu.'
            },
            'summa': {
              'required': 'Maksun summa puuttuu.',
              'min': 'Maksun summan on oltava enemmän kuin 0.',
              'max': 'Maksun summan on oltava pienempi kuin 9999999,99.'
            },
            'ei-kuvia': 'Ole hyvä ja lisää vähintään yksi kuva.'
          },
          'kirjanpitajan-kommentti': 'Kirjanpitäjän kommentti',
          'lisaa-tiedosto': 'Lisää tiedosto klikkaamalla tai vetämällä se tähän',
        }
      },
      'kirjanpito-lahetys-veroilmoitus': {
        'input-start-regular-tmi': 'Moikka',
        'input-start-regular-oy': 'Moikka',
        'input-start-holvi': 'Hei,',
        'input-end-regular-tmi': 'Jos sinulle tulee mieleen kysymyksiä veroilmoitukseen liittyen, autan mielelläni.',
        'input-end-regular-oy': 'Palaan tilinpäätöksen osalta asiaan vielä erillisessä sähköpostissa.\n\nJos sinulle tulee mieleen kysymyksiä veroilmoitukseen liittyen, autan mielelläni.',
        'input-end-holvi': 'Ystävällisin terveisin,\n\n',
        'email-generated-regular-tmi': 'Vuoden {{vuosi}} veroilmoitus on nyt lähetetty Verohallinnolle. {{liitteetText}} Kaikki on nyt siis valmista viime vuoden osalta. 😊',
        'email-generated-regular-oy': 'Yrityksesi {{yrityksenNimi}} tilikauden {{vuosi}} veroilmoitus on nyt lähetetty Verohallinnolle.{{liitteetText}} Voit tarkastella ilmoitusta OmaVerossa muutaman päivän kuluttua.',
        'email-generated-holvi': 'Olemme laatineet yrityksesi v. {{vuosi}} veroilmoituksen. Pääset tarkastelemaan veroilmoitusta omavero-palvelussa.\n\nÄlä epäröi ottaa yhteyttä, mikäli sinulla tulee kysyttävää kirjanpitoasioihisi liittyen, autamme mielellämme. Voit ottaa meihin yhteyttä Holvi Zen asiakaspalveluumme sivulla https://support.holvi.com/hc/fi/requests/new. Huomaathan, että et voi vastata tähän sähköpostiin.',
        'account-9940-text-html': 'Tilikaudelta jäi {{sum}} euroa maksettavaa jäännösveroa. Verohallinto ilmoittaa jäännösveron eräpäivän kun he ovat käsitelleet veroilmoituksen ja tehneet verotuspäätöksen. Verotuspäätöksessä on tarkemmat tiedot jäännösveron määrästä ja ohjeet maksamista varten. Verotuspäätös lähetetään postitse ja se löytyy myös OmaVerosta. Jäännösverolle kertyy viivästyskorkoa. Jos haluatte maksaa jäännösveron ennen eräpäivää, ilmoitattehan siitä minulle. Lisätietoa jäännösverosta löydät verohallinnon <a href="https://www.vero.fi/yritykset-ja-yhteisot/verot-ja-maksut/maksaminen-yritykset-ja-yhteisot/yhteison-jaannosveron-maksaminen/" target="_blank">ohjeesta</a>.',
        'liitteet-text': ' Liitteenä myös {{liitteet}}.',
        'liitteena-veroilmoitus': ' Ohessa kopio lähetetystä ilmoituksesta.',
        'tuloslaskelma': 'tuloslaskelma',
        'veroilmoitus-liitteineen': 'lähetetty veroilmoitus liitteineen',
        'pitka-tuloslaskelma': 'pitkä tuloslaskelma',
        'tase-erittelyt': 'tase-erittelyt',
        'liitetiedot': 'liitetiedot',
        'virallinen-tuloslaskelma': 'virallinen tuloslaskelma',
        'virallinen-tase': 'virallinen tase',
        'veroilmoitus': 'veroilmoitus',
        'and': 'sekä'
      },
      'kirjanpito-lahetys-tilinpaatos': {
        'input-start': 'Moikka',
        'email-generated': 'Tilinpäätös {{vuosi}} on nyt valmis ja löydät sen Lemonaidin selainversion Raportit-kohdasta.{{liitteetText}} Voit allekirjoittaa tilinpäätöksen Lemonaidissa. Huomaathan, että kaikkien hallituksen jäsenten tulee allekirjoittaa tilinpäätös omilla Lemonaid-tunnuksillaan.\n\nAllekirjoitettu tilinpäätös täytyy vahvistaa varsinaisessa yhtiökokouksessa. Kokous on pidettävä 6 kuukauden sisällä tilikauden päättymisestä. Kokouksesta tehdään pöytäkirja ja se pitää allekirjoittaa. Saat tehtyä pöytäkirjan ja allekirjoitettua sen helposti Lemonaidissa.\n\nTämän jälkeen allekirjoitettu ja yhtiökokouksessa vahvistettu tilinpäätös pitää rekisteröidä. Teen sen puolestasi heti kun yhtiökokouksen pöytäkirja on allekirjoitettu.\n\nSinulla on siis vielä neljä tehtävää tilinpäätöstä koskien:<b><ol><li>Käy läpi tilinpäätös</li><li>Allekirjoita tilinpäätös ja hallituksen pöytäkirja tilinpäätöksen hyväksymisestä</li><li>Pidä varsinainen yhtiökokous</li><li>Allekirjoita varsinaisen yhtiökokouksen pöytäkirja</li></ol></b>Tämän jälkeen lähetän tilinpäätöksen rekisteröitäväksi PRH:lle.',
        'email-generated-tarkastettava-ja-osingonjako': 'Tilinpäätös {{vuosi}} on nyt valmis.{{liitteetText}} Tilinpäätöksen allekirjoittaa yhtiön hallitus ja se voidaan tehdä perinteisesti tai sähköisiä allekirjoituspalveluja käyttäen.\n\nTilinpäätös pitää vielä tilintarkastaa. Palaan tämän tiimoilta asiaan erillisessä sähköpostissa.\n\nKun tilinpäätös on tilintarkastettu ja allekirjoitettu, se täytyy vahvistaa varsinaisessa yhtiökokouksessa. Kokous on pidettävä 6 kuukauden kuluessa tilikauden päättymisen jälkeen. Kokouksesta tehdään pöytäkirja ja se pitää allekirjoittaa.\n\nTämän jälkeen allekirjoitettu ja yhtiökokouksessa vahvistettu tilinpäätös pitää rekisteröidä. Toimittakaa minulle allekirjoitettu tilinpäätös, yhtiökokouksen pöytäkirja ja tilintarkastuskertomus niin teen rekisteröinnin puolestanne.\n\nTilinpäätökseen kirjattiin jaettavia osinkoja {{osingonjaonMaara}}€. Huomaathan, että osingot voidaan maksaa vasta kun yhtiökokouksen pöytäkirja on allekirjoitettu ja osinkojen ennakonpidätys laskettu. Kun olen saanut allekirjoitetun yhtiökokouksen pöytäkirjan, lähetän vielä erillisen sähköpostin osingon ja ennakonpidätyksen maksamisesta.',
        'email-generated-tarkastettava': 'Tilinpäätös {{vuosi}} on nyt valmis.{{liitteetText}} Tilinpäätöksen allekirjoittaa yhtiön hallitus ja se voidaan tehdä perinteisesti tai sähköisiä allekirjoituspalveluja käyttäen.\n\nTilinpäätös pitää vielä tilintarkastaa. Palaan tämän tiimoilta asiaan erillisessä sähköpostissa.\n\nKun tilinpäätös on tilintarkastettu ja allekirjoitettu, se täytyy vahvistaa varsinaisessa yhtiökokouksessa. Kokous on pidettävä 6 kuukauden kuluessa tilikauden päättymisen jälkeen. Kokouksesta tehdään pöytäkirja ja se pitää allekirjoittaa.\n\nTämän jälkeen allekirjoitettu ja yhtiökokouksessa vahvistettu tilinpäätös pitää rekisteröidä. Toimittakaa minulle allekirjoitettu tilinpäätös, yhtiökokouksen pöytäkirja ja tilintarkastuskertomus niin teen rekisteröinnin puolestanne.',
        'email-generated-osingonjako': 'Tilinpäätös {{vuosi}} on nyt valmis ja löydät sen Lemonaidin selainversion Raportit-kohdasta.{{liitteetText}} Voit allekirjoittaa tilinpäätöksen Lemonaidissa. Huomaathan, että kaikkien hallituksen jäsenten tulee allekirjoittaa tilinpäätös omilla Lemonaid-tunnuksillaan.\n\nAllekirjoitettu tilinpäätös täytyy vahvistaa varsinaisessa yhtiökokouksessa. Kokous on pidettävä 6 kuukauden sisällä tilikauden päättymisestä. Kokouksesta tehdään pöytäkirja ja se pitää allekirjoittaa. Saat tehtyä pöytäkirjan ja allekirjoitettua sen helposti Lemonaidissa.\n\nTämän jälkeen allekirjoitettu ja yhtiökokouksessa vahvistettu tilinpäätös pitää rekisteröidä. Teen sen puolestasi heti kun yhtiökokouksen pöytäkirja on allekirjoitettu.\n\nSinulla on siis vielä neljä tehtävää tilinpäätöstä koskien:<b><ol><li>Käy läpi tilinpäätös</li><li>Allekirjoita tilinpäätös ja hallituksen pöytäkirja tilinpäätöksen hyväksymisestä</li><li>Pidä varsinainen yhtiökokous</li><li>Allekirjoita varsinaisen yhtiökokouksen pöytäkirja</li></ol></b>Tämän jälkeen lähetän tilinpäätöksen rekisteröitäväksi PRH:lle.\n\nTilinpäätökseen kirjattiin jaettavia osinkoja {{osingonjaonMaara}}€. Huomaathan, että osingot voidaan maksaa vasta kun yhtiökokouksen pöytäkirja on allekirjoitettu ja osinkojen ennakonpidätys laskettu. Kun olen saanut allekirjoitetun yhtiökokouksen pöytäkirjan, lähetän vielä erillisen sähköpostin osingon ja ennakonpidätyksen maksamisesta.',
        'input-end': 'Mikäli sinulla herää kysyttävää näihin asioihin liittyen, autan mielelläni.',
        'liitteet-text': ' Liitteenä {{liitteet}}.',
        'liitteet-text-tilintarkastetaan': ' Liitteenä {{liitteet}}.',
        'tuloslaskelma': 'pitkä tuloslaskelma',
        'tase-erittelyt': 'tase-erittelyt',
        'tilinpaatos': 'tilinpäätös',
        'tilinpaatos-to-besigned': 'allekirjoitettava tilinpäätös',
        'and': 'ja'
      },
      'kirjanpito-lahetys': {
        'tuloslaskelma': 'tuloslaskelma',
        'tase': 'tase',
        'paakirja': 'pääkirja',
        'alv-ilmoitus': 'ALV-ilmoitus',
        'selvitettavat': 'selvitettävät',
        'alv-verkkolasku-lahetetty': 'Maksettavasta arvonlisäverosta on lähetetty verkkolasku pankkiisi.',
        'alv-verkkolasku-lahetetty-payments': 'Maksettavasta arvonlisäverosta on lähetetty verkkolasku Lemonaidiin.',
        'teknisista-syista-erapaiva': 'Huomioithan, että teknisistä syistä eräpäivä laskulla on siirretty eteenpäin. Eräpäivä verottajalle ei silti muutu ja maksu on suoritettava verottajan määräämänä eräpäivänä.',

        'regular': {
          'input-start': 'Moikka',
          'input-end': 'Jos sinulle tulee mieleen kysymyksiä kirjanpitoon liittyen, autan mielelläni.',
          'liitteet-text': ', ohessa {{liitteet}}',

          'mail-text-monthly-alv-jakso': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Maksettavaa arvonlisäveroa kertyi {{alvSum}} euroa ja maksun eräpäivä on {{dueDate}}.',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Palautettavaa arvonlisäveroa kertyi {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron yrityksen tilille tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-not-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä maksettavaa arvonlisäveroa {{alvSum}} euroa. Lopullinen arvonlisäveron määrä määräytyy vasta kun koko alv-kauden kirjanpidot on tehty.',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä palautettavaa arvonlisäveroa {{alvSum}} euroa. Lopullinen arvonlisäveron määrä määräytyy vasta kun koko alv-kauden kirjanpidot on tehty.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta ei ole tähän mennessä kertynyt maksettavaa tai palautettavaa arvonlisäveroa. Lopullinen arvonlisäveron määrä määräytyy vasta kun koko alv-kauden kirjanpidot on tehty.',
          },
          'mail-text-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi maksettavaa arvonlisäveroa {{alvSum}} euroa ja maksun eräpäivä on {{dueDate}}.',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi palautettavaa arvonlisäveroa {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron yrityksen tilille tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-non-alv-client': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}.',

          'ei-selvitettavia': 'Hienoa, aineistossa oli kaikki kunnossa! 😊',

          'selvitettavat-ostot-tmi': 'Kirjanpidossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät ne Lemonaidista ja voit lisätä siellä kuhunkin tapahtumaan tositteita ja lisätietoja. Mikäli kyseessä on oma meno, merkitse se Lemonaidissa yksityisotoksi.',
          'selvitettavat-ostot-oy': 'Kirjanpidossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät nämä tapahtumat Lemonaidista. Lisääthän siellä kuhunkin tapahtumaan tositteet ja selvitykset viimeistään seuraavan kuun kirjanpidon aineiston toimituksen yhteydessä. Muistathan, että tilinpäätökseen ei voi jättää selvittämättömiä tapahtumia.',

          'selvitettavat-myynnit': 'Tiliotteella oli myyntitapahtumia, joihin en löytänyt tositteita (lista liitteenä). Näihin tarvitaan vielä tositteet tai selvitykset. Voit lisätä ne suoraan Lemonaidiin.',

          'selvitettavat-ostot-ja-myynnit-tmi': 'Kirjanpidossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät ne Lemonaidista ja voit lisätä siellä kuhunkin tapahtumaan tositteita ja lisätietoja. Mikäli kyseessä on oma meno, merkitse se Lemonaidissa yksityisotoksi.\n\nLisäksi tiliotteella oli myyntitapahtumia, joihin en löytänyt tositteita (lista liitteenä). Näihin tarvitaan vielä tositteet tai selvitykset. Voit lisätä ne suoraan Lemonaidiin.',
          'selvitettavat-ostot-ja-myynnit-oy': 'Kirjanpidossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät nämä tapahtumat Lemonaidista. Lisääthän siellä kuhunkin tapahtumaan tositteet ja selvitykset viimeistään seuraavan kuun kirjanpidon aineiston toimituksen yhteydessä. Muistathan, että tilinpäätökseen ei voi jättää selvittämättömiä tapahtumia.\n\nLisäksi tiliotteella oli myyntitapahtumia, joihin en löytänyt tositteita (lista liitteenä). Näihin tarvitaan vielä tositteet tai selvitykset. Voit lisätä ne suoraan Lemonaidiin.',
        },

        'holvi': {
          'input-start': 'Hei!',
          'input-end': 'Jos sinulla on kysyttävää kirjanpitoasi koskien, autamme mielellämme. Voit ottaa meihin yhteyttä Holvi Zen asiakaspalveluumme sivulla https://support.holvi.com/hc/fi/requests/new. Huomaathan, että et voi vastata tähän sähköpostiin.\n\nYstävällisin terveisin,',
          'liitteet-text': ', ohessa {{liitteet}}',

          'mail-text-monthly-alv-jakso': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Maksettavaa arvonlisäveroa kertyi {{alvSum}} euroa ja se menee maksuun Holvi-tililtäsi {{weekdayOfDueDate}}na {{dueDate}}. Varmistathan, että tililläsi on tuolloin riittävästi katetta. Jos maksua ei pystytä tekemään, sinun pitää hoitaa se itse. ',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Palautettavaa arvonlisäveroa kertyi {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron Holvi-tilillesi tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-not-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä maksettavaa arvonlisäveroa {{alvSum}} euroa.',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta on kertynyt tähän mennessä palautettavaa arvonlisäveroa {{alvSum}} euroa.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Kuluvalta alv-kaudelta ei ole tähän mennessä kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-alv-month': {
            'positive-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi maksettavaa arvonlisäveroa {{alvSum}} euroa ja se menee maksuun Holvi-tililtäsi {{weekdayOfDueDate}}na {{dueDate}}. Varmistathan, että tililläsi on tuolloin riittävästi katetta. Jos maksua ei pystytä tekemään, sinun pitää hoitaa se itse. ',
            'negative-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta kertyi palautettavaa arvonlisäveroa {{alvSum}} euroa. Verohallinto palauttaa arvonlisäveron Holvi-tilillesi tai se käytetään muiden verojesi maksuun.',
            'zero-alv': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}. Alv-kaudelta ei kertynyt maksettavaa tai palautettavaa arvonlisäveroa.',
          },
          'mail-text-non-alv-client': '{{kuukaudenNimi}}n kirjanpito on nyt tehty{{liitteetText}}.',
          'ei-selvitettavia': 'Aineistossa kaikki kunnossa! 😊',
          'selvitettavat-ostot': 'Aineistossa oli tapahtumia, joihin tarvitaan vielä lisätietoja. Löydät lisäselvitystä vaativat tapahtumat oheisesta listasta. Lisääthän selvitykset kuhunkin tositteeseen Holvi-tililläsi pikimmiten, jotta voimme huomioida ne seuraavan kirjanpidon laadinnan yhteydessä.\n\n',
          'selvitettavat-myynnit': 'Aineistossa oli myyntitapahtumia, joihin emme löytäneet tositteita tai tarvittavia lisätietoja. Löydät tapahtumat oheisesta listasta. Lisääthän tarvittavat tositteet tai lisätiedot kuhunkin tositteeseen Holvi-tililläsi viipymättä, jotta voimme huomioida ne seuraavan kirjanpidon laadinnan yhteydessä.\n\n'
        }
      },
      'lemonaid-user-terms-dialog': {
        'kayttoehdot': 'Käyttöehdot',
        'hyvaksy': 'Hyväksy',
        'kayttoehdot-ovat-muuttuneet': 'Käyttöehdot ovat muuttuneet',
        'sinun-pitaa-hyvaksya': 'Lemonaid-ohjelmiston käyttöehdot ovat muuttuneet ja sinun pitää hyväksyä ne ennen kuin voit jatkaa.',
        'kayttoehdot-hyvaksytty': 'Uudet käyttöehdot on nyt hyväksytty ja Lemonaid valmiina käyttöön.',
      },
      'lemonaid-sopimus-dialog': {
        'toimeksiantosopimus-nonbreak': 'Toimeksiantosopimus',
        'toimeksiantosopimus-break': 'Toimeksianto&shy;sopimus',
        'toimeksiantosopimuksen': 'Toimeksiantosopimuksen',
        'valmis-kaytettavaksi': 'Lemonaid-tunnuksesi on nyt aktivoitu.',
        'ennen-aloittamista': 'Ole hyvä ja käy läpi seuraavat vaiheet aloittaaksesi palvelun.',
        'palvelumme-sisaltaa': 'Palvelumme sisältää henkilökohtaisen kirjanpitäjän ja taloushallinnon työkalut kiinteällä kuukausihinnalla.',
        'lue-tarkempi-erittely': 'Lue tarkempi erittely palvelusta, sen sisällöstä ja rajauksista toimeksiantosopimuksesta.',
        'kuukausihinta-alkaessa': 'Kiinteä kuukausihinta palvelun alkaessa',
        'kuukaudessa': 'kk',
        'ensimmainen-kuukausi': 'Ensimmäinen kuukausi, jolta kuukausihinta veloitetaan',
        'aiempiin-laskutetaan-tuntiperusteisesti': 'Tätä aiempiin kuukausiin liittyvä kirjanpitotyö laskutetaan tuntiperusteisesti.',
        'lukenut-ja-hyvaksyn': 'Olen lukenut ja hyväksyn',
        'hyvaksy-required': 'Sopimus on hyväksyttävä ennen jatkamista',
        'palvelukuvaus': 'Palvelukuvaus',
        'yleiset-sopimusehdot': 'Yleiset sopimusehdot',
        'sopimus-henkilotietojen': 'Sopimus henkilötietojen käsittelystä',
        'hyvaksy-ja-jatka': 'Hyväksy ja jatka',
        'tunnistaminen': 'Tunnistaminen',
        'asiakkaan-tunnistaminen': 'Käyttäjän tunnistaminen',
        'rahanpesulaki-velvoittaa-tunnistamaan': 'Laki rahanpesun ja terrorismin rahoittamisen estämisestä velvoittaa meidät tunnistamaan asiakkaamme.',
        'rahanpesulaki-velvoittaa-keramaan': 'Laki rahanpesun ja terrorismin rahoittamisen estämisestä velvoittaa meidät keräämään tuntemistietoja toimeksiantajan yhtiöstä.',
        'anna-lisatiedot': 'Ole hyvä ja anna lisätiedot.',
        'yhtiosi-tuntemistiedot': 'Yhtiösi tuntemistiedot pitää päivittää. Ole hyvä ja anna ajantasaiset tuntemistiedot.',
        'tunnistaudu-pankkitunnuksilla': 'Ole hyvä ja tunnistaudu napauttamalla pankkisi kuvaketta.',
        'tunnistautuminen-onnistui': 'Käyttäjän vahva tunnistaminen onnistui!',
        'tunnistautuminen-epaonnistui': 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        'tunnistaudu': 'Tunnistaudu',
        'sopimus-hyvaksytty': 'Voit nyt aloittaa palvelun käytön.',
        'tuntemistiedot': 'Tuntemistiedot',
        'kyc': {
          'yhtion-tunteminen': 'Yhtiön tunteminen',
          'osakkaat': 'Osakkaat',
          'osakkeiden-lukumaara': 'Osakkeiden lukumäärä',
          'osakkeiden-lukumaara-puuttuu': 'Osakkaiden lukumäärä puuttuu.',
          'kansallisuus': 'Kansallisuus',
          'kansallisuus-required': 'Kansallisuus puuttuu',
          'pep': 'Poliittisesti vaikutusvaltainen henkilö',
          'kuka-on-pep': 'Kuka on poliittisesti vaikutusvaltainen henkilö?',
          'pep-required': 'Valinta puuttuu',

          'pep-miksi-kerataan': 'Miksi tietoja kerätään?',
          'pep-naita-tuntemistietoja': `Näitä tuntemistietoja ja muita henkilötietoja voidaan käyttää rahanpesun ja terrorismin rahoittamisen estämiseen, paljastamiseen ja selvittämiseen sekä rahanpesun ja terrorismin rahoittamisen ja sen rikoksen,
      jolla rahanpesun tai terrorismin rahoittamisen kohteena oleva omaisuus tai rikoshyöty on saatu, tutkintaan saattamista varten.`,
          'pep-teksti': `Poliittisesti vaikutusvaltainen henkilö (“PEP-henkilö“) on luonnollinen henkilö, joka toimii tai on toiminut viimeisen vuoden aikana merkittävässä julkisessa tehtävässä.<br/><br/>
      <b>Merkittävällä julkisella tehtävällä tarkoitetaan toimimista seuraavissa tehtävissä:</b>
      <br/><br/>
      - valtionpäämiehenä, hallituksen päämiehenä, ministerinä, vara- tai apulaisministerinä<br/>
      - parlamentin jäsenenä<br/>
      - poliittisten puolueiden johtoelinten jäsenenä<br/>
      - ylimpien tuomioistuinten, perustuslakituomioistuimen tai muun vastaavan oikeuselimen jäsenenä, jonka päätöksiin ei voida poikkeustapauksia lukuun ottamatta hakea muutosta<br/>
      - tilintarkastustuomioistuimen ja valtiontalouden tarkastusvirastoa vastaavan valtion varainhoitoa tarkastavan ylimmän päättävän elimen jäsenenä<br/>
      - keskuspankin johtokunnan jäsenenä<br/>
      - suurlähettiläänä tai asiainhoitajana<br/>
      - puolustusvoimissa vähintään kenraalikuntaan kuuluvana upseerina<br/>
      - valtion kokonaan omistaman yrityksen hallinto-, johto- ja valvontaelimen jäsenenä<br/>
                          - kansainvälisten yhteisöjen johtajana, varajohtajana ja hallituksen jäsenenä`,

          'kylla': 'Kyllä',
          'ei': 'Ei',
          'lisaa-osakas': 'Lisää osakas',
          'anna-osakkeiden-tiedot': 'Anna yhtiön osakkaiden tiedot ja omistusosuudet.',
          'hallitus-ja-toimitusjohtaja': 'Hallitus ja toimitusjohtaja',
          'hallituksen-puheenjohtaja': 'Hallituksen puheenjohtaja',
          'hallituksen-jasen': 'Hallituksen jäsen',
          'hallituksen-varajasen': 'Hallituksen varajäsen',
          'toimitusjohtaja': 'Toimitusjohtaja',
          'anna-paattajien-tiedot': 'Anna yhtiön päättäjien tiedot',
          'hallituksen-jasenen-tiedot': 'Anna yhtiön hallituksen jäsenten tiedot.',
          'vahintaan-yksi-hallituksen-jasenen': 'Ole hyvä ja lisää vähintään yksi hallituksen jäsen.',
          'vahintaan-yksi-osakas': 'Ole hyvä ja lisää vähintään yksi osakas.',
          'lisaa-jasen': 'Lisää jäsen',
          'edunsaajat': 'Tosiasialliset edunsaajat',
          'edunsaajien-tiedot': 'Anna muiden mahdollisten tosiasiallisten edunsaajien tiedot.',
          'lisaa-edunsaaja': 'Lisää edunsaaja',
          'osakas-on-luonnollinen': 'Osakas on luonnollinen henkilö',
          'osakas-on-yritys': 'Osakas on yritys',
          'ei-suomalaista-tunnusta': 'Ei suomalaista henkilö- tai y-tunnusta',
          'nimi': 'Nimi',
          'nimi-required': 'Nimi puuttuu',
          'hetu-ytunnus': 'Hetu / y-tunnus',
          'hetu-ytunnus-required': 'Henkilötunnus tai y-tunnus puuttuu',
          'hetu-ytunnus-invalid': 'Henkilötunnus tai y-tunnus on virheellinen',
          'hetu': 'Hetu',
          'hetu-required': 'Henkilötunnus puuttuu',
          'hetu-invalid': 'Henkilötunnus on virheellinen',
          'syntymaaika': 'Syntymäaika',
          'syntymaaika-required': 'Syntymäaika puuttuu',
          'rooli': 'Rooli',
          'rooli-required': 'Valitse rooli',
          'miksi-tietoja-kerataan': 'Miksi tietoja kerätään?',
          'edunsaajalla-tarkoitetaan': 'Tosiasiallisella edunsaajalla tarkoitetaan henkilöitä, jotka tietyn omistusosuuden nojalla voivat vaikuttaa yrityksen päätöksentekoon. Voimassa olevan lainsäädännön mukaisesti tosiasiallisia edunsaajia yrityksessä ovat a) Ne luonnolliset henkilöt, joilla on suoraan omistuksessaan tai hallinnassaan yli 25% osakkeista (pääomasta, osuuksista tai äänivallasta) tai b) Jotka muulla tavoin suoraan tai välillisesti yhden tai useamman yrityksen kautta hallitsevat yritystä. Välillinen hallinta muodostuu, mikäli sopimuksen tekevästä yrityksestä omistaa yli 25% toinen yritys, jonka enemmistöomistajana on luonnollinen henkilö, jolla on omistuksessaan tai hallinnassaan yli 50% kyseisen yrityksen osakkeista (pääomasta, osuuksista tai äänivallasta).',
          'naita-tuntemistietoja': 'Näitä tuntemistietoja ja muita henkilötietoja voidaan käyttää rahanpesun ja terrorismin rahoittamisen estämiseen, paljastamiseen ja selvittämiseen sekä rahanpesun ja terrorismin rahoittamisen ja sen rikoksen, jolla rahanpesun tai terrorismin rahoittamisen kohteena oleva omaisuus tai rikoshyöty on saatu, tutkintaan saattamista varten.',
          'jos-muutoksia': 'Jos näihin tuntemistietoihin tulee muutoksia, on niistä viivytyksettä ilmoitettava Tilitoimisto Lemon Tree Oy:lle.',
          'oma-ytunnus': 'Tunnus ei voi olla yrityksen oma y-tunnus'
        }
      },
      'lemonaid-uusi-sopimus': {
        'uusi-toimeksiantosopimus': 'Uusi toimeksiantosopimus',
        'uusi-toimeksiantosopimus-break': 'Uusi toimeksianto&shy;sopimus',
        'toimeksiantosopimus-paivittyy': 'Toimeksiantosopimus päivittyy. Uusi sopimus pitää hyväksyä {{loppupaiva}} mennessä.',
        'uudessa-sopimuksessa-palvelun-sisalto': 'Uudessa sopimuksessa palvelun sisältö on määritelty tarkemmin.',
        'kuukausihintanne-ei-muuttuu': 'Kiinteä kuukausihintanne ei muutu.',
        'jos-sopimusta-ei-hyvaksyta': 'Jos sopimusta ei hyväksytä, nykyinen toimeksianto päättyy {{loppupaiva}}.',
        'uuden-sopimuksen-kuukausihinta': 'Uuden sopimuksen kiinteä kuukausihinta on',
        'alv': 'ALV',
        'myohemmin': 'Myöhemmin',
        'hyvaksy': 'Hyväksy',
        'hyvaksy-ja-jatka': 'Hyväksy ja jatka',
        'toimeksiantosopimus-hyvaksytty': 'Toimeksiantosopimus hyväksytty',
        'uusi-toimeksiantosopimus-nyt-hyvaksytty': 'Uusi toimeksiantosopimus on nyt hyväksytty ja voit jatkaa palvelun käyttöä.'
      },
      'sopimus-tauolla': {
        'tauko': 'Tauko',
        'sopimuksesi-on-tauolla': 'Sopimuksesi on tauolla, etkä voi sen aikana käyttää kaikkia Lemonaidin ominaisuuksia. Voit jatkaa sopimusta olemalla yhteydessä kirjanpitäjääsi. Painamalla nappia ilmoitat kirjanpitäjällesi halusta jatkaa sopimusta ja kirjanpitäjäsi ottaa sinuun yhteyttä.',
        'lopeta-tauko': 'Haluan jatkaa sopimusta',
        'kiitos-yhteydenotostasi': 'Kiitos yhteydenotostasi',
        'kirjanpitajan-tiedotus': 'Tieto on välitetty kirjanpitäjällesi. Hän on pian sinuun yhteydessä.'
      },
      'magic-link': {
        'expired-ajopaivakirja-link-regular': 'Veroilmoitus on lähetetty ja lomaketta ei voi enää täyttää. Jos lomakkeelle tulee tehdä muutoksia, ole hyvä ja ota yhteys kirjanpitäjääsi.',
        'expired-ajopaivakirja-link-holvi': 'Veroilmoitus on lähetetty ja lomaketta ei voi enää täyttää. Jos lomakkeelle tulee tehdä muutoksia, ole hyvä ja ota yhteys Holvi Zen helpdesk:iin.',
        'expired-link': 'Käyttämäsi linkki on vanhentunut ja turvallisuussyistä sitä ei voi enää käyttää. Ilmoitathan meille asiasta niin lähetämme uuden linkin.',
        'used-link': 'Käyttämäsi linkki on avattu jo aiemmin ja turvallisuussyistä sitä ei voi enää käyttää. Kirjaudu sisään painamalla alla olevaa nappulaa.'
      },
      'new-user-welcome': {
        'title': 'Lemonaidin käyttöönotto',
        'start-text': 'Ole hyvä ja syötä puuttuvat tiedot',
        'etunimi': 'Etunimi',
        'sukunimi': 'Sukunimi',
        'approve-user-terms-start': 'Hyväksyn ',
        'approve-user-terms-link': 'käyttöehdot',
        'approve-user-terms-error': 'Käyttöehdot on hyväksyttävä ennen jatkamista',
        'create-account': 'Luo tili',
      },
      'ajopaivakirja': {
        'field-error': 'Ole hyvä ja täytä tämä kenttä',
        'auton-kaytto-tyoajoissa-too-large-error': 'Työajojen määrä ei voi olla isompi kuin kokonaiskilometrimäärä',
        'kirjanpidon-ulkopuoliset-vahennykset': 'Kirjanpidon ulkopuoliset vähennykset',
        'toiminimen-veroilmoituksella-voidaan-vahentaa': `Toiminimen veroilmoituksella voidaan vähentää kirjanpidon ulkopuolisia kuluja. Täytä tiedot tähän lomakkeeseen niin siirrämme ne
    veroilmoituksellesi. Lomake tulee lähettää viimeistään {{loppupaiva}}, jotta tiedot ehtivät varmuudella veroilmoitukselle.
        Mikäli sinulla on kysymyksiä vähennyksiin tai lomakkeeseen liittyen voit ottaa yhteyttä asiakaspalveluun tai kirjanpitäjääsi.`,
        'yritys': 'Yritys',
        'verovuosi': 'Verovuosi',
        'oma-ajoneuvon-kaytto': 'Oma ajoneuvon käyttö elinkeinotoiminnassa',
        'auton-kayton-km-maara': 'Auton käytön kokonaiskilometrimäärä',
        'auton-kaytto-tyoajoissa': 'Auton käyttö työajoissa',
        'onko-autoa-kaytetty-tyoajoissa': 'Onko autoa käytetty työajoissa',
        'alle-50': 'Alle 50 %:ia',
        'yli-50': 'Yli 50%:ia',
        'jos-autoa-kaytetty-alle-50': 'Jos autoa on käytetty alle 50 %:ia työajoissa, tehdään veroilmoituksella lisävähennys 0,43 €/km korvauksena auton käytön kustannuksista.',
        'jos-autoa-kaytetty-yli-50': 'Jos autoa on käytetty yli 50 %:ia työajoissa, katsotaan auton kuuluvat yrityksen varoihin ja silloin kaikki autokulut kirjataan kirjanpitoon. Veroilmoituksella yksityiskäytön osuus huomioidaan vähennettävissä kuluissa.',
        'auton-tiedot-perustuvat': 'Auton käyttöselvityksen tiedot perustuvat',
        'ajopaivakirjaan': 'ajopäiväkirjaan',
        'muuhun-selvitykseen': 'muuhun selvitykseen',
        'yritaja-sailyttaa-itsellaan': 'Yrittäjä säilyttää ajopäiväkirjan / muun selvityksen ajoista itsellään.',
        'tyomatkat': 'Työmatkat',
        'yli-10h-lukumaara': 'Yli 10h kestäneiden kotimaan matkojen lukumäärä',
        'yli-6h-lukumaara': 'Yli 6h kestäneiden kotimaan matkojen lukumäärä',
        'vuoden-paivaraha': 'Vuonna 2019 kokopäiväraha on 42 € ja puolipäiväraha 19 €.',
        'ulkomaan-lukumaara': 'Ulkomaan matkapäivien lukumäärä (kpl)',
        'ulkomaan-paivarahojen-yhteismaara': 'Ulkomaan päivärahojen yhteismäärä (€)',
        'laske-kaikkien-paivarahojen': 'Laske kaikkien ulkomaan päivärahojen summat yhteen ja syötä kokonaissumma yllä olevaan kenttään.',
        'tyohuonevahennys': 'Työhuonevähennys',
        'ei-tyohuonevahennysta': 'Ei työhuonevähennystä',
        'kotia-paasaantoisesti': 'Kotia on käytetty pääsääntöisesti yrityksen työtilana.',
        'kotia-osa-aikaisesti': 'Kotia on käytetty osa-aikaisesti yrityksen työtilana.',
        'kotia-satunnaisesti': 'Kotia on käytetty satunnaisesti yrityksen työtilana.',
        'vakutaan-etta-antamani tiedot': 'Vakuutan, että antamani tiedot ovat oikein ja vähennettävät kulut aihellisia',
        'sitoidun-sailyttamaan': 'Sitoidun säilyttämään tähän lomakkeseen liittyvät asiakirjat vähintään 6 vuotta (esim. ajopäiväkirja)',
        'submit-successful': 'Lomake on nyt lähetetty ja siirrämme tiedot veroilmoituksellesi.',
        'checkboxes-required': 'Hyväksy ehdot ennen jatkamista',
      },
      'ulkopuoliset-kulut': {
        'field-error': 'Ole hyvä ja täytä tämä kenttä',
        'auton-kaytto-tyoajoissa-too-large-error': 'Työajojen määrä ei voi olla isompi kuin kokonaiskilometrimäärä',
        'kirjanpidon-ulkopuoliset-vahennykset': 'Kirjanpidon ulkopuoliset vähennykset',

        'voidaan-vahentaa-kuluja': 'Toiminimen verotuksessa voidaan vähentää kirjanpidon ulkopuolisia kuluja. Täytä tiedot kuluista tälle lomakkeelle niin siirrämme ne veroilmoituksellesi.',
        'tulee-lahettaa': {
          'holvi': {
            'yearly': 'Lomake tulee lähettää viimeistään {{loppupaiva}}.\nHuomioitavaa: Täytä tiedot koko vuoden osalta myös työajojen osalta, vaikka olisit ilmoittanut tiedot aikaisemmin ajalta 1.1.-30.9.{{year}}}.',
            'half-year': ' Lomake tulee lähettää viimeistään {{loppupaiva}}. Ilmoitathan myös, mikäli sinulla ei ole elinkeinotoiminnan ajoja v. {{year}}. Ilmoita toteutuneet ajokilometrit työ- ja yksityisajojen osalta 1.1.-30.6.{{year}}.',
          },
          'regular': 'Toiminimen verotuksessa voidaan vähentää kirjanpidon ulkopuolisia kuluja. Täytä tiedot kuluista tälle lomakkeelle niin siirrämme ne veroilmoituksellesi. Lomake tulee lähettää viimeistään {{loppupaiva}}, jotta tiedot ehtivät varmuudella veroilmoituksellesi. Mikäli sinulla on kysymyksiä vähennyksiin tai lomakkeeseen liittyen voit ottaa yhteyttä kirjanpitäjääsi.'
        },
        'yritys': 'Yritys',
        'verovuosi': 'Verovuosi',
        'ei-ilmoitettavaa': 'Ei ilmoitettavaa',
        'ajoneuvon-kaytto': 'Ajoneuvon käyttö elinkeinotoiminnassa',
        'ajoneuvot': 'Ajoneuvot',
        'rekisteritunnus': 'Rekisteritunnus',
        'ajoneuvotyyppi': 'Ajoneuvotyyppi',
        'henkiloauto': 'Henkilöauto',
        'pakettiauto': 'Pakettiauto',
        'muu-ajoneuvo': 'Muu ajoneuvo',
        'ajettu-yhteensa': 'Ajettu vuoden aikana yhteensä (km)',
        'ajettu-tyoajoa': 'Ajettu työajoa (km)',
        'lisaa-kaikki-ajoneuvot-joita': 'Lisää kaikki ajoneuvot, joita olet käyttänyt tilikauden aikana yritystoiminnassasi. Syötä “Ajettu vuoden aikana yhteensä (km)” -kenttään kaikkien ajoneuvolla tilikauden aikana tehtyjen ajojen yhteissumma. Syötä “Ajettu työajoa (km)” -kenttään yritystoimintaan liittyvien ajojen yhteissumma.',
        'lisaa-ajoneuvo': 'Lisää ajoneuvo',
        'onko-autoa-kaytetty-tyoajoissa': 'Onko autoa käytetty työajoissa',
        'alle-50': 'Alle 50 %:ia',
        'yli-50': 'Yli 50%:ia',
        'jos-autoa-kaytetty-alle-50': 'Jos autoa on käytetty alle 50 %:ia työajoissa, tehdään veroilmoituksella lisävähennys {{kilometrikorvaus}} €/km korvauksena auton käytön kustannuksista.',
        'jos-autoa-kaytetty-yli-50': 'Jos autoa on käytetty yli 50 %:ia työajoissa, katsotaan auton kuuluvat yrityksen varoihin ja silloin kaikki autokulut kirjataan kirjanpitoon. Veroilmoituksella yksityiskäytön osuus huomioidaan vähennettävissä kuluissa.',
        'auton-tiedot-perustuvat': 'Auton käyttöselvityksen tiedot perustuvat',
        'ajopaivakirjaan': 'ajopäiväkirjaan',
        'muuhun-selvitykseen': 'muuhun selvitykseen',
        'yritaja-sailyttaa-itsellaan': 'Yrittäjä säilyttää ajopäiväkirjan / muun selvityksen ajoista itsellään.',
        'tyomatkat': 'Työmatkat',
        'kotimaan-tyomatkat': 'Kotimaan työmatkat',
        'olet-oikeutettu-paivarahavahennykseen': 'Olet oikeutettu päivärahavähennykseen, jos työmatkasi suuntautuu vähintään 15 kilometrin etäisyydelle kotoa tai työpaikalta ja matka kestää yli kuusi tuntia. 6-10 tunnin matkasta voidaan tehdä verotuksessa {{osapaivaraha}} euron vähennys (osapäiväraha) ja yli 10 tunnin matkasta {{kokopaivaraha}} euron vähennys (kokopäiväraha). Syötä alla oleviin kenttiin tilikauden aikana tekemiesi työmatkojen lukumäärät.',
        'yli-10h-lukumaara': '(yli 10 tuntia kestävien työmatkojen matkapäivien lukumäärä)',
        'kokopaivarahojen-lukumaara': 'Kokopäivärahojen lukumäärä',
        'yli-6h-lukumaara': '(yli 6 tuntia kestävien työmatkojen matkapäivien lukumäärä)',
        'osapaivarahojen-lukumaara': 'Osapäivärahojen lukumäärä',
        'ulkomaan-tyomatkat': 'Ulkomaan työmatkat',
        'ulkomaan-lukumaara': 'Ulkomaan matkapäivien lukumäärä (kpl)',
        'ulkomaan-paivarahojen-yhteismaara': 'Ulkomaan päivärahojen yhteismäärä (€)',
        'laske-ulkomaan-paivarahojen': 'Laske kaikkien ulkomaan päivärahojen summat yhteen ja syötä kokonaissumma tähän kenttään.',
        'maakohtaised-paivarahat': 'Maakohtaiset päivärahat voit tarkistaa täältä.',
        'laske-kaikkien-paivarahojen': 'Laske kaikkien ulkomaan päivärahojen summat yhteen ja syötä kokonaissumma yllä olevaan kenttään.',
        'tyohuonevahennys': 'Työhuonevähennys',
        'ei-tyohuonevahennysta': 'Ei työhuonevähennystä',
        'kotia-paasaantoisesti': 'Kotia on käytetty pääsääntöisesti yrityksen työtilana (vähennys {{vahennys}} €/ v.)',
        'kotia-osa-aikaisesti': 'Kotia on käytetty osa-aikaisesti yrityksen työtilana (vähennys {{vahennys}} €/ v.)',
        'kotia-satunnaisesti': 'Kotia on käytetty satunnaisesti yrityksen työtilana (vähennys {{vahennys}} €/ v.)',
        'vakutaan-etta-antamani tiedot': 'Vakuutan, että antamani tiedot ovat oikein ja vähennettävät kulut aihellisia',
        'sitoidun-sailyttamaan': 'Sitoidun säilyttämään tähän lomakkeseen liittyvät asiakirjat vähintään 6 vuotta (esim. ajopäiväkirja)',
        'submit-successful': 'Lomake on nyt lähetetty ja siirrämme tiedot veroilmoituksellesi.',
        'checkboxes-required': 'Hyväksy ehdot ennen jatkamista'
      },
      'general-bank-selection': {
        'pankkiyhteysvaltuutus': 'Pankkiyhteysvaltuutus',
        'yksityistili': 'Yksityistili',
        'yritystili': 'Yritystili',
        'corporate': 'Corporate',
        'palvelun-kaytto-edellyttaa': 'Palvelun käyttö edellyttää yrityksen pankkitilin liittämistä Lemonaidiin pankkiyhteysvaltuutuksen avulla. Valtuutuksen avulla haemme yrityksesi pankkitilin tapahtumat kirjanpitoon.\n\nOle hyvä ja anna valtuutus yrityksesi pankkitilille valitsemalla pankkisi alta. Valtuutuksen antaminen vie vain muutaman minuutin. Tarvitset valtuutuksen antamiseen pankkitunnuksiasi.',
        'valittu-pankki': 'Valittu pankki',
        'valitse-tilin-tyyppi': 'Valitse yrityksesi käytössä olevan tilin tyyppi.',
      },
      'viia-login-required': {
        'ei-tileja': {
          'yhdista-yrityksesi-pankkitili': 'Yhdistä yrityksesi pankkitili Lemonaidiin',
          'palvelun-kayto-edellyttaa': 'Palvelun käyttö edellyttää yrityksen pankkitilin liittämistä Lemonaidiin pankkiyhteysvaltuutuksen avulla. Kun valtuutus on annettu, pystymme hakemaan yrityksesi pankkitilin tapahtumat järjestelmäämme.\n\nValtuutuksen antaminen vie vain muutaman minuutin ja se on voimassa 180 päivää kerrallaan.\n\nOle hyvä ja anna valtuutus yrityksesi pankkitilille painamalla jatka. Tarvitset valtuutuksen antamiseen pankkitunnuksiasi.'
        },
        'on-tileja': {
          'yhteys-vanhenemassa': 'Pankkiyhteys&shyvaltuutus on vanhenemassa',
          'yhteys-ei-ole-aktiivinen': 'Pankkiyhteysvaltuutuksesi ei ole aktiivinen tai se on vanhenemassa.',
          'valtuutus-kunnossa': 'Tilien valtuutus kunnossa:\n',
          'valtuutus-puuttuu': 'Valtuutus puuttuu tileiltä:\n',
          'valtuutuksen-antaminen': '\n\nValtuutuksen antaminen vie vain muutaman minuutin ja se on voimassa 180 päivää kerrallaan. Tarvitset valtuutuksen antamiseen pankkitunnuksiasi.',

        },
        'login-ok': {
          'yhdistetty-onnistuneesti': 'Tili yhdistetty onnistuneesti!',
          'valtuutus-on-voimassa': 'Pankkiyhteysvaltuutus on voimassa enintään 180 päivää kerrallaan. Pyydämme uutta valtuutusta kun nykyinen valtuutus on päättymässä.',
        },
        'login-failed': {
          'virhe': 'Virhe',
          'yhdistely-epaonnistui': 'Pankkiyhteyden luominen epäonnistui tai keskeytettiin.',
        },
        'lue-lisaa': 'Lue lisää pankkiyhteysvaltuutuksesta',
        'valtuutus-pitka-selitus': `<b>Mikä pankkiyhteysvaltuutus on?</b>\n\nYrityksesi pankkitilin ja Lemonaidin välille voidaan luoda yhteys, jota pitkin tilitapahtumat liikkuvat. Yhteyden luomiseen tarvitaan pankkitilin omistajan antama pankkiyhteysvaltuutus.\n\n
        <b>Mihin pankkiyhteysvaltuutusta tarvitaan?</b>\n
        Pankkiyhteysvaltuutuksen avulla haemme yrityksesi pankkitilien tilitapahtumat järjestelmäämme. Tilitapahtumat toimivat kirjanpidon perustana ja niiden avulla pystymme merkitsemään lähettämäsi myyntilaskut automaattisesti maksetuiksi.\n\n
        <b>Miten valtuutus annetaan?</b>\n
        Valtuutuksen antaminen vie vain muutaman minuutin. Kun valtuutusta tarvitaan, näet Lemonaidissa tämän ponnahdusikkunan. Klikkaamalla ”jatka”, pääset antamaan valtuutuksen. Sinut ohjataan Aiia-palveluun, jossa sinut tunnistetaan sähköpostivarmistuksella. Tämän jälkeen valitse listasta pankki, missä yrityksesi tili on ja tunnistaudu verkkopankkitunnuksillasi. Tunnistuksen jälkeen valitse listasta yrityksesi tili ja jatka valtuutuksen antaminen loppuun.\n\n
        <b>Mihin tiliin annan valtuutuksen?</b>\n
        Tarvitsemme valtuutuksen yrityksesi pankkitilille. Jos yritykselläsi ei ole virallista yritystiliä, valitse se tili mihin yrityksen myynnit maksetaan. Jos yrityksesi käytössä on useampia tilejä, anna valtuutus niihin kaikkiin. Huomaa, että yrityksen tileillä saa olla lähtökohtaisesti vain yrityksen tilitapahtumia, älä siis käytä yritystiliä omien ostojen tekemiseen.\n\n
        <b>Kuinka kauan valtuutus on voimassa?</b>\n
        Valtuutus on oletuksena voimassa 180 päivää kerrallaan. Joissain tapauksissa valtuutus voi olla voimassa lyhyemmän ajan. Kun valtuutuksen voimassaoloaika on päättymässä, pyydämme sinua antamaan valtuutuksen uudestaan. Voimassaoloajasta riippumatta voit päättää valtuutuksen koska tahansa.\n\n
        <b>Onko yhteys turvallinen?</b>\n
        Käytämme pankkiyhteyden luomiseen EU-direktiiviin perustuvaa PSD2-rajapintaa. Rajapinnan palveluntarjoaja on Tanskalainen Aiia A/S, joka on Tanskan Finanssivalvonnan lisensoima maksutoimeksianto- ja tilitietopalvelun tarjoaja. PSD2-rajapinta on turvallinen, luotettava ja standardoitu kanava, jossa kaikki liikkuva data on päästä-päähän salattua.\n\n
        <b> Mitä valtuutus maksaa?</b>\n
        PSD2-maksupalveludirektiivin mukaan pankki ei voi periä PSD2-rajapinnan käytöstä maksuja. Näin ollen valtuutuksen antamisesta tai pankkiyhteyden käyttämisestä ei pitäisi syntyä yrityksellesi kuluja.`
      },
      'holvi-oauth': {
        'pankkiyhteysvaltuutus': 'Pankkiyhteysvaltuutus',
        'selitys': 'Haemme tilitapahtumat ja tositteet automaattisesti Holvista ja tarvitsemme siihen suostumuksesi.\n\nValtuuden antaminen tapahtuu Holvin käyttöliittymässä omilla pankkitunnuksillasi Holviin.\n\nValtuus ei anna Tilitoimisto Lemon Treelle oikeutta käyttää tiliäsi tai suorittaa maksuja, voimme ainoastaan lukea tietoja, jotka Holvista löytyvät.',
        'completed': {
          'valtuutus-onnistui': 'Valtuutus onnistui!',
          'valtuutus-annettiin-onnistuneesti': 'Valtuutus annettiin onnistuneesti. Pyydämme tarvittaessa valtuutuksen uudelleen automaattisesti. ',
        },
        'failed': {
          'virhe': 'Virhe',
          'yhdistely-epaonnistui': 'Pankkiyhteyden luominen epäonnistui tai keskeytettiin.',
        },
        'lue-lisaa': 'Lue lisää pankkiyhteysvaltuutuksesta',
        'valtuutus-pitka-selitus': `<b>Mikä pankkiyhteysvaltuutus on?</b>\n\nYrityksesi pankkitilin ja Lemonaidin välille luodaan yhteys, jota pitkin tilitapahtumat liikkuvat. Yhteyden luomiseen tarvitaan pankkitilin omistajan antama pankkiyhteysvaltuutus.\n\n
                                    <b>Mihin pankkiyhteysvaltuutusta tarvitaan?</b>\n
                                    Pankkiyhteysvaltuutuksen avulla haemme yrityksesi pankkitilien tilitapahtumat järjestelmäämme. Tilitapahtumat toimivat kirjanpidon perustana ja niiden avulla pystymme merkitsemään lähettämäsi myyntilaskut automaattisesti maksetuiksi.\n\n
                                    <b>Miten valtuutus annetaan?</b>\n
                                    Valtuutuksen antaminen vie vain muutaman minuutin. Kun valtuutusta tarvitaan, näet Lemonaidissa tämän ponnahdusikkunan. Klikkaamalla ”jatka”, pääset antamaan valtuutuksen. Sinut ohjataan Holviin, jossa sinut tunnistetaan pankkitunnuksilla. Vie valtuutuksen antaminen loppuun Holvissa ja palaa Lemonaidiin.\n\n
                                    <b>Mihin tiliin annan valtuutuksen?</b>\n
                                    Tarvitsemme valtuutuksen yrityksesi pankkitilille. Jos yritykselläsi ei ole virallista yritystiliä, valitse se tili mihin yrityksen myynnit maksetaan. Jos yrityksesi käytössä on useampia tilejä, anna valtuutus niihin kaikkiin. Huomaa, että yrityksen tileillä saa olla lähtökohtaisesti vain yrityksen tilitapahtumia, älä siis käytä yritystiliä omien ostojen tekemiseen.\n\n
                                    <b>Kuinka kauan valtuutus on voimassa?</b>\n
                                    Voit päättää valtuutuksen milloin tahansa kirjautumalla Holviin ja poistamalla valtuutuksen.\n\n
                                    <b>Mitä valtuutus maksaa?</b>\n
                                    Käyttämämme pankkiyhteysteknologia on sinulle täysin ilmaista. Valtuutuksen antamisesta tai pankkiyhteyden käyttämisestä ei synny yrityksellesi kuluja.\n\n
                                    <b>Onko yhteys turvallinen?</b>\n
                                    Käytämme pankkiyhteyden luomiseen Holvi-pankin suosittelemaa menetelmää. Menetelmä on turvallinen, luotettava ja standardoitu kanava, jossa kaikki liikkuva data on päästä-päähän salattua.`
      },
      'raportit': {
        'tuloslaskelma': 'Tuloslaskelma',
        'tulos': 'Tulos',
        'tase': 'Tase',
        'tulos-ja-tase': 'Tulos ja tase',
        'tase-ja-tulos': 'Tase ja Tulos',
        'alv-laskelma': {
          'alv-laskelma': 'ALV-laskelma',
          'verollinen': 'Verollinen',
          'veroton': 'Veroton',
          'vero': 'Vero',
          'myynnit': 'Myynnit',
          'ostot': 'Ostot',
        },
        'paakirja': 'Pääkirja',
        'paivakirja': 'Päiväkirja',
        'alv-ilmoitus': {
          'alv-ilmoitus': 'Arvonlisäveroilmoitus',
          'lahetetty-verohallinnolle': 'Lähetetty verohallinnolle',
          'esikatseluversio': 'Esikatseluversio - EI lähetetty verohallinnolle',
          'vero-kotimaan-myynnista-verokannoittain': 'Vero kotimaan myynnistä verokannoittain',
          '255-vero': '25,5 %:n vero',
          '24-vero': '24 %:n vero',
          '14-vero': '14 %:n vero',
          '10-vero': '10 %:n vero',

          'vero-ostoista-ja-maahantuonneista': 'Vero ostoista ja maahantuonneista',
          'vero-tavaraostoista-muista-eu-maista': 'Vero tavaraostoista muista EU-maista',
          'vero-palveluostoista-muista-eu-maista': 'Vero palveluostoista muista EU-maista',
          'vero-tavaroiden-maahantuonneista-eun-ulkopuolelta': 'Vero tavaroiden maahantuonneista EU:n ulkopuolelta',
          'vero-rakentamispalvelun-ja-metalliromun-ostoista': 'Vero rakentamispalvelun ja metalliromun ostoista',

          'vahennettava-vero': 'Vähennettävä vero',
          'verokauden-vahennettava-vero': 'Verokauden vähennettävä vero',
          'alarajahuojennus': 'Alarajahuojennus',
          'alarajahuojennukseen-oikeuttava-liikevaihto': 'Alarajahuojennukseen oikeuttava liikevaihto',
          'alarajahuojennukseen-oikeuttava-vero': 'Alarajahuojennukseen oikeuttava vero',
          'alarajahuojennuksen-maara': 'Alarajahuojennuksen määrä',
          'maksettava-vero-palautukseen-oikeuttava-vero': 'Maksettava vero / palautukseen oikeuttava vero',

          'myynnit-ostot-ja-maahantuonnit': 'Myynnit, ostot ja maahantuonnit',
          '0-verokannan-alainen-liikevaihto': '0-verokannan alainen liikevaihto',
          'tavaroiden-myynnit-muihin-eu-maihin': 'Tavaroiden myynnit muihin EU-maihin',
          'palveluiden-myynnit-muihin-eu-maihin': 'Palveluiden myynnit muihin EU-maihin',
          'tavaraostot-muista-eu-maista': 'Tavaraostot muista EU-maista',
          'palveluostot-muista-eu-maista': 'Palveluostot muista EU-maista',
          'tavaroiden-maahantuonnit-eun-ulkopuolelta': 'Tavaroiden maahantuonnit EU:n ulkopuolelta',
          'rakentamispalvelun-ja-metalliromun-myynnit': 'Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)',
          'rakentamispalvelun-ja-metalliromun-ostot': 'Rakentamispalvelun ja metalliromun ostot',

          'yhteyshenkilo': 'Yhteyshenkilö',
          'nimi': 'Nimi',
          'puhelinnumero': 'Puhelinnumero',
          'maksutiedot': 'Maksutiedot',
          'saaja': 'Saaja',
          'verohallinto': 'Verohallinto',
          'viitenumero': 'Viitenumero',
          'ei-viitenumeroa': 'Suomi.fi -valtuutus puuttuu. Tarkista viitenumero OmaVerosta.',
          'erapaiva': 'Eräpäivä',
          'summa': 'Summa',
          'tilinumerot': 'Tilinumerot',
          'pankki': 'Pankki'
        },
        'veroilmoitus': 'Veroilmoitus',
        'selvitettavat-ostot': 'Selvitettävät ostot',
        'selvitettavat-myynnit': 'Selvitettävät myynnit',
        'tase-erittely': 'Tase-erittely',
        'tilintarkastuskertomus': 'Tilintarkastuskertomus',
        'tiliote': {
          'tiliote': 'Tiliote',
          'arkistointitunnus-tili-bic': 'Arkistointitunnus\nSaajan tilinumero/BIC\n',
          'arvopaiva': 'Arvopäivä',
          'selitys': 'Selitys',
          'tap-nro': 'Tap. nro',
          'maara': 'Määrä',
          'viitenumero': 'Viitenumero'
        },
        'tilinpaatos': 'Tilinpäätös',
        'liitetiedot': 'Liitetiedot',
        'yhtiokokouksen-poytakirja': 'Yhtiökokouksen pöytäkirja',
        'brutto-tulos': 'Bruttotulos',
        'brutto-tuloslaskelma': 'Bruttotuloslaskelma',
        'brutto-tuloslaskelma-lyhyt': 'Bruttotuloslaskelma',
        'lyhyt-tase-ja-tulos': 'Lyhyt tase ja tulos',
        'virallinen-tase': 'Virallinen tase',
        'virallinen-tulos': 'Virallinen tuloslaskelma',
        'virallinen-tulos-ja-tase': 'Virallinen tuloslaskelma ja tase',
        'virallinen-tase-ja-tulos': 'Virallinen tase ja tuloslaskelma',
        'virallinen': {
          'vastaavaa': 'V a s t a a v a a',
          'pysyvat-vastaavat': 'PYSYVÄT VASTAAVAT',
          'aineettomat-hyodykkeet': 'Aineettomat hyödykkeet',
          'kehittamismenot': 'Kehittämismenot',
          'aineettomat-oikeudet': 'Aineettomat oikeudet',
          'liikearvo': 'Liikearvo',
          'muut-aineettomat-hyodykkeet': 'Muut aineettomat hyödykkeet',
          'ennakkomaksut': 'Ennakkomaksut',
          'aineettomat-hyodykkeet-yhteensa': 'Aineettomat hyödykkeet yhteensä',
          'aineelliset-hyodykkeet': 'Aineelliset hyödykkeet',
          'maa-ja-vesialueet': 'Maa- ja vesialueet',
          'rakennukset-ja-rakennelmat': 'Rakennukset ja rakennelmat',
          'koneet-ja-kalusto': 'Koneet ja kalusto',
          'muut-aineelliset-hyodykkeet': 'Muut aineelliset hyödykkeet',
          'ennakkomaksut-ja-keskeneraiset-hankinnat': 'Ennakkomaksut ja keskeneräiset hankinnat',
          'aineelliset-hyodykkeet-yhteensa': 'Aineelliset hyödykkeet yhteensä',
          'sijoitukset': 'Sijoitukset',
          'osuudet-saman-konsernin-yrityksissa': 'Osuudet saman konsernin yrityksissä',
          'saamiset-saman-konsernin-yrityksilta': 'Saamiset saman konsernin yrityksiltä',
          'osuudet-omistusyhteysyrityksissa': 'Osuudet omistusyhteysyrityksissä',
          'saamiset-omistusyhteysyrityksilta': 'Saamiset omistusyhteysyrityksiltä',
          'muut-osakkeet-ja-osuudet': 'Muut osakkeet ja osuudet',
          'muut-saamiset': 'Muut saamiset',
          'sijoitukset-yhteensa': 'Sijoitukset yhteensä',
          'pysyvat-vastaavat-yhteensa': 'PYSYVÄT VASTAAVAT YHTEENSÄ',
          'vaihtuvat-vastaavat': 'VAIHTUVAT VASTAAVAT',
          'vaihto-omaisuus': 'Vaihto-omaisuus',
          'aineet-ja-tarvikkeet': 'Aineet ja tarvikkeet',
          'keskeneraiset-tuotteet': 'Keskeneräiset tuotteet',
          'valmiit-tuotteet-tavarat': 'Valmiit tuotteet/tavarat',
          'muu-vaihto-omaisuus': 'Muu vaihto-omaisuus',
          'vaihto-omaisuus-yhteensa': 'Vaihto-omaisuus yhteensä',
          'pitkaaikaiset-saamiset': 'Pitkäaikaiset saamiset',
          'myyntisaamiset': 'Myyntisaamiset',
          'lainasaamiset': 'Lainasaamiset',
          'maksamattomat-osakkeet-osuudet': 'Maksamattomat osakkeet/osuudet',
          'siirtosaamiset': 'Siirtosaamiset',
          'pitkaaikaiset-saamiset-yhteensa': 'Pitkäaikaiset saamiset yhteensä',
          'lyhytaikaiset-saamiset': 'Lyhytaikaiset saamiset',
          'lyhytaikaiset-saamiset-yhteensa': 'Lyhytaikaiset saamiset yhteensä',
          'rahoitusarvopaperit': 'Rahoitusarvopaperit',
          'muut-arvopaperit': 'Muut arvopaperit',
          'rahoitusarvopaperit-yhteensa': 'Rahoitusarvopaperit yhteensä',
          'rahat-ja-pankkisaamiset': 'Rahat ja pankkisaamiset',
          'vaihtuvat-vastaavat-yhteensa': 'VAIHTUVAT VASTAAVAT YHTEENSÄ',
          'vastaavaa-yhteensa': 'V a s t a a v a a yhteensä',
          'vastattavaa': 'V a s t a t t a v a a',
          'oma-paaoma': 'OMA PÄÄOMA',
          'osake-osuus-tai-muu-vastaava-paaoma': 'Osake-, osuus- tai muu vastaava pääoma',
          'arvonkorotusrahasto': 'Arvonkorotusrahasto',
          'vararahasto': 'Vararahasto',
          'muut-rahastot': 'Muut rahastot',
          'sijoitetun-vapaan-oman-paaoman-rahasto': 'Sijoitetun vapaan oman pääoman rahasto',
          'yhtiojarjestyksen-tai-saantojen-mukaiset-rahastot': 'Yhtiöjärjestyksen tai sääntöjen mukaiset rahastot',
          'muut-rahastot-yhteensa': 'Muut rahastot yhteensä',
          'paaoma-ay': 'Pääoma (ay)',
          'peruspaaoma-tmi': 'Peruspääoma (tmi)',
          'edellisten-tilikausien-voitto-tappio': 'Edellisten tilikausien voitto (tappio)',
          'yksityistilit-tilikaudella': 'Yksityistilit tilikaudella',
          'tilikauden-voitto-tappio': 'Tilikauden voitto (-tappio)',
          'paaomalaina': 'Pääomalaina',
          'oma-paaoma-yhteensa': 'OMA PÄÄOMA YHTEENSÄ',
          'tilinpaatossiirtojen-kertyma': 'TILINPÄÄTÖSSIIRTOJEN KERTYMÄ',
          'poistoero': 'Poistoero',
          'verotusperaiset-varaukset': 'Verotusperäiset varaukset',
          'tilinpaatossiirtojen-kertyma-yhteensa': 'TILINPÄÄTÖSSIIRTOJEN KERTYMÄ YHTEENSÄ',
          'pakolliset-varaukset': 'PAKOLLISET VARAUKSET',
          'elakevaraukset': 'Eläkevaraukset',
          'verovaraukset': 'Verovaraukset',
          'muut-pakolliset-varaukset': 'Muut pakolliset varaukset',
          'pakolliset-varaukset-yhteensa': 'PAKOLLISET VARAUKSET YHTEENSÄ',
          'vieras-paaoma': 'VIERAS PÄÄOMA',
          'pitkaaikainen-vieras-paaoma': 'Pitkäaikainen vieras pääoma',
          'paaomalainat': 'Pääomalainat',
          'vaihtovelkakirjalainat': 'Vaihtovelkakirjalainat',
          'lainat-rahoituslaitoksilta': 'Lainat rahoituslaitoksilta',
          'saadut-ennakot': 'Saadut ennakot',
          'ostovelat': 'Ostovelat',
          'velat-saman-konsernin-yrityksille': 'Velat saman konsernin yrityksille',
          'velat-omistusyhteysyrityksille': 'Velat omistusyhteysyrityksille',
          'muut-velat': 'Muut velat',
          'siirtovelat': 'Siirtovelat',
          'pitkaaikainen-vieras-paaoma-yhteensa': 'Pitkäaikainen vieras pääoma yhteensä',
          'lyhytaikainen-vieras-paaoma': 'Lyhytaikainen vieras pääoma',
          'lyhytaikainen-vieras-paaoma-yhteensa': 'Lyhytaikainen vieras pääoma yhteensä',
          'vieras-paaoma-yhteensa': 'VIERAS PÄÄOMA YHTEENSÄ',
          'vastattavaa-yhteensa': 'V a s t a t t a v a a yhteensä',

          'liikevaihto': 'L I I K E V A I H T O',
          'valmiiden-ja-keskeneraisten-muutokset': 'Valmiiden ja keskeneräisten tuotteiden varastojen muutokset',
          'valmistus-omaan-kayttoon': 'Valmistus omaan käyttöön',
          'liiketoiminnan-muut-tuotot': 'Liiketoiminnan muut tuotot',
          'materiaalit-ja-palvelut': 'Materiaalit ja palvelut',
          'aineet-tarvikkeet-ja-tavarat': 'Aineet, tarvikkeet ja tavarat',
          'ostot-tilikauden-aikana': 'Ostot tilikauden aikana',
          'varastojen-muutos': 'Varastojen muutos',
          'aineet-tarvikkeet-ja-tavarat-yhteensa': 'Aineet, tarvikkeet ja tavarat yhteensä',
          'ulkopuoliset-palvelut': 'Ulkopuoliset palvelut',
          'materiaalit-ja-palvelut-yhteensa': 'Materiaalit ja palvelut yhteensä',
          'henkilostokulut': 'Henkilöstökulut',
          'palkat-ja-palkkiot': 'Palkat ja palkkiot',
          'henkilostosivukulut': 'Henkilöstösivukulut',
          'elakekulut': 'Eläkekulut',
          'muut-henkilosivukulut': 'Muut henkilösivukulut',
          'henkilostosivukulut-yhteensa': 'Henkilöstösivukulut yhteensä',
          'henkilostokulut-yhteensa': 'Henkilöstökulut yhteensä',
          'poistot-ja-arvonalentumiset': 'Poistot ja arvonalentumiset',
          'suunnitelman-mukaiset-poistot': 'Suunnitelman mukaiset poistot',
          'arvonalentumiset-pysyvien-vastaavien-hyodykkeista': 'Arvonalentumiset pysyvien vastaavien hyödykkeistä',
          'vaihtuvien-vastaavien-poikkeukselliset-arvonalentumiset': 'Vaihtuvien vastaavien poikkeukselliset arvonalentumiset',
          'poistot-ja-arvonalentumiset-yhteensa': 'Poistot ja arvonalentumiset yhteensä',
          'liiketoiminnan-muut-kulut': 'Liiketoiminnan muut kulut',
          'liikevoitto': 'L I I K E V O I T T O (- T A P P I O) YHTEENSÄ',
          'rahoitustuotot-ja-kulut': 'Rahoitustuotot ja -kulut',
          'tuotot-osuuksista-saman-konsernin-yrityksissa': 'Tuotot osuuksista saman konsernin yrityksissä',
          'tuotot-osuuksista-omistusyhteysyrityksissa': 'Tuotot osuuksista omistusyhteysyrityksissä',
          'tuotot-muista-pysyvien-vastaavien-sijoituksista': 'Tuotot muista pysyvien vastaavien sijoituksista',
          'saman-konsernin-yrityksiltä': 'Saman konsernin yrityksiltä',
          'muilta': 'Muilta',
          'muut-korko-ja-rahoitustuotot': 'Muut korko- ja rahoitustuotot',
          'saman-konsernin-yrityksilta': 'Saman konsernin yrityksiltä',
          'arvonalentumiset-pysyvien-vastaavien-sijoituksista': 'Arvonalentumiset pysyvien vastaavien sijoituksista',
          'arvonalentumiset-vaihtuvien-vastaavien-rahoitusarvopapereista': 'Arvonalentumiset vaihtuvien vastaavien rahoitusarvopapereista',
          'korkokulut-ja-muut-rahoituskulut': 'Korkokulut ja muut rahoituskulut',
          'muille': 'Muille',
          'rahoitustuotot-ja-kulut-yhteensä': 'Rahoitustuotot ja -kulut yhteensä',
          'satunnaiset-erät': 'Satunnaiset erät',
          'voitto-ennen-tilinpaatossiirtoja-ja-veroja': 'V O I T T O (- T A P P I O) ENNEN TILINPÄÄTÖSSIIRTOJA JA VEROJA',
          'tilinpaattosiirrot': 'Tilinpäätössiirrot',
          'poistoeron-muutos': 'Poistoeron muutos',
          'verotusperusteisten-varausten-muutos': 'Verotusperusteisten varausten muutos',
          'konserniavustus': 'Konserniavustus',
          'tilinpaattosiirrot-yhteensä': 'Tilinpäätössiirrot yhteensä',
          'tuloverot': 'Tuloverot',
          'muut-valittomat-verot': 'Muut välittömät verot',
          'tilikauden-voitto': 'TILIKAUDEN VOITTO (-TAPPIO)'
        },
        'luettelo-kirjanpidoista': 'Luettelo kirjanpidoista ja aineistoista',
        'tilikartta': 'Tilikartta',
        'hallituksen-poytakirja': 'Hallituksen pöytäkirja',
        'laskutustiedot': 'Laskutustiedot',
        'toimeksiantosopimus': 'Toimeksiantosopimus',
        'palvelukuvaus': 'Palvelukuvaus',
        'valittu-paivamaara-ei-tilikaudella': 'Valittu päivämäärä ei ole millään järjestelmästä löytyvällä tilikaudella.',
        'tilille-ei-vienteja': 'Tilille ei löytynyt yhtään vientiä',
        'ei-tapahtumia': 'Valitulla aikavälillä ei ole tapahtumia.',
        'laji': 'Laji',
        'tosite': 'Tosite',
        'pvm': 'Pvm',
        'selite': 'Selite',
        'alv': 'ALV',
        'debet': 'Debet',
        'kredit': 'Kredit',
        'saldo': 'Saldo',
        'alkusaldo': 'Alkusaldo',
        'yhteensa': 'Yhteensä',
        'yhteensa-pienella': 'yhteensä',
        'tulos-ennen-veroja': 'tulos ennen veroja',
        'tilikauden-voitto': 'tilikauden voitto',
        'tase-erittelyt-laati': 'Tase-erittelyt laati',
        'liite-tase-erittelyyn': 'Liite tase-erittelyyn',
        'erillisen-liitteen-mukaan': 'Erillisen liitteen mukaan',
        'muutos': 'Muutos',
        'tili': 'Tili',
        'kirjanpitajan-kommentit': 'Kirjanpitäjän kommentit',
        'otot': 'Otot',
        'panot': 'Panot',
        'kpl': 'kpl',
        'kuluva-tilikausi': 'Kuluva tilikausi',
        'edellinen-tilikausi': 'Edellinen tilikausi',
        'vertailuaikavali': 'Vertailuaikaväli',
        'ei-tilikaudella': 'Ei tilikaudella',
        'vertailuaikavali-ei-tilikaudella': 'Vertailuaikaväli ei tilikaudella',
        'reskontra': {
          'reskontra': 'Reskontra',
          'reskontraraportti': 'Reskontraraportti',
          'tili': 'Tili',
          'pvm': 'Päivämäärä',
          'naytetaan': 'Näytetään',
          'avoimet-viennit': 'Avoimet viennit',
          'kaikki-viennit': 'Kaikki viennit',
          'debet': 'Debet',
          'kredit': 'Kredit',
          'puuttuu': 'Puuttuu',
          'saldo': 'Saldo'
        },
        'varsinaisen-yhtiokokouksen-poytakirja': 'Varsinaisen yhtiökokouksen pöytäkirja',
        'lataa-pdf': 'Lataa PDF'
      },
      'reports-lemonaid': {
        'tilikausi': 'Tilikausi',
        'ei-tilikausia-text': 'Yhtään avattua tilikautta ei löytynyt. Ole hyvä ja ota yhteys kirjanpitäjääsi.',

        'lataa-asiakirja': 'Lataa asiakirja',
        'lataa-tilinpaatos': 'Lataa tilinpäätös',
        'lataa-poytakirja': 'Lataa pöytäkirja',

        'tilinpaatos': 'Tilinpäätös',
        'hallituksen-poytakirja': 'Hallituksen pöytäkirja',
        'tilinpaatos-ja-hallituksen-poytakirja': 'Tilinpäätös ja hallituksen pöytäkirja',
        'allekirjoitukset': 'Allekirjoitukset',
        'tilinpaatoksen-allekirjoittaa': 'Tilinpäätöksen allekirjoittaa <b>toimitusjohtaja</b> (jos yhtiöllä on toimitusjohtaja) ja <b>hallitus</b> (pois lukien varajäsenet).',
        'hyvaksymisesta-on-laadittava': 'Tilinpäätöksen hyväksymisestä on myös laadittava hallituksen pöytäkirja. Tilinpäätöksen allekirjoitus ja hallituksen pöytäkirja voidaan tehdä Lemonaidissa. Allekirjoittamiseen tarvitaan Lemonaid-tunnukset. Jos tarvittavilla henkilöillä ei vielä ole tunnuksia, voit pyytää niitä kirjanpitäjältäsi. Tilinpäätös voidaan myös allekirjoittaa perinteisesti, tässä tapauksessa ota yhteys kirjanpitäjääsi.',
        'alla-on-listattuna': 'Alla on listattuna ilmoittamanne hallitus ja mahdollinen toimitusjohtaja. Tarkista, että kaikki jäsenet löytyvät listalta ja että heillä on oikea rooli. Mikäli joku jäsenistä puuttuu listalta, ota yhteys kirjanpitäjääsi.',
        'tilinpaatoksen-allekirjoittavat-henkilot': 'Tilinpäätöksen allekirjoittavat henkilöt',
        'rooli': 'Rooli',
        'hyvaksymispoytakirjan-tiedot': 'Tilinpäätöksen hyväksymispöytäkirjan tiedot',
        'pvm': 'Päivämäärä',
        'hyvaksy-valinta': 'Hyväksy valinta',
        'muokkaa-allekirjoittajia': 'Muokkaa allekirjoittajia',
        'allekirjoita': 'Allekirjoita',
        'allekirjoita-tilinpaatos': 'Allekirjoita tilinpäätös ja hallituksen pöytäkirja',
        'allekirjoita-poytakirja': 'Allekirjoita pöytäkirja',
        'olen-kaynyt-lapi': 'Olen käynyt läpi tilinpäätöksen ja hallituksen pöytäkirjan ja allekirjoitan ne.',
        'tilinpaatos-pitaa-rekisteroida': 'Tilinpäätös ja tiedot voitonjaosta pitää rekisteröidä PRH:lle.',
        'valtuutan-lemon-treen': 'Valtuutan Tilitoimisto Lemon Tree Oy:n tekemään rekisteröinnit.',
        'valtuutan-lemon-treen-required': 'Ole hyvä ja hyväksy valtuutus.',
        'ylla-oleva-poytakirja': 'Yllä oleva pöytäkirja vastaa varsinaisen yhtiökokouksen kulkua. Allekirjoitan pöytäkirjan.',
        'allekirjoitettu-sahkoisesti': 'Allekirjoitettu sähköisesti',
        'allekirjoitus-puuttuu': 'Allekirjoitus puuttuu',
        'tilinpaatos-ei-viela-tehty': 'Tilinpäätöstä ei ole vielä tehty tilikaudelle.',
        'tilinpaatos-asiakirjaa-ladataan': 'Tilinpäätösasiakirjaa ladataan...',
        'tilinpaatoksen-tietoja-ladataan': 'Tilinpäätöksen tietoja ladataan...',
        'no-lemonaid-user': 'Henkilöllä ei ole Lemonaid-tunnuksia, joten allekirjoitusta ei voida tehdä. Pyydä kirjanpitäjältä tunnukset.',

        'tilinpaatos-allekirjoitettu': 'Tilinpäätös allekirjoitettu',
        'avaa-yhtiokokouksen-poytakirja': 'Avaa yhtiökokouksen pöytäkirja',

        'ei-tilitarkasteta': {
          'tilinpaatos-allekirjoitettu-osaltasi': 'Tilinpäätös on nyt allekirjoitettu osaltasi. Kun kaikki allekirjoitukset on tehty,  tilinpäätös vahvistetaan varsinaisessa yhtiökokouksessa. Kokouksesta tehdään pöytäkirja Yhtiökokouksen pöytäkirja -välilehdellä.',
          'tilinpaatos-valmis-vahvistettavaksi': 'Tilinpäätös on nyt allekirjoitettu ja valmis vahvistettavaksi varsinaisessa yhtiökokouksessa. Voitte nyt tehdä kokouksesta pöytäkirjan Yhtiökokouksen pöytäkirja -välilehdellä.',
          'tilinpaatos-pitaa-allekirjoittaa': 'Tilinpäätös pitää allekirjoittaa ennen varsinaisen yhtiökokouksen pitämistä.',
        },
        'tilitarkastetaan': {
          'tilinpaatos-allekirjoitettu-osaltasi': 'Tilinpäätös on nyt allekirjoitettu osaltasi. Kun kaikki allekirjoitukset on tehty,  tilinpäätös vahvistetaan varsinaisessa yhtiökokouksessa. Kokouksesta tehdään pöytäkirja Yhtiökokouksen pöytäkirja -välilehdellä.',
          'tilinpaatos-valmis-tilintarkastettavaksi': 'Tilinpäätös on nyt allekirjoitettu ja valmis tilintarkastettavaksi. Tarkastuksen jälkeen tilinpäätös vahvistetaan varsinaisessa yhtiökokouksessa ja siitä tehdään pöytäkirja Yhtiökokouksen pöytäkirja -välilehdellä.',
          'tilinpaatos-pitaa-tilintarkastaa': 'Tilinpäätös pitää tilintarkastaa ennen varsinaisen yhtiökokouksen pitämistä.',
        },

        'varsinainen-yhtiokokous': 'Varsinainen yhtiökokous',
        'varsinaisen-yhtiokokouksen-poytakirja': 'Varsinaisen yhtiökokouksen pöytäkirja',
        'yhtiokokouksen-poytakirja': 'Yhtiökokouksen pöytäkirja',
        'voit-luoda-ja-allekirjoittaa': 'Voit luoda ja allekirjoittaa täällä varsinaisen yhtiökokouksen pöytäkirjan, mikäli kokous on lainmukaisesti kutsuttu koolle ja kokouksessa on päätetty vain lain velvoittamista asioista. Mikäli kokouksen yhteydessä on päätetty myös muista asioista, pitää pöytäkirja tehdä perinteisesti. Allekirjoituksia varten kokouksen puheenjohtajalla ja pöytäkirjan tarkastajalla on oltava Lemonaid-tunnukset.',
        'perustiedot': 'Perustiedot',
        'kokouksen-aika': 'Kokouksen järjestämisaika',
        'kokouksen-aika-min-date': 'Kokous ei voi olla ennen tilinpäätöksen allekirjoitushetkeä',
        'kokouspaikka': 'Kokouksen järjestämispaikka',
        'kokouspaikka-esimerkki': 'Esim. \"Yhtiön toimisto\"',
        'poytakirjan-numero': 'Pöytäkirjan numero',
        'lasnaolijat': 'Läsnäolijat',
        'nimi': 'Nimi',
        'osakkeet': 'Osakkeet',
        'kokouksen-avaus': 'Kokouksen avaus',
        'kokouksen-avasi': 'Kokouksen avasi',
        'kokouksen-avasi-name': 'Kokouksen avasi {{name}}.',
        'kokouksen-jarjestaytyminen': 'Kokouksen järjestäytyminen',
        'puheenjohtaja': 'Puheenjohtaja',
        'sihteeri': 'Sihteeri',
        'poytakirjan-tarkastaja': 'Pöytäkirjan tarkastaja',
        'osinkojen-jakaminen': 'Osinkojen jakaminen',
        'osinkoa-ei-jaeta': 'Osinkoa ei jaeta',
        'osinkoa-jaetaan': 'Osinkoa jaetaan {{sum}} euroa (hallituksen ehdotus)',
        'esikatsele-ja-allekirjoita': 'Esikatsele ja allekirjoita',
        'muokkaa-poytakirjaa': 'Muokkaa pöytäkirjaa',

        'hallituksen-paatos': 'Hallituksen päätös',
        'paatoksentekoon-osallistuneet': 'Päätöksentekoon osallistuneet',
        'asema-hallituksessa': 'Asema hallituksessa',
        'paatoksenteon-laillisuus': 'Päätöksenteon laillisuus',
        'paatokset-tehtiin-kokousta-pitamatta': 'Päätökset tehtiin kokousta pitämättä osakeyhtiölain mukaisesti. Kaikki hallituksen jäsenet osallistuivat päätöksentekoon. Näin ollen tämän pöytäkirjan mukaiset päätökset ovat laillisia.',
        'tilinpaatoksen-kasitteleminen': 'Tilinpäätöksen käsitteleminen',
        'esitettiin-tilinpaatosajanjaksolta': 'Esitettiin tilinpäätös ajanjaksolta {{ajanjakso}}. Hyväksyttiin ja allekirjoitettiin tilinpäätösasiakirjat.',
        'esitys-tuloksen-kayttamisesta': 'Esitys taseen osoittaman tuloksen käyttämisestä',
        'todettiin-etta-tilinpaatoksen-voitto': 'Todettiin, että tilinpäätöksen osoittama voitto oli {{profit}} euroa.',
        'todettiin-etta-tilinpaatoksen-tappio': 'Todettiin, että tilinpäätöksen osoittama tappio oli {{profit}} euroa.',
        'paatettiin-esittaa-etta-jaetaan-osinkoa': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta jaetaan osinkoa {{dividendPerShare}} euroa osakkeelta eli yhteensä {{dividendTotal}} euroa ja että loput tuloksesta siirretään voitto/tappio -tilille. Todettiin, että yhtiön jakokelpoiset varat ja maksukyky mahdollistavat esitetyn osingon jakamisen.',
        'paatettiin-esittaa-etta-ei-jaeta-osinkoa-tilikauden-voitto': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta ei jaeta osinkoa ja tilikauden voitto kirjataan edellisten tilikausien voitto/tappio -tilille.',
        'paatettiin-esittaa-etta-ei-jaeta-osinkoa-tilikauden-tappio': 'Päätettiin esittää yhtiökokoukselle, että tilikaudelta ei jaeta osinkoa ja tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.',

        'poytakirja': 'Pöytäkirja',
        'aika': 'Kokouksen järjestämisaika',
        'paikka': 'Kokouksen järjestämispaikka',
        'lasna': 'Läsnä',
        'osakas': 'Osakas',
        'aanet': 'Äänet',
        'kokouksen-avaaminen': 'Kokouksen avaaminen',
        'kokouksen-puheenjohtajaksi-ja-tarkistajaksi': 'Kokouksen puheenjohtajaksi valittiin {{puheenjohtaja}} ja pöytäkirjantarkastajaksi {{tarkastaja}}.',
        'aaniluettelon-vahvistaminen': 'Ääniluettelon vahvistaminen',
        'puheenjohtaja-totesi': 'Puheenjohtaja totesi, että ääniluettelo on nähtävillä kokouksessa. Päätettiin vahvistaa ääniluettelo.',
        'kokouksen-laillisuus': 'Kokouksen laillisuus',
        'todettiin-etta-kaikki-osakkeet': 'Todettiin, että kaikki yhtiön osakkeet ovat edustettuina kokouksessa, joten kokous on laillinen ja päätösvaltainen.',
        'todettiin-kokoon-kutsutuksi': 'Todettiin kokous osakeyhtiölain ja yhtiöjärjestyksen mukaisesti kokoon kutsutuksi ja päätösvaltaiseksi.',
        'tilinpaatoksen-esittaminen': 'Tilinpäätöksen esittäminen',
        'esitettiin-tilinpaatos': 'Esitettiin tilinpäätös tilikaudelta {{start}} - {{end}}.',
        'esitettiin-tilinpaatos-ja-tilintarkastuskertomus': 'Esitettiin tilinpäätös tilikaudelta {{start}} - {{end}} ja sen pohjalta laadittu tilintarkastuskertomus.',
        'tilinpaatoksen-vahvistaminen': 'Tilinpäätöksen vahvistaminen',
        'vahvistettiin-tilinpaatos': 'Vahvistettiin tilinpäätös hallituksen esittämässä muodossa.',
        'taseen-osoittaman': 'Taseen osoittaman tuloksen käsittely',
        'todettiin-etta-vahvistettu-tase': 'Todettiin, että vahvistettu tase osoittaa {{profit}} euron voittoa.',
        'paatettiin-jakaa-osinkoa': 'Päätettiin jakaa osinkoa {{dividendPerShare}} euroa osakkeelta, yhteensä {{dividendTotal}} euroa. Osinko on nostettavissa {{dividendPayoutTime}}. Päätettiin kirjata loppuosa voitosta edellisten tilikausien voitto / tappio -tilille.',
        'heti-yhtiokokouksen-jalkeen': 'heti yhtiökokouksen jälkeen',
        'kun-poytakirja-on-allekirjoitettu': 'kun varsinaisen yhtiökokouksen pöytäkirja on allekirjoitettu',
        'todettiin-etta-osoittaa-voittoa-ei-osinkoa': 'Todettiin, että vahvistettu tase osoittaa {{profit}} euron voittoa. Päätettiin, että osinkoa ei jaeta. Päätettiin kirjata voitto edellisten tilikausien voitto / tappio -tilille.',
        'todettiin-etta-osoittaa-tappiota-ei-osinkoa': 'Todettiin, että vahvistettu tase osoittaa {{loss}} euron tappiota. Päätettiin kirjata tappio edellisten tilikausien voitto / tappio -tilille. Päätettiin, että osinkoa ei jaeta.',
        'todettiin-etta-osittaa-tappiota-jakaa-osinkoa': 'Todettiin, että vahvistettu tase osoittaa {{loss}} euron tappiota. Päätettiin kirjata tappio edellisten tilikausien voitto / tappio -tilille. Päätettiin jakaa osinkoa {{dividendPerShare}} euroa osakkeelta, yhteensä {{dividendTotal}} euroa. Osinko on nostettavissa {{dividendPayoutTime}}.',
        'vastuuvapaudesta-paattaminen': 'Vastuuvapaudesta päättäminen',
        'myonnettiin-vastuuvapautus': 'Myönnettiin vastuuvapautus hallituksen jäsenille ja toimitusjohtajalle (jos valittu) tilikaudelta {{start}} - {{end}}.',
        'kokouksen-paattaminen': 'Kokouksen päättäminen',
        'puheenjohtaja-paattii-kokouksen': 'Koska muita käsiteltäviä asioita ei ollut, puheenjohtaja päätti kokouksen.',
        'henkiloita-ei-voi-valita': 'Harmaana olevia henkilöitä ei voi valita, koska heillä ei ole aktiivisia Lemonaid-tunnuksia.',
        'valitse-eri-henkilot': 'Ole hyvä ja valitse puheenjohtajaksi ja pöytäkirjan tarkastajaksi eri henkilöt.',

        'tilikausi-paketissa': 'Tilikausi paketissa!',
        'poytakirja-allekirjoitettu-tilikausi-paketissa': 'Varsinaisen yhtiökokouksen pöytäkirja on nyt allekirjoitettu ja tilikausi {{year}} on valmis. Lemonaid arkistoi allekirjoitetut tilinpäätösasiakirjat ja voit tarvittaessa ladata ne täältä.',

        'yhtiokokouksen-poytakirjaa-ladataan': 'Yhtiökokouksen pöytäkirjaa ladataan...',
        'yhtiokokouksen-poytakirjan-tietoja-ladataan': 'Yhtiökokouksen pöytäkirjan tietoja ladataan...',

        'tilinpaatos-no-signers-error': 'Ole hyvä ja valitse ainakin yksi allekirjoittaja',
        'yhtiokokous-no-signers-error': 'Ole hyvä ja valitse ainakin yksi läsnäolija'
      },
      'luettelo-kirjanpidoista': {
        'luettelo-kirjanpidoista-ja-aineistoista': 'Luettelo kirjanpidoista ja aineistoista',
        'tilikaudella': 'Tilikaudella',
        'luettelo-kirjanpidoista-ja-aineistoista-tilikaudella': 'Luettelo kirjanpidoista ja aineistoista tilikaudella {{tilikausi}}',
        'kirjanpidot-ja-aineistot': 'Kirjanpidot ja aineistot',
        'tilinpaatos': 'Tilinpäätös',
        'tase-erittelyt': 'Tase-erittelyt',
        'virallinen-tuloslaskelma': 'Virallinen tuloslaskelma',
        'virallinen-tase': 'Virallinen tase',
        'tilikohtainen-tuloslaskelma': 'Tilikohtainen tuloslaskelma',
        'tilikohtainen-tase': 'Tilikohtainen tase',
        'paakirjat': 'Pääkirjat',
        'paivakirjat': 'Päiväkirjat',
        'palkkakirjanpito': 'Palkkakirjanpito',
        'tililuettelo': 'Tililuettelo',
        'tositelajit': 'Tositelajit',
        'tositelaji': 'Tositelaji',
        'tunnus': 'Tunnus',
        'vastatili': 'Vastatili',
        'lisatietoja': 'Lisätietoja',
        'muut-tositteet': 'Muut tositteet',
        'kirjanpitajan-tai-jarjestelman-luoma': 'Kirjanpitäjän luoma tai järjestelmän luoma',
        'sahkoinen-tiliote': 'Sähköinen tiliote',
        'kayttajan-luoma': 'Lemonaid-käyttäjän luoma',
        'kirjanpitajan-luoma': 'Kirjanpitäjän luoma',
        'sahkoisen-tiliotteen-kirjaukset': 'Sähköisen tiliotteen kirjaukset on luotu automaattisesti tilitapahtumien pohjalta ja niihin on liitetty asiakkaan toimittamat tositteet.',
        'kayttajan-luoma-kirjaus': 'Lemonaid-käyttäjän luoma kirjaus on luotu automaattisesti asiakkaan toimittaman tositteen pohjalta',
        'muut-tositteet-sisaltaa': 'Muut tositteet tositelaji sisältää järjestelmän automaattisesti luomat kirjaukset sekä kirjanpitäjään itse luomia tositteet, joita voi olla esim. alkusaldot, palkkalaskelmat tai myyntiraportit.',
        'sailytystavat': 'Säilytystavat',
        'kaikki-aineisto-sailytetaan': 'Kaikki aineisto säilytetään Tilitoimisto Lemon Tree Oy:n (jäljempänä Tilitoimisto) tietojärjestelmässä kirjanpitovelvollisen ja Tilitoimisto välisen toimeksiannon jatkuessa. Tilitoimisto toimittaa aineiston pdf-tiedostoina pyydettäessä. Toimeksiannon päätyttyä kaikki aineisto säilytetään kirjanpitovelvollisen hallinnoimilla sähköisillä tallennusvälineillä.'
      },
      'laskutustiedot-pdf': {
        'laskutustiedot': 'Laskutustiedot',
        'hyva-toimittajamme-pyydamme-teita': 'Hyvä toimittajamme,\n\nPyydämme teitä lähettämään laskunne sähköisesti alla olevaan verkkolaskuosoitteeseen.',
        'ostolaskujen-vastaanotto': 'Ostolaskujen vastaanotto',
        'verkkolaskuosoite': 'Verkkolaskuosoite',
        'verkkolaskujen-valittaja': 'Verkkolaskujen välittäjä',
        'ystavallisin-terveisin': 'Ystävällisin terveisin,'
      }
    }
  }
}
