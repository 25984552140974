import { AsiakkaanTuote, LaskunLiitetiedosto } from '../../model/lasku'

export class LaskuUriService {
  getPdfUri(asiakasId: string | number, laskuId: string): string {
    return 'laskut/' + asiakasId + '/' + laskuId + '.pdf'
  }

  getTyojonoLaskuTulostusUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_tulostus/' + tyojonoId
  }

  getTyojonoLaskuGeneroiPdfUudelleenUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_tee_pdf_uudelleen/' + tyojonoId
  }

  getTyojonoLaskuMyyntilaskuOstolaskuksi(myyjanAsiakasAvain: string, tyojonoAvain): string {
    return '/tyojono/' + myyjanAsiakasAvain + '/myyntilasku-ostolaskuksi/' + tyojonoAvain
  }

  getTyojonoLaskuEmailUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_email/' + tyojonoId
  }

  getTyojonoPerintaLaskuEmailUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_perintaan/' + tyojonoId
  }

  getTyojonoPerintaLaskunPeruutusEmailUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_perinta_peruutettu/' + tyojonoId
  }

  getTyojonoTiedotaPerinnassaOlevanLaskunMuutoksestaTarvittaessaUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/tiedota_perinnassa_olevan_laskun_muutoksesta_tarvittaessa/' + tyojonoId
  }

  getTyojonoLaskuTasapainoitaJaLahetaUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_tasapainoita_ja_laheta/' + tyojonoId
  }

  getTyojonoLaskuSahkoinenlUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_sahkoinen/' + tyojonoId
  }

  getTyojonoLaskuVientiLemonatoriinUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_lemonaattoriin/' + tyojonoId
  }

  getTyojonoLaskuVientiLemonatoriinDataUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono-data/' + asiakasId + '/lasku_lemonaattoriin/' + tyojonoId
  }

  getTyojonoLaskuSqlVientiLemonatoriinMassaAsiakasFanoutUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_lemonaattoriin_massa_asiakas_fanout/' + tyojonoId
  }
  getTyojonoLaskuPostgresUri(asiakasAvain: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasAvain + '/lasku_to_postgres/' + tyojonoId
  }

  getLaskuTilatAsiakkaanEraantyneetUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_tilat_eraantyneet/' + tyojonoId
  }

  getLaskuEraantyneetSmsUri(asiakasId: string | number, tyojonoId: string): string {
    return '/tyojono/' + asiakasId + '/lasku_eraantynyt_sms/' + tyojonoId
  }

  getLaskutCollectionUri(asiakasId: string | number): string {
    return '/laskut/' + asiakasId + '/laskut'
  }

  getLaskuUri(asiakasId: string | number, laskuId: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuId
  }

  getLaskuasetuksetUri(asiakasAvain: string, asetusAvain: string): string {
    return '/customers/' + asiakasAvain + '/customer-invoices-settings/' + asetusAvain
  }

  getLaskunLahetystiedotUri(asiakasId: string | number, laskuAvain: string, kasiteltavanAvain: string): string {
    return '/laskut/' + asiakasId + '/lahetystiedot/' + kasiteltavanAvain
  }

  getLaskuToimintalokiUri(asiakasId: string | number, laskuId: string, lokiId: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuId + '/toimintaloki/' + lokiId
  }

  getLaskuHistoriaUri(asiakasId: string | number, laskuId: string, lokiId: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuId + '/historia/' + lokiId
  }

  getLaskuReskontraUri(asiakasId: string | number, laskuAvain: string, reskontraAvain: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuAvain + '/lasku-reskontra/' + reskontraAvain
  }

  getLaskuReskontraCollectionUri(asiakasId: string | number, laskuAvain: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuAvain + '/lasku-reskontra'
  }

  getLaskunAsiakkaanUri(asiakasId: string | number, asiakasAvain: string) {
    return '/laskut/' + asiakasId + '/asiakkaat/' + asiakasAvain
  }

  getLaskuToimintalokiCollectionUri(asiakasId: string | number, laskuId: string): string {
    return '/laskut/' + asiakasId + '/laskut/' + laskuId + '/toimintaloki'
  }

  getTypeaheadAsiakasDokumentinUri(asiakasId: string): string {
    return '/laskut/' + asiakasId + '_typeahead_asiakas'
  }

  getTypeaheadTuoteDokumentinUri(asiakasId: string): string {
    return '/laskut/' + asiakasId + '_typeahead_tuote'
  }

  getTuoteUri(asiakasId: string, tuote: AsiakkaanTuote): string {
    return '/laskut/' + asiakasId + '/tuotteet/' + tuote.$key
  }


  annaLiitetiedostonCloudStorageUri(asiakasAvain: string, liitetiedosto: LaskunLiitetiedosto): string {
    return 'laskut/' + asiakasAvain + '/liitetiedostot/' + liitetiedosto.avain
  }


  teeLaskunEmailTag(laskuUri: string, asikasAvain: string, asiakasId: string, kasiteltavanAvain: string): string {
    return 'lasku2_____' + laskuUri + '_____' + kasiteltavanAvain + '_____' + asikasAvain + '_____' + asiakasId
  }

  puraLaskunEmailTag(tag: string): { laskuUri: string, laskuAvain: string, kasiteltavanAvain: string, asiakasId: string, asiakasAvain: string } {
    if (tag.startsWith('lasku1_____')) {
      const split = tag.replace('lasku1_____', '').split('_____')
      const laskuUri = split[0]
      const asiakasId = tag.replace('lasku1_____/laskut/', '').replace(/\/laskut\/.+/, '')
      const laskuAvain = laskuUri.replace('/laskut/' + asiakasId + '/laskut/', '')
      return {
        laskuUri: laskuUri,
        asiakasId: asiakasId,
        kasiteltavanAvain: split[1],
        laskuAvain: laskuAvain,
        asiakasAvain: null
      }
    } else if (tag.startsWith('lasku2_____')) {
      const split = tag.replace('lasku2_____', '').split('_____')
      const laskuUri = split[0]
      const asiakasId = tag.replace('lasku2_____/laskut/', '').replace(/\/laskut\/.+/, '')
      const laskuAvain = laskuUri.replace('/laskut/' + asiakasId + '/laskut/', '')
      return {
        laskuUri: laskuUri,
        asiakasId: split[3],
        kasiteltavanAvain: split[1],
        laskuAvain: laskuAvain,
        asiakasAvain: split[2]
      }
    }
    throw new Error('Laskutagia ei osata purkaa ' + tag)
  }
}
