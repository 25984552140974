import { Injectable } from '@angular/core'

import { TositeUriService } from 'app/_jaettu/service/tosite/tosite-uri.service'

import { FirestoreTositteenAlkuperainenTiedosto, FirestoreTositteenKuva, FirestoreTosite } from 'app/_jaettu/model/tosite'
import { KayttajanTiedot } from 'app/_jaettu/model/kayttaja'
import { getPdfRenderScale } from 'app/_jaettu/utils/pdf'

import { LruMap } from 'app/_jaettu/data-structures/lru-map'
import { PdfConversionJobSettingsWasm } from 'app/_angular/service/pdf.service'
import { LemonBarcodeDetector, QrTaiViivakoodi } from 'app/_angular/service/barcode/barcode-detector.service'


export interface LisattyCacheKuva {
  annaKuvanPromise(): Promise<Blob>
}

@Injectable()
export class TositeKuvaCacheService {

  addedImageCache: Map<string, LisattyCacheKuva> = new Map()
  networkImageCache: LruMap<string, Blob> = new LruMap(100)

  constructor(
    private _tositeUriService: TositeUriService,
    private _lemonBarcodeDetector: LemonBarcodeDetector
  ) { }

  public lisaaLisattyTiedostoCacheen(kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, kuva: FirestoreTositteenKuva, file: File | Blob) {

    const cacheKuva: LisattyCacheKuva = {
      annaKuvanPromise(): Promise<Blob> {
        return Promise.resolve(file)
      }
    }

    const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
    this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + kuvanUrl, cacheKuva)
    this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + kuvanUrl, cacheKuva)

  }

  public poistaKuitinLisatytTiedostotCachesta(tosite: FirestoreTosite) {
    const keys = Array.from(this.addedImageCache.keys())
    for (const key of keys) {
      if (key.indexOf(tosite.kuvakansio) > -1) {
        this.addedImageCache.delete(key)
      }
    }
  }

  public siirraKuvatKuitille(kayttajanTiedot: KayttajanTiedot, kuiteista: FirestoreTosite[], kuittiin: FirestoreTosite): void {

    if (!kuittiin.kuvat) { kuittiin.kuvat = {} }
    if (!kuittiin.alkuperaiset) { kuittiin.alkuperaiset = {} }

    for (const kuitista of kuiteista) {

      // Siirrä kuvat
      if (kuitista.kuvat) {
        for (const kuva of Object.values(kuitista.kuvat)) {

          const oldKuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitista.kuvakansio, kuva.avain, kuva.type)
          const oldFullUrl = '/api/1/kuitit/kuvat/kuva/' + oldKuvanUrl
          const oldThumbUrl = '/api/1/kuitit/kuvat/thumb/' + oldKuvanUrl
          const oldFull = this.addedImageCache.get(oldFullUrl)
          const oldThumb = this.addedImageCache.get(oldThumbUrl)

          const newKuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuittiin.kuvakansio, kuva.avain, kuva.type)
          if (oldFull) {
            // console.log(oldKuvanUrl, ' --> ', newKuvanUrl)
            this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + newKuvanUrl, oldFull)
            this.addedImageCache.delete(oldFullUrl)
          }
          if (oldThumb) {
            // console.log(oldKuvanUrl, ' --> ', newKuvanUrl)
            this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + newKuvanUrl, oldThumb)
            this.addedImageCache.delete(oldThumbUrl)
          }

          kuittiin.kuvat[kuva.avain] = kuva
        }
      }

      // Siirrä alkuperäiset
      if (kuitista.alkuperaiset) {
        for (const alkuperainen of Object.values(kuitista.alkuperaiset)) {
          kuittiin.alkuperaiset[alkuperainen.avain] = alkuperainen
        }
      }

      this.poistaKuitinLisatytTiedostotCachesta(kuitista)

    }

  }

  public annaKuvienUrlit(tosite: FirestoreTosite, kayttajanTiedot: KayttajanTiedot, type: 'thumb' | 'full'): string[] {
    return Object
      .values(tosite.kuvat ?? {})
      .filter(k => !k.poistettu)
      .sort((a, b): number => {
        if (a.alkuperaisenAvain === b.alkuperaisenAvain) {
          return a.jarjestys < b.jarjestys ? -1 : a.jarjestys > b.jarjestys ? 1 : 0
        }
        if (a.alkuperaisenAvain && b.alkuperaisenAvain) {
          return a.alkuperaisenAvain.localeCompare(b.alkuperaisenAvain)
        }
        if (a.alkuperaisenAvain) {
          return 1
        }
        if (b.alkuperaisenAvain) {
          return -1
        }
        return 0
      })
      .map(kuva => {
        return this.annaKuvanUrl(kayttajanTiedot, tosite, kuva, type)
      })
  }

  public annaKuvanUrl(kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, kuva: FirestoreTositteenKuva, type: 'thumb' | 'full'): string {
    const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
    if (type === 'full') {
      return '/api/1/kuitit/kuvat/kuva/' + kuvanUrl
    } else if (type === 'thumb') {
      return '/api/1/kuitit/kuvat/thumb/' + kuvanUrl
    }
    throw new Error('Tuntematon tyyppi: ' + type)
  }

  public hoidaPdfnRajayttaminenKuviksi(
    tosite: FirestoreTosite,
    alkuperainen: FirestoreTositteenAlkuperainenTiedosto,
    kayttajanTiedot: KayttajanTiedot,
    wasmSettings: PdfConversionJobSettingsWasm,
    kuvienTargetWidth: number,
    parsiKoodiEnsimmaisestaXSivusta: number,
    koodiPromise: Promise<QrTaiViivakoodi>
  ): { koodi: Promise<QrTaiViivakoodi>, kuvat: FirestoreTositteenKuva[] } {

    const that = this

    const kasitellytKuvat: FirestoreTositteenKuva[] = []
    for (let kuvanIndeksi = 1; kuvanIndeksi <= wasmSettings.document.getPageCount(); kuvanIndeksi++) {

      // Create firestore tosite image metadata
      const kuva: FirestoreTositteenKuva = {
        avain: alkuperainen.avain + '_' + kuvanIndeksi,
        jarjestys: kuvanIndeksi,
        poistettu: false,
        type: 'jpg',
        alkuperaisenAvain: alkuperainen.avain,
      }
      kasitellytKuvat.push(kuva)

      // As the server does not contain an image for this yet, prepare a cached version.
      // Note that this is lazily executed, so if no one requests the image, it is NOT
      // rendered at all.
      let promise: Promise<Blob> = null
      const cacheKuva: LisattyCacheKuva = {
        annaKuvanPromise(): Promise<Blob> {
          if (!promise) {
            promise = that._renderPdfPage(wasmSettings, kuvanIndeksi, kuvienTargetWidth)
          }
          return promise
        }
      }

      const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
      this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + kuvanUrl, cacheKuva)
      if (kuvanIndeksi === 0) {
        this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + kuvanUrl, cacheKuva)
      }
      if (parsiKoodiEnsimmaisestaXSivusta > 0 && parsiKoodiEnsimmaisestaXSivusta >= kuvanIndeksi) {
        // console.log('Kuvapromise sivulle ' + kuvanIndeksi)
        koodiPromise = this._annaKoodiPromise(koodiPromise, cacheKuva)
      }

    }

    return { koodi: koodiPromise ?? Promise.resolve({}), kuvat: kasitellytKuvat }

  }

  private async _annaKoodiPromise(koodiPromise: Promise<QrTaiViivakoodi>, cacheKuva: LisattyCacheKuva): Promise<QrTaiViivakoodi> {
    return (koodiPromise ?? Promise.resolve({}) as Promise<QrTaiViivakoodi>).then(result => {
      // console.log('JAAHA', result)
      if (result.qr || result.viiva) {
        return result
      }
      // console.log('PALAUTA PROMISE')
      return cacheKuva.annaKuvanPromise().then(kuva => {
        // console.log('got image: ' + kuva.length)
        return this._lemonBarcodeDetector.detect(kuva).then(koodi => {
          // console.log('got koodi', koodi)
          if (koodi?.qr) {
            result.qr = koodi.qr
          } else if (koodi?.viiva) {
            result.viiva = koodi.viiva
          }
          return result
        })
      })
    })
  }

  private _renderPdfPage(
    wasmSettings: PdfConversionJobSettingsWasm,
    kuvanIndeksi: number,
    kuvienTargetWidth: number
  ): Promise<Blob> {
    // console.log('Pyydetty kuva ' + kuvanIndeksi)
    const page = wasmSettings.document.getPage(kuvanIndeksi)
    const info = page.getSize()
    const scale = getPdfRenderScale(kuvienTargetWidth, 3, info.width)
    const render = page.render(scale)
    page.destroy()

    const imageData = new ImageData(new Uint8ClampedArray(render.bitmap), render.width, render.height)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = render.width
    canvas.height = render.height
    ctx.putImageData(imageData, 0, 0)
    return this._canvasToBlob(canvas)

  }

  private _canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {

        if (canvas.toBlob) {
          canvas.toBlob(blob => { resolve(blob) }, 'image/png', 100)
        } else if (canvas.toDataURL) {
          const asDataUrl = canvas.toDataURL('image/png', 100)
          resolve(this._dataURLtoBlob(asDataUrl))
        } else {
          reject(new Error('The browser does NOT support HTMLCanvasElement.toBlob() or HTMLCanvasElement.toDataUrl()!!'))
        }

      } catch (err) {
        reject(err)
      }
    })
  }

  private _dataURLtoBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

}
