<div class="tositteen-muokkaus-page">
  <form [formGroup]="form" novalidate autocomplete="kirjanpitoaineistoarkki">

    <div class="blue-background">
      <div style="margin-left: auto; margin-right: auto">

        <div class="ylapalkki">

          <button class="sulje" mat-button (click)="peruuta()" type="button">
            <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
          </button>

          <h2 style="margin: 0; width: calc(100% - 18vw); text-align: center;">
            {{ 'kuitit.muokkaa.otsikko-muokkaa' | translate | async }}
          </h2>

        </div>

      </div>
    </div>

    <div class="kuitti-container">

      <div class="tosite-container">

        <div class="tosite-tiedot">

          <ng-container *ngIf="showMaksutavat">
            <div class="mat-form-field" style="font-size: 10.5px;">{{ 'kuitit.muokkaa.laji' | translate | async }} *</div>
            <div style="padding-bottom: 5px;">
              <app-kuitit-maksutapa formControlName="maksutapa" [showAllPaymentMethods]="true" [filterNonActive]="true" [error]="maksutapa?.errors?.length"></app-kuitit-maksutapa>
              <mat-error *ngIf="maksutapa?.errors?.required && (maksutapa?.dirty || maksutapa?.touched)">
                {{ 'kuitit.muokkaa.validation.maksutapa.required' | translate | async }}
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngIf="showPvm">
            <ng-container *ngIf="dateSelectionType === 'day'; else monthInput">
              <mat-form-field>
                <mat-label>{{ pvmText }}</mat-label>
                <input numberDatePicker matInput formControlName="tositteenPvm" [name]="namename" [matDatepicker]="pickerDay" [min]="minDate" [max]="maxDate" autocomplete="1" data-lpignore="true" required>
                <ng-container *ngIf="tositteenPvm; let n">
                  <ng-container *ngIf="(n?.dirty || n?.touched)">
                    <mat-error *ngIf="n.errors?.required">{{ 'kuitit.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerParse">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerMax">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerMin">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
                  </ng-container>
                </ng-container>
                <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                <mat-datepicker #pickerDay></mat-datepicker>
              </mat-form-field>
            </ng-container>
            <ng-template #monthInput>
              <mat-form-field>
                <mat-label>{{ pvmText }}</mat-label>
                <input monthPicker numberDatePicker matInput formControlName="tositteenPvm" [name]="namename" [matDatepicker]="pickerMonth" [min]="minDate" [max]="maxDate" autocomplete="1" data-lpignore="true" required>
                <ng-container *ngIf="tositteenPvm; let n">
                  <ng-container *ngIf="(n?.dirty || n?.touched)">
                    <mat-error *ngIf="n.errors?.required">{{ 'kuitit.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerParse">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerMax">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
                    <mat-error *ngIf="n.errors?.matDatepickerMin">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
                  </ng-container>
                </ng-container>
                <mat-datepicker-toggle matSuffix [for]="pickerMonth"></mat-datepicker-toggle>
                <mat-datepicker #pickerMonth></mat-datepicker>
              </mat-form-field>
            </ng-template>
          </ng-container>

          <br />

          <mat-form-field *ngIf="!hideSum">
            <mat-label>{{ summaText }}</mat-label>
            <input class="alignLeft" decimalNumberField matInput formControlName="summa" [name]="namename" autocomplete="1" data-lpignore="true" required>
            <mat-error *ngIf="summa?.errors?.required && (summa?.dirty || summa?.touched)">
              {{ 'kuitit.muokkaa.validation.summa.required' | translate | async }}
            </mat-error>
            <mat-error *ngIf="summa?.errors?.min && (summa?.dirty || summa?.touched)">
              {{ 'kuitit.muokkaa.validation.summa.min' | translate | async }}
            </mat-error>
            <mat-error *ngIf="summa?.errors?.max && (summa?.dirty || summa?.touched)">
              {{ 'kuitit.muokkaa.validation.summa.max' | translate | async }}
            </mat-error>
          </mat-form-field>

          <br />

          <div *ngIf="alvPortaat">
            <mat-form-field>
              <mat-label>{{ 'kuitit.muokkaa.alv' | translate | async }}</mat-label>
              <mat-select formControlName="alvVahennysoikeus">
                <mat-option *ngFor="let porras of alvPortaat" [value]="porras">
                  {{ porras }} %
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
          </div>

          <div class="el-kommentti" *ngIf="maksajaTaiMaksunSaaja">{{ maksajaTaiMaksunSaaja }}</div>

          <mat-form-field>
            <mat-label>{{ 'kuitit.muokkaa.lisatiedot' | translate | async }}</mat-label>
            <textarea [name]="namename" matInput formControlName="lisatiedot" cdkTextareaAutosize cdkAutosizeMinRows="1.5" cdkAutosizeMaxRows="35" autocomplete="1"></textarea>
          </mat-form-field>

          <div class="kirj-kommentti" *ngIf="kirjanpitajanKommentti">{{'kuitit.muokkaa.kirjanpitajan-kommentti' | translate | async }}: {{ kirjanpitajanKommentti}}</div>
          <div *ngIf="kuitti.userApiMessage" class="tosite-api-msg" style="margin-top: 1em;">{{kuitti.userApiMessage}}</div>

          <div class="button-container">
            <div>

              <div *ngIf="virheviestiEiKuvia" class="error-text" style="padding-bottom: 2em;">
                {{virheviestiEiKuvia}}
              </div>

              <div *ngIf="virheviestiLatausOnKesken" class="error-text" style="padding-bottom: 2em;">
                {{virheviestiLatausOnKesken}}
              </div>

              <div *ngIf="virheviestiPysyva" class="error-text" style="padding-bottom: 2em;">
                {{virheviestiPysyva}}
              </div>

              <div *ngIf="(latausvirheetObservable | async)?.length > 0" class="error-text" style="padding-bottom: 2em; text-align: left;">
                {{'lasku.tiedoston-lahettaminen-epaonnistui-alku' | translate | async}}
                <ul>
                  <li *ngFor="let virhe of latausvirheetObservable | async">{{virhe}}</li>
                </ul>
                {{'lasku.tiedoston-lahettaminen-epaonnistui-loppu' | translate | async}}
              </div>

              <button class="primarybutton" mat-button (click)="save()">{{'yleiset.tallenna' | translate | async}}</button>
              <br />

              <div *ngIf="(latausvirheetObservable | async)?.length && (donePercentageObservable | async) && uploadTasks.length > 0" style="width:50%; margin-top: 2em; text-align: center; display: inline-block">
                <mat-progress-bar mode="determinate" [value]="donePercentageObservable | async"></mat-progress-bar>
                <div style="padding-top: 3px; font-size: 12px">{{ 'kuitit.muokkaa.ladataan' | translate | async }}</div>
              </div>

            </div>
          </div>

        </div>

        <div class="images-container">

          <div *ngFor="let kuva of kuvatDisplay" class="mat-elevation-z4 image-box">

            <div app-tosite-kuva [url]="kuva.url" [expandable]="true" style="width: 100%; height: 100%; position: relative;"></div>

            <button *ngIf="!kuva.lukittu" class="poista-btn mat-elevation-z4" mat-icon-button (click)="poistaKuva(kuva)" type="button">
              <mat-icon class="poista-icon">close</mat-icon>
            </button>
          </div>

          <div *ngFor="let pdfData of pdfKuvatDisplay">
            <app-tositteen-pdf-images-box [inputPdfData]="pdfData.kuvat" [lukittu]="pdfData.lukittu" (poistaKuvat)="poistaPdf(pdfData)"></app-tositteen-pdf-images-box>
          </div>

          <div class="drag-box" (click)="uploadKuva()" [class.dragging-active]="(isDraggingObservable | async) || droppi.isDraggingOverDropZone" [class.dragging-passive]="!(isDraggingObservable | async) && !droppi.isDraggingOverDropZone">
            <ngx-file-drop #droppi dropZoneClassName="drag-area" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (onFileDrop)="fileDrop($event)">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="uploadKuva">
                <div class="drag-text-and-icon" [class.blue-text]="(isDraggingObservable | async) || droppi.isDraggingOverDropZone">
                  <div class="drag-box-text">{{ 'kuitit.muokkaa.lisaa-tiedosto' | translate | async }}</div>
                  <i class="fa fa-arrow-down drag-box-icon"></i>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>
          <div *ngIf="kuvavirheAvain" class="red-text" style="text-align: center;">{{ kuvavirheAvain | translate: kuvavirheParametrit | async }}</div>
        </div>

      </div>

    </div>

  </form>
</div>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" accept="image/*,application/pdf,image/heic" #fileInput multiple="multiple" />