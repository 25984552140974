export class CodeCheckService {

  public isValidPhoneNumber(suspect: string): boolean {
    if (!suspect) {
      return false
    }
    const trimmed = suspect.replace(/\s+/g, '')
    return /^[\d +]+$/.test(trimmed) && trimmed.length > 9
  }

  public isValidInternationalPhoneNumber(suspect: string): boolean {
    if (!suspect) {
      return false
    }
    const trimmed = suspect.replace(/\s+/g, '')
    return trimmed.startsWith('+') && this.isValidPhoneNumber(suspect)
  }
  public isPhoneNumberWithFinnishCountryCode(suspect: string): boolean {
    if (!suspect) {
      return false
    }
    const trimmed = suspect.replace(/\s+/g, '')
    return trimmed.startsWith('+358') && this.isValidPhoneNumber(suspect)
  }
  public isValidFinnishPhoneNumberInInternationalForm(suspect: string): boolean {
    if (!this.isPhoneNumberWithFinnishCountryCode(suspect)) {
      return false
    }
    const trimmed = suspect.replace(/\s+/g, '').replace('+358', '')
    // List of valid starting numbers according to https://www.traficom.fi/fi/viestinta/laajakaista-ja-puhelin/matkaviestinverkkojen-suuntanumerot
    const validStartNums = ['40', '41', '42', '43', '44', '45', '46', '49', '50']

    for (const startNum of validStartNums) {
      if (trimmed.startsWith(startNum)) {
        return true
      }
    }
    return false
  }
  /**
  * Note! Only use to check Finnish numbers
  */
  public finnishPhoneNumberContainsErroneusLeadingZero(suspect: string): boolean {
    if (!this.isPhoneNumberWithFinnishCountryCode(suspect)) {
      return false
    }
    const trimmed = suspect.replace(/\s+/g, '').replace('+358', '')
    return trimmed.startsWith('0')
  }

  /**
  * Inspired by https://github.com/mharj/hetu
  * 
  * vuonna 2000 tai sen jälkeen syntyneillä kirjaimet A, B, C, D, E, F.
  * 1900-luvulla syntyneillä nykyinen yhdysmerkki (-) tai kirjaimet Y, X, W, V, U.
  * 1800-luvulla syntyneillä plusmerkki (+).
  */
  isValidFinnishPersonalId(hetu: string): boolean {
    if (!hetu) {
      return false
    }
    if (hetu.length !== 11) {
      return false
    }
    const d = hetu.toUpperCase()
    if (d[10] !== this.getCC(parseInt(d[0] + d[1] + d[2] + d[3] + d[4] + d[5] + d[7] + d[8] + d[9], 10) % 31)) {
      return false
    }
    return true
  }
  private c = '0123456789ABCDEFHJKLMNPRSTUVWXY'
  private getCC = (num: number): string | null => {
    return this.c[num] ? this.c[num] : null
  }

  // Y tunnus related stuff from https://github.com/vkomulai/finnish-business-ids

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private BUSINESS_ID_REGEX = /^[\d]{7}-[\d]$/
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private VAT_NUMBER_REGEX = /^FI[\d]{8}$/
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private MULTIPLIERS = [7, 9, 10, 5, 8, 4, 2]

  // private static randomBusinessIdWithoutChecksum(): string {
  //   while (true) {
  //     const businessId = (Math.floor(Math.random() * 1000000) + 1000000).toString()
  //     if (LemonaidValidators.calculateChecksum(businessId) !== -1) {
  //       return businessId
  //     }
  //   }
  // }

  // public static generateBusinessId(): string {
  //   const businessId = LemonaidValidators.randomBusinessIdWithoutChecksum()
  //   const checksum = LemonaidValidators.calculateChecksum(businessId)
  //   return `${businessId}-${checksum}`
  // }

  // public static generateVatNumber(): string {
  //   const countryCode = 'FI'
  //   const businessId = LemonaidValidators.randomBusinessIdWithoutChecksum()
  //   const checksum = LemonaidValidators.calculateChecksum(businessId)
  //   return countryCode + businessId + checksum
  // }

  public isValidYTunnus(businessId: string): boolean {
    if (!this.BUSINESS_ID_REGEX.test(businessId)) {
      return false
    }
    const givenChecksum = parseInt(businessId.substring(8, 9), 10)
    const idNumbers = businessId.substring(0, 7)
    const calculatedChecksum = this.calculateYTunnusChecksum(idNumbers)
    return calculatedChecksum === givenChecksum
  }

  public isValidVatNumber(vatNumber: string): boolean {
    if (!this.VAT_NUMBER_REGEX.test(vatNumber)) {
      return false
    }
    const vatAsBusinessId = `${vatNumber.substring(2, 9)}-${vatNumber.substring(9, 10)}`

    return this.isValidYTunnus(vatAsBusinessId)
  }

  private calculateYTunnusChecksum(idNumbers: string): number {
    let sum = 0
    for (let i = 0; i < idNumbers.length; i++) {
      sum += parseInt(idNumbers[i], 10) * this.MULTIPLIERS[i]
    }
    let remainder = sum % 11
    if (remainder === 1) {
      return -1
    } else if (remainder > 1) {
      remainder = 11 - remainder
    }
    return remainder
  }

  /**
   * Muuttaa suomalaisen y-tunnuksen VAT tunnukseksi.
   * @param ytunnus
   * @returns
   */
  annaVatTunnus(ytunnus: string): string {
    if (!this.isValidYTunnus(ytunnus)) {
      return ytunnus
    }
    const trimmed = ytunnus.replace(/-/g, '')
    if (
      trimmed.startsWith('1') ||
      trimmed.startsWith('2') ||
      trimmed.startsWith('3') ||
      trimmed.startsWith('4') ||
      trimmed.startsWith('5') ||
      trimmed.startsWith('6') ||
      trimmed.startsWith('7') ||
      trimmed.startsWith('8') ||
      trimmed.startsWith('9') ||
      trimmed.startsWith('0')
    ) {
      return 'FI' + trimmed
    }
    return trimmed
  }

  annaYTunnus(vatTunnus: string): string {
    if (vatTunnus?.toUpperCase().startsWith('FI')) {
      const ilmanEtuliitetta = vatTunnus.substring(2)
      if (ilmanEtuliitetta.length === 8) {
        return ilmanEtuliitetta.substring(0, 7) + '-' + ilmanEtuliitetta.substring(7)
      }
      return ilmanEtuliitetta
    }
    return vatTunnus
  }

}
