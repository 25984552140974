import { Component, Inject, OnInit, OnDestroy, AfterContentChecked, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { Laskuasetukset } from '../../_jaettu/model/lasku'
import { BicService } from '../../_shared-core/service/bic.service'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { Maa, MaaService } from '../../_jaettu-angular/service/maa.service'

import { Subject, Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { WindowSizeService } from 'app/_jaettu-angular/service/window.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'

@Component({
  templateUrl: './lasku-taydenna-asetukset.dialog.html'
})
export class LaskuTaydennaAsetuksetDialog implements OnInit, OnDestroy, AfterContentChecked {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  yrityksenTiedotForm: UntypedFormGroup = null
  yrityksenTiedotErrors: FieldErrors = {}
  yhteystiedotForm: UntypedFormGroup = null
  yhteystiedotErrors: FieldErrors = {}
  // ibanVihje = ''
  mobiili = false
  maat: Observable<Maa[]>

  private _asetukset: Laskuasetukset = null

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { mobiili: boolean },
    private _dialogRef: MatDialogRef<LaskuTaydennaAsetuksetDialog>,
    private _validationService: FormValidationService,
    private _laskuService: LaskuService,
    private _bicService: BicService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _maaService: MaaService,
    private _stringService: StringService,
    private _windowSizeService: WindowSizeService,
    private _ibanService: IbanService
  ) {

    this._windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.mobiili = window.innerWidth < 670 || window.innerHeight < 670
    })

    this.maat = this._maaService.kaikkiMaatObservable
    this.mobiili = this.data.mobiili ? true : false

    // Luo yrityksen tiedot lomake
    this.yrityksenTiedotForm = new UntypedFormGroup({
      'nimi': new UntypedFormControl('', [Validators.required]),
      'ytunnus': new UntypedFormControl('', [Validators.required]),
      'katuosoite': new UntypedFormControl('', [Validators.required]),
      'postitoimipaikka': new UntypedFormControl('', [Validators.required]),
      'postinro': new UntypedFormControl('', [Validators.required]),
      'maa': new UntypedFormControl('', [Validators.required])
    })

    // Luo yrityksen tiedot lomake
    this.yhteystiedotForm = new UntypedFormGroup({
      'iban': new UntypedFormControl('', [Validators.required, LemonaidValidators.ibanValidator]),
      'pankki': new UntypedFormControl('', [Validators.required]),
      'bic': new UntypedFormControl('', [Validators.required, LemonaidValidators.bicValidator]),
      'aspaemail': new UntypedFormControl('', [Validators.required]),
      'aspapuh': new UntypedFormControl('', [])
    })

    // Yrityksen tiedot
    this.yrityksenTiedotForm.get('nimi').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.nimi = value })
    this.yrityksenTiedotForm.get('ytunnus').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.ytunnus = value })
    this.yrityksenTiedotForm.get('katuosoite').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.katuosoite = value })
    this.yrityksenTiedotForm.get('postitoimipaikka').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.postitmp = value })
    this.yrityksenTiedotForm.get('postinro').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.postinro = value })
    this.yrityksenTiedotForm.get('maa').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.maakoodi = value })

    // Yhteystiedot
    this.yhteystiedotForm.get('iban').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._asetukset.iban = this._stringService.removeAllWhiteSpaces(value) || null
      const pankinNimi = this._bicService.annaPankinNimi(this._asetukset.iban)
      const bic = this._bicService.annaBicKoodi(this._asetukset.iban)

      // let pankki = ''
      if (bic) {
        this.yhteystiedotForm.get('bic').setValue(bic)
        // pankki += 'BIC: ' + bic
      }
      if (pankinNimi) {
        this.yhteystiedotForm.get('pankki').setValue(bic)
        // pankki += ' (' + pankinNimi + ')'
      }
      // this.ibanVihje = pankki.trim()
    })

    this.yhteystiedotForm.get('pankki').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._asetukset.pankki = value?.trim() || null
    })

    this.yhteystiedotForm.get('bic').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._asetukset.bic = this._stringService.removeAllWhiteSpaces(value) || null
    })

    this.yhteystiedotForm.get('aspaemail').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.replyto = value })
    this.yhteystiedotForm.get('aspapuh').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this._asetukset.puhelin = value })


    // Load data
    this._laskuService.asetuksetObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asetukset => {

      if (asetukset) {
        this._asetukset = asetukset
      } else {
        this._asetukset = {
          avain: null,
          nimi: null,
          ytunnus: null,
          katuosoite: null,
          postitmp: null,
          postinro: null,
          maakoodi: 'FIN',
          date: null,
          replyto: null,
          puhelin: null,
          bic: null,
          iban: null,
          pankki: null,
          base64Logo: null,
          ohjeet: null,
          slogan: null,
          piilotaYrityksenNimiLaskulla: false,
          summahuomautusPiilotettu: false,
          muistutusSpostiasetukset: {},
          spostiasetukset: {},
          tervetuloaNahty: false
        }
      }

      this._asetukset.tervetuloaNahty = true

      // Yrityksen tiedot
      this.yrityksenTiedotForm.get('nimi').setValue(this._asetukset.nimi)
      this.yrityksenTiedotForm.get('ytunnus').setValue(this._asetukset.ytunnus)
      this.yrityksenTiedotForm.get('katuosoite').setValue(this._asetukset.katuosoite)
      this.yrityksenTiedotForm.get('postitoimipaikka').setValue(this._asetukset.postitmp)
      this.yrityksenTiedotForm.get('postinro').setValue(this._asetukset.postinro)
      this.yrityksenTiedotForm.get('maa').setValue(this._asetukset.maakoodi)

      // Pankkiyhteys
      const trimmed = this._stringService.removeAllWhiteSpaces(this._asetukset.iban || '')
      this.yhteystiedotForm.get('iban').setValue(this._ibanService.formatoiIban(trimmed))
      this.yhteystiedotForm.get('bic').setValue(this._asetukset.bic)
      this.yhteystiedotForm.get('pankki').setValue(this._asetukset.pankki)

      this.yhteystiedotForm.get('aspaemail').setValue(this._asetukset.replyto)
      this.yhteystiedotForm.get('aspapuh').setValue(this._asetukset.puhelin)

    })

  }

  ngAfterContentChecked() {
    this.yrityksenTiedotErrors = this._validationService.updateValidationStatus('laskuasetukset', this.yrityksenTiedotForm, true)
    this.yhteystiedotErrors = this._validationService.updateValidationStatus('laskuasetukset', this.yhteystiedotForm, true)
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  tallenna() {
    this._ladataanService.aloitaLataaminen()
    this._laskuService.updateLaskuasetukset(this._asetukset).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close('onnistui')
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

}
