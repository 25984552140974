<div class="detail-page">

  <div class="otsake-container">

    <button class="sulje light-blue-text" mat-button (click)="peruuta()" type="button">
      <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
    </button>

    <div class="otsake">
      <div>
        <span>{{'kuitit.katsele.tosite' | translate | async}}</span>
        <h2 class="light-blue-text">{{otsakeObservable | async}}</h2>
      </div>
      <!-- <div *ngIf="naytaTavallinenOtsake()">
        <span>{{'lasku.katsele-muokattava-dialog.lasku' | translate | async}}</span>
        <h2 class="light-blue-text">{{laskuSharedService.annaMuotoiltuLaskunumero(kasiteltava)}} {{kasiteltava?.asiakas?.nimi}}</h2>
      </div>
      <div *ngIf="naytaMaksuaikaa()">
        <span>{{'lasku.katsele-muokattava-dialog.maksuaikaa' | translate | async}}</span>
        <h2 [class.light-blue-text]="annaMaksuaikaa() > -1" [class.red-text]="annaMaksuaikaa() < 0">{{annaMaksuaikaa()}} pv</h2>
      </div> -->
      <div *ngIf="showSumma">
        <span>{{'kuitit.katsele.summa' | translate | async}}</span>
        <h2 class="blue-text">{{tosite?.sum ?? tosite?.summa | lmoney: 'EUR' | async}}</h2>
      </div>
    </div>

  </div>

  <div class="nappula-container">
    <div class="nappulat">

      <button *ngIf="tosite?.einvoiceApprovalStatus === 'pending-approval'" mat-button class="primarybutton" (click)="approveInvoice(tosite)">{{ 'tositteet.laskujen-hyvaksyminen.hyvaksy' | translate | async }}</button>
      <button *ngIf="tosite?.einvoiceApprovalStatus === 'pending-approval'" mat-button class="primarybutton" (click)="rejectInvoice(tosite)">{{ 'tositteet.laskujen-hyvaksyminen.hylkaa' | translate | async }}</button>

      <button mat-button *ngIf="!tosite?.x" class="secondarybutton" (click)="lataaKuitti()">{{ 'kuitit.katsele.lataa' | translate | async }}</button>
      <button mat-button class="secondarybutton" (click)="muokkaa()">{{ 'yleiset.muokkaa' | translate | async }}</button>

      <button *ngIf="!tosite?.x && tosite?.type !== 'einvoice'" mat-button class="secondarybutton" (click)="poista()">{{'kuitit.katsele.poista' | translate | async}}</button>

    </div>
  </div>

  <div class="dark-gray-text" style="margin-bottom: 20px;" *ngIf="!!einvoiceApprovals.length">
    <div>{{ 'tositteet.laskujen-hyvaksyminen.verkkolaskun-hyvaksynyt' | translate | async }}: </div>
    <div style="padding-left: 20px;" *ngFor="let approval of einvoiceApprovals">
      {{ approval }}
    </div>
  </div>

  <div class="dark-gray-text" style="padding-bottom: 15px;" *ngIf="tosite?.x && tosite?.w">{{tosite.w}}</div>
  <div class="el-kommentti" *ngIf="(!tosite?.x || !tosite?.w) && (tosite?.el || tosite?.seller)">{{tosite.el || tosite.seller}}</div>
  <div>{{tosite?.selite}}</div>
  <div class="selv-kommentti" *ngIf="tosite?.x && tosite?.m">{{tosite.m}}</div>
  <div *ngIf="tosite.userApiMessage" class="tosite-api-msg">{{tosite.userApiMessage}}</div>

  <div *ngIf="tosite?.alvVahennysoikeus !== null">{{'kuitit.katsele.alv' | translate | async}} {{ tosite?.alvVahennysoikeus }} %</div>

  <!-- <div *ngIf="alkuperaiset" class="alkuperaiset-container">
    <div *ngFor="let alkuperainen of alkuperaiset">
      {{alkuperainen.nimi}}
      <button mat-button class="secondarybutton" (click)="lataaAlkuperainen(alkuperainen)">
        <span>Lataa tiedosto</span>
      </button>
    </div>
  </div> -->

  <div class="tosite-sivut">
    <div *ngFor="let kuva of kuvat">
      <div app-tosite-kuva [url]="kuva" class="mat-elevation-z4" style="width: 100%; min-height: 100px; margin-top: 15px; overflow-y: hidden; position: relative; border-radius: 2px;"></div>
    </div>
  </div>

</div>