<div class="all">

  <div class="sisalto" *ngIf="aktiivinenValilehti == 0">
    <div class="containerii" style="margin-top: 2em;">

      <div class="keskitettava">

        <div class="light-blue-text otsikko" style="margin-top: 0;">{{ 'lasku.laheta-comp.email.otsikko' | translate | async }}</div>

        <div class="light-gray-text ohjeteksti">
          {{ (onkoMuistutus ? 'lasku.laheta-comp.email.info-muistutus' : 'lasku.laheta-comp.email.info-tavallinen') | translate | async }}
        </div>

        <div *ngIf="onkoMuistutus" style="padding-bottom: 1em;">
          <form [formGroup]="kuluForm">
            <div style="display: flex; flex-flow: row wrap; justify-content: left; align-items: baseline; max-width: 400px; margin-left: auto; margin-right: auto;">
              <mat-form-field style="width: 42%;">
                <mat-label>{{ 'lasku.sposti-dialog.huomautuskulu' | translate | async }}</mat-label>
                <input decimalNumberField matInput formControlName="huomautuskulu">
                <mat-error>{{fieldErrors.huomautuskulu}}</mat-error>
              </mat-form-field>
              <mat-checkbox style="margin-left: auto;" formControlName="viivastyskorko">{{'lasku.maksumuistutus.lisaa-korko' | translate | async}} ({{korko | lmoney: data.kasiteltava?.valuutta | async}})</mat-checkbox>
            </div>
          </form>
        </div>

        <form [formGroup]="emailForm" novalidate style="margin-top: 1em;">
          <div style="padding-bottom: 0.5em; min-height: 55px;">
            <mat-chip-grid #vastaanottajaChips style="margin-bottom: 0.5em">
              <mat-chip-option *ngFor="let vastaanottaja of email.vastaanottajat" [selectable]="false" [removable]="true" (removed)="poistaAsiakas(vastaanottaja)">
                {{vastaanottaja}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-grid>
          </div>
          <mat-form-field>
            <mat-label>{{ 'lasku.sposti-dialog.lisaavastaanottaja' | translate | async }}</mat-label>
            <input type="email" [name]="namename" autocomplete="kirjanpitotietoarkki" [matChipInputFor]="vastaanottajaChips" cdkFocusRegionstart (blur)="lisaa()" formControlName="lisaavastaanottaja" (matChipInputTokenEnd)="lisaa()" matInput data-lpignore="true" />
            <mat-error>{{fieldErrors.lisaavastaanottaja}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'lasku.sposti-dialog.aihe' | translate | async }}</mat-label>
            <input #aihe type="text" [name]="namename" formControlName="aihe" matInput required data-lpignore="true" />
            <mat-error>{{fieldErrors.aihe}}</mat-error>
          </mat-form-field>

          <mat-form-field style="margin-top: 20px;">
            <mat-label>{{ 'lasku.sposti-dialog.otsikko' | translate | async }}</mat-label>
            <input type="text" [name]="namename" formControlName="otsikko" matInput required data-lpignore="true" />
            <mat-error>{{fieldErrors.otsikko}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'lasku.sposti-dialog.teksti' | translate | async }}</mat-label>
            <textarea rows="2" [name]="namename" formControlName="teksti" matInput cdkTextareaAutosize required data-lpignore="true"></textarea>
            <mat-error>{{fieldErrors.teksti}}</mat-error>
          </mat-form-field>
        </form>

        <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 1em; margin-bottom: 1em;">
          {{'lasku.validation.asetukset.oletuksia-sposti' | translate | async}}
        </div>

        <div style="display: flex; align-items: center; justify-content: center; margin: 2em 1em 2em;">
          <button class="primarybutton" mat-button (click)="send()" [disabled]="!emailForm.valid">{{'lasku.sposti-dialog.laheta' | translate | async}}</button>
        </div>
      </div>
      <div class="keskitettava">
        <div style="margin: 0; font-size: 15px;" class="light-blue-text">{{'lasku.sposti-dialog.esikatselu' | translate | async}}</div>
        <lasku-email-esikatselu-perinteinen [tiedot]="esikatselutiedot | async"></lasku-email-esikatselu-perinteinen>
      </div>
    </div>
  </div>

  <div class="sisalto" *ngIf="aktiivinenValilehti == 1">

    <div class="containerii">

      <div class="keskitettava">

        <div class="light-blue-text otsikko">{{ 'lasku.laheta-comp.sahkoinen.otsikko' | translate | async }}</div>

        <div class="light-gray-text ohjeteksti">
          {{ (onkoMuistutus ? 'lasku.laheta-comp.sahkoinen.info-muistutus' : 'lasku.laheta-comp.sahkoinen.info-tavallinen') | translate | async }}
        </div>

        <div *ngIf="onkoMuistutus" style="padding-bottom: 1em;">
          <form [formGroup]="kuluForm">
            <div style="display: flex; flex-flow: row wrap; justify-content: left; align-items: baseline; max-width: 400px; margin-left: auto; margin-right: auto;">
              <mat-form-field style="width: 42%;">
                <mat-label>{{ 'lasku.sposti-dialog.huomautuskulu' | translate | async }}</mat-label>
                <input decimalNumberField matInput formControlName="huomautuskulu">
                <mat-error>{{fieldErrors.huomautuskulu}}</mat-error>
              </mat-form-field>
              <mat-checkbox style="margin-left: auto;" formControlName="viivastyskorko">{{'lasku.maksumuistutus.lisaa-korko' | translate | async}} ({{korko | lmoney: data.kasiteltava?.valuutta | async}})</mat-checkbox>
            </div>
          </form>
        </div>

        <form [formGroup]="sahkoinenForm" novalidate style="margin-top: 1em; max-width: 400px; margin-left: auto; margin-right: auto;">

          <mat-form-field>
            <mat-label>{{ 'lasku.sposti-dialog.verkkolasku.valittajatunnus' | translate | async }}</mat-label>
            <mat-select formControlName="valittaja" required>
              <mat-option *ngFor="let operator of verkkolaskuoperaattorit" [value]="operator.tunnus">
                {{ operator.nimi }} ({{ operator.tunnus }})
              </mat-option>
            </mat-select>
            <mat-error *ngIf="valittajaControl?.errors?.required && (valittajaControl?.dirty || valittajaControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.valittaja.required' | translate | async}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'lasku.sposti-dialog.verkkolasku.osoite' | translate | async }}</mat-label>
            <input type="text" [name]="namename" formControlName="osoite" matInput required data-lpignore="true" />
            <mat-hint *ngIf="peppolValidationObservable | async; let peppolMessage">{{ peppolMessage.message }}</mat-hint>
            <mat-error *ngIf="osoiteControl?.errors?.minlength && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.minlength' | translate | async}}</mat-error>
            <mat-error *ngIf="osoiteControl?.errors?.required && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.required' | translate | async}}</mat-error>
            <mat-error *ngIf="osoiteControl?.errors?.valittaja && (osoiteControl?.dirty || osoiteControl?.touched)">{{'lasku.laheta-comp.sahkoinen.validation.osoite.valittaja' | translate | async}}</mat-error>
          </mat-form-field>

          <!-- <div style="margin-top: 4em;">
            <div>Verkkolaskuosoite hukassa?</div>
            <mat-form-field> -->
          <!-- 'lasku.sposti-dialog.verkkolasku.ytunnus' | translate | async -->
          <!-- <mat-label>{{ 'Etsi y-tunnuksella' }}</mat-label>
<input type="text" formControlName="ytunnus"  matInput />
              <mat-hint>{{osoitehaunHintTeksti}}</mat-hint>
              <mat-error>{{fieldErrors.ytunnus}}</mat-error>
            </mat-form-field>
            <div *ngIf="osoitehaunTulokset">
              <div>Löytyneet verkkolaskuosoitteet</div>
              <div *ngFor="let tulos of osoitehaunTulokset">
                <div [innerHtml]="tulos | ljson"></div>
              </div>
            </div>
          </div> -->

        </form>

        <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 1em; margin-bottom: 1em;">
          {{'lasku.validation.asetukset.oletuksia-sposti' | translate | async}}
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin: 2em 1em 2em;">
          <button class="primarybutton" mat-button (click)="lahetaSahkoinen()">{{'lasku.sposti-dialog.laheta' | translate | async}}</button>
        </div>

      </div>
    </div>
  </div>

  <div class="sisalto" *ngIf="aktiivinenValilehti == 2">

    <div class="containerii">

      <div class="keskitettava">

        <div class="light-blue-text otsikko">{{ 'lasku.laheta-comp.itse.otsikko' | translate | async }}</div>

        <div class="light-gray-text ohjeteksti">
          {{ (onkoMuistutus ? 'lasku.laheta-comp.itse.info-muistutus' : 'lasku.laheta-comp.itse.info-tavallinen') | translate | async }}
        </div>

        <div *ngIf="onkoMuistutus" style="padding-bottom: 1em;">
          <form [formGroup]="kuluForm">
            <div style="display: flex; flex-flow: row wrap; justify-content: left; align-items: baseline; max-width: 400px; margin-left: auto; margin-right: auto;">
              <mat-form-field style="width: 42%;">
                <mat-label>{{ 'lasku.sposti-dialog.huomautuskulu' | translate | async }}</mat-label>
                <input decimalNumberField matInput formControlName="huomautuskulu">
                <mat-error>{{fieldErrors.huomautuskulu}}</mat-error>
              </mat-form-field>
              <mat-checkbox style="margin-left: auto;" formControlName="viivastyskorko">{{'lasku.maksumuistutus.lisaa-korko' | translate | async}} ({{korko | lmoney: data.kasiteltava?.valuutta | async}})</mat-checkbox>
            </div>
          </form>
        </div>
        <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 1em; margin-bottom: 1em; width: 100%; text-align: center;">
          {{'lasku.validation.asetukset.oletuksia-sposti' | translate | async}}
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin: 2em 1em 2em;">
          <button class="primarybutton" mat-button (click)="saveAndPrint()" type="button">
            {{'lasku.sposti-dialog.itse.lataa' | translate | async}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="sisalto" *ngIf="aktiivinenValilehti == 3">
    Coming...
  </div>

</div>