import { Component, OnInit, Inject, ErrorHandler, OnDestroy, HostListener, EventEmitter, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { FirestoreTosite } from 'app/_jaettu/model/tosite'
import { TositeKopioija } from 'app/_jaettu/service/tosite/tosite.kopioija'
import { DateService } from 'app/_shared-core/service/date.service'
import { TositeService } from 'app/_angular/service/tosite/tosite.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { KirjanpidonPeruste } from 'app/_jaettu/model/kayttaja'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { MaksutService } from 'app/_jaettu/service/maksut.service'

interface MaksettuMuuallaFormGroup {
  tositteenPvm: FormControl<Date>
}

export interface MaksutMaksupaivaDialogData {
  kuitti: FirestoreTosite
  kirjanpidonPeruste: KirjanpidonPeruste
}

@Component({
  templateUrl: './maksut-maksupaiva.dialog.html'
})
export class MaksutMaksupaivaDialog implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  voidaanMitatoida = false

  form: FormGroup<MaksettuMuuallaFormGroup> = null
  tositteenPvm: FormControl<Date> = null

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2010, 0, 1)

  private _kuitti: FirestoreTosite = null

  namename = 'poiuyhgbvcdertyuj' + Math.random()

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MaksutMaksupaivaDialogData,
    private _dialogRef: MatDialogRef<MaksutMaksupaivaDialog>,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _tositeKopioija: TositeKopioija,
    private _dateService: DateService,
    private _tositeService: TositeService,
    private _formValidationService: FormValidationService,
    private _timestampService: TimestampService,
    private _maksutService: MaksutService
  ) {
    this._kuitti = this._tositeKopioija.kopioiTosite(data.kuitti)
    this.maxDate = this._dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this._dateService.lisaaKuukausia(new Date(), -24)
  }

  ngOnInit() {
    this.tositteenPvm = new FormControl<Date>(null, [Validators.required])

    this.form = new FormGroup<MaksettuMuuallaFormGroup>({
      tositteenPvm: this.tositteenPvm
    })

    this.tositteenPvm.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._kuitti.paymentDate = this._dateService.dateToNumber(value)
    })

    this.tositteenPvm.setValue(new Date())

  }

  @HostListener('document:keydown.enter')
  merkitseMaksetuksi() {
    this.tallenna()
  }

  private _saveInFlight = false
  async tallenna() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    this._ladataanService.aloitaLataaminen()
    try {
      if (this.data.kirjanpidonPeruste === KirjanpidonPeruste.MAKSU) {
        const paymentDateLocal = this._dateService.numberToLocalDate(this._kuitti.paymentDate)
        const paymentDateTs = this._timestampService.localDateToTimestamp(paymentDateLocal)

        this._kuitti.p = this._kuitti.paymentDate
        this._kuitti.pvm = paymentDateTs
        this._kuitti.localPvm = paymentDateLocal
      }
      await this._tositeService.tallennaKuitti(this._kuitti)
      this._dialogRef.close()
    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._saveInFlight = false
      this._ladataanService.lopetaLataaminen()
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  kaytaErapaivaa() {
    this._kuitti.paymentDate = this._kuitti.dueDate
    this.tositteenPvm.setValue(this._dateService.numberToDate(this._kuitti.paymentDate))
  }

}
