import { DateService } from '../../../_shared-core/service/date.service'
import { KopioijaPalvelu } from '../kopioija.service'
import { LaskuSharedService } from './lasku-shared.service'

import {
  TuoteAvaimet, LaskunTyypit, UUDEN_LASKUN_AVAIN,
  LaskuBase, LaskunTila, Lasku, LaskunumeroTyyppi,
  Laskuasetukset, LaskunTuote, LaskunAsiakas, AsiakkaanTuote,
  AsiakkaanTuoteLaskussa, AsiakkaanTuoteWithoutKey, LaskunAlv,
  LaskunLiitetiedosto,
  LaskunLahetystyyppi
} from '../../model/lasku'

import { Timestamp } from '../../../_shared-core/model/common'

import { TimestampProviderBase } from '../../../_shared-core/service/timestamp-provider.interface'
import { LaskuKorkoService } from './lasku-korko.service'

export interface OssProvider {
  onkoLaskunLuonutAsiakasOssRekisterissa: () => Promise<boolean>
}

export class LaskuKopioija {

  constructor(
    private dateService: DateService,
    private timestampService: TimestampProviderBase,
    private kopioijaPalvelu: KopioijaPalvelu,
    private shared: LaskuSharedService,
    private _korkoService: LaskuKorkoService
  ) { }

  private valmisteleUusiLasku(lasku: LaskuBase): LaskuBase {
    lasku.lukossa = false
    lasku.email = null
    lasku.print = null
    lasku.nro = null
    lasku.avain = UUDEN_LASKUN_AVAIN
    return lasku
  }

  public annaUusiLasku(): Lasku {
    const juurilasku: Lasku = {
      avain: null,
      asiakas: null,
      nro: null,
      pvm: null,
      pvml: null,
      p: null,
      erapvm: null,
      erapvml: null,
      erap: null,
      toimituspvm: null,
      toimituspvml: null,
      toimitusp: null,
      viitteenne: null,
      lisatiedot: null,
      tyyppi: null,
      nrotyyppi: LaskunumeroTyyppi.TAVALLINEN,
      kieli: 'fi',
      valuutta: null,
      tuotteet: null,
      date: null,
      lukossa: true,
      email: null,
      print: null,
      sahkoinen: null,
      avoinna: 0,
      avoinnaRypas: 0,
      summa: 0,
      summaAlv0: 0,
      summaRypas: 0,
      summaRypasAlv0: 0,
      summaHyvityksetLaskunPvm: 0,
      summaReskontraLaskunPvm: 0,
      tila: LaskunTila.avoin,
      korvaus: [],
      // reskontra: [],
      liitteet: [],
      lahetystyyppi: LaskunLahetystyyppi.KERTA,
      viivastyskorkoprosentti: this._korkoService.annaOletusViivastyskorkoProsenttiNumber(),
      perintatiedot: null,
      asetukset: null
    }

    this.valmisteleUusiLasku(juurilasku)

    // Defaults
    const pvm = this.timestampService.now()
    const erapvm = this.timestampService.lisaaPaiviaTimestamp(pvm, 14)

    const localNow = this.dateService.timestampToLocalDate(pvm)
    const localEraNow = this.dateService.timestampToLocalDate(erapvm)

    juurilasku.toimituspvm = pvm
    juurilasku.toimituspvml = localNow
    juurilasku.toimitusp = this.dateService.localDateToNumber(localNow)

    juurilasku.date = pvm

    juurilasku.pvm = pvm
    juurilasku.pvml = localNow
    juurilasku.p = this.dateService.localDateToNumber(localNow)

    juurilasku.erapvm = erapvm
    juurilasku.erapvml = localEraNow
    juurilasku.erap = this.dateService.localDateToNumber(localEraNow)

    juurilasku.valuutta = 'EUR'
    juurilasku.tyyppi = LaskunTyypit.TAVALLINEN.avain
    juurilasku.kieli = 'fi'

    juurilasku.asiakas = this.copyAsiakas(this.annaUusiAsiakas())
    juurilasku.tuotteet = []

    return juurilasku
  }

  public annaUusiTuote(): AsiakkaanTuote {
    return {
      $key: null,
      nimi: '',
      hinta: 0.0,
      date: null,
      alv: {}
    }
  }

  public annaUusiAsiakas(): LaskunAsiakas {
    return {
      avain: null,
      nimi: null,
      ytunnus: null,
      katuosoite: null,
      postitmp: null,
      postinro: null,
      maa: 'FIN',
      date: null,
      laskunVastaanottajat: [],
      laskunKieli: null,
      laskunTyyppi: null,
      laskunValuutta: null,
      sahkoinenosoite: {
        sahkoinenOsoite: null,
        sahkoinenValittaja: null
      },
      asiakastyyppi: null,
      viimeisinLaskuLahetetty: null
    }
  }

  private paivitaLaskunPaivamaara(lasku: LaskuBase): Timestamp {
    // Asetetaan laskun päivämääräksi nykyinen
    const nytPvm = this.timestampService.now()
    const asLocalDate = this.dateService.timestampToLocalDate(nytPvm)

    lasku.pvm = nytPvm
    lasku.pvml = asLocalDate
    lasku.p = this.dateService.localDateToNumber(asLocalDate)

    return nytPvm
  }

  paivitaLaskunPaivamaaraJaErapaiva(lasku: LaskuBase) {

    // Lasketaan paljonko on maksuaikaa
    const maksuaikaa = this.shared.laskeMaksuaikaa(lasku)
    this.paivitaLaskunPaivamaaraJaErapaivaMaaralla(lasku, maksuaikaa)

  }

  private paivitaLaskunPaivamaaraJaErapaivaMaaralla(lasku: LaskuBase, maksuaikaa: number) {

    // Asetetaan laskun päivämääräksi nykyinen
    const nytPvm = this.paivitaLaskunPaivamaara(lasku)

    // Siirretään eräpäivää eteenpäin jos tarvis
    const erapaivaUusi = this.timestampService.lisaaPaiviaTimestamp(nytPvm, maksuaikaa)

    lasku.erapvm = erapaivaUusi
    lasku.erapvml = this.dateService.timestampToLocalDate(lasku.erapvm)
    lasku.erap = this.dateService.localDateToNumber(lasku.erapvml)

  }

  private kopioiMuokattavaksiIlmanPaivamaarienKorjauksia(lasku: LaskuBase): LaskuBase {
    const kopioitu = this.copyLaskuBase(lasku)
    const valmisteltu = this.valmisteleUusiLasku(kopioitu)

    valmisteltu.date = this.timestampService.now()

    // Poista huomautuskulu ja korko
    if (valmisteltu.tuotteet) {
      valmisteltu.tuotteet = valmisteltu.tuotteet.filter(laskunTuote => {
        return !laskunTuote.tuote || (laskunTuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && laskunTuote.tuote.avain !== TuoteAvaimet.KORKOKULU)
      })
    }

    return valmisteltu
  }

  private kopioiMuokattavaksi(lasku: LaskuBase): LaskuBase {
    const valmisteltu = this.kopioiMuokattavaksiIlmanPaivamaarienKorjauksia(lasku)
    this.paivitaLaskunPaivamaaraJaErapaiva(valmisteltu)
    return valmisteltu
  }

  public kopioiEiLahetettyMuokattavaksi(juurilasku: Lasku, kasiteltava: LaskuBase, laskunAsiakas: LaskunAsiakas): { muokattavaJuuri: Lasku, muokattavaKasiteltava: LaskuBase } {
    const muokattavaksiJuuri = this.copyLasku(juurilasku)
    const muokattavaksiKasiteltava = this.shared.annaKasiteltavaLasku(muokattavaksiJuuri, kasiteltava.avain)
    this.paivitaAsiakkaanTiedotKasiteltavassa(muokattavaksiKasiteltava, laskunAsiakas)
    this.paivitaLaskunPaivamaaraJaErapaiva(muokattavaksiKasiteltava)
    return {
      muokattavaJuuri: muokattavaksiJuuri,
      muokattavaKasiteltava: muokattavaksiKasiteltava
    }
  }

  public kopioiLaskuMuokattavaksi(lasku: LaskuBase, laskunAsiakas: LaskunAsiakas): LaskuBase {
    const kopioitu = this.kopioiMuokattavaksi(lasku)
    kopioitu.nrotyyppi = LaskunumeroTyyppi.KORJAUS

    this.paivitaAsiakkaanTiedotKasiteltavassa(kopioitu, laskunAsiakas)

    return kopioitu
  }

  public kopioiLaskuHyvitettavaksi(lasku: LaskuBase, laskunAsiakas: LaskunAsiakas): LaskuBase {
    const kopioitu = this.kopioiLaskuMuokattavaksi(lasku, laskunAsiakas)

    if (kopioitu.tuotteet) {
      for (const tuote of kopioitu.tuotteet) {
        tuote.maara = 0 - tuote.maara
      }
    }

    this.paivitaAsiakkaanTiedotKasiteltavassa(kopioitu, laskunAsiakas)

    kopioitu.nrotyyppi = LaskunumeroTyyppi.HYVITYS
    kopioitu.liitteet = []

    return kopioitu
  }

  public async kopioiUusiLasku(
    lasku: LaskuBase,
    laskunAsiakas: LaskunAsiakas,
    ossProvider: OssProvider
  ): Promise<Lasku> {
    const uusi = this.annaUusiLasku()

    uusi.kieli = lasku.kieli
    uusi.lisatiedot = lasku.lisatiedot
    uusi.tuotteet = lasku.tuotteet
    uusi.tyyppi = lasku.tyyppi
    uusi.valuutta = lasku.valuutta
    uusi.viitteenne = lasku.viitteenne
    uusi.asetukset = lasku.asetukset

    const kopioitu = this.copyLasku(uusi)

    if (kopioitu.tyyppi === LaskunTyypit.EU_KULUTTAJA.avain) {
      const ossRekisterissa = await ossProvider.onkoLaskunLuonutAsiakasOssRekisterissa()
      if (ossRekisterissa) {
        kopioitu.tyyppi = LaskunTyypit.EU_KULUTTAJA_PALVELU.avain
      } else {
        kopioitu.tyyppi = LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain
      }
    }

    if (kopioitu.tuotteet) {
      kopioitu.tuotteet = kopioitu.tuotteet.filter(tuote => {
        return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU)
      })
    }

    this.paivitaAsiakkaanTiedotKasiteltavassa(kopioitu, laskunAsiakas)

    return kopioitu
  }

  private paivitaAsiakkaanTiedotKasiteltavassa(kasiteltava: LaskuBase, laskunAsiakas: LaskunAsiakas) {
    if (kasiteltava && laskunAsiakas) {

      if (!kasiteltava.asiakas) {
        kasiteltava.asiakas = {} as LaskunAsiakas
      }

      kasiteltava.asiakas.avain = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.avain)
      kasiteltava.asiakas.date = this.timestampService.now()
      kasiteltava.asiakas.katuosoite = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.katuosoite)
      kasiteltava.asiakas.laskunKieli = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.laskunKieli)
      kasiteltava.asiakas.laskunTyyppi = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.laskunTyyppi)
      kasiteltava.asiakas.laskunValuutta = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.laskunValuutta)

      kasiteltava.asiakas.laskunVastaanottajat = []
      if (laskunAsiakas.laskunVastaanottajat) {
        for (const vastaanottaja of laskunAsiakas.laskunVastaanottajat) {
          kasiteltava.asiakas.laskunVastaanottajat.push(vastaanottaja)
        }
      }

      kasiteltava.asiakas.maa = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.maa)
      kasiteltava.asiakas.nimi = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.nimi)
      kasiteltava.asiakas.postinro = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.postinro)
      kasiteltava.asiakas.postitmp = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.postitmp)
      kasiteltava.asiakas.ytunnus = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.ytunnus)
      kasiteltava.asiakas.asiakastyyppi = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.asiakastyyppi)
      kasiteltava.asiakas.viimeisinLaskuLahetetty = this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.viimeisinLaskuLahetetty)

      if (laskunAsiakas.poistettu) {
        kasiteltava.asiakas.poistettu = true
      } else {
        delete kasiteltava.asiakas.poistettu
      }

      if (laskunAsiakas.sahkoinenosoite) {
        kasiteltava.asiakas.sahkoinenosoite = {
          sahkoinenOsoite: this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.sahkoinenosoite.sahkoinenOsoite),
          sahkoinenValittaja: this.kopioijaPalvelu.nullTaiArvo(laskunAsiakas.sahkoinenosoite.sahkoinenValittaja)
        }
      } else {
        kasiteltava.asiakas.sahkoinenosoite = null
      }

    }
  }

  public kopioiLaskuMuistutusLaskuksi(
    juurilasku: Lasku,
    lasku: LaskuBase,
    laskunAsiakas: LaskunAsiakas,
    alvt: LaskunAlv[]
  ): { kopioitu: LaskuBase, korkokulu: LaskunTuote, huomautuskulu: LaskunTuote } {

    const edellinenMuistutus = this.shared.annaViimeisinMuistutuslasku(juurilasku)

    let alv: LaskunAlv = {
      prosentti: 0,
      tunniste: 'huomautuskulukorkoalv'
    }

    if (alvt) {
      for (const alvi of alvt) {
        if (alvi.prosentti.toFixed(2) === '0.00') {
          alv = alvi
          break
        }
      }
    }

    const vanhatHuomautuskulut = []
    if (edellinenMuistutus && edellinenMuistutus.tuotteet) {
      for (const vanhaTuote of edellinenMuistutus.tuotteet) {
        if (vanhaTuote.tuote.avain === TuoteAvaimet.HUOMAUTUSKULU) {
          vanhatHuomautuskulut.push(vanhaTuote)
        }
      }
    }

    const kopioitu = this.kopioiMuokattavaksiIlmanPaivamaarienKorjauksia(lasku)
    this.paivitaLaskunPaivamaaraJaErapaivaMaaralla(kopioitu, 5)
    kopioitu.nrotyyppi = LaskunumeroTyyppi.MUISTUTUS

    const korkokulu: LaskunTuote = {
      alv: alv,
      hinta: 0,
      maara: 1,
      ale: 0,
      tuote: {
        avain: TuoteAvaimet.KORKOKULU,
        date: this.timestampService.now(),
        hinta: 0,
        nimi: this.shared.annaLokalisoituMerkkijono('lasku.viivastyskorko-tuote', lasku),
        alv: {}
      }
    }

    const huomautuskulu: LaskunTuote = {
      alv: alv,
      hinta: 0,
      maara: 1,
      ale: 0,
      tuote: {
        avain: TuoteAvaimet.HUOMAUTUSKULU,
        date: this.timestampService.now(),
        hinta: 0,
        nimi: this.shared.annaLokalisoituMerkkijono('lasku.huomautuskulu-tuote', lasku, { numero: vanhatHuomautuskulut.length + 1 }),
        alv: {}
      }
    }

    for (const vanhaHuomautuskulu of vanhatHuomautuskulut) {
      kopioitu.tuotteet.push(vanhaHuomautuskulu)
    }

    kopioitu.tuotteet.push(huomautuskulu)
    kopioitu.tuotteet.push(korkokulu)

    this.paivitaAsiakkaanTiedotKasiteltavassa(kopioitu, laskunAsiakas)

    return {
      huomautuskulu: huomautuskulu,
      kopioitu: kopioitu,
      korkokulu: korkokulu
    }
  }

  public copyAsetukset(asetukset: Laskuasetukset): Laskuasetukset {
    return this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville<Laskuasetukset>(asetukset)
  }

  public copyLasku(lasku: Lasku): Lasku {
    const copied: Lasku = {

      // Base
      avain: this.kopioijaPalvelu.nullTaiArvo(lasku.avain),
      lukossa: this.kopioijaPalvelu.nullTaiArvo(lasku.lukossa),
      asiakas: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.asiakas),
      nro: this.kopioijaPalvelu.nullTaiArvo(lasku.nro),
      nrotyyppi: this.kopioijaPalvelu.nullTaiArvo(lasku.nrotyyppi),
      pvm: this.kopioijaPalvelu.nullTaiArvo(lasku.pvm),
      pvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.pvml),
      p: this.kopioijaPalvelu.nullTaiArvo(lasku.p),
      erapvm: this.kopioijaPalvelu.nullTaiArvo(lasku.erapvm),
      erapvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.erapvml),
      erap: this.kopioijaPalvelu.nullTaiArvo(lasku.erap),
      toimituspvm: this.kopioijaPalvelu.nullTaiArvo(lasku.toimituspvm),
      toimituspvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.toimituspvml),
      toimitusp: this.kopioijaPalvelu.nullTaiArvo(lasku.toimitusp),
      viitteenne: this.kopioijaPalvelu.nullTaiArvo(lasku.viitteenne),
      lisatiedot: this.kopioijaPalvelu.nullTaiArvo(lasku.lisatiedot),
      tyyppi: this.kopioijaPalvelu.nullTaiArvo(lasku.tyyppi),
      kieli: this.kopioijaPalvelu.nullTaiArvo(lasku.kieli),
      valuutta: this.kopioijaPalvelu.nullTaiArvo(lasku.valuutta),
      tuotteet: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.tuotteet),
      date: this.kopioijaPalvelu.nullTaiArvo(lasku.date),
      summa: this.kopioijaPalvelu.nullTaiArvo(lasku.summa),
      summaAlv0: this.kopioijaPalvelu.nullTaiArvo(lasku.summaAlv0),
      summaRypas: this.kopioijaPalvelu.nullTaiArvo(lasku.summaRypas),
      summaRypasAlv0: this.kopioijaPalvelu.nullTaiArvo(lasku.summaRypasAlv0),
      summaHyvityksetLaskunPvm: this.kopioijaPalvelu.nullTaiArvo(lasku.summaHyvityksetLaskunPvm),
      summaReskontraLaskunPvm: this.kopioijaPalvelu.nullTaiArvo(lasku.summaReskontraLaskunPvm),
      avoinna: this.kopioijaPalvelu.nullTaiArvo(lasku.avoinna),
      avoinnaRypas: this.kopioijaPalvelu.nullTaiArvo(lasku.avoinnaRypas),
      email: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.email),
      print: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.print),
      sahkoinen: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.sahkoinen),
      liitteet: this.kopioiLaskunLiitteet(lasku.liitteet),

      // Lasku
      tila: this.kopioijaPalvelu.nullTaiArvo(lasku.tila),
      // reskontra: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.reskontra),
      korvaus: this.copyLaskuBaset(lasku.korvaus),
      viitenumero: this.kopioijaPalvelu.nullTaiArvo(lasku.viitenumero),
      lahetystyyppi: this.kopioijaPalvelu.nullTaiArvo(lasku.lahetystyyppi),
      asetukset: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville<Laskuasetukset>(lasku.asetukset)
    }
    if (lasku.tagTilitapahtumat) {
      copied.tagTilitapahtumat = this.kopioijaPalvelu.cloneArrayDeep(lasku.tagTilitapahtumat)
    }
    if (lasku.ulkoinenLaskunro !== undefined) {
      copied.ulkoinenLaskunro = lasku.ulkoinenLaskunro
    }
    if (lasku.ulkoinenLaskunroLajittelu !== undefined) {
      copied.ulkoinenLaskunroLajittelu = lasku.ulkoinenLaskunroLajittelu
    }
    if (lasku.ulkoinenLaskuHyvitysTaiMuistutus !== undefined) {
      copied.ulkoinenLaskuHyvitysTaiMuistutus = lasku.ulkoinenLaskuHyvitysTaiMuistutus
    }
    if (lasku.kommentti) {
      copied.kommentti = lasku.kommentti
    }
    if (lasku.viivastyskorkoprosentti !== undefined) {
      copied.viivastyskorkoprosentti = lasku.viivastyskorkoprosentti
    }
    if (lasku.eraantynytViestiLahetetty !== undefined) {
      copied.eraantynytViestiLahetetty = lasku.eraantynytViestiLahetetty
    }

    if (lasku.omitFromSearches !== undefined) {
      copied.omitFromSearches = lasku.omitFromSearches
    }
    if (lasku.omitFromStatistics !== undefined) {
      copied.omitFromStatistics = lasku.omitFromStatistics
    }
    if (lasku.source !== undefined) {
      copied.source = lasku.source
    }
    if (lasku.sourceId !== undefined) {
      copied.sourceId = lasku.sourceId
    }

    if (lasku.perintatiedot) {
      copied.perintatiedot = {
        lahetettyPeruutusFrontend: lasku.perintatiedot.lahetettyPeruutusFrontend,
        lahetettyPeruutus: lasku.perintatiedot.lahetettyPeruutus,
        lahetettyFrontend: lasku.perintatiedot.lahetettyFrontend,
        lahetetty: lasku.perintatiedot.lahetetty,
        lahetykset: this.kopioijaPalvelu.cloneDeep(lasku.perintatiedot.lahetykset),
        vastaanottaja: lasku.perintatiedot.vastaanottaja,
        status: lasku.perintatiedot.status,
        success: lasku.perintatiedot.success
      }
    }
    return copied
  }

  private kopioiLaskunLiitteet(liitteet: LaskunLiitetiedosto[]): LaskunLiitetiedosto[] {
    if (liitteet) {
      const uudetLiitteet: LaskunLiitetiedosto[] = []
      for (const lahde of liitteet) {
        uudetLiitteet.push({
          avain: this.kopioijaPalvelu.nullTaiArvo(lahde.avain),
          fileEnding: this.kopioijaPalvelu.nullTaiArvo(lahde.fileEnding),
          koko: this.kopioijaPalvelu.nullTaiArvo(lahde.koko),
          nimi: this.kopioijaPalvelu.nullTaiArvo(lahde.nimi),
          lisatty: this.kopioijaPalvelu.nullTaiArvo(lahde.lisatty),
          lisattyl: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lahde.lisattyl)
        })
      }
      return uudetLiitteet
    }
    return null
  }

  private copyLaskuBaset(baset: LaskuBase[]): LaskuBase[] {
    if (!baset) {
      return null
    }
    const paluuarvo: LaskuBase[] = []
    for (const base of baset) {
      paluuarvo.push(this.copyLaskuBase(base))
    }
    return paluuarvo
  }

  public copyLaskuBase(lasku: LaskuBase): LaskuBase {
    const copied: LaskuBase = {

      // Base
      avain: this.kopioijaPalvelu.nullTaiArvo(lasku.avain),
      lukossa: this.kopioijaPalvelu.nullTaiArvo(lasku.lukossa),
      asiakas: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.asiakas),
      nro: this.kopioijaPalvelu.nullTaiArvo(lasku.nro),
      nrotyyppi: this.kopioijaPalvelu.nullTaiArvo(lasku.nrotyyppi),
      pvm: this.kopioijaPalvelu.nullTaiArvo(lasku.pvm),
      pvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.pvml),
      p: this.kopioijaPalvelu.nullTaiArvo(lasku.p),
      erapvm: this.kopioijaPalvelu.nullTaiArvo(lasku.erapvm),
      erapvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.erapvml),
      erap: this.kopioijaPalvelu.nullTaiArvo(lasku.erap),
      toimituspvm: this.kopioijaPalvelu.nullTaiArvo(lasku.toimituspvm),
      toimituspvml: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.toimituspvml),
      toimitusp: this.kopioijaPalvelu.nullTaiArvo(lasku.toimitusp),
      viitteenne: this.kopioijaPalvelu.nullTaiArvo(lasku.viitteenne),
      lisatiedot: this.kopioijaPalvelu.nullTaiArvo(lasku.lisatiedot),
      tyyppi: this.kopioijaPalvelu.nullTaiArvo(lasku.tyyppi),
      kieli: this.kopioijaPalvelu.nullTaiArvo(lasku.kieli),
      valuutta: this.kopioijaPalvelu.nullTaiArvo(lasku.valuutta),
      tuotteet: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.tuotteet),
      date: this.kopioijaPalvelu.nullTaiArvo(lasku.date),
      summa: this.kopioijaPalvelu.nullTaiArvo(lasku.summa),
      summaAlv0: this.kopioijaPalvelu.nullTaiArvo(lasku.summaAlv0),
      summaHyvityksetLaskunPvm: this.kopioijaPalvelu.nullTaiArvo(lasku.summaHyvityksetLaskunPvm),
      summaReskontraLaskunPvm: this.kopioijaPalvelu.nullTaiArvo(lasku.summaReskontraLaskunPvm),
      avoinna: this.kopioijaPalvelu.nullTaiArvo(lasku.avoinna),
      email: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.email),
      print: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.print),
      sahkoinen: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville(lasku.sahkoinen),
      liitteet: this.kopioiLaskunLiitteet(lasku.liitteet),
      asetukset: this.kopioijaPalvelu.kopioiJaAsetaNullPuuttuville<Laskuasetukset>(lasku.asetukset)
    }

    if (lasku.tagTilitapahtumat) {
      copied.tagTilitapahtumat = this.kopioijaPalvelu.cloneArrayDeep(lasku.tagTilitapahtumat)
    }

    return copied
  }

  getDateOrNull(raw: string): Date | null {
    if (raw) {
      return new Date(raw)
    }
    return null
  }

  // prepareLaskunAsiakas(asiakas: LaskunAsiakasLaskussa): LaskunAsiakasLaskussa {
  //   return {
  //     avain: this.valueOrNull(asiakas.avain),
  //     nimi: this.valueOrNull(asiakas.nimi),
  //     ytunnus: this.valueOrNull(asiakas.ytunnus),
  //     katuosoite: this.valueOrNull(asiakas.katuosoite),
  //     postitmp: this.valueOrNull(asiakas.postitmp),
  //     postinro: this.valueOrNull(asiakas.postinro),
  //     maa: this.valueOrNull(asiakas.maa),
  //     date: this.valueOrNull(asiakas.date),
  //     laskunVastaanottajat: this.valueOrNull(asiakas.laskunVastaanottajat)
  //   }
  // }

  // muunnaLaskunAsiakasAsiakkaaksi(asiakas: LaskunAsiakasLaskussa): LaskunAsiakas {
  //   return {
  //     $key: this.valueOrNull(asiakas.avain),
  //     nimi: this.valueOrNull(asiakas.nimi),
  //     ytunnus: this.valueOrNull(asiakas.ytunnus),
  //     katuosoite: this.valueOrNull(asiakas.katuosoite),
  //     postitmp: this.valueOrNull(asiakas.postitmp),
  //     postinro: this.valueOrNull(asiakas.postinro),
  //     maa: this.valueOrNull(asiakas.maa),
  //     date: this.valueOrNull(asiakas.date),
  //     laskunVastaanottajat: this.valueOrNull(asiakas.laskunVastaanottajat),
  //     laskunValuutta: this.valueOrNull(asiakas.laskunValuutta),
  //     laskunTyyppi: this.valueOrNull(asiakas.laskunTyyppi),
  //     laskunKieli: this.valueOrNull(asiakas.laskunKieli)
  //   }
  // }

  // copyAsiakasLaskuun(asiakas: LaskunAsiakas): LaskunAsiakasLaskussa {
  //   return {
  //     avain: this.valueOrNull(asiakas.$key),
  //     nimi: this.valueOrNull(asiakas.nimi),
  //     ytunnus: this.valueOrNull(asiakas.ytunnus),
  //     katuosoite: this.valueOrNull(asiakas.katuosoite),
  //     postitmp: this.valueOrNull(asiakas.postitmp),
  //     postinro: this.valueOrNull(asiakas.postinro),
  //     maa: this.valueOrNull(asiakas.maa),
  //     date: this.valueOrNull(asiakas.date),
  //     laskunVastaanottajat: this.valueOrNull(asiakas.laskunVastaanottajat),
  //     laskunValuutta: this.valueOrNull(asiakas.laskunValuutta),
  //     laskunTyyppi: this.valueOrNull(asiakas.laskunTyyppi),
  //     laskunKieli: this.valueOrNull(asiakas.laskunKieli)
  //   }
  // }

  public copyAsiakas(asiakas: LaskunAsiakas): LaskunAsiakas {
    const palautettava: LaskunAsiakas = {
      avain: this.valueOrNull(asiakas.avain),
      nimi: this.valueOrNull(asiakas.nimi),
      ytunnus: this.valueOrNull(asiakas.ytunnus),
      katuosoite: this.valueOrNull(asiakas.katuosoite),
      postitmp: this.valueOrNull(asiakas.postitmp),
      postinro: this.valueOrNull(asiakas.postinro),
      maa: this.valueOrNull(asiakas.maa),
      date: this.valueOrNull(asiakas.date),
      laskunVastaanottajat: this.valueOrNull(asiakas.laskunVastaanottajat),
      laskunValuutta: this.valueOrNull(asiakas.laskunValuutta),
      laskunTyyppi: this.valueOrNull(asiakas.laskunTyyppi),
      laskunKieli: this.valueOrNull(asiakas.laskunKieli),
      sahkoinenosoite: this.kopioijaPalvelu.cloneDeep(asiakas.sahkoinenosoite),
      asiakastyyppi: this.valueOrNull(asiakas.asiakastyyppi),
      viimeisinLaskuLahetetty: this.valueOrNull(asiakas.viimeisinLaskuLahetetty)
    }

    if (asiakas.poistettu !== undefined) {
      palautettava.poistettu = asiakas.poistettu
    }

    return palautettava
  }

  // public copyAsiakasMuokattavaksi(asiakas: LaskunAsiakas): LaskunAsiakas {
  //   return {
  //     $key: this.valueOrNull(asiakas.$key),
  //     nimi: this.valueOrNull(asiakas.nimi),
  //     ytunnus: this.valueOrNull(asiakas.ytunnus),
  //     katuosoite: this.valueOrNull(asiakas.katuosoite),
  //     postitmp: this.valueOrNull(asiakas.postitmp),
  //     postinro: this.valueOrNull(asiakas.postinro),
  //     maa: this.valueOrNull(asiakas.maa),
  //     date: this.valueOrNull(asiakas.date),
  //     laskunVastaanottajat: this.valueOrNull(asiakas.laskunVastaanottajat),
  //     laskunValuutta: this.valueOrNull(asiakas.laskunValuutta),
  //     laskunTyyppi: this.valueOrNull(asiakas.laskunTyyppi),
  //     laskunKieli: this.valueOrNull(asiakas.laskunKieli)
  //   }
  // }

  private valueOrNull(value: any): any {
    return value ? value : null
  }

  copyTuoteLaskuun(tuote: AsiakkaanTuote): AsiakkaanTuoteLaskussa {
    if (!tuote) {
      return {
        avain: null,
        nimi: null,
        hinta: null,
        date: null,
        alv: null
      }
    }
    return {
      avain: this.valueOrNull(tuote.$key),
      nimi: this.valueOrNull(tuote.nimi),
      hinta: this.valueOrNull(tuote.hinta),
      date: this.valueOrNull(tuote.date),
      alv: this.valueOrNull(tuote.alv)
    }
  }

  copyTuoteLaskusta(tuote: AsiakkaanTuoteLaskussa): AsiakkaanTuote {
    if (!tuote) {
      return {
        $key: null,
        nimi: null,
        hinta: null,
        date: null,
        alv: null
      }
    }
    return {
      $key: this.valueOrNull(tuote.avain),
      nimi: this.valueOrNull(tuote.nimi),
      hinta: this.valueOrNull(tuote.hinta),
      date: this.valueOrNull(tuote.date),
      alv: this.valueOrNull(tuote.alv)
    }
  }

  public teeAsiakkaanTuoteLaskuntuotteesta(laskunTuote: LaskunTuote): AsiakkaanTuote {
    return {
      $key: laskunTuote.tuote.avain,
      date: laskunTuote.tuote.date,
      hinta: laskunTuote.tuote.hinta,
      nimi: laskunTuote.tuote.nimi,
      alv: laskunTuote.tuote.alv
    }
  }

  public copyTuote(tuote: AsiakkaanTuoteLaskussa | AsiakkaanTuote): AsiakkaanTuoteWithoutKey {
    return {
      nimi: this.valueOrNull(tuote.nimi),
      hinta: this.valueOrNull(tuote.hinta),
      date: this.valueOrNull(tuote.date),
      alv: this.valueOrNull(tuote.alv)
    }
  }

  public copyTuoteMuokattavaksi(tuote: AsiakkaanTuote): AsiakkaanTuote {
    return {
      $key: this.valueOrNull(tuote.$key),
      nimi: this.valueOrNull(tuote.nimi),
      hinta: this.valueOrNull(tuote.hinta),
      date: this.valueOrNull(tuote.date),
      alv: this.valueOrNull(tuote.alv)
    }
  }

  // public getDate(date: Date): string {
  //   return date.toJSON()
  // }

}
